import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { fromCart } from '.';
import { CartEffects } from './cart.effects';

@NgModule({
  imports: [StoreModule.forFeature(fromCart.cartFeatureKey, fromCart.cartReducer), EffectsModule.forFeature([CartEffects])],
})
export class CartStoreModule {}
