import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UploadChangeParam } from '../../../../@pages/components/upload/interface';
import { CLOUD_UPLOAD_DOC_URL, IMAGE_CLOUD_URL } from '../../../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { CartActions, CartSelectors } from '../../../../store/cart';
import { FillCartsByFileProgress } from '../../../../store/cart/cart.actions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { BasketFillByFileAlertComponent } from './basket-fill-by-file-alert/basket-fill-by-file-alert.component';
import { Subject } from 'rxjs';
import { CartFileAddressProcess } from '../../../../store/cart/cart.models';

@Component({
  selector: 'app-basket-fill-by-file',
  templateUrl: './basket-fill-by-file.component.html',
  styleUrls: ['./basket-fill-by-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketFillByFileComponent implements OnInit, OnChanges {
  @Input() file: UploadChangeParam;
  @Output() uploadDone: EventEmitter<any> = new EventEmitter();
  currentStep = 'fileUploadProgress';
  CLOUD_UPLOAD_DOC_URL = CLOUD_UPLOAD_DOC_URL;
  progressUpload: number = 0;
  fillCartByFileProgress$ = this.store.select(CartSelectors.selectFillCartByFileProgress);
  fileTemplateUrl = IMAGE_CLOUD_URL + '/20230522/4779d3bf-70f3-4fc5-a704-f7bc0c7e3708.xlsx';
  modalRef: BsModalRef;
  protected unsubscribe$ = new Subject();

  constructor(private store: Store<AppState>, private cdr: ChangeDetectorRef, private modalService: BsModalService) {}

  ngOnInit() {
    this.fillCartByFileProgress$.pipe(takeUntil(this.unsubscribe$)).subscribe((currentFile) => {
      if (currentFile) {
        this.currentStep = 'fileUploadEnd';
        this.progressUpload = 0;
        this.cdr.markForCheck();
        this.store.dispatch(new CartActions.LoadCart());
        if (!this.modalRef) {
          console.log('test');
          this.onShowInfoModal(currentFile);
        }
      } else {
        this.uploadDone.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.file) {
      this.uploadFile(this.file);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onChangeStep(step: string): void {
    this.currentStep = step;
  }

  uploadFile(event: UploadChangeParam): void {
    if (!event.file.response) {
      this.currentStep = 'fileUploadProgress';
      this.progressUpload = event.event ? event.event.percent : 0;
      this.cdr.markForCheck();
    } else {
      const filePath = event.file.response.relativeUrl;
      if (filePath) {
        this.store.dispatch(new CartActions.FillCartsByFileProgress({ filePath }));
      }
    }
  }

  onShowInfoModal(data: CartFileAddressProcess): void {
    const firstOfList = data.cartFileAddressResponseList[0];
    const filterData = firstOfList.cartFilePositionResponseList.filter((item) => item.loadedQuantity - item.calculatedQuantity !== 0);
    if (filterData.length) {
      this.modalRef = this.modalService.show(BasketFillByFileAlertComponent, {
        class: 'modal-dialog-centered modal-notify basket-error',
        ignoreBackdropClick: true,
        keyboard: false,
        animated: false,
        initialState: { fileDataList: filterData },
      });
      this.modalRef.content.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.modalRef.hide();
        this.modalRef = null;
        this.store.dispatch(new CartActions.FillCartsByFileProgressReset());
        this.uploadDone.emit();
      });
    } else {
      this.store.dispatch(new CartActions.FillCartsByFileProgressReset());
      this.uploadDone.emit();
    }
  }
}
