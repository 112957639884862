import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

enum Communities {
  'clan' = 'Клан',
  'alliance' = 'Альянс',
  'guild' = 'Гильдия',
  'all' = 'Общий чат',
  'support' = 'Техническая поддержка',
}

enum CommunitiesRe {
  'clan' = 'клане',
  'alliance' = 'альянсе',
  'guild' = 'гильдие',
  'all' = 'общем чате',
  'support' = 'в чате тех. поддержки',
}

@Component({
  selector: 'app-chat-community',
  templateUrl: './chat-community.component.html',
  styleUrls: ['./chat-community.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatCommunityComponent implements OnInit {
  @Input() community;
  @Input() user;
  @Input() type;

  get name() {
    return 'Тех. поддержка';
  }

  get inCommunity() {
    if (['clan', 'alliance', 'guild'].includes(this.type) && this.user && this.user[this.type]) {
      return this.user[this.type].id;
    }
    return true;
  }

  get namere() {
    if (!this.type) return '';
    return CommunitiesRe[this.type];
  }

  get firstMessage() {
    return 'Создать запрос, если что-то не работает';
  }

  get secondMessage() {
    return 'Техническая поддержка';
  }

  constructor() {}

  ngOnInit() {}
}
