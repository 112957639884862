export interface Outlet {
  addressSapId: number;
  clientSapId: number;
  salesOrgSapId: string;
  plantSapId: string;
  addressName: string;
  postcode: number;
  regionCode: number;
  regionName: string;
  district: string;
  cityName: string;
  cityName1: string;
  street: string;
  house: string;
  housing: string;
  building: string;
  room: string;
  remark: string;
  guid: string;
  name: string;
  segmentName: Status;
  statusPower: number;
  clientName: string;
  subdistributor: string;
  latitude: number;
  longitude: number;
  users: string;
}

export enum OrderStatus {
  inBasket = 0,
  accepted = 1,
  finished = 2,
  denied = 3,
}

export enum Status {
  gold = 'Gold',
  silver = 'Silver',
  iron = 'Iron',
}

export interface OutletStatistic {
  success: number;
  moderation: number;
  total: number;
  cost: number;
}
