import { Deserializable } from './deserializable';

export class StatisticsPoints implements Deserializable {
  kpi?: any[];
  revenue?: number;
  costs?: number;
  points?: any[];

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
