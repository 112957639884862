import { Injectable } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncItem, AsyncItemState, makeAsyncItem } from '@Mesh/core/models/async-item';
import { AsyncNews, News } from '@Mesh/core/models/news';

@Injectable({ providedIn: 'root' })
export class NewsLastAsyncService {
  private request: Subscription;
  private announcer = new BehaviorSubject<AsyncNews>(buildGhost());

  news$ = this.announcer.asObservable();

  constructor() {}

  last(): Observable<AsyncItem<News>> {
    this.announcer.next(buildGhost());
    //this.queryServer();

    return this.news$;
  }
}

function buildGhost() {
  const ghosts = null;
  return wrapAsAsyncItem(ghosts);
}

function wrapAsAsyncItem(item) {
  return makeAsyncItem(item, AsyncItemState.LOADING);
}

function load(item) {
  const hasData = !!item.data;
  const state = hasData ? AsyncItemState.LOADED : AsyncItemState.LOADING;

  return makeAsyncItem(state === AsyncItemState.LOADING ? null : item.data, state);
}
