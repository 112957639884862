import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { fromOrderCart } from '.';
import { OrderCartEffects } from './order-cart.effects';

@NgModule({
  imports: [StoreModule.forFeature(fromOrderCart.cartFeatureKey, fromOrderCart.cartReducer), EffectsModule.forFeature([OrderCartEffects])],
})
export class OrderCartStoreModule {}
