import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
  @Input('src') src: string;
  @Input('name') name: string;
  @Input('surname') surname: string;
  constructor() {}

  ngOnInit() {}
}
