import { createSelector } from '@ngrx/store';
import { selectTaskState } from '@Mesh/store/app.state';
import { TaskState } from '@Mesh/store/task/task.state';
import { Task } from '@Mesh/core/models/task';

export const taskSelectors = {
  getActiveTasksTasks: createSelector(selectTaskState, (state: TaskState) => state.activeTasks),
  getActiveTotalCount: createSelector(selectTaskState, (state: TaskState) => state.activeTotalCount),
  getTask: (id) =>
    createSelector(selectTaskState, (state: TaskState) => {
      if (state.activeTasks || state.moderationTasks) {
        return [...state.activeTasks, ...state.moderationTasks].find((item) => {
          return item.id === id;
        });
      } else {
        return {} as Task;
      }
    }),
  getHistoryTasks: createSelector(selectTaskState, (state: TaskState) => state.historyTasks),
  getModerationTasks: createSelector(selectTaskState, (state) => state.moderationTasks),
  getHistoryTasksTotalCount: createSelector(selectTaskState, (state) => state.historyTasksTotalCount),
  getModerationTotalCount: createSelector(selectTaskState, (state: TaskState) => state.moderationTotalCount),
  getCompletedTotalCount: createSelector(selectTaskState, (state: TaskState) => state.completedTotalCount),
  getError: createSelector(selectTaskState, (state: TaskState) => state.error),
  getTotalPages: createSelector(selectTaskState, (state: TaskState) => state.totalPages),
  historyTasksCurrentPage: createSelector(selectTaskState, (state: TaskState) => state.historyTasksCurrentPage),
  getTaskProgress: createSelector(selectTaskState, (state) => state.currentTaskProgress),
  selectCurrentTask: createSelector(selectTaskState, (state) => state.currentTask),
  selectLoadingCurrentTask: createSelector(selectTaskState, (state) => state.loadingCurrentTask),
};

export const getTaskProgressStatusNew = createSelector(
  taskSelectors.getTaskProgress,
  (progress) => progress && progress.stepCount === progress.statusCounts.find(({ status }) => status === 'NEW').stepCount
);

export const getTaskProgressStatusAccepted = createSelector(
  taskSelectors.getTaskProgress,
  (progress) => progress && progress.stepCount === progress.statusCounts.find(({ status }) => status === 'ACCEPTED').stepCount
);
