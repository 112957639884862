import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DelayedOrderHistoryComponent } from './delayed-order-history.component';
import { DelayedOrderHistoryItemComponent } from './delayed-order-history-item/delayed-order-history-item.component';

@NgModule({
  declarations: [DelayedOrderHistoryComponent, DelayedOrderHistoryItemComponent],
  exports: [DelayedOrderHistoryComponent],
  entryComponents: [DelayedOrderHistoryComponent],
  imports: [CommonModule],
})
export class DelayedOrderHistoryModule {}
