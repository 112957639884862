import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { MinimalCartRules } from '../../../../store/cart/cart.models';

@Component({
  selector: 'app-basket-fill-minimal-alert',
  templateUrl: './basket-fill-minimal-alert.component.html',
  styleUrls: ['./basket-fill-minimal-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketFillMinimalAlertComponent implements OnInit {
  @Input() minimalCartRuleCart: MinimalCartRules[];
  @Output() canceled: EventEmitter<boolean> = new EventEmitter();
  @Output() ordered: EventEmitter<boolean> = new EventEmitter();
  @Output() minimalAdded: EventEmitter<boolean> = new EventEmitter();
  categoriesName: string[] = [];

  constructor() {}

  ngOnInit() {
    this.minimalCartRuleCart.forEach((item) => {
      const categories = Object.values(item.categoryNames)[0];
      this.categoriesName = [...this.categoriesName, categories];
    });
  }

  get categories() {
    return this.categoriesName.length === 0 ? '' : '"' + this.categoriesName.join('", "') + '"';
  }
  onMinimalAdded() {
    this.minimalAdded.emit();
  }

  onOrder() {
    this.ordered.emit();
  }

  onCancel() {
    this.canceled.emit();
  }
}
