import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';

@Component({
  selector: 'pg-menu-items',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('toggleHeight', [
      state(
        'close',
        style({
          height: '0',
          overflow: 'hidden',
          marginBottom: '0',
          display: 'none',
        })
      ),
      state(
        'open',
        style({
          display: 'block',
          height: '*',
          marginBottom: '10px',
          overflow: 'hidden',
        })
      ),
      transition('close => open', animate('140ms ease-in')),
      transition('open => close', animate('140ms ease-out')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, AfterViewInit {
  menuItems = [];
  currentItem = null;
  isPerfectScrollbarDisabled = false;
  public config: PerfectScrollbarConfigInterface = {};

  constructor(private cdr: ChangeDetectorRef, private router: Router) {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.togglePerfectScrollbar();
    });
  }

  @HostListener('window:resize', [])
  onResize() {
    this.togglePerfectScrollbar();
  }

  togglePerfectScrollbar() {
    this.isPerfectScrollbarDisabled = window.innerWidth < 1025;
    this.cdr.detectChanges();
  }

  @Input()
  set Items(value) {
    this.menuItems = value;
  }

  get Items() {
    let matchingItemIndex = -1;
    let similarItemIndex = 0;
    const items = this.menuItems.map((mi) => {
      return {
        ...mi,
        thumbNailClass: undefined,
      };
    });

    for (let i = 0; i < items.length; i++) {
      if (this.router.url === items[i].routerLink) {
        matchingItemIndex = i;
        break;
      } else if (this.router.url.includes(items[i].routerLink)) {
        similarItemIndex = i;
      }
    }
    const activeItemIndex = matchingItemIndex !== -1 ? matchingItemIndex : similarItemIndex;
    console.log(activeItemIndex);

    items[activeItemIndex].thumbNailClass += ' bg-success';

    console.log(items.filter((i) => i.thumbNailClass?.includes('bg-success')));

    return items;
  }

  toggleNavigationSub(event, item) {
    event.preventDefault();
    if (!item.submenu) {
      return;
    }
    if (this.currentItem && this.currentItem != item) {
      this.currentItem['toggle'] = 'close';
    }
    this.currentItem = item;
    item.toggle = item.toggle === 'close' ? 'open' : 'close';
    this.cdr.detectChanges();
  }
}
