import { LoginState } from './login/login.state';
import { RouterStateUrl } from './router/router.state';
import { UserState } from './user/user.state';
import { OutletState } from './outlet/outlet.state';
import { TaskState } from '@Mesh/store/task/task.state';
import { createFeatureSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { PlanState } from '@Mesh/store/plan/plan.state';
import { OrderState } from '@Mesh/store/order/order.state';
import { DocumentState } from '@Mesh/store/document/document.state';
import { BonusState } from '@Mesh/store/bonus/bonus.state';
import { financeFeatureKey } from '@Mesh/store/finance/finance.reducer';
import { FinanceState } from '@Mesh/store/finance/finance.state';
import { NotificationState } from './notification/notification.state';

// export const reducers: ActionReducerMap<any> = {
//   router: routerReducer,
// };
//
// export const metaReducers: MetaReducer<AppState>[] = [
//   initStateFromLocalStorage
// ];

export const selectLoginState = createFeatureSelector<AppState, LoginState>('login');

export const selectUserState = createFeatureSelector<AppState, UserState>('user');

export const selectDocumentState = createFeatureSelector<AppState, DocumentState>('document');

export const selectBonusState = createFeatureSelector<AppState, BonusState>('bonus');

export const selectOutletState = createFeatureSelector<AppState, OutletState>('outlet');

export const selectOrderState = createFeatureSelector<AppState, OrderState>('order');

export const selectTaskState = createFeatureSelector<AppState, TaskState>('task');

export const selectPlanState = createFeatureSelector<AppState, PlanState>('plan');

export const selectFinanceState = createFeatureSelector<AppState, FinanceState>(financeFeatureKey);

export const selectRouterState = createFeatureSelector<AppState, RouterReducerState<RouterStateUrl>>('router');

export const selectNotificationState = createFeatureSelector<AppState, NotificationState>('notification');

export interface AppState {
  login: LoginState;
  router: RouterReducerState<RouterStateUrl>;
  user: UserState;
  document: DocumentState;
  bonus: BonusState;
  outlet: OutletState;
  task: TaskState;
  plan: PlanState;
  order: OrderState;
  notification: NotificationState;
  [financeFeatureKey]: FinanceState;
}
