import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable, Subject } from 'rxjs';
import { Outlet } from '../../../../core/models';
import { CategotyTree, IQLCategory, IQLManufacturer, IQLBrand, IQLBrandFamily, IQLProductType } from '../../../../core/models/categories';
import { DataService } from '../../../../core/services';
import { IProductParamCategory } from '../../../../core/services/product-table.service';
import { BasketHelper } from '../../../../shared/helpers/basket.helper';
import { CatalogHelper } from '../../../../shared/helpers/catalog.helper';
import { ResizeHelper } from '../../../../shared/helpers/resize.helper';
import { AppState } from '../../../../store/app.state';
import { bonusSelectors } from '../../../../store/bonus';
import { HierarchyProduct } from '../../../../store/cart/cart.models';
import { CatalogPageTypes } from '../../catalog.routing';

@Component({
  selector: 'app-basket-hierarchy-filter',
  templateUrl: './basket-hierarchy-filter.component.html',
  styleUrls: ['./basket-hierarchy-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketHierarchyFilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() categories: CategotyTree[];
  @Input() filtersReset: number;
  @Input() selectedFilters: { filterId: string; filterName: string; value: string }[] = [];
  @Output() paramsUpdated = new EventEmitter<HierarchyProduct>();
  @Output() setFilters = new EventEmitter<{ filterId: string; filterName: string; value: string }[]>();

  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;

  currentOutlet$: Observable<Outlet>;
  currentOutlet: Outlet;

  currentCatMainIdx: number = null;
  currentCatMain: CategotyTree = null;

  currentCatIdx: number = null;
  currentCat: IQLCategory = null;

  currentManufactureIdx: number = null;
  currentManufacture: IQLManufacturer = null;

  currentBrandIdx: number = null;
  currentBrand: IQLBrand = null;

  currentBrandFamilyIdx: number = null;
  currentBrandFamily: IQLBrandFamily = null;

  currentProductTypeIdx: number = null;
  currentProductType: IQLProductType = null;

  readonly pageTypes = CatalogPageTypes;
  private readonly activeBonus$ = this.store.select(bonusSelectors.getActiveBonus);
  private u$ = new Subject();

  params: HierarchyProduct = {
    manufacturerNameId: null,
    categoryNameId: null,
    brandNameId: null,
    brandFamilyNameId: null,
    productTypeNameId: null,
    materialGroupNameId: null,
  };

  search$: Subject<string> = new Subject();
  swiperConfig: SwiperConfigInterface = {
    observer: true,
    direction: 'horizontal',
    slidesPerView: 'auto',
    // freeMode: true,
    // centeredSlides: false,
    // spaceBetween: 10,
  };
  constructor(
    public resize: ResizeHelper,
    public basket: BasketHelper,
    public store: Store<AppState>,
    public cdr: ChangeDetectorRef,
    public dataService: DataService,
    public catalogHelper: CatalogHelper,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {}

  ngOnChanges({ filtersReset }: SimpleChanges) {
    if (filtersReset?.currentValue) {
      this.removeFilters();
    }
  }

  updateParams() {
    this.paramsUpdated.emit(this.params);
    this.setSelectedFilters();
  }

  getAddress(outlet: Outlet) {
    return [outlet.street, outlet.house, outlet.housing, outlet.room].filter((s) => !!s).join(' ');
  }

  ngOnDestroy(): void {
    this.u$.next();
    this.u$.unsubscribe();
  }

  selectCatMain(i: number, cat: CategotyTree) {
    this.currentCatMainIdx = i;
    this.currentCatMain = cat;

    this.params.materialGroupNameId = cat !== null ? cat.materialGroupNameId : null;

    this.selectCat(null, null);
  }

  selectCat(i: number, man: IQLCategory) {
    this.currentCatIdx = i;
    this.currentCat = man;

    this.params.categoryNameId = man !== null ? man.categoryNameId : null;

    this.selectManufacture(null, null);
  }

  selectManufacture(i: number, man: IQLManufacturer) {
    this.currentManufactureIdx = i;
    this.currentManufacture = man;

    this.params.manufacturerNameId = man !== null ? man.manufacturerNameId : null;

    this.selectBrand(null, null);
  }

  selectBrand(i: number, brand: IQLBrand) {
    this.currentBrandIdx = i;
    this.currentBrand = brand;

    this.params.brandNameId = brand !== null ? brand.brandNameId : null;

    this.selectBrandFamily(null, null);
  }

  selectBrandFamily(i: number, brandF: IQLBrandFamily) {
    this.currentBrandFamilyIdx = i;
    this.currentBrandFamily = brandF;

    this.params.brandFamilyNameId = brandF !== null ? brandF.brandFamilyNameId : null;

    this.selectProductType(null, null);
  }

  selectProductType(i: number, productType: IQLProductType) {
    this.currentProductTypeIdx = i;
    this.currentProductType = productType;

    this.params.productTypeNameId = productType !== null ? productType.productTypeNameId : null;

    this.updateParams();
  }

  private setSelectedFilters() {
    const arr: { filterId: string; filterName: string; value: string }[] = [];
    arr.push({
      filterId: 'materialGroupNameId',
      filterName: this.currentCatMain?.materialGroupName,
      value: this.params.materialGroupNameId,
    });

    arr.push({ filterId: 'categoryNameId', filterName: this.currentCat?.categoryName, value: this.params.categoryNameId });
    arr.push({
      filterId: 'manufacturerNameId',
      filterName: this.currentManufacture?.manufacturerName,
      value: this.params.manufacturerNameId,
    });
    arr.push({ filterId: 'brandNameId', filterName: this.currentBrand?.brandName, value: this.params.brandNameId });
    arr.push({ filterId: 'brandFamilyNameId', filterName: this.currentBrandFamily?.brandFamilyName, value: this.params.brandFamilyNameId });
    arr.push({ filterId: 'productTypeNameId', filterName: this.currentProductType?.productTypeName, value: this.params.productTypeNameId });
    const result = arr.filter((el) => el.filterName && el.value);
    this.setFilters.emit(result);
  }

  removeFilters() {
    this.setFilters.emit([]);

    this.selectCatMain(null, null);

    this.updateParams();
  }

  removeFilter(filter: { filterId: string; filterName: string; value: string }) {
    if (filter.filterId === 'materialGroupNameId') {
      this.selectCatMain(null, null);
    } else if (filter.filterId === 'categoryNameId') {
      this.selectCat(null, null);
    } else if (filter.filterId === 'manufacturerNameId') {
      this.selectManufacture(null, null);
    } else if (filter.filterId === 'brandNameId') {
      this.selectBrand(null, null);
    } else if (filter.filterId === 'brandFamilyNameId') {
      this.selectBrandFamily(null, null);
    } else if (filter.filterId === 'productTypeNameId') {
      this.selectProductType(null, null);
    }

    this.updateParams();
  }
}
