import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderCartState, cartFeatureKey } from './order-cart.reducers';

const selectCartState = createFeatureSelector<OrderCartState>(cartFeatureKey);

export const selectCart = createSelector(selectCartState, (state) => state.cart);

export const numberOfCartItems = createSelector(selectCart, (items) => items.length);

export const getCartAmount = createSelector(selectCart, (items) => items.reduce((prev, curr) => prev + (curr?.quantity || 0), 0));

export const selectTotalCartPrice = createSelector(selectCart, (items) => {
  return items.reduce((prev, curr) => prev + (curr.price || 0), 0);
});

const selectCartProducts = createSelector(selectCartState, (state) => state.cartProducts);

const selectCartProductById = (productId: number) => {
  return createSelector(selectCartProducts, (products) => {
    return products.find((el) => el.id === productId);
  });
};

export const selectCartItemByMaterialIdAndUnit = (materialId: number, unit: string) => {
  return createSelector(selectCart, (items) => {
    return items.find((el) => el.materialId === materialId && el.unit === unit);
  });
};

export const selectCartItemByMaterialIdAndUnitAndDistributorName = (materialId: number, unit: string, distributorName: string) => {
  return createSelector(selectCart, (items) => {
    return items.find((el) => el.materialId === materialId && el.unit === unit && el.distributorName === distributorName);
  });
};

export const selectProductsForCartDisplay = createSelector(selectCart, selectCartProducts, (cartItems, cartProducts) => {
  return cartItems;
});

export const totalAmountOfOrder = createSelector(selectProductsForCartDisplay, (items) => {
  return items.reduce((prev, curr) => prev + curr.ndsPrice * curr.quantity, 0);
});

export const selectStockMap = createSelector(selectCartState, (state) => state.cartStockMap);

export const selectSelectedStockByProductId = (productId: number) => {
  return createSelector(selectStockMap, (stockMap) => {
    return stockMap[productId];
  });
};

export const selectStockByProductIdUnit = (productId: number, unit: string) => {
  return createSelector(selectCartProductById(productId), (product) => {
    return product && product.stock.find((stock) => stock.unit === unit);
  });
};

export const selectStockByProductIdUnitDistributor = (productId: number, unit: string, distributorName: string) => {
  return createSelector(selectCartProductById(productId), (product) => {
    let answer = null;
    product.groupedStock.forEach((group) => {
      group.stock.forEach((stock) => {
        if (stock.unit === unit && stock.distributorName === distributorName) {
          answer = stock;
        }
      });
    });
    return answer;
    // return product && product.stock.find((stock) => {
    //   return (stock.unit === unit && stock.distributorName === distributorName)
    // });
  });
};

export const selectCartItemByProductIdInRows = (productId: number) => {
  return createSelector(selectStockMap, selectCart, (stockMap, items) => {
    const stock = stockMap[productId];
    return items.find(
      (el) =>
        el?.materialId === stock?.materialId &&
        el?.unit === stock?.unit &&
        el?.distributorCode === stock?.distributorCode &&
        el?.distributorName === stock?.distributorName &&
        el?.salesOrgSapId === stock?.salesOrgSapId &&
        el?.ndsPrice === stock?.ndsPrice
    );
    // return items.find((el) => el.materialId === stock.materialId);
  });
};

export const selectCartItemQuantityByProductIdInRows = (productId: number) => {
  return createSelector(selectCartItemByProductIdInRows(productId), (cartItem) => {
    return cartItem?.quantity || 0;
  });
};

export const possibleToDecrement = (productId: number) => {
  return createSelector(selectCartItemQuantityByProductIdInRows(productId), (quantity) => {
    return quantity > 0;
  });
};

export const possibleToIncrement = (productId: number) => {
  return createSelector(
    selectSelectedStockByProductId(productId),
    selectCartItemQuantityByProductIdInRows(productId),
    (stock, quantity) => {
      return stock?.quantityMaterial > quantity;
    }
  );
};
