import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IChangedCartPosition } from '@Mesh/pages/catalog/basket/basket-interfaces';
import { BsModalRef } from 'ngx-bootstrap';
import { LoadCart } from '@Mesh/store/cart/cart.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';

@Component({
  selector: 'app-cart-changed-modal',
  templateUrl: './cart-changed-modal.component.html',
  styleUrls: ['./cart-changed-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartChangedModalComponent {
  @Input()
  data: {
    positions: IChangedCartPosition[];
    message: string;
  };

  constructor(private modalRef: BsModalRef, private store: Store<AppState>) {}

  continue() {
    this.modalRef.hide();
    this.store.dispatch(new LoadCart());
  }
}
