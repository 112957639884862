import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { IPageableList } from '@Mesh/core/models/pageable';
import {
  SumByDistributor,
  Balance,
  FinanceSchedule,
  FinanceScheduleNearest,
  GetFinanceRequestCriteria,
  PayHistory,
  BalanceTree,
} from '@Mesh/store/finance/finance.models';
import { HttpClient } from '@angular/common/http';
import { API_USER_URL } from '@Env/environment';
import { objectToParams } from '@Mesh/shared/helpers/object.helpers';
import { GetFinanceSum } from '../models/request/get-finance-sum';
import { FinanceSum } from '../models/finance-sum';

const url = API_USER_URL + '/finance';

@Injectable({
  providedIn: 'root',
})
export class FinanceService {
  constructor(private httpClient: HttpClient) {}

  getPayHistory(criteria: Partial<GetFinanceRequestCriteria>): Observable<IPageableList<PayHistory>> {
    const params = objectToParams(criteria);
    return this.httpClient.get<IPageableList<PayHistory>>(url + '/payHistory', { params });
  }

  getBalance(criteria: GetFinanceSum): Observable<Balance[]> {
    const params = objectToParams(criteria);
    return this.httpClient.get<Balance[]>(url + '/balance', { params });
  }

  getFinanceSchedule(criteria: GetFinanceSum): Observable<IPageableList<FinanceSchedule>> {
    const params = objectToParams(criteria);
    return this.httpClient.get<IPageableList<FinanceSchedule>>(url + '/paySchedule', { params });
  }

  getFinanceScheduleNearest(criteria: GetFinanceSum): Observable<FinanceScheduleNearest[]> {
    const params = objectToParams(criteria);
    return this.httpClient.get<FinanceScheduleNearest[]>(url + '/paySchedule/nearest', { params });
  }

  getFinanceSum(criteria: GetFinanceSum): Observable<FinanceSum> {
    const params = objectToParams(criteria);
    return this.httpClient.get<FinanceSum>(url + '/sum', { params });
  }

  getBalanceByDistributor(criteria: { clientSapId: Array<number> }): Observable<Array<SumByDistributor>> {
    const params = objectToParams(criteria);
    return this.httpClient.get<Array<SumByDistributor>>(`${API_USER_URL}/finance/balance-by-distributor`, { params });
  }

  getBalanceTree(criteria: { clientSapId: Array<number> }): Observable<Array<BalanceTree>> {
    const params = objectToParams(criteria);
    return this.httpClient.get<Array<BalanceTree>>(`${API_USER_URL}/finance/balance-tree`, { params });
  }
}
