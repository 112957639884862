import { TableColumn, TableColumnType } from '@Mesh/core/models/table';

export interface IStickers {
  id: number;
  name: string;
  bgc: string;
  icon: string;
  margin: string;
  height: string;
  width: string;
  headerBgc: string;
}

export const planListColumns: TableColumn[] = [
  {
    type: TableColumnType.textImage,
    headerClass: 'align-center',
    key: 'clientName',
    class: 'd-flex align-items-center',
    img: 'icon',
    label: 'Название',
    sortable: true,
    maxWidth: 170,
    minWidth: 170,
  },
  {
    type: TableColumnType.progress,
    headerClass: 'align-center text-center',
    key: 'left',
    class: 'd-flex align-items-center text-center ',
    label: 'прогресс',
    maxWidth: 158,
  },
  {
    type: TableColumnType.text,
    headerClass: 'align-center text-center',
    key: 'left',
    class: 'd-flex align-items-center text-center',
    label: 'осталось купить',
    subName: 'шт',
    maxWidth: 213,
  },
  {
    type: TableColumnType.text,
    headerClass: 'align-center text-center',
    key: 'left',
    class: 'd-flex align-items-center text-center',
    subName: '₽',
    label: 'МРЦ',
    maxWidth: 213,
  },
  {
    type: TableColumnType.text,
    headerClass: 'align-center text-center',
    key: 'count',
    label: 'Цена за шт',
    subName: '₽',
    class: 'd-flex align-items-center text-center',
    maxWidth: 213,
  },
  {
    type: TableColumnType.text,
    headerClass: 'align-center text-center',
    key: 'left',
    class: 'd-flex align-items-center block-text',
    label: 'БЛОК',
    subName: 'пачек',
    minWidth: 218,
    maxWidth: 213,
  },
  {
    type: TableColumnType.text,
    headerClass: 'align-center text-center',
    key: 'left',
    class: 'd-flex align-items-center box-text',
    subName: 'пачек',
    label: 'Коробка',
    maxWidth: 213,
  },

  {
    type: TableColumnType.text,
    headerClass: 'align-center text-center',
    key: 'count',
    label: 'Цена',
    subName: '₽',
    class: 'd-flex align-items-center text-center block-text',
    maxWidth: 213,
  },
  {
    type: TableColumnType.quantityButtons,
    headerClass: 'align-center text-center',
    label: 'Количество позиций',
    class: 'd-flex align-items-center',
    maxWidth: 250,
    minWidth: 250,
  },
];

export const planStickers: IStickers[] = [
  {
    id: 1,
    name: 'ТАБАК ВАЛ',
    bgc: 'linear-gradient(46.19deg, #F09819 5.66%, #EDDE5D 94.35%',
    icon: 'tabac-icon',
    margin: '0 0 0 10px',
    width: '32px',
    height: '31px',
    headerBgc: 'bg-yellow',
  },
  {
    id: 2,
    name: 'ТОП SKU',
    bgc: 'linear-gradient(78.43deg, #B982FF 3.79%, #FA51FD 93.8%)',
    icon: 'top-sku-icon',
    margin: '0 0 0 7px',
    width: '31px',
    height: '31px',
    headerBgc: 'bg-purple',
  },
  {
    id: 3,
    name: 'ТОП Марки',
    bgc: 'linear-gradient(38.22deg, #6558F7 38.08%, #D7D0FF 197.55%)',
    icon: 'top-marks-icon',
    margin: '0 0 0 7px',
    width: '31px',
    height: '31px',
    headerBgc: 'bg-dark-purple',
  },
  {
    id: 4,
    name: 'Drive List',
    bgc: 'linear-gradient(46.19deg, #FF1F01 5.66%, #FD5D1B 42.15%, #FFC700 94.35%)',
    icon: 'drive-list-icon',
    margin: '0 0 0 7px',
    width: '27px',
    height: '31px',
    headerBgc: 'bg-orange',
  },
  {
    id: 5,
    name: 'JTI Вал',
    bgc: 'linear-gradient(135deg, #00EE94 0%, rgba(0,102,255,0.05) 100%)',
    icon: 'jti-icon',
    margin: '2px 0 0 0',
    width: '41px',
    height: '31px',
    headerBgc: 'bg-light-green',
  },
  {
    id: 6,
    name: 'PMI Вал',
    bgc: 'linear-gradient(135deg, #166FFF 0%, #09B2FF 100%)',
    icon: 'pmi-icon',
    margin: '2px 0 0 1px',
    width: '42px',
    height: '31px',
    headerBgc: 'bg-light-blue',
  },
  {
    id: 7,
    name: 'ITG Вал',
    bgc: 'linear-gradient(135deg, #FF1680 0%, #FF0909 100%)',
    icon: 'itg-icon',
    margin: '7px 0 6px 0',
    width: '39px',
    height: '23px',
    headerBgc: 'bg-pink',
  },
  {
    id: 8,
    name: 'IQOS Вал',
    bgc: 'linear-gradient(46.19deg, #F09819 5.66%, #EDDE5D 94.35%)',
    icon: 'iqos-icon',
    margin: '9px 0 0 0',
    width: '45px',
    height: '13px',
    headerBgc: 'bg-faded-blue',
  },
  {
    id: 9,
    name: 'RedBull ',
    bgc: 'linear-gradient(46.19deg, #F09819 5.66%, #EDDE5D 94.35%)',
    icon: 'redbull-icon',
    margin: '5px 0 0 0',
    width: '45px',
    height: '22px',
    headerBgc: 'bg-red',
  },
  {
    id: 10,
    name: '3+К+Б ',
    bgc: 'linear-gradient(46.19deg, #F09819 5.66%, #EDDE5D 94.35%)',
    icon: 'combo-icon',
    margin: '3px 0 0 11px',
    width: '43px',
    height: '29px',
    headerBgc: 'bg-dark-pink',
  },
];

export const productTableRatings = [
  {
    id: 6,
    name: 'ITG Вал',
    left: 35,
    bgc: 'linear-gradient(135deg, #FF1680 0%, #FF0909 100%)',
  },
  {
    id: 7,
    name: 'Табак Вал',
    left: 35,
    bgc: 'linear-gradient(46.19deg, #F09819 5.66%, #EDDE5D 94.35%)',
  },
  {
    id: 1,
    name: 'ТОП SKU',
    left: 35,
    bgc: 'linear-gradient(78.43deg, #B982FF 3.79%, #FA51FD 93.8%)',
  },
];
