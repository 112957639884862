import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BasketHelper {
  mobileBasketToggle = false;
  showBasketList = false;
  readonly showMobileBasketList$ = new BehaviorSubject(false);
  positionQuantities = [];

  readonly productsCount$ = new BehaviorSubject(0);

  constructor() {}

  basketListToggle(flag = false, web = true) {
    if (flag) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }
    if (web) {
      this.showBasketList = flag;
    } else {
      this.showMobileBasketList$.next(flag);
    }
  }

  incrementProducts() {
    this.productsCount$.next(this.productsCount$.value + 1);
  }

  decrementProducts() {
    this.productsCount$.next(this.productsCount$.value - 1);
  }
}
