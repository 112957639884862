import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { CartActions, CartSelectors } from '@Mesh/store/cart';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { OrderCompletedState, OrderCompletedStatusType } from '@Mesh/store/cart/cart.models';
import { Subscription } from 'rxjs';
import { RequestInvoiceDelayedOrder } from '../../../../store/order/order.actions';

@Component({
  selector: 'app-success-screen',
  templateUrl: './success-screen.component.html',
  styleUrls: ['./success-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessScreenComponent implements OnDestroy {
  @Output() closeCart = new EventEmitter();

  readonly details$ = this.store.select(CartSelectors.selectOrderCompletedState);
  readonly outlet$ = this.store.select(outletSelectors.getActive);
  readonly today = new Date();

  orderCompletedStatusType = OrderCompletedStatusType;
  orders: OrderCompletedState[];
  indexActiveOrder: number;
  sub: Subscription;
  showSuccesInvoce: boolean = false;

  constructor(private store: Store<AppState>, private cdr: ChangeDetectorRef) {
    this.sub = this.details$.subscribe((orders: OrderCompletedState[]) => {
      this.orders = orders;
      this.indexActiveOrder = 0;
    });
  }

  next(): void {
    this.indexActiveOrder++;
    this.cdr.detectChanges();
  }

  close() {
    this.closeCart.emit();
  }

  onRequestInvoice(): void {
    const delayedOrderId = this.orders[this.indexActiveOrder].delayedOrderId;
    if (delayedOrderId) {
      this.store.dispatch(new RequestInvoiceDelayedOrder({ delayedOrderId, shouldRequestInvoice: true }));
    }
    this.showSuccesInvoce = true;
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
