import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-delay-order-request-invoice',
  templateUrl: './delay-order-request-invoice.component.html',
  styleUrls: ['./delay-order-request-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelayOrderRequestInvoiceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
