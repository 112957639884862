import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { BalanceTreeByDistributor, BalanceTreeItem } from '@Mesh/store/finance/finance.models';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-tree-wrapper',
  templateUrl: './tree-wrapper.component.html',
  styleUrls: ['./tree-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideVertical', [
      state(
        '*',
        style({
          height: 0,
        })
      ),
      state(
        'show',
        style({
          height: '*',
        })
      ),
      transition('* <=> show', [animate('800ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
    ]),
    trigger('animateArrowIcon', [
      state(
        '*',
        style({
          transform: 'rotate(0deg)',
        })
      ),
      state(
        'show',
        style({
          transform: 'rotate(180deg)',
        })
      ),
      transition('* => *', [animate('600ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
    ]),
  ],
})
export class TreeWrapperComponent {
  TREE_DATA: BalanceTreeItem[] = [];

  private _financeFullData: BalanceTreeByDistributor[];
  @Input() set financeFullData(financeFullData: BalanceTreeByDistributor[]) {
    this._financeFullData = financeFullData;

    if (financeFullData && this.TREE_DATA.length === 0) {
      this.TREE_DATA = this.setTreeView(this._financeFullData);
      this.TREE_DATA = this.dataSource.data = this.TREE_DATA;
    }
  }
  get financeFullData(): BalanceTreeByDistributor[] {
    return this._financeFullData;
  }
  @Output() public scrollDown = new EventEmitter();

  treeControl = new NestedTreeControl<BalanceTreeItem>((node) => node.childs);
  dataSource = new MatTreeNestedDataSource<BalanceTreeItem>();
  animationState: string = '*';
  fisrtChildNode: BalanceTreeItem;
  secondChildNode: BalanceTreeItem;

  constructor() {}

  hasChild = (_: number, node: BalanceTreeItem) => !!node.childs && node.childs.length >= 0;

  checkNode(node: BalanceTreeItem, item?: HTMLElement) {
    this.fisrtChildNode = node.childs[0];
    this.secondChildNode = node.childs[1];
    if (node.show && node.show === 'show') {
      node.show = null;
      if (this.fisrtChildNode && this.secondChildNode) {
        this.fisrtChildNode.show = null;
        this.secondChildNode.show = null;
      } else {
        this.fisrtChildNode.childs?.forEach((el) => {
          el.show = null;
          return el.childs.map((item) => (item.show = null));
        });
        this.fisrtChildNode.show = null;
      }
    } else {
      this.animationState = 'show';
      node.show = this.animationState;
      this.fisrtChildNode.show = null;
      if (node.name === 'График платежей') {
        item.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
      if (this.secondChildNode) {
        this.secondChildNode.show = null;
      }
    }
  }

  scrollBottom() {
    this.scrollDown.emit();
  }

  setTreeView(items: BalanceTreeByDistributor[]): any[] {
    if (items && items.length) {
      return items.map((item, i) => {
        if (item.childs?.length) {
          return {
            ...item,
            show: null,
            childs: this.setTreeView(item.childs),
            hasChild: true,
          };
        } else {
          return {
            ...item,
            show: null,
            childs: this.setBalanceChild(item),
            hasChild: true,
          };
        }
      });
    } else {
      return [];
    }
  }

  setBalanceChild(item: BalanceTreeByDistributor) {
    return [
      {
        ...item,
        name: 'Баланс',
        template: 'balancePayScheduleTemplate',
        hasChild: true,
        childs: [
          {
            ...item,
            name: null,
            template: 'detailsBalanceTemplate',
            hasChild: false,
            childs: [],
            show: null,
          },
        ],
      },
      {
        ...item,
        name: 'График платежей',
        template: 'balancePayScheduleTemplate',
        childs: [
          {
            ...item,
            template: 'detailsPayScheduleTemplate',
            childs: [],
            hasChild: false,
            show: null,
          },
        ],
      },
    ];
  }

  isBeforeToday(visitDate: Date, time: string = null): boolean {
    if (time) {
      const timeArray = time && time.split(':');
      visitDate.setHours(Number(timeArray[0]), Number(timeArray[1]), Number(timeArray[2]));
    }
    const today = new Date();
    return today > visitDate;
  }

  isToday(someDate: Date): boolean {
    const today = new Date();
    const currentDay = new Date(someDate);
    return (
      someDate &&
      currentDay.getDate() == today.getDate() &&
      currentDay.getMonth() == today.getMonth() &&
      currentDay.getFullYear() == today.getFullYear()
    );
  }
}
