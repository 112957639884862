import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Outlet } from '../../../../core/models';
import { CartRuleGroupedStatus, MinimalCartRuleGroupedStatus } from '../../../../store/cart/cart.models';
import { RecommendOrderBonus } from '@Mesh/store/order/order.model';
import { AppState } from '../../../../store/app.state';
import { Store } from '@ngrx/store';
import { CartActions, CartSelectors } from '../../../../store/cart';

@Component({
  selector: 'app-basket-total-info',
  templateUrl: './basket-total-info.component.html',
  styleUrls: ['./basket-total-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketTotalInfoComponent implements OnChanges {
  @Input() outlet: Outlet;
  @Input() totalOrderPrice: number;
  @Input() totalAmountOfOrder: number;
  @Input() totalOrderDiscountPrice: number;
  @Input() minimalCartRulesGroupStatus: MinimalCartRuleGroupedStatus;
  @Input() nextDeliveryDate: string;
  @Input() recommendedOrder: RecommendOrderBonus;
  @Input() recommendedOrderActive: boolean;
  @Output() ordered = new EventEmitter<any>();
  @Output() activeChanges = new EventEmitter<{ active: boolean; recommendedOrderId: number }>();
  @Output() updatedBasketToOrder = new EventEmitter<any>();
  CartRuleGroupedStatus = CartRuleGroupedStatus;
  today = new Date();
  readonly showRecommendedOrderButton$ = this.store.select(CartSelectors.selectShowRecommendedOrderButton);

  constructor(private cdr: ChangeDetectorRef, private store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.recommendedOrder && changes.recommendedOrder.currentValue) {
      const recommendedOrder = changes.recommendedOrder.currentValue;

      this.store.dispatch(
        new CartActions.CalculateRecommendedOrderCart({
          recommendedOrderId: recommendedOrder.id,
        })
      );
    }
  }

  onOrder(): void {
    this.ordered.emit();
  }

  onActiveRecommendedOrder(event: { active: boolean; recommendedOrderId: number }): void {
    this.activeChanges.emit(event);
  }

  onUpdatedBasketToOrder(event: number): void {
    this.updatedBasketToOrder.emit(event);
  }

  get hasDiscount() {
    return this.totalOrderPrice !== this.totalOrderDiscountPrice;
  }
}
