import { initialState, PlanState } from '@Mesh/store/plan/plan.state';
import { PlanActionsTypes, PlanActionsUnion } from '@Mesh/store/plan/plan.actions';
import { withLoadable } from '@Mesh/utils/loadable/with-loadable';

function basePlanReducer(state = initialState, action: PlanActionsUnion): PlanState {
  switch (action.type) {
    case PlanActionsTypes.PlanLoadSuccess:
      return {
        ...state,
        data: action.payload.plan,
      };
    case PlanActionsTypes.PlanSetActive:
      return {
        ...state,
        active: action.payload.active,
      };
    case PlanActionsTypes.PlanLoadError:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export function planReducer(state: PlanState, action: PlanActionsUnion): PlanState {
  return withLoadable(basePlanReducer, {
    loadingActionType: PlanActionsTypes.PlanLoadRequest,
    successActionType: PlanActionsTypes.PlanLoadSuccess,
    errorActionType: PlanActionsTypes.PlanLoadError,
  })(state, action);
}
