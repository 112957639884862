import { Action, ActionReducer } from '@ngrx/store';
import { LocalStorageService } from '@Mesh/core/services/local-storage';
import { merge, pick } from 'lodash-es';
import { LoginActionsTypes } from '@Mesh/store/login/login.actions';

export function storageMetaReducer<S, A extends Action = Action>(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService
) {
  let onInit = true; // after load/refresh…
  return function (reducer: ActionReducer<S, A>) {
    return function (state: S, action: A): S {
      const nextState = reducer(state, action);

      if (onInit) {
        onInit = false;
        const savedState = storageService.getItem(localStorageKey);
        return merge(nextState, savedState);
      }

      if (LoginActionsTypes.Logout.includes(action.type)) {
        storageService.removeAll();
        return reducer(undefined, action);
      }

      const stateToSave = pick(nextState, saveKeys);
      storageService.setItem(localStorageKey, stateToSave);

      return nextState;
    };
  };
}
