import { createDefaultLoadable, Loadable } from '@Mesh/utils/loadable/loadable';
import { Task, TaskProgress } from '@Mesh/core/models/task';

export interface TaskState extends Loadable {
  activeTasks: Task[];
  activeTotalCount: number;
  moderationTasks: Task[];
  moderationTotalCount: number;
  completedTotalCount: number;
  historyTasks: Task[] | null; // need for dashboard page
  errors: any[] | null;
  historyTasksTotalCount: number;
  totalPages: number;
  activeTasksCurrentPage: number;
  moderationTasksCurrentPage: number;
  historyTasksCurrentPage: number;
  currentTaskProgress: TaskProgress;
  currentTask: Task;
  loadingCurrentTask: boolean;
}

export const initialState: TaskState = {
  ...createDefaultLoadable(),
  activeTasks: [],
  activeTotalCount: 0,
  moderationTasks: [],
  moderationTotalCount: 0,
  completedTotalCount: 0,
  historyTasks: [],
  historyTasksTotalCount: 0,
  errors: null,
  totalPages: 0,
  activeTasksCurrentPage: 0,
  moderationTasksCurrentPage: 0,
  historyTasksCurrentPage: 0,
  currentTaskProgress: null,
  currentTask: null,
  loadingCurrentTask: false,
};
