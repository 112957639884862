import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { TaskChat } from '@Mesh/core/models/comment';

@Component({
  selector: 'app-chat-task-preview',
  templateUrl: './chat-task-preview.component.html',
  styleUrls: ['./chat-task-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTaskPreviewComponent implements OnInit {
  @Input() taskChat: TaskChat;
  @Input() clientSapId: number;
  @Input() addressSapId: number;
  @Output() startedTask: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onStartTask() {
    this.startedTask.emit(true);
  }
}
