import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-ghost',
  templateUrl: './task-item-ghost.component.html',
  styleUrls: ['./task-item-ghost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskItemGhostComponent implements OnInit {
  ngOnInit(): void {}
}
