import { createSelector } from '@ngrx/store';

import { selectUserState } from '../app.state';
import { UserState } from './user.state';

export const userSelectors = {
  users: createSelector(selectUserState, (state: UserState) => state.users),
  totalCount: createSelector(selectUserState, (state: UserState) => state.usersTotalCount),
  newUser: createSelector(selectUserState, (state: UserState) => state.users?.find((user) => user.id === state.newUserId)),
  user: createSelector(selectUserState, (state: UserState) => state.users?.find((user) => user.id === state.selectedUserId)),
  statusSendSmsRemoveUser: createSelector(selectUserState, (state: UserState) => state.statuses.smsRemoveUser),
  statusVerifyAddUser: createSelector(selectUserState, (state: UserState) => state.statuses.verifyAddUser),
  statusVerifyRemoveUser: createSelector(selectUserState, (state: UserState) => state.statuses.verifyRemoveUser),
  statusSentInvitationAddNewUser: createSelector(selectUserState, (state: UserState) => state.statuses.sentInvitationAddNewUser),
  isExistingUser: createSelector(selectUserState, (state: UserState) => state.isExistingUser),
  statusUpdateUser: createSelector(selectUserState, (state: UserState) => state.statuses.updateUser),
  clientSapIds: createSelector(selectUserState, (state: UserState) => state.clientSapIds),
  error: createSelector(selectUserState, (state: UserState) => state.error),
  usersTotalPages: createSelector(selectUserState, (state: UserState) => state.usersTotalPages),
  usersCurrentPage: createSelector(selectUserState, (state: UserState) => state.usersCurrentPage),
  selectLoading: createSelector(selectUserState, (state) => state.userLoading),
};
