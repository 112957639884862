import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DelayedOrderEditComponent } from './delayed-order-edit.component';
import { BasketHeaderModule } from '../../../catalog/basket/basket-header/basket-header.module';
import { BasketDeliveryModule } from '../../../catalog/basket/basket-delivery/basket-delivery.module';
import { OrderCardItemComponent } from './order-card-item/order-card-item.component';
import { DelayedOrderUpdateNotifyModule } from './delayed-order-update-notify/delayed-order-update-notify.module';
import { GroupByModule } from '../../../../shared/modules/group-by/group-by.module';

@NgModule({
  declarations: [DelayedOrderEditComponent, OrderCardItemComponent],
  exports: [DelayedOrderEditComponent],
  entryComponents: [DelayedOrderEditComponent],
  imports: [CommonModule, BasketHeaderModule, BasketDeliveryModule, DelayedOrderUpdateNotifyModule, GroupByModule],
})
export class DelayedOrderEditModule {}
