import { Action } from '@ngrx/store';
import { GetDocumentCriteria } from '@Mesh/core/models/request/get-document-criteria';
import { Documents } from '@Mesh/core/models/documents';

export enum DocumentActionsTypes {
  DocumentLoadSuccess = '[Document] Document  Success',
  DocumentLoadError = '[Document] Document  Error',
  DocumentLoadRequest = '[Document] Document  Request',
  DocumentDownloadRequest = '[Document] Document download Request',
  DocumentDownloadSuccess = '[Document] Document download success',
  DocumentSetTotalCount = '[Document] Document set total count',
  DocumentSetActive = '[Document] Document set active',
  DocumentSetActiveSuccess = '[Document] Document set active success',
  DocumentSignRequest = '[Document] Document sign request',
  DocumentSignSuccess = '[Document] Document sign success',
  DocumentSignError = '[Document] Document sign error',
  DocumentSignedRequest = '[Document] Document Signed request',
  DocumentSignedSuccess = '[Document] Document Signed success',
  DocumentSignedError = '[Document] Document Signed error',
}

export class DocumentSignRequest implements Action {
  readonly type = DocumentActionsTypes.DocumentSignRequest;

  constructor(public payload: { id: number }) {}
}

export class DocumentSignSuccess implements Action {
  readonly type = DocumentActionsTypes.DocumentSignSuccess;

  constructor(public payload: { identifierId: string }) {}
}

export class DocumentSignError implements Action {
  readonly type = DocumentActionsTypes.DocumentSignError;

  constructor(public error: { errors: any[] | null }) {}
}

export class DocumentSignedRequest implements Action {
  readonly type = DocumentActionsTypes.DocumentSignedRequest;

  constructor(public payload: { id: number; body: { code: string } }) {}
}

export class DocumentSignedSuccess implements Action {
  readonly type = DocumentActionsTypes.DocumentSignedSuccess;

  constructor() {}
}

export class DocumentSignedError implements Action {
  readonly type = DocumentActionsTypes.DocumentSignedError;

  constructor(public error: { errors: any[] | null }) {}
}

export class DocumentLoadRequest implements Action {
  readonly type = DocumentActionsTypes.DocumentLoadRequest;

  constructor(public payload: GetDocumentCriteria) {}
}

export class DocumentLoadSuccess implements Action {
  readonly type = DocumentActionsTypes.DocumentLoadSuccess;

  constructor(public payload: { data: Documents[] }) {}
}

export class DocumentLoadError implements Action {
  readonly type = DocumentActionsTypes.DocumentLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class DocumentSetTotalCount implements Action {
  readonly type = DocumentActionsTypes.DocumentSetTotalCount;

  constructor(public payload: { totalCount: number }) {}
}

export class DocumentSetActive implements Action {
  readonly type = DocumentActionsTypes.DocumentSetActive;

  constructor(public payload: { id: number | null }) {}
}

export class DocumentSetActiveSuccess implements Action {
  readonly type = DocumentActionsTypes.DocumentSetActiveSuccess;
}

export class DocumentDownloadRequest implements Action {
  readonly type = DocumentActionsTypes.DocumentDownloadRequest;

  constructor(public payload: { url: string; filename: string }) {}
}

export class DocumentDownloadSuccess implements Action {
  readonly type = DocumentActionsTypes.DocumentDownloadSuccess;
}

export type ActionsUnion =
  | DocumentSignRequest
  | DocumentSignSuccess
  | DocumentSignError
  | DocumentSignedRequest
  | DocumentSignedSuccess
  | DocumentSignedError
  | DocumentLoadRequest
  | DocumentLoadSuccess
  | DocumentLoadError
  | DocumentSetTotalCount
  | DocumentSetActive
  | DocumentSetActiveSuccess
  | DocumentDownloadRequest
  | DocumentDownloadSuccess;
