import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../../../core/models';
import { GetUserHistoryCriteria } from '../../../../core/models/request/get-user-bonus-history';
import { UserHistoryBonus } from '../../../../core/models/user-bonus-history';
import { BonusService } from '../../../../core/services/bonus.service';
import { AppState } from '../../../../store/app.state';
import { bonusSelectors } from '@Mesh/store/bonus';
import { SelectedUserBonusBalanceReset } from '../../../../store/bonus/bonus.actions';
@Component({
  selector: 'app-user-bonus-modal',
  templateUrl: './user-bonus-modal.component.html',
  styleUrls: ['./user-bonus-modal.component.scss'],
})
export class UserBonusModalComponent implements OnInit, OnDestroy {
  @Input() user: User;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  bonusHistory: UserHistoryBonus[] = [];
  private unsubscribe$ = new Subject();
  loadingBonusHistory: boolean;
  pageToLoadNextBonuses: number = 1;
  userBalance$: Observable<number>;

  constructor(private bonusService: BonusService, private store: Store<AppState>) {}

  ngOnInit() {
    this.loadBonusHistory();
    this.userBalance$ = this.store.pipe(select(bonusSelectors.getSelectedUserBonusBalance));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.next(null);
    this.store.dispatch(new SelectedUserBonusBalanceReset());
  }

  onClosed() {
    this.closed.emit();
  }

  onScrollEnd() {
    this.loadBonusHistory();
  }

  loadBonusHistory() {
    if (this.loadingBonusHistory) {
      return;
    }

    this.loadingBonusHistory = true;
    let params: GetUserHistoryCriteria = {
      userIds: [this.user.id],
      sort: 'id',
      direction: 'DESC',
    };
    if (this.pageToLoadNextBonuses > 1) {
      params = { ...params, page: this.pageToLoadNextBonuses };
    }
    this.bonusService
      .getUserHistoryBonus(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.bonusHistory = [...this.bonusHistory, ...data.content];
        this.loadingBonusHistory = false;
        this.pageToLoadNextBonuses++;
      });
  }
}
