import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { withLatestFrom, switchMap, map } from 'rxjs/operators';
import { DelayedOrderCart } from '../../core/models/delayed-order';
import { Product } from '../../core/models/product';
import {
  OrderCartActionsTypes,
  AddProduct,
  DecrementProduct,
  IncrementProductInCart,
  DecrementProductInCart,
  EmptyAction,
  ChangeAmount,
  UpdateCartSuccess,
} from './order-cart.actions';
import {
  selectSelectedStockByProductId,
  selectCartItemByMaterialIdAndUnitAndDistributorName,
  selectStockByProductIdUnitDistributor,
} from './order-cart.selectors';

@Injectable()
export class OrderCartEffects {
  @Effect()
  changeProductAmount$ = this.actions$.pipe(
    ofType<AddProduct | DecrementProduct>(OrderCartActionsTypes.AddProduct, OrderCartActionsTypes.DecrementProduct),
    switchMap((action) => {
      return of(action).pipe(
        withLatestFrom(this.store.select(selectSelectedStockByProductId(action.payload.product.id))),
        switchMap(([, stock]) => {
          return of(stock).pipe(
            withLatestFrom(
              this.store.select(
                selectCartItemByMaterialIdAndUnitAndDistributorName(action.payload.product.materialId, stock.unit, stock.distributorName)
              )
            ),
            map(([, product]) => {
              const quantity =
                action.type === OrderCartActionsTypes.AddProduct
                  ? product
                    ? product.quantity + 1
                    : 1
                  : product
                  ? product.quantity - 1
                  : 0;
              const body: Partial<DelayedOrderCart> = {
                quantity,
                materialId: action.payload.product.materialId,
                mrc: stock?.mrc,
                unit: stock?.unit,
                salesOrgSapId: stock?.salesOrgSapId,
                distributorCode: stock?.distributorCode,
                distributorName: stock?.distributorName,
                ndsPrice: stock?.ndsPrice,
                product: action.payload.product as unknown as Product,
              };
              return new UpdateCartSuccess(body as DelayedOrderCart);
            })
          );
        })
      );
    })
  );

  @Effect()
  changeProductAmountInCart$ = this.actions$.pipe(
    ofType<IncrementProductInCart | DecrementProductInCart>(
      OrderCartActionsTypes.IncrementProductInCart,
      OrderCartActionsTypes.DecrementProductInCart
    ),
    switchMap((action) => {
      return of(action).pipe(
        withLatestFrom(
          this.store.select(selectStockByProductIdUnitDistributor(action.payload.id, action.payload.unit, action.payload.distributorName))
        ),
        switchMap(([, stock]) => {
          return of(stock).pipe(
            withLatestFrom(
              this.store.select(
                selectCartItemByMaterialIdAndUnitAndDistributorName(action.payload.materialId, stock.unit, action.payload.distributorName)
              )
            ),
            map(([, product]) => {
              const quantity =
                action.type === OrderCartActionsTypes.IncrementProductInCart
                  ? product
                    ? product.quantity + 1
                    : 1
                  : product
                  ? product.quantity - 1
                  : 0;
              const body: Partial<DelayedOrderCart> = {
                quantity,
                materialId: action.payload.materialId,
                mrc: stock?.mrc,
                unit: stock?.unit,
                salesOrgSapId: stock?.salesOrgSapId,
                distributorCode: stock?.distributorCode,
                distributorName: stock?.distributorName,
                ndsPrice: stock?.ndsPrice,
                product: action.payload as unknown as Product,
              };
              return new UpdateCartSuccess(body as DelayedOrderCart);
            })
          );
        })
      );
    })
  );

  @Effect()
  changeAmount$ = this.actions$.pipe(
    ofType<ChangeAmount>(OrderCartActionsTypes.ChangeAmount),
    switchMap((action) => {
      return of(action).pipe(
        withLatestFrom(this.store.select(selectSelectedStockByProductId(action.payload.product.id))),
        switchMap(([, stock]) => {
          return of(stock).pipe(
            withLatestFrom(
              this.store.select(
                selectCartItemByMaterialIdAndUnitAndDistributorName(action.payload.product.materialId, stock.unit, stock.distributorName)
              )
            ),
            map(([, product]) => {
              const quantity = action.payload.amount;
              if (quantity === product?.quantity) {
                return new EmptyAction();
              }
              const body: Partial<DelayedOrderCart> = {
                quantity,
                materialId: action.payload.product.materialId,
                mrc: stock?.mrc,
                unit: stock?.unit,
                salesOrgSapId: stock?.salesOrgSapId,
                distributorCode: stock?.distributorCode,
                distributorName: stock?.distributorName,
                ndsPrice: stock?.ndsPrice,
                product: action.payload.product as unknown as Product,
              };
              return new UpdateCartSuccess(body as DelayedOrderCart);
            })
          );
        })
      );
    })
  );

  constructor(private actions$: Actions, private store: Store<any>) {}
}
