import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { CartFileAddressProcess, CartFilePositionResponseList } from '../../../../../store/cart/cart.models';

@Component({
  selector: 'app-basket-fill-by-file-alert',
  templateUrl: './basket-fill-by-file-alert.component.html',
  styleUrls: ['./basket-fill-by-file-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketFillByFileAlertComponent implements OnInit {
  @Input() fileDataList: CartFilePositionResponseList[];
  @Output() closed: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClosed(): void {
    this.closed.emit();
  }
}
