import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chat-dialog-tab',
  templateUrl: './chat-dialog-tab.component.html',
  styleUrls: ['./chat-dialog-tab.component.scss'],
})
export class ChatDialogTabComponent implements OnInit {
  @Input() title: string;

  @Input() active = false;

  @Input() tabTooltip: string;

  @Input() countMessages: number;

  @Input() id: number;

  @Input() type: string;

  @Input() route: string;

  constructor() {}

  ngOnInit() {}
}
