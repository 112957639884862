import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { IMAGES_URL } from '../../../../../../environments/environment';
import { DelayedOrderCart } from '../../../../../core/models/delayed-order';
import { Product } from '../../../../../core/models/product';

@Component({
  selector: 'app-order-card-item',
  templateUrl: './order-card-item.component.html',
  styleUrls: ['./order-card-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderCardItemComponent implements OnInit {
  @Input() orderCard: DelayedOrderCart;
  @Input() ordersProducts: Record<number, Product>;
  @Output() decrementedAmount: EventEmitter<any> = new EventEmitter();
  @Output() incrementedAmount: EventEmitter<any> = new EventEmitter();
  @Output() deleted: EventEmitter<any> = new EventEmitter();
  IMAGES_URL = IMAGES_URL;

  constructor() {}

  ngOnInit() {}

  decrementAmount() {
    this.decrementedAmount.emit();
  }

  incrementAmount() {
    this.incrementedAmount.emit();
  }

  deleteCartItem() {
    this.deleted.emit();
  }
}
