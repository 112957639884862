import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NotificationEffects } from './notification.effects';
import { notificationReducer } from './notification.reducers';

@NgModule({
  imports: [StoreModule.forFeature('notification', notificationReducer), EffectsModule.forFeature([NotificationEffects])],
})
export class NotificationStoreModule {}
