import { Injectable } from '@angular/core';
import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ChatLikesService {
  constructor(private feathers: FeathersService) {}

  get service() {
    return this.feathers.service('message-likes');
  }

  toggleLike(type: string, typeId: number, messageId: number, isLikeOrDislike: boolean): any {
    return this.service.create({ type, typeId, messageId, like: isLikeOrDislike });
  }
}
