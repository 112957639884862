import { AbstractControl, ValidatorFn } from '@angular/forms';

export function atLeastOneGreaterThanZeroValidator(): ValidatorFn {
  return (group: AbstractControl): { [key: string]: any } | null => {
    const controls = group.value;
    for (const key in controls) {
      if (controls[key] > 0) {
        return null; // valid
      }
    }
    return { atLeastOneGreaterThanZero: true }; // invalid
  };
}
