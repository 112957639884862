import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { pagesToggleService } from '../../../../@pages/services/toggler.service';
import { AppState } from '../../../../store/app.state';
import { CartActions, CartSelectors } from '../../../../store/cart';
import { UpdateRecommendedOrderInCart } from '../../../../store/cart/cart.actions';
import { RecommendOrderBonus } from '../../../../store/order/order.model';
import { outletSelectors } from '../../../../store/outlet/outlet.selectors';
import { BasketStateType } from '../basket.component';

@Component({
  selector: 'app-recommended-orders',
  templateUrl: './recommended-orders.component.html',
  styleUrls: ['./recommended-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendedOrdersComponent implements OnInit {
  currentRecommendedOrderId: number;
  @Input()
  public set recommendOrderBonus(value: RecommendOrderBonus[]) {
    this._recommendOrderBonus = value;
  }
  public get recommendOrderBonus(): RecommendOrderBonus[] {
    return this._recommendOrderBonus;
  }
  private _recommendOrderBonus: RecommendOrderBonus[];
  @Input()
  public set type(value: string) {
    this._type = value;
    if (value !== BasketStateType.DEFAULT) {
      this.showProgress = true;
      if (this.recommendOrderBonus && this.recommendOrderBonus.length) {
        this.onChangeOrder(this.recommendOrderBonus[0].id);
      }
      this.cdr.detectChanges();
    } else {
      this.showProgress = false;
      this.cdr.detectChanges();
    }
  }
  public get type(): string {
    return this._type;
  }
  private _type: string;
  @Output() changedOrder: EventEmitter<number> = new EventEmitter();

  readonly totalAmountRecommendedCart$ = this.store.select(CartSelectors.totalRecommendedCartAmount);
  readonly numberOfRecommendedCart$ = this.store.select(CartSelectors.numberOfRecommendedCart);
  readonly activeOutlet$ = this.store.select(outletSelectors.getActive);
  readonly dateDelivery$ = this.store.select(CartSelectors.nextDeliveryDate);
  readonly changesRecommendedCart$ = this.store.select(CartSelectors.changesRecommendedCart);

  showProgress: boolean = false;

  constructor(private store: Store<AppState>, private toggler: pagesToggleService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.store.dispatch(new CartActions.LoadDeliveryDate());
    this.toggler.basketToggle.subscribe((open) => {
      if (!open) {
        this.showProgress = false;
        this.cdr.detectChanges();
      }
    });
  }

  onClosedProgress() {
    this.showProgress = false;
    this.store.dispatch(new CartActions.LoadCart());
    this.changedOrder.emit(null);
  }

  onOpenProgress() {
    this.showProgress = true;
    this.onChangeOrder(this.recommendOrderBonus[0].id);
  }

  onChangeOrder(recommendedOrderId: number) {
    this.changedOrder.emit(recommendedOrderId);
    this.currentRecommendedOrderId = recommendedOrderId;
  }

  onUpdateRecommendOrder() {
    this.store.dispatch(new UpdateRecommendedOrderInCart({ recommendedOrderId: this.currentRecommendedOrderId }));
  }
}
