import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinimalCardRulesComponent } from './minimal-card-rules.component';

@NgModule({
  declarations: [MinimalCardRulesComponent],
  exports: [MinimalCardRulesComponent],
  imports: [CommonModule],
})
export class MinimalCardRulesModule {}
