import { AsyncItem } from './async-item';
import { Deserializable } from './deserializable';
import { Client } from '@Mesh/core/models/client';

export class User implements Deserializable {
  addresses: string;
  avatar: string;
  client: Client;
  email: string;
  id: number;
  name: string;
  permissions: { addressSapId: number; permissions: ('ADD_USER' | 'ORDR_TSK' | 'ADDR_BOSS')[] }[];
  phone: string;
  surname: string;
  username: string;
  position: string;
  possibleClientSapId: number;
  possibleEmail: string;
  possiblePhone: string;
  status: string;
  type: 'OWNER' | 'EMPLOYEE' | 'MANAGER';
  permissionsIcons?: string[];

  declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
  }

  get short_name(): string {
    if (this.client.clientName) {
      return this.client.clientName.substr(0, 1).toUpperCase();
    }
  }

  get town(): string {
    const regex = /(г(.*))(ул)/gm;
    const groups = regex.exec(this.client?.urAdr);
    return groups !== null && groups.length > 0 ? groups[1] : '';
  }

  get full_name(): string {
    const result = [];
    if (this.name) {
      result.push(this.name);
    }
    if (this.surname) {
      result.push(this.surname);
    }
    return result.length ? result.join(' ') : '';
  }

  deserialize(input: any): this {
    if (!input) {
      return null;
    }
    Object.assign(this, input);
    return this;
  }
}

export interface IUserInfo {
  accessTokenExpires: number;
  refreshTokenExpires: number;
  accessToken: string;
  refreshToken: string;
  user: User;
}

export interface IUserInfoResponse {
  accessTokenExpires: number;
  refreshTokenExpires: number;
  access_token: string;
  refresh_token: string;
  user: User;
}

export interface IUserData {
  id: number;
  name: string;
  phone: string;
  status: string;
  type: string;
  username: string;
  avatar: string;
  email: string;
  possibleClientSapId: number;
  possibleEmail: string;
  possiblePhone: string;
  position: string;
  client: Client;
  permissions: { addressSapId: number; permissions: ('ADD_USER' | 'ORDR_TSK' | 'ADDR_BOSS')[] }[];
  addresses: string;
}

export type AsyncUser = AsyncItem<User>;
