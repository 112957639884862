import { Observable } from 'rxjs';
import { Product, Stock } from '../../../core/models/product';

export abstract class CartProductsFacade {
  abstract increment(product: Product): void;
  abstract decrement(product: Product): void;
  abstract changeAmount({ product, amount }): void;
  abstract toggleFavorite(product): void;
  abstract getIsFavorite(product): Observable<boolean>;
  abstract getQuantity(product: Product): Observable<any>;
  abstract getPossibleToDecrement(product: Product): Observable<any>;
  abstract getPossibleToIncrement(product: Product): Observable<any>;
  abstract getMaxQuantity(product: Product): Observable<any>;
  abstract getStock(product: Product): Observable<any>;
  abstract getStockMap(): Observable<any>;
  abstract setStock(productId: number, stock: Stock): void;
  abstract setProduct(products: Product[]): void;
  abstract getProductStockMap(): Observable<any>;
}
