import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Product } from '../../../../core/models/product';

@Component({
  selector: 'app-chat-task-product',
  templateUrl: './chat-task-product.component.html',
  styleUrls: ['./chat-task-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTaskProductComponent implements OnInit {
  @Input() products: Product[];
  @Input() disabled: boolean;
  constructor() {}

  ngOnInit() {}
}
