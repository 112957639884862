import { Directive, OnInit, ElementRef, HostListener, Input, ComponentRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayRef, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Directive({ selector: '[appOverlay]' })
export class AppOverlayDirective implements OnInit {
  private overlayRef: OverlayRef;

  @Input('appOverlay') template: TemplateRef<ElementRef>;

  open = false;
  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay
  ) {}

  @HostListener('click')
  click() {
    this.open = !this.open;
    if (this.open) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    const tooltipPortal = new TemplatePortal<ElementRef>(this.template, this.viewContainerRef);
    this.overlayRef.attach(tooltipPortal);
  }

  hide() {
    this.overlayRef.detach();
  }

  ngOnInit() {
    const positionStrategy = this.overlayPositionBuilder.connectedTo(
      this.elementRef,
      { originX: 'center', originY: 'top' },
      { overlayX: 'center', overlayY: 'bottom' }
    );
    // .flexibleConnectedTo(this.elementRef)
    // .withPositions([{
    //   originX: 'center',
    //   originY: 'top',
    //   overlayX: 'center',
    //   overlayY: 'bottom',
    // }]);
    this.overlayRef = this.overlay.create({ positionStrategy });
  }
}
