import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { DelayedOrderHistory } from '../../../../../core/models/delayed-order';

@Component({
  selector: 'app-delayed-order-history-item',
  templateUrl: './delayed-order-history-item.component.html',
  styleUrls: ['./delayed-order-history-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelayedOrderHistoryItemComponent implements OnInit {
  @Input() orderHistory: DelayedOrderHistory;

  constructor() {}

  ngOnInit() {}
}
