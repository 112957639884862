import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { OrderCompletedItem } from '../../../../../store/cart/cart.models';

@Component({
  selector: 'app-basket-order-product-item',
  templateUrl: './basket-order-product-item.component.html',
  styleUrls: ['./basket-order-product-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketOrderProductItemComponent implements OnInit {
  @Input() orderProduct: OrderCompletedItem;

  constructor() {}

  ngOnInit() {}
}
