import { Action } from '@ngrx/store';
import { ClientBonus } from '@Mesh/core/models/client-bonus';
import { ClientBonusPredict } from '@Mesh/core/models/client-bonus-predict';
import { GetOutletBonusCriteria } from '@Mesh/core/models/request/get-outlet-bonus-criteria';
import { Bonus } from '@Mesh/core/models/bonus';
import { OutletBonusPredict } from '@Mesh/core/models/outlet-bonus-predict';

export enum BonusActionsTypes {
  ClientBonusLoadRequest = '[Bonus] Client bonus Request',
  ClientBonusLoadSuccess = '[Bonus] Client bonus Success',
  ClientBonusLoadError = '[Bonus] Client bonus Error',

  ClientBonusPredictLoadRequest = '[Bonus] Predict client bonus Request',
  ClientBonusPredictLoadSuccess = '[Bonus] Predict client bonus Success',
  ClientBonusPredictTotalAmountLoadSuccess = '[Bonus] Predict client bonus total amount Success',
  ClientBonusPredictLoadError = '[Bonus] Predict client bonus Error',

  ClientsBonusByPeriodLoadRequest = '[Bonus] Clients bonus by period Request',
  ClientsBonusByPeriodLoadSuccess = '[Bonus] Clients bonus by period Success',
  ClientsBonusByPeriodLoadError = '[Bonus] Clients bonus by period Error',

  OutletBonusesLoadRequest = '[Bonus] Outlet bonus Request',
  OutletBonusesLoadSuccess = '[Bonus] Outlet bonus Success',
  OutletBonusesLoadError = '[Bonus] Outlet bonus Error',

  OutletBonusPredictLoadRequest = '[Bonus] Outlet bonus predict Request',
  OutletBonusPredictLoadSuccess = '[Bonus] Outlet bonus predict Success',
  OutletBonusPredictLoadError = '[Bonus] Outlet bonus predict Error',

  OutletBonusesPredictLoadRequest = '[Bonus] Outlet bonuses predict Request',
  OutletBonusesPredictLoadSuccess = '[Bonus] Outlet bonuses predict Success',
  OutletBonusesPredictLoadError = '[Bonus] Outlet bonuses predict Error',

  OutletAwaitingSumLoadRequest = '[Bonus] Outlet awaiting sum Request',
  OutletAwaitingSumLoadRequestSuccess = '[Bonus] Outlet awaiting sum Success',
  OutletAwaitingSumLoadRequestError = '[Bonus] Outlet awaiting sum Error',

  LoadPrediction = '[Bonus] Load Prediction',
  LoadPredictionSuccess = '[Bonus] Load Prediction success',
  LoadPredictionFailure = '[Bonus] Load Prediction failure',

  SetActiveBonus = '[Bonus] Set Active bonus',
  ResetBonusValue = '[Bonus] Reset bonus value',
  SetActiveBonusAndPredictBonusByPlanId = '[Bonus] SetActiveBonusById',

  SelectedUserBonusBalanceLoad = '[Bonus] SelectedUser bonus balance Load',
  SelectedUserBonusBalanceLoadSuccess = '[Bonus] SelectedUser bonus balance Success',
  SelectedUserBonusBalanceLoadError = '[Bonus] SelectedUser bonus balance Error',
  SelectedUserBonusBalanceReset = '[Bonus] SelectedUser bonus balance Reset',
}

export class ClientBonusLoadRequest implements Action {
  readonly type = BonusActionsTypes.ClientBonusLoadRequest;
}

export class SetActiveBonus implements Action {
  readonly type = BonusActionsTypes.SetActiveBonus;

  constructor(public payload: { bonus: Bonus | null }) {}
}

export class SetActiveBonusAndPredictBonusByPlanId implements Action {
  readonly type = BonusActionsTypes.SetActiveBonusAndPredictBonusByPlanId;

  constructor(public payload: { planId: number | null }) {}
}

export class ClientBonusLoadSuccess implements Action {
  readonly type = BonusActionsTypes.ClientBonusLoadSuccess;

  constructor(public payload: { data: ClientBonus }) {}
}

export class ClientBonusLoadError implements Action {
  readonly type = BonusActionsTypes.ClientBonusLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class ClientBonusPredictLoadRequest implements Action {
  readonly type = BonusActionsTypes.ClientBonusPredictLoadRequest;
}

export class ClientBonusPredictLoadSuccess implements Action {
  readonly type = BonusActionsTypes.ClientBonusPredictLoadSuccess;

  constructor(public payload: { data: ClientBonusPredict }) {}
}

export class ClientBonusPredictTotalAmountLoadSuccess implements Action {
  readonly type = BonusActionsTypes.ClientBonusPredictTotalAmountLoadSuccess;

  constructor(public payload: { total: number }) {}
}

export class ClientBonusPredictLoadError implements Action {
  readonly type = BonusActionsTypes.ClientBonusPredictLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class ClientsBonusByPeriodLoadRequest implements Action {
  readonly type = BonusActionsTypes.ClientsBonusByPeriodLoadRequest;

  constructor(public payload: { startAt: string; finishAt: string }) {}
}

export class ClientsBonusByPeriodLoadSuccess implements Action {
  readonly type = BonusActionsTypes.ClientsBonusByPeriodLoadSuccess;

  constructor(public payload: { data: ClientBonus[] }) {}
}

export class ClientsBonusByPeriodLoadError implements Action {
  readonly type = BonusActionsTypes.ClientsBonusByPeriodLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class OutletBonusesLoadRequest implements Action {
  readonly type = BonusActionsTypes.OutletBonusesLoadRequest;

  constructor(public payload: GetOutletBonusCriteria) {}
}

export class OutletBonusesLoadSuccess implements Action {
  readonly type = BonusActionsTypes.OutletBonusesLoadSuccess;

  constructor(public payload: { data: Bonus[]; bonusSum: number }) {}
}

export class OutletBonusesLoadError implements Action {
  readonly type = BonusActionsTypes.OutletBonusesLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class OutletBonusPredictLoadRequest implements Action {
  readonly type = BonusActionsTypes.OutletBonusPredictLoadRequest;
}

export class OutletBonusPredictLoadSuccess implements Action {
  readonly type = BonusActionsTypes.OutletBonusPredictLoadSuccess;

  constructor(public payload: { data: OutletBonusPredict }) {}
}

export class OutletBonusPredictLoadError implements Action {
  readonly type = BonusActionsTypes.OutletBonusPredictLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class OutletBonusesPredictLoadRequest implements Action {
  readonly type = BonusActionsTypes.OutletBonusesPredictLoadRequest;

  constructor(public payload: GetOutletBonusCriteria) {}
}

export class OutletBonusesPredictLoadSuccess implements Action {
  readonly type = BonusActionsTypes.OutletBonusesPredictLoadSuccess;

  constructor(public payload: { data: Bonus[]; bonusPredictSum: number }) {}
}

export class OutletBonusesPredictLoadError implements Action {
  readonly type = BonusActionsTypes.OutletBonusesPredictLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class OutletAwaitingSumLoadRequest implements Action {
  readonly type = BonusActionsTypes.OutletAwaitingSumLoadRequest;

  constructor(public payload: GetOutletBonusCriteria) {}
}

export class OutletAwaitingSumLoadSuccess implements Action {
  readonly type = BonusActionsTypes.OutletAwaitingSumLoadRequestSuccess;

  constructor(public payload: { data: number }) {}
}

export class OutletAwaitingSumLoadError implements Action {
  readonly type = BonusActionsTypes.OutletAwaitingSumLoadRequestError;

  constructor(public error: { errors: any[] | null }) {}
}

export class LoadPrediction implements Action {
  readonly type = BonusActionsTypes.LoadPrediction;
}

export class LoadPredictionSuccess implements Action {
  readonly type = BonusActionsTypes.LoadPredictionSuccess;

  constructor(public data: Bonus[]) {}
}

export class LoadPredictionFailure implements Action {
  readonly type = BonusActionsTypes.LoadPredictionFailure;
}

export class ResetBonusValue implements Action {
  readonly type = BonusActionsTypes.ResetBonusValue;
}

export class SelectedUserBonusBalanceLoad implements Action {
  readonly type = BonusActionsTypes.SelectedUserBonusBalanceLoad;

  constructor(public payload: { userId: number }) {}
}

export class SelectedUserBonusBalanceLoadSuccess implements Action {
  readonly type = BonusActionsTypes.SelectedUserBonusBalanceLoadSuccess;

  constructor(public payload: { bonus: number }) {}
}

export class SelectedUserBonusBalanceLoadError implements Action {
  readonly type = BonusActionsTypes.SelectedUserBonusBalanceLoadError;

  constructor(public error: { errors: any[] | null }) {}
}
export class SelectedUserBonusBalanceReset implements Action {
  readonly type = BonusActionsTypes.SelectedUserBonusBalanceReset;
  constructor() {}
}

export type ActionsUnion =
  | ClientBonusLoadRequest
  | ClientBonusLoadSuccess
  | ClientBonusLoadError
  | ClientBonusPredictLoadRequest
  | ClientBonusPredictLoadSuccess
  | ClientBonusPredictLoadError
  | ClientBonusPredictTotalAmountLoadSuccess
  | OutletBonusesLoadRequest
  | OutletBonusesLoadSuccess
  | OutletBonusesLoadError
  | OutletBonusesPredictLoadRequest
  | OutletBonusesPredictLoadSuccess
  | OutletBonusesPredictLoadError
  | OutletBonusPredictLoadRequest
  | OutletBonusPredictLoadSuccess
  | OutletBonusPredictLoadError
  | ClientsBonusByPeriodLoadRequest
  | ClientsBonusByPeriodLoadSuccess
  | ClientsBonusByPeriodLoadError
  | OutletAwaitingSumLoadRequest
  | OutletAwaitingSumLoadSuccess
  | OutletAwaitingSumLoadError
  | LoadPrediction
  | LoadPredictionSuccess
  | LoadPredictionFailure
  | SetActiveBonus
  | ResetBonusValue
  | SetActiveBonusAndPredictBonusByPlanId
  | SelectedUserBonusBalanceLoad
  | SelectedUserBonusBalanceLoadSuccess
  | SelectedUserBonusBalanceLoadError
  | SelectedUserBonusBalanceReset;
