import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewChecked,
  ChangeDetectorRef,
  isDevMode,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { pagesToggleService } from '../../../@pages/services/toggler.service';
import { Notifications, NotService } from '../../../core/services';

@Component({
  selector: 'app-quickview-basket',
  templateUrl: './quickview-basket.component.html',
  styleUrls: ['./quickview-basket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickviewBasketComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  oldBasket: boolean = false;
  private _isOpen: boolean = false;

  get isOpen(): boolean {
    return this._isOpen;
  }

  set isOpen(value: boolean) {
    this._isOpen = value;
    this.cdr.detectChanges();
    if (!value) {
      const queryParams = {
        action: null,
        action_type: null,
        action_id: null,
        sent_by: null,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      });
    }
  }

  constructor(
    private toggler: pagesToggleService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotService
  ) {}

  get isDevMode() {
    return isDevMode();
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.toggler.basketToggle.subscribe((message) => {
        this.toggle();
      })
    );
    this.subscriptions.push(
      this.notificationService.subscribe(Notifications.MenuOpened, {
        next: (val) => {
          if (val && window.innerWidth <= 600 && this.isOpen) {
            this.isOpen = false;
          }
        },
      })
    );
    this.route.queryParams
      .filter((params) => params.action)
      .subscribe((params) => {
        if (params.action === 'basket') {
          if (!this.isOpen) {
            this.toggler.toggleBacket(true);
          }
        }
      });
  }

  closeQuickview() {
    if (this.isOpen) {
      this.toggler.toggleBacket(false);
    }
  }

  toggle() {
    const bodyNode = document.body || document.getElementsByTagName('body')[0];
    if (this.isOpen) {
      this.isOpen = false;
      bodyNode.classList.remove('open-basket');
    } else {
      bodyNode.classList.add('open-basket');
      this.isOpen = true;
    }
    this.cdr.detectChanges();
  }

  onChangedBasket() {
    this.oldBasket = !this.oldBasket;
    this.cdr.markForCheck();
  }
}
