import { Component, OnInit, ElementRef, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';

type ChatMenuEvent = { sender: HTMLElement };

export enum MenuActions {
  EDIT = 'edit',
  REMOVE = 'remove',
  FORWARD = 'forward',
  QUOTE = 'quote',
}

@Component({
  selector: 'app-chat-dialog-context-menu',
  templateUrl: './chat-dialog-context-menu.component.html',
  styleUrls: ['./chat-dialog-context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatDialogContextMenuComponent implements OnInit {
  @Input() actions: {
    [MenuActions.EDIT]: boolean;
    [MenuActions.REMOVE]: boolean;
    [MenuActions.FORWARD]: boolean;
    [MenuActions.QUOTE]: boolean;
  };
  @Output() edit = new EventEmitter();
  @Output() copy = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() forward = new EventEmitter();
  @Output() quote = new EventEmitter();
  show = false;

  get editable() {
    return !this.actions || this.actions[MenuActions.EDIT];
  }
  get removable() {
    return !this.actions || this.actions[MenuActions.REMOVE];
  }
  get forwardable() {
    return !this.actions || this.actions[MenuActions.FORWARD];
  }
  get quotable() {
    return !this.actions || this.actions[MenuActions.QUOTE];
  }

  constructor(private elRef: ElementRef) {}

  ngOnInit() {}
}
