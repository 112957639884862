import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
  Input,
  AfterViewChecked,
} from '@angular/core';
import { ChatService } from '../chat.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { User } from '@Mesh/core/models/user';
import { ChatDialogService } from '../chat-dialog/chat-dialog.service';
import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { ChatSettingsService } from '@Mesh/core/services/chat/chat-settings.service';
import { IMessagesList, MessagesService } from '@Mesh/core/services/chat/messages.service';
import { UserService } from '@Mesh/core/services/user.service';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { pagesToggleService } from '../../../../@pages/services/toggler.service';
@Component({
  selector: 'app-chat-main-page',
  templateUrl: './chat-main-page.component.html',
  styleUrls: ['./chat-main-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMainPageComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  u$ = new Subject();
  communitiesSwiperIndex = 0;
  communities$: Observable<any[]>;
  current_user: User;
  searchDialogQuery: string = '';
  dialogs$: Observable<IMessagesList>;
  dialogsData: any;
  taskDialogs: any;
  chatDialogs: any;
  total: number;
  dialogsLoading: boolean = false;
  private _users: any[];
  private _searchType: boolean = true;

  @Output('onCreateChatWith') onCreateChatWith = new EventEmitter();
  @Output('onOpenChatWith') onOpenChatWith = new EventEmitter();
  @Output('onUserToChat') onUserToChat = new EventEmitter();

  swiperConfig: SwiperConfigInterface = {
    init: true,
    observer: true,
    direction: 'horizontal',
    initialSlide: 0,
    // spaceBetween: 16,
    slidesPerView: 'auto',
    freeMode: true,
    preloadImages: true,
    pagination: false,
    centeredSlides: false,
  };

  @ViewChildren(SwiperDirective) swiperViewes: QueryList<SwiperDirective>;
  @ViewChild(SwiperDirective) swiper: SwiperDirective;

  constructor(
    private chatService: ChatService,
    private messagesService: MessagesService,
    private chatSettingsService: ChatSettingsService,
    private chatDialogService: ChatDialogService,
    private cdr: ChangeDetectorRef,
    private feathersService: FeathersService,
    private userService: UserService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private pagesToggleService: pagesToggleService
  ) {}

  ngAfterViewChecked(): void {}

  ngAfterViewInit() {
    this.swiperViewes.changes.pipe(takeUntil(this.u$)).subscribe(() => {
      this.swiperViewes.forEach((item) => item.update());
      //this.swiper.update();
    });
  }

  get users(): any[] {
    return this._users;
  }

  set users(value: any[]) {
    this._users = value;

    this.fixSearch();
  }

  get searchType(): boolean {
    return this._searchType;
  }

  set searchType(value: boolean) {
    this._searchType = value;
  }

  search(value) {
    this.searchDialogQuery = value;
    this.updateChats();
  }

  onSettings(settings) {}

  updateChats() {
    this.dialogsLoading = true;
    const query = {};
    if (this.searchDialogQuery && this.searchDialogQuery.length > 0) {
      query['$like'] = this.searchDialogQuery;
    }
    this.messagesService.findChats(query);
  }

  ngOnDestroy() {
    this.u$.next();
    this.u$.unsubscribe();
  }

  fixSearch() {
    if (
      this.searchDialogQuery &&
      this.searchDialogQuery.length > 0 &&
      this.dialogsData?.data &&
      this.dialogsData?.data?.length === 0 &&
      this.users &&
      this.users.length > 0
    ) {
      this.searchType = false;
    }
    if (
      this.searchDialogQuery &&
      this.searchDialogQuery.length > 0 &&
      this.users &&
      this.users.length === 0 &&
      this.dialogsData?.data?.length > 0
    ) {
      this.searchType = true;
    }
  }

  ngOnInit() {
    // this.messagesService.dialogService().on('patched', async patchedChat => {
    //   this.updateChats();
    // });

    this.messagesService.subjects['users-chat/list'].pipe(takeUntil(this.u$)).subscribe((dialogs) => {
      this.dialogsData = dialogs;
      this.chatDialogs = (this.dialogsData?.data || []).filter((item) => item.type === 'task-comments');
      if (dialogs.data) {
        const totalUnread = dialogs.data.reduce((accumulator, dialog) => accumulator + dialog.totalUnread, 0);
        this.pagesToggleService.setHasMessages(totalUnread > 0);
      }
      this.total = dialogs.total;

      this.fixSearch();

      this.cdr.detectChanges();
      this.dialogsLoading = false;
    });

    //console.log('dialogs:', this.dialogsData);
    //console.log('updateAgain');
    this.updateChats();
    /*this.chatSettingsService.service.on('patched', (data) => {
      // //console.log(data);
      this.updateChats();
    });

    this.chatSettingsService.service.on('created', (data) => {
      // //console.log(data);
      this.updateChats();
    });*/

    this.chatDialogService.messagesSubject.pipe(takeUntil(this.u$)).subscribe(({ type, data }) => {
      if (type === 'created') {
        this.updateChats();
      }
    });

    /*this.chatSettingsService.service.on('removed', (data) => {
      // //console.log(data);
      this.updateChats();
    });*/

    this.chatService.updateDialogs.pipe(takeUntil(this.u$)).subscribe(() => {
      this.updateChats();
    });

    this.feathersService.currentUserSubject.pipe(takeUntil(this.u$)).subscribe((user) => {
      this.current_user = user;
    });
  }

  userToChat(user) {
    this.onUserToChat.emit(user);
  }

  loadMoreAvailDialogs(update?) {
    // tslint:disable-next-line: curly
    //if (!update && this.dialogsData?.data?.length >= this.total) return;
    //this.messagesService.findChats({$limit: 10, $skip: this.dialogsData.data.length});
  }
}
