import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ChatService } from '../chat.service';
import { first } from 'rxjs/operators';
import { User } from '@Mesh/core/models/user';

@Component({
  selector: 'app-chat-users-list',
  templateUrl: './chat-users-list.component.html',
  styleUrls: ['./chat-users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatUsersListComponent implements OnInit {
  @Output() userSelect = new EventEmitter<{ user: User }>();
  @Output() usersEvent = new EventEmitter<User[]>();
  @Input() set searchUsers(value) {
    if (this.searchQuery === value) return;
    this.searchQuery = value;
    this.resetUsers();
    this.loadMoreUsers();
  }
  u$ = new Subject();
  usersToChat$: Observable<any[]>;
  groups;
  page = 0;
  users = [];
  searchQuery;
  constructor(private chatService: ChatService, private cdr: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.u$.next();
    this.u$.unsubscribe();
  }
  ngOnInit() {
    this.loadMoreUsers();
  }

  resetUsers() {
    this.users = [];
    this.page = 0;
  }

  trackGroupsBy(i, group) {
    return `group-${group.key}`;
  }

  trackUsersBy(i, user) {
    return `group-user-${user.id}`;
  }

  loadMoreUsers() {
    /* this.usersToChat$ =  */ this.chatService
      .getUsers({ page: this.page++, searchQuery: this.searchQuery })
      .pipe(first())
      .subscribe(({ data }) => {
        this.users = [...this.users, ...data];
        this.usersEvent.emit(this.users);
        const grouped_obj = this.users.reduce((accum, item) => {
          const first_letter = item.surname?.charAt(0) || '';
          (accum[first_letter] = accum[first_letter] || []).push(item);
          return accum;
        }, {});
        this.groups = Object.keys(grouped_obj)
          .map((key) => ({ key: key, items: grouped_obj[key] }))
          .sort((v1, v2) => {
            return v1.key > v2.key ? 1 : -1;
          });
        this.cdr.detectChanges();
      });
  }
}
