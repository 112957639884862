import { Injectable } from '@angular/core';
import { API_USER_URL } from '@Env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GetDocumentCriteria } from '@Mesh/core/models/request/get-document-criteria';
import { Observable } from 'rxjs';
import { stringifyObject } from '@Mesh/core/services/util';
import { IPageableList } from '@Mesh/core/models/pageable';
import { Documents } from '@Mesh/core/models/documents';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  readonly baseUrl = `${API_USER_URL}/document`;

  constructor(private http: HttpClient) {}

  getDocuments(param: GetDocumentCriteria): Observable<IPageableList<Documents>> {
    console.log('PARAMS::::', param);
    const stringData = stringifyObject(param);
    const params = new HttpParams({ fromObject: stringData });
    return this.http.get<IPageableList<Documents>>(`${this.baseUrl}`, { params });
  }

  signIQRetail(docId: number): Observable<{ identifierId: string }> {
    return this.http.get<{ identifierId: string }>(`${this.baseUrl}/confirm-sign?documentIds=${docId}`);
  }

  signedIQRetail(body: { code: string; identifier: string; documentIds: number[] }): Observable<{ documentId: number }> {
    return this.http.post<{ documentId: number }>(`${this.baseUrl}/sign-all`, body);
  }

  downloadFile(url: string, filename: string): void {
    this.http.get(url, { responseType: 'blob' as 'json' }).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      if (filename) {
        downloadLink.setAttribute('download', filename);
      }
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }
}
