import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppState } from '@Mesh/store/app.state';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { OutletLoadPageRequest } from '@Mesh/store/outlet/outlet.actions';

@Injectable({ providedIn: 'root' })
export class ActiveGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  activeOutlet = JSON.parse(localStorage.getItem('IQR-outlet__storage'));

  canActivate(): Observable<boolean> {
    return this.store.select(outletSelectors.getActive).pipe(
      tap((active) => {
        if (!active && !this.activeOutlet?.active) {
          return this.store.dispatch(new OutletLoadPageRequest({ page: 0, itemsPerPage: 10 }));
        }
      }),
      map(() => {
        return true;
      })
    );
  }
}
