import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatNotificationsListComponent } from './chat-notifications-list.component';
import { ChatNotificationsListItemComponent } from './chat-notifications-list-item/chat-notifications-list-item.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PreventParentScrollModule } from 'ngx-prevent-parent-scroll';

@NgModule({
  declarations: [ChatNotificationsListComponent, ChatNotificationsListItemComponent],
  exports: [ChatNotificationsListComponent],
  imports: [CommonModule, InfiniteScrollModule, PreventParentScrollModule],
})
export class ChatNotificationsListModule {}
