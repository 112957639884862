export const companyMock = {
  tasks: [],
  outlet: {
    addressSapId: 800426452,
    addressName: 'Владимирская обл. Владимир г Нижняя Дуброва ул д.36 тел.89004817482',
    clientSapId: 300029638,
    clientName: 'Ушаков Павел Викторович ИП',
    room: null,
    building: null,
    cityName: 'Владимир г',
    cityName1: null,
    district: null,
    guid: 'ZSTREET0000000000000000000001532',
    street: 'Нижняя Дуброва ул',
    house: 'д.36',
    housing: null,
    plantSapId: '330I',
    postcode: null,
    regionCode: '33',
    regionName: 'Владимирская обл.',
    remark: null,
    salesOrgSapId: '330E',
    segmentName: 'Silver',
    subdistributor: null,
    latitude: 56.105411,
    longitude: 40.3469689,
    users: null,
  },
  client: {
    clientSapId: 300029638,
    clientName: 'Ушаков Павел Викторович ИП',
    inn: '332805004496',
    kpp: null,
    ogrn: '304332823200012',
    urAdr: 'Владимирская обл. Владимир г Верхняя Дуброва ул рынок "Слобода"',
  },
  started: 15,
  completed: 0,
  predict: 0,
  prepayment: 0,
};

export const historyMock = {
  historyData: [
    {
      docNumber: '330EЗАН10834',
      docDate: '2021-08-31',
      countProduct: 15,
      cost: 25357.2,
      crTime: '09:55:55',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10834',
      docDateTime: '2021-08-31T09:55:55',
      delivered: null,
    },
    {
      docNumber: '330EПЛА00832',
      docDate: '2021-08-27',
      countProduct: 5,
      cost: 7029.72,
      crTime: '15:19:51',
      sapStatus: null,
      status: 2,
      orderNumber: '330EПЛА00832',
      docDateTime: '2021-08-27T15:19:51',
      delivered: null,
    },
    {
      docNumber: '330EПЛА00771',
      docDate: '2021-08-24',
      countProduct: 38,
      cost: 63406.56,
      crTime: '10:28:04',
      sapStatus: null,
      status: 2,
      orderNumber: '330EПЛА00771',
      docDateTime: '2021-08-24T10:28:04',
      delivered: null,
    },
    {
      docNumber: '330EПЛА00746',
      docDate: '2021-08-20',
      countProduct: 18,
      cost: 21173.09,
      crTime: '15:11:52',
      sapStatus: null,
      status: 2,
      orderNumber: '330EПЛА00746',
      docDateTime: '2021-08-20T15:11:52',
      delivered: null,
    },
    {
      docNumber: '330EПЛА00685',
      docDate: '2021-08-17',
      countProduct: 27,
      cost: 43086.96,
      crTime: '09:32:20',
      sapStatus: null,
      status: 2,
      orderNumber: '330EПЛА00685',
      docDateTime: '2021-08-17T09:32:20',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10806',
      docDate: '2021-08-13',
      countProduct: 10,
      cost: 13293.42,
      crTime: '14:11:32',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10806',
      docDateTime: '2021-08-13T14:11:32',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10739',
      docDate: '2021-08-10',
      countProduct: 28,
      cost: 40748.73,
      crTime: '09:49:56',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10739',
      docDateTime: '2021-08-10T09:49:56',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10711',
      docDate: '2021-08-06',
      countProduct: 6,
      cost: 10888.92,
      crTime: '13:56:28',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10711',
      docDateTime: '2021-08-06T13:56:28',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10638',
      docDate: '2021-08-03',
      countProduct: 18,
      cost: 32406.36,
      crTime: '09:38:51',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10638',
      docDateTime: '2021-08-03T09:38:51',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10610',
      docDate: '2021-07-30',
      countProduct: 21,
      cost: 28412.28,
      crTime: '15:53:33',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10610',
      docDateTime: '2021-07-30T15:53:33',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10542',
      docDate: '2021-07-27',
      countProduct: 12,
      cost: 19019.64,
      crTime: '09:50:16',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10542',
      docDateTime: '2021-07-27T09:50:16',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10514',
      docDate: '2021-07-23',
      countProduct: 12,
      cost: 18816.84,
      crTime: '14:50:35',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10514',
      docDateTime: '2021-07-23T14:50:35',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10450',
      docDate: '2021-07-20',
      countProduct: 16,
      cost: 38060.16,
      crTime: '10:03:40',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10450',
      docDateTime: '2021-07-20T10:03:40',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10449',
      docDate: '2021-07-20',
      countProduct: 15,
      cost: 20912.2,
      crTime: '10:03:17',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10449',
      docDateTime: '2021-07-20T10:03:17',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10421',
      docDate: '2021-07-16',
      countProduct: 26,
      cost: 40002.29,
      crTime: '15:20:58',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10421',
      docDateTime: '2021-07-16T15:20:58',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10355',
      docDate: '2021-07-13',
      countProduct: 16,
      cost: 27831.36,
      crTime: '10:00:04',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10355',
      docDateTime: '2021-07-13T10:00:04',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10326',
      docDate: '2021-07-09',
      countProduct: 24,
      cost: 22769.32,
      crTime: '15:51:32',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10326',
      docDateTime: '2021-07-09T15:51:32',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10256',
      docDate: '2021-07-06',
      countProduct: 20,
      cost: 34996.68,
      crTime: '09:50:10',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10256',
      docDateTime: '2021-07-06T09:50:10',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10228',
      docDate: '2021-07-02',
      countProduct: 13,
      cost: 24870.6,
      crTime: '14:59:44',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10228',
      docDateTime: '2021-07-02T14:59:44',
      delivered: null,
    },
    {
      docNumber: '330EЗАН10156',
      docDate: '2021-06-29',
      countProduct: 23,
      cost: 49851.37,
      crTime: '09:43:14',
      sapStatus: null,
      status: 2,
      orderNumber: '330EЗАН10156',
      docDateTime: '2021-06-29T09:43:14',
      delivered: null,
    },
  ],
  tasks: [],
  outlet: {
    addressSapId: 801898712,
    addressName: 'Владимирская обл. Владимир г Сперанского ул д.11 ',
    clientSapId: 300483200,
    clientName: 'Келехсаев Сергей Борисович ИП',
    room: null,
    building: null,
    cityName: 'Владимир г',
    cityName1: null,
    district: null,
    guid: '3E38E7B6DE014EE88E0D58BE3422887F',
    street: 'Сперанского ул',
    house: 'д.11',
    housing: null,
    plantSapId: '330I',
    postcode: null,
    regionCode: '33',
    regionName: 'Владимирская обл.',
    remark: null,
    salesOrgSapId: '330E',
    segmentName: 'Silver',
    subdistributor: null,
    latitude: 56.1069481,
    longitude: 40.3572231,
    users: null,
  },
  orderDetails: [],
};

export const ordersMock = {
  orders: [],
  outlet: {
    addressSapId: 801898712,
    addressName: 'Владимирская обл. Владимир г Сперанского ул д.11 ',
    clientSapId: 300483200,
    clientName: 'Келехсаев Сергей Борисович ИП',
    room: null,
    building: null,
    cityName: 'Владимир г',
    cityName1: null,
    district: null,
    guid: '3E38E7B6DE014EE88E0D58BE3422887F',
    street: 'Сперанского ул',
    house: 'д.11',
    housing: null,
    plantSapId: '330I',
    postcode: null,
    regionCode: '33',
    regionName: 'Владимирская обл.',
    remark: null,
    salesOrgSapId: '330E',
    segmentName: 'Silver',
    subdistributor: null,
    latitude: 56.1069481,
    longitude: 40.3572231,
    users: null,
  },
  orderDetails: [],
};

export const organizationMock = {
  client: {
    clientSapId: 300483200,
    clientName: 'Келехсаев Сергей Борисович ИП',
    inn: '332809689811',
    kpp: null,
    ogrn: '320332800036711',
    urAdr: 'Владимирская обл. Владимир г 1-й Сосенский проезд д.5',
  },
  documents: [],
  users: [],
};

export const targetsMock = {
  plans: [
    {
      planName: 'Табак Вал',
      quant: 1930,
      plan_id: 3500000028,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'ТОП Марки, ПАЧ',
      quant: 1450,
      plan_id: 2222222212,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'Контрацептивы, руб',
      quant: null,
      plan_id: 3500000005,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'PMI Вал',
      quant: 660,
      plan_id: 1,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'JTI Вал',
      quant: 690,
      plan_id: 2,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'ITG Вал',
      quant: 450,
      plan_id: 3,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'ЭТП, руб',
      quant: 4366.33,
      plan_id: 3500000001,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'Duracell, руб',
      quant: 1406.37,
      plan_id: 3500000103,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'IQOS Вал, БЛК',
      quant: 130,
      plan_id: 7,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'Red Bull, руб',
      quant: 932.02,
      plan_id: 3500000039,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'Drive List, ПАЧ',
      quant: 45,
      plan_id: 2222222213,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'ТОП-24. Закупить по 1 блоку',
      quant: 270,
      plan_id: 2222222214,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'Зажигалки, руб',
      quant: null,
      plan_id: 3500000003,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'Зажигалки + Презервативы + Батарейки, руб',
      quant: 1221.31,
      plan_id: 3500000012,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
    {
      planName: 'Эл-ты питания, руб',
      quant: null,
      plan_id: 3500000004,
      keyGroup: null,
      nameGroup: null,
      image: null,
    },
  ],
  bonuses: [],
  predictBonuses: [],
};

export const tasksMock = {
  tasks: [],
};

export const tradePointsMock = {
  outlets: [
    {
      addressSapId: 801898712,
      addressName: 'Владимирская обл. Владимир г Сперанского ул д.11 ',
      clientSapId: 300483200,
      clientName: 'Келехсаев Сергей Борисович ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '3E38E7B6DE014EE88E0D58BE3422887F',
      street: 'Сперанского ул',
      house: 'д.11',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1069481,
      longitude: 40.3572231,
      users: null,
    },
    {
      addressSapId: 800494656,
      addressName: '600033 Владимирская обл. Владимир г Мещерская ул д.4 тел.9209056290',
      clientSapId: 300173945,
      clientName: 'Прунов Сергей Алексеевич ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'ZSTREET0000000000000000000001532',
      street: 'Мещерская ул',
      house: 'д.4',
      housing: null,
      plantSapId: '330I',
      postcode: '600033',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.0975209,
      longitude: 40.3487212,
      users: null,
    },
    {
      addressSapId: 801804554,
      addressName: 'Владимирская обл. Владимир г Диктора Левитана ул д.24 ',
      clientSapId: 200178355,
      clientName: 'АВМ ПЕТРОЛИУМ ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '7F09FF4EB32444E9826F7C3EE6FFC43B',
      street: 'Диктора Левитана ул',
      house: 'д.24',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: null,
      subdistributor: null,
      latitude: 56.1148206,
      longitude: 40.3742164,
      users: null,
    },
    {
      addressSapId: 800426452,
      addressName: 'Владимирская обл. Владимир г Нижняя Дуброва ул д.36 тел.89004817482',
      clientSapId: 300029638,
      clientName: 'Ушаков Павел Викторович ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'ZSTREET0000000000000000000001532',
      street: 'Нижняя Дуброва ул',
      house: 'д.36',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.105411,
      longitude: 40.3469689,
      users: null,
    },
    {
      addressSapId: 801977233,
      addressName: 'Владимирская обл. Владимир г Ленина пр-кт д.7 ',
      clientSapId: 200234879,
      clientName: 'Фаэтон Трейд ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '8B642269C6B54A319FE0A13D959BF86B',
      street: 'Ленина пр-кт',
      house: 'д.7',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1215951,
      longitude: 40.382117,
      users: null,
    },
    {
      addressSapId: 801998730,
      addressName: 'Владимирская обл. Владимир г Гагарина ул д.5 ',
      clientSapId: 200234980,
      clientName: 'ТПК ФАЭТОН ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '7EDD97CC949E45D4B86D5C77DD749447',
      street: 'Гагарина ул',
      house: 'д.5',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1298042,
      longitude: 40.4029626,
      users: null,
    },
    {
      addressSapId: 801321691,
      addressName: '600000 Владимирская обл. Владимир г Большая Московская ул д.19а тел.89046522197',
      clientSapId: 300435870,
      clientName: 'Рыбин А.С. ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'C4E5AAF41494443DA28441231A0EE301',
      street: 'Большая Московская ул',
      house: 'д.19а',
      housing: null,
      plantSapId: '330I',
      postcode: '600000',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1288293,
      longitude: 40.4018521,
      users: null,
    },
    {
      addressSapId: 801321690,
      addressName: '600025 Владимирская обл. Владимир г Дворянская ул д.10 тел.89005862838',
      clientSapId: 300435870,
      clientName: 'Рыбин А.С. ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'DDC23E23A43D4B0FBC4D0A62ACF79E9E',
      street: 'Дворянская ул',
      house: 'д.10',
      housing: null,
      plantSapId: '330I',
      postcode: '600025',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1261039,
      longitude: 40.3926405,
      users: null,
    },
    {
      addressSapId: 801411878,
      addressName: '600001 Владимирская обл. Владимир г Ленина пр-кт д.3 ',
      clientSapId: 200220217,
      clientName: 'ТД Владимирский стандарт ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '9227E7C497304EAAB6FFB4A1971DC70C',
      street: 'Ленина пр-кт',
      house: 'д.3',
      housing: null,
      plantSapId: '330I',
      postcode: '600001',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1214425,
      longitude: 40.3822996,
      users: null,
    },
    {
      addressSapId: 801321595,
      addressName: '600022 Владимирская обл. Владимир г Ленина пр-кт д.46 тел.9209151295',
      clientSapId: 300435870,
      clientName: 'Рыбин А.С. ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'A34BD14722884B73B9DD2E07BF64A301',
      street: 'Ленина пр-кт',
      house: 'д.46',
      housing: null,
      plantSapId: '330I',
      postcode: '600022',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1184057,
      longitude: 40.3518545,
      users: null,
    },
    {
      addressSapId: 801195264,
      addressName: '600033 Владимирская обл. Владимир г Мещерская ул д.13 ',
      clientSapId: 200013134,
      clientName: 'Элвика ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '582319FBA66D45F3AF24E81C1FAFE0A1',
      street: 'Мещерская ул',
      house: 'д.13',
      housing: null,
      plantSapId: '330I',
      postcode: '600033',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.094716,
      longitude: 40.350795,
      users: null,
    },
    {
      addressSapId: 801294122,
      addressName: '600033 Владимирская обл. Владимир г Диктора Левитана ул д.5В ',
      clientSapId: 200205759,
      clientName: 'Рубин ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '1F68B6CFCE7A4F3896F2F660351439F5',
      street: 'Диктора Левитана ул',
      house: 'д.5В',
      housing: null,
      plantSapId: '330I',
      postcode: '600033',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.117441,
      longitude: 40.372894,
      users: null,
    },
    {
      addressSapId: 800849385,
      addressName: '600021 Владимирская обл. Владимир г Стрелецкий пер д.3 . тел.9101786083',
      clientSapId: 200100598,
      clientName: 'Ривьера ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'E7DD4195B3CF4DA1983CE2B42C27131E',
      street: 'Стрелецкий пер',
      house: 'д.3',
      housing: null,
      plantSapId: '330I',
      postcode: '600021',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: '.',
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.130011,
      longitude: 40.378068,
      users: null,
    },
    {
      addressSapId: 801985929,
      addressName: 'Владимирская обл. Владимир г Мещёрская ул д.4 тел.9607351561',
      clientSapId: 300494898,
      clientName: 'Романов Владислав Дмитриевич ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'ZSTREET0000000000000000000001532',
      street: 'Мещёрская ул',
      house: 'д.4',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.0966408,
      longitude: 40.3470165,
      users: null,
    },
    {
      addressSapId: 800624852,
      addressName: '600037 Владимирская обл. Владимир г Фатьянова ул д.12 тел.4922526241',
      clientSapId: 200013859,
      clientName: 'Железнодорожный Магазин ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '795E99F8AD6E48EDADAF09739B86148C',
      street: 'Фатьянова ул',
      house: 'д.12',
      housing: null,
      plantSapId: '330I',
      postcode: '600037',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1049393,
      longitude: 40.3658658,
      users: null,
    },
    {
      addressSapId: 800980095,
      addressName: '600000 Владимирская обл. Владимир г Большая Московская ул д.18 ',
      clientSapId: 200109862,
      clientName: 'Эльдорадо-Экспресс ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'AD95716C025C4EC49A246CBF2ACC681F',
      street: 'Большая Московская ул',
      house: 'д.18',
      housing: null,
      plantSapId: '330I',
      postcode: '600000',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1275284,
      longitude: 40.4006639,
      users: null,
    },
    {
      addressSapId: 800553103,
      addressName: '600015 Владимирская обл. Владимир г Чайковского ул д.3 ',
      clientSapId: 300026205,
      clientName: 'Майдаченко Виктор Николаевич ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'C39B15816143400A994BB9C7D7C813A8',
      street: 'Чайковского ул',
      house: 'д.3',
      housing: null,
      plantSapId: '330I',
      postcode: '600015',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.122269,
      longitude: 40.36144,
      users: null,
    },
    {
      addressSapId: 801634240,
      addressName: 'Владимирская обл. Владимир г Строителей пр-кт д.18Б ',
      clientSapId: 200015935,
      clientName: 'ТД-опт ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '795D808712704CB78244471659D45036',
      street: 'Строителей пр-кт',
      house: 'д.18Б',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1426973,
      longitude: 40.3656906,
      users: null,
    },
    {
      addressSapId: 801216273,
      addressName: '600022 Владимирская обл. Владимир г Ленина пр-кт д.67 ',
      clientSapId: 300395711,
      clientName: 'Шмонин С.В. ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '4E2E63E4521D412BBDF5EA1E47DB37CB',
      street: 'Ленина пр-кт',
      house: 'д.67',
      housing: null,
      plantSapId: '330I',
      postcode: '600022',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.116387,
      longitude: 40.346888,
      users: null,
    },
    {
      addressSapId: 801215576,
      addressName: '600000 Владимирская обл. Владимир г Большая Московская ул д.19 тел.9206283992',
      clientSapId: 200178871,
      clientName: 'Шерлок Владимир ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '404DA890D8AA4811AFC67655C9F975BE',
      street: 'Большая Московская ул',
      house: 'д.19',
      housing: null,
      plantSapId: '330I',
      postcode: '600000',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: 'D50002',
      latitude: 56.1286261,
      longitude: 40.4013299,
      users: null,
    },
    {
      addressSapId: 800429061,
      addressName: '600015 Владимирская обл. Владимир г Алябьева ул д.18 тел.9157750035',
      clientSapId: 200012932,
      clientName: 'Селена-Н ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'A8071CF670DB4EF4928A98100B23B106',
      street: 'Алябьева ул',
      house: 'д.18',
      housing: null,
      plantSapId: '330I',
      postcode: '600015',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.124804542541,
      longitude: 40.355894818524,
      users: null,
    },
    {
      addressSapId: 801215307,
      addressName: '600014 Владимирская обл. Владимир г Строителей пр-кт д.28 ',
      clientSapId: 300395379,
      clientName: 'Чернышова Т.Ю. ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'FCD2289F6F324A848736EB8013E2B38A',
      street: 'Строителей пр-кт',
      house: 'д.28',
      housing: null,
      plantSapId: '330I',
      postcode: '600014',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1380334,
      longitude: 40.3619329,
      users: null,
    },
    {
      addressSapId: 800428786,
      addressName: '600001 Владимирская обл. Владимир г Пичугина ул д.7 ',
      clientSapId: 200013484,
      clientName: 'Прогресс ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'B004F53A3C3A4C42AC55B643D2172025',
      street: 'Пичугина ул',
      house: 'д.7',
      housing: null,
      plantSapId: '330I',
      postcode: '600001',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.12205896399,
      longitude: 40.377547775463,
      users: null,
    },
    {
      addressSapId: 800428631,
      addressName: '600001 Владимирская обл. Владимир г Диктора Левитана ул д.3В тел.4922321166',
      clientSapId: 200013371,
      clientName: 'НасТА ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '7A0853FCDFA248C59035C89C1078B6C2',
      street: 'Диктора Левитана ул',
      house: 'д.3В',
      housing: null,
      plantSapId: '330G',
      postcode: '600001',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330G',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.118315197196,
      longitude: 40.372582450608,
      users: null,
    },
    {
      addressSapId: 801206264,
      addressName: '600024 Владимирская обл. Владимир г 3-я Кольцевая ул д.16 ',
      clientSapId: 200147266,
      clientName: 'Воля ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'C87E7E7A974545139CEA36DE39CBC98C',
      street: '3-я Кольцевая ул',
      house: 'д.16',
      housing: null,
      plantSapId: '330I',
      postcode: '600024',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Iron',
      subdistributor: null,
      latitude: 56.107478,
      longitude: 40.359662,
      users: null,
    },
    {
      addressSapId: 800584317,
      addressName: 'Владимирская обл. Владимир г МОПРа ул д.15 ',
      clientSapId: 200015935,
      clientName: 'ТД-опт ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'C4F1AD06CCFD495A97F91A2C836235C4',
      street: 'МОПРа ул',
      house: 'д.15',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1255228,
      longitude: 40.3735811,
      users: null,
    },
    {
      addressSapId: 801967007,
      addressName: 'Владимирская обл. Владимир г Балакирева ул д.51а ',
      clientSapId: 200015935,
      clientName: 'ТД-опт ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'CEF8FF4EC2AD4DDB83B607F1E94E2280',
      street: 'Балакирева ул',
      house: 'д.51а',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1316273,
      longitude: 40.3541993,
      users: null,
    },
    {
      addressSapId: 800584081,
      addressName: '600015 Владимирская обл. Владимир г Чайковского ул д.38 ',
      clientSapId: 200015935,
      clientName: 'ТД-опт ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'FAA269DB33864E36AA134236C9E4C431',
      street: 'Чайковского ул',
      house: 'д.38',
      housing: null,
      plantSapId: '330I',
      postcode: '600015',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1266379,
      longitude: 40.3608858,
      users: null,
    },
    {
      addressSapId: 800584044,
      addressName: '600033 Владимирская обл. Владимир г Доватора ул д.2 ',
      clientSapId: 200015935,
      clientName: 'ТД-опт ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'E078BEDCBAFF446FA3EEC4EDAA429258',
      street: 'Доватора ул',
      house: 'д.2',
      housing: null,
      plantSapId: '330I',
      postcode: '600033',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1148175,
      longitude: 40.3750254,
      users: null,
    },
    {
      addressSapId: 801975341,
      addressName: 'Владимирская обл. Владимир г Сурикова ул д.14 ',
      clientSapId: 300444726,
      clientName: 'Задорожный Леонид Иванович ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'D56A7959355549ECB61727B091195EF2',
      street: 'Сурикова ул',
      house: 'д.14',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1272965,
      longitude: 40.3541721,
      users: null,
    },
    {
      addressSapId: 801966732,
      addressName: 'Владимирская обл. Владимир г 9 Января ул д.7 ',
      clientSapId: 200228860,
      clientName: '24 Градуса франчайзинг ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'B7C9F5BDC5F94D48851F04BC1AE3239E',
      street: '9 Января ул',
      house: 'д.7',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1256863,
      longitude: 40.3746305,
      users: null,
    },
    {
      addressSapId: 801467428,
      addressName: '600001 Владимирская обл. Владимир г Ленина пр-кт д.7 ',
      clientSapId: 200222685,
      clientName: 'Аякс ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '8B642269C6B54A319FE0A13D959BF86B',
      street: 'Ленина пр-кт',
      house: 'д.7',
      housing: null,
      plantSapId: '330I',
      postcode: '600001',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: null,
      subdistributor: null,
      latitude: 56.1216027,
      longitude: 40.3807187,
      users: null,
    },
    {
      addressSapId: 801205103,
      addressName: '600021 Владимирская обл. Владимир г Красноармейская ул д.24А ',
      clientSapId: 200013484,
      clientName: 'Прогресс ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'E864B86481A74A0BB18AB4FAA1ADDE1B',
      street: 'Красноармейская ул',
      house: 'д.24А',
      housing: null,
      plantSapId: '330I',
      postcode: '600021',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: null,
      subdistributor: null,
      latitude: 56.128711,
      longitude: 40.373198291015,
      users: null,
    },
    {
      addressSapId: 801975183,
      addressName: 'Владимирская обл. Владимир г Ленина пр-кт д.62 ',
      clientSapId: 300492044,
      clientName: 'Седых Евгения Николаевна ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '7388B20CBDAA4FECBDD65085C2C47CD5',
      street: 'Ленина пр-кт',
      house: 'д.62',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1177009,
      longitude: 40.3491714,
      users: null,
    },
    {
      addressSapId: 801975188,
      addressName: 'Владимирская обл. Владимир г Чайковского ул д.46 ',
      clientSapId: 300492044,
      clientName: 'Седых Евгения Николаевна ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '671CCC1568164132853539BEE74996CC',
      street: 'Чайковского ул',
      house: 'д.46',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Iron',
      subdistributor: null,
      latitude: 56.1290232,
      longitude: 40.3603947,
      users: null,
    },
    {
      addressSapId: 801975184,
      addressName: 'Владимирская обл. Владимир г Нижняя Дуброва ул д.21А ',
      clientSapId: 300492044,
      clientName: 'Седых Евгения Николаевна ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '64D3998026524A3584616FE6D46E1C1C',
      street: 'Нижняя Дуброва ул',
      house: 'д.21А',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1052018,
      longitude: 40.3573881,
      users: null,
    },
    {
      addressSapId: 801975049,
      addressName: 'Владимирская обл. Владимир г Верхняя Дуброва ул д.21 ',
      clientSapId: 300492044,
      clientName: 'Седых Евгения Николаевна ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '8DC7C65EC1E04AABABDA252F6614ED65',
      street: 'Верхняя Дуброва ул',
      house: 'д.21',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.110073,
      longitude: 40.3468795,
      users: null,
    },
    {
      addressSapId: 801975047,
      addressName: 'Владимирская обл. Владимир г Чайковского ул д.38 ',
      clientSapId: 300492044,
      clientName: 'Седых Евгения Николаевна ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'FAA269DB33864E36AA134236C9E4C431',
      street: 'Чайковского ул',
      house: 'д.38',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1256989,
      longitude: 40.3617395,
      users: null,
    },
    {
      addressSapId: 801712773,
      addressName: 'Владимирская обл. Владимир г Диктора Левитана ул д.55 ',
      clientSapId: 300026078,
      clientName: 'Шкут Р.Л. ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'B7F701230E3649EFA406EA66D9A41174',
      street: 'Диктора Левитана ул',
      house: 'д.55',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1080578,
      longitude: 40.3735745,
      users: null,
    },
    {
      addressSapId: 800789834,
      addressName: '600037 Владимирская обл. Владимир г Нижняя Дуброва ул д.19А тел.9038335599',
      clientSapId: 200013784,
      clientName: 'Герда ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '0AA992A4C9894F51B6E993651DF1A034',
      street: 'Нижняя Дуброва ул',
      house: 'д.19А',
      housing: null,
      plantSapId: '330I',
      postcode: '600037',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.105810033889,
      longitude: 40.35871,
      users: null,
    },
    {
      addressSapId: 801951673,
      addressName: 'Владимирская обл. Владимир г Дворянская ул д.27А ',
      clientSapId: 300483200,
      clientName: 'Келехсаев Сергей Борисович ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '36A24BB27AC84D8386A60E50B2D98170',
      street: 'Дворянская ул',
      house: 'д.27А',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1262904,
      longitude: 40.3898666,
      users: null,
    },
    {
      addressSapId: 801966654,
      addressName: 'Владимирская обл. Владимир г 9 Января ул д.4а ',
      clientSapId: 300489904,
      clientName: 'Кудрявцева Светлана Вадимовна ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'F976A2BF582F421F9A15EAAAAC8093DB',
      street: '9 Января ул',
      house: 'д.4а',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: null,
      subdistributor: null,
      latitude: 56.1255688,
      longitude: 40.3746989,
      users: null,
    },
    {
      addressSapId: 800942263,
      addressName: '600037 Владимирская обл. Владимир г Тихонравова ул д.13 тел.4922442540',
      clientSapId: 200016754,
      clientName: 'Игруис ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'A203860B501F4D69A8FA1BB93BF6D052',
      street: 'Тихонравова ул',
      house: 'д.13',
      housing: null,
      plantSapId: '330I',
      postcode: '600037',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.103905268513,
      longitude: 40.350759511639,
      users: null,
    },
    {
      addressSapId: 800442268,
      addressName: '600033 Владимирская обл. Владимир г Диктора Левитана ул д.53 тел.89209362684',
      clientSapId: 200013859,
      clientName: 'Железнодорожный Магазин ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '7242CBBCB8E14BEC818126F8786B0351',
      street: 'Диктора Левитана ул',
      house: 'д.53',
      housing: null,
      plantSapId: '330I',
      postcode: '600033',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.108061277621,
      longitude: 40.374198772678,
      users: null,
    },
    {
      addressSapId: 800442112,
      addressName: '600033 Владимирская обл. Владимир г Мещёрская ул р-к "Флора" (секция №447) ',
      clientSapId: 300026205,
      clientName: 'Майдаченко Виктор Николаевич ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '22969471C6024C7F92EAB10FBEBB3FE4',
      street: 'Мещёрская ул',
      house: null,
      housing: null,
      plantSapId: '330I',
      postcode: '600033',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: 'р-к "Флора" (секция №447)',
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.0973999,
      longitude: 40.3475699,
      users: null,
    },
    {
      addressSapId: 800442070,
      addressName: '600033 Владимирская обл. Владимир г Мещерская ул д.4 ',
      clientSapId: 300026205,
      clientName: 'Майдаченко Виктор Николаевич ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '8BE3B012B2E34FE9AC2B680C5868C172',
      street: 'Мещерская ул',
      house: 'д.4',
      housing: null,
      plantSapId: '330I',
      postcode: '600033',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.0973999,
      longitude: 40.3475699,
      users: null,
    },
    {
      addressSapId: 800441725,
      addressName: '600028 Владимирская обл. Владимир г Строителей пр-кт д.15Д тел.4922448121',
      clientSapId: 200013404,
      clientName: 'Александрия ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '175B8F204BA2492C80F4FFA56C8A3274',
      street: 'Строителей пр-кт',
      house: 'д.15Д',
      housing: null,
      plantSapId: '330I',
      postcode: '600028',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1351654,
      longitude: 40.3659512,
      users: null,
    },
    {
      addressSapId: 801386331,
      addressName: '600028 Владимирская обл. Владимир г Строителей пр-кт д.46 ',
      clientSapId: 300166675,
      clientName: 'Лукашов Владимир Николаевич ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '4C752D12DD1445F1B734C910B4AA823A',
      street: 'Строителей пр-кт',
      house: 'д.46',
      housing: null,
      plantSapId: '330I',
      postcode: '600028',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Iron',
      subdistributor: null,
      latitude: 56.1334719,
      longitude: 40.3593412,
      users: null,
    },
    {
      addressSapId: 800440560,
      addressName: '600015 Владимирская обл. Владимир г Чайковского ул д.3 рынок Чайковского тел.9042602653',
      clientSapId: 300151202,
      clientName: 'Туваева Татьяна Николаевна ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'C39B15816143400A994BB9C7D7C813A8',
      street: 'Чайковского ул',
      house: 'д.3',
      housing: null,
      plantSapId: '330I',
      postcode: '600015',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: 'рынок Чайковского',
      salesOrgSapId: '330E',
      segmentName: 'Iron',
      subdistributor: null,
      latitude: 56.1232681,
      longitude: 40.3619875,
      users: null,
    },
    {
      addressSapId: 801495745,
      addressName: '600037 Владимирская обл. Владимир г Нижняя Дуброва ул д.3 ',
      clientSapId: 200222432,
      clientName: 'ДИК ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '1098C33F405B4F118450CCF5FFEC9F03',
      street: 'Нижняя Дуброва ул',
      house: 'д.3',
      housing: null,
      plantSapId: '330I',
      postcode: '600037',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1073511,
      longitude: 40.3686553,
      users: null,
    },
    {
      addressSapId: 801692927,
      addressName: 'Владимирская обл. Владимир г Ново-Ямская ул д.26А ',
      clientSapId: 200228860,
      clientName: '24 Градуса франчайзинг ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'F7F547B4719C40D48945BFD8D31C5B2A',
      street: 'Ново-Ямская ул',
      house: 'д.26А',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1226643,
      longitude: 40.3622119,
      users: null,
    },
    {
      addressSapId: 801406583,
      addressName: '600014 Владимирская обл. Владимир г Строителей пр-кт д.28 ',
      clientSapId: 300455757,
      clientName: 'Аникеева Наталья Романовна ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'FCD2289F6F324A848736EB8013E2B38A',
      street: 'Строителей пр-кт',
      house: 'д.28',
      housing: null,
      plantSapId: '330I',
      postcode: '600014',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1382452,
      longitude: 40.3623165,
      users: null,
    },
    {
      addressSapId: 801930688,
      addressName: 'Владимирская обл. Владимир г Большая Московская ул д.19а п.1 ',
      clientSapId: 300435870,
      clientName: 'Рыбин А.С. ИП',
      room: 'п.1',
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'ZROOM000000100000000000000001365',
      street: 'Большая Московская ул',
      house: 'д.19а',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1287267,
      longitude: 40.4028528,
      users: null,
    },
    {
      addressSapId: 800544512,
      addressName: '600015 Владимирская обл. Владимир г Чайковского ул д.3Б Рынок к2 ',
      clientSapId: 200094719,
      clientName: 'Владрегионснаб ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '1CEAAD0608F748B0A34C15F2D82469D6',
      street: 'Чайковского ул',
      house: 'д.3Б',
      housing: null,
      plantSapId: '330I',
      postcode: '600015',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: 'Рынок к2',
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.122262020001,
      longitude: 40.361647071688,
      users: null,
    },
    {
      addressSapId: 801419265,
      addressName: '600037 Владимирская обл. Владимир г Нижняя Дуброва ул д.13 ',
      clientSapId: 300448298,
      clientName: 'Романов Дмитрий Борисович ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '4448436EDC59488EB2CC3316C41A50C9',
      street: 'Нижняя Дуброва ул',
      house: 'д.13',
      housing: null,
      plantSapId: '330I',
      postcode: '600037',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1061205,
      longitude: 40.3634684,
      users: null,
    },
    {
      addressSapId: 801349360,
      addressName: '600014 Владимирская обл. Владимир г Лакина ул д.10 ',
      clientSapId: 200082388,
      clientName: 'ТАТНЕФТЬ-АЗС-ЗАПАД ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'AFA82281D97C4278A399D330141473C0',
      street: 'Лакина ул',
      house: 'д.10',
      housing: null,
      plantSapId: '330I',
      postcode: '600014',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1464846,
      longitude: 40.3579645,
      users: null,
    },
    {
      addressSapId: 800824833,
      addressName: '600022 Владимирская обл. Владимир г Ленина пр-кт д.48 ',
      clientSapId: 200109862,
      clientName: 'Эльдорадо-Экспресс ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'E4F97C6A579C41CFAABECEBCE662395C',
      street: 'Ленина пр-кт',
      house: 'д.48',
      housing: null,
      plantSapId: '330I',
      postcode: '600022',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.118318198895,
      longitude: 40.354040884282,
      users: null,
    },
    {
      addressSapId: 801996222,
      addressName: 'Владимирская обл. Владимир г Большая Московская ул д.19а ',
      clientSapId: 300496786,
      clientName: 'Рыбина Екатерина Павловна ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'C4E5AAF41494443DA28441231A0EE301',
      street: 'Большая Московская ул',
      house: 'д.19а',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1289621,
      longitude: 40.4019288,
      users: null,
    },
    {
      addressSapId: 801996219,
      addressName: 'Владимирская обл. Владимир г Дворянская ул д.10 ',
      clientSapId: 300496786,
      clientName: 'Рыбина Екатерина Павловна ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'DDC23E23A43D4B0FBC4D0A62ACF79E9E',
      street: 'Дворянская ул',
      house: 'д.10',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1261676,
      longitude: 40.3929947,
      users: null,
    },
    {
      addressSapId: 801996218,
      addressName: 'Владимирская обл. Владимир г Ленина пр-кт д.46 ',
      clientSapId: 300496786,
      clientName: 'Рыбина Екатерина Павловна ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'A34BD14722884B73B9DD2E07BF64A301',
      street: 'Ленина пр-кт',
      house: 'д.46',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1182405,
      longitude: 40.3522307,
      users: null,
    },
    {
      addressSapId: 800729204,
      addressName: '600037 Владимирская обл. Владимир г Тихонравова ул д.12а тел.9209098647',
      clientSapId: 200120353,
      clientName: 'Кристалл ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'D5166A10B9A14B568C11E72CB06BA9C0',
      street: 'Тихонравова ул',
      house: 'д.12а',
      housing: null,
      plantSapId: '330I',
      postcode: '600037',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: null,
      subdistributor: null,
      latitude: 56.104097,
      longitude: 40.3519709,
      users: null,
    },
    {
      addressSapId: 801995010,
      addressName: 'Владимирская обл. Владимир г Нижняя Дуброва ул д.3 ',
      clientSapId: 300496711,
      clientName: 'Глазков Денис Валерьевич ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '1098C33F405B4F118450CCF5FFEC9F03',
      street: 'Нижняя Дуброва ул',
      house: 'д.3',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Iron',
      subdistributor: null,
      latitude: 56.1081609,
      longitude: 40.3688775,
      users: null,
    },
    {
      addressSapId: 800957280,
      addressName: '600024 Владимирская обл. Владимир г Верхняя Дуброва ул д.19 ',
      clientSapId: 200147266,
      clientName: 'Воля ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '13CE2FE2CE8543969700D9ED342F8F39',
      street: 'Верхняя Дуброва ул',
      house: 'д.19',
      housing: null,
      plantSapId: '330I',
      postcode: '600024',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1104998,
      longitude: 40.3456977,
      users: null,
    },
    {
      addressSapId: 801387758,
      addressName: '600033 Владимирская обл. Владимир г Диктора Левитана ул д.39 ',
      clientSapId: 200015935,
      clientName: 'ТД-опт ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'C122759EB3804373889318A2D7BB32CA',
      street: 'Диктора Левитана ул',
      house: 'д.39',
      housing: null,
      plantSapId: '330I',
      postcode: '600033',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: null,
      subdistributor: null,
      latitude: 56.1123578,
      longitude: 40.3740037,
      users: null,
    },
    {
      addressSapId: 801994831,
      addressName: 'Владимирская обл. Владимир г Лакина ул д.153Б ',
      clientSapId: 200234980,
      clientName: 'ТПК ФАЭТОН ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '6F3BB76B71C34B3894D09AF6C9F8B204',
      street: 'Лакина ул',
      house: 'д.153Б',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1348335,
      longitude: 40.3529058,
      users: null,
    },
    {
      addressSapId: 801994853,
      addressName: 'Владимирская обл. Владимир г Перекопский военный г-к д.18 ',
      clientSapId: 200233594,
      clientName: 'ВЕЛЕС ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'B8A7C076970C4FDFAB6E790D8DDD474C',
      street: 'Перекопский военный г-к',
      house: 'д.18',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1348256,
      longitude: 40.3722651,
      users: null,
    },
    {
      addressSapId: 801994855,
      addressName: 'Владимирская обл. Владимир г Строителей пр-кт д.30В ',
      clientSapId: 200233594,
      clientName: 'ВЕЛЕС ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'F7A89E93CDDD40AFA0A927181C023980',
      street: 'Строителей пр-кт',
      house: 'д.30В',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1387399,
      longitude: 40.3578031,
      users: null,
    },
    {
      addressSapId: 801387197,
      addressName: '600024 Владимирская обл. Владимир г Ленина пр-кт д.47А ',
      clientSapId: 200147266,
      clientName: 'Воля ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'F79954AB1C644C4F8A544637C43A4FD0',
      street: 'Ленина пр-кт',
      house: 'д.47А',
      housing: null,
      plantSapId: '330I',
      postcode: '600024',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1176032,
      longitude: 40.3545108,
      users: null,
    },
    {
      addressSapId: 801378184,
      addressName: '600000 Владимирская обл. Владимир г Гагарина ул д.5 ',
      clientSapId: 300026205,
      clientName: 'Майдаченко Виктор Николаевич ИП',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '7EDD97CC949E45D4B86D5C77DD749447',
      street: 'Гагарина ул',
      house: 'д.5',
      housing: null,
      plantSapId: '330I',
      postcode: '600000',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1294215,
      longitude: 40.4027643,
      users: null,
    },
    {
      addressSapId: 800439061,
      addressName: '600024 Владимирская обл. Владимир г Солнечная ул д.54 тел.4922441231',
      clientSapId: 200016225,
      clientName: 'Калинка ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'A063874B80734B07B1FB017F4B3BA95E',
      street: 'Солнечная ул',
      house: 'д.54',
      housing: null,
      plantSapId: '330I',
      postcode: '600024',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.11402614452,
      longitude: 40.35351229154,
      users: null,
    },
    {
      addressSapId: 801377783,
      addressName: '600033 Владимирская обл. Владимир г Мещёрская ул д.4 п.64 Флора с.401 тел.89036471715',
      clientSapId: 300448298,
      clientName: 'Романов Дмитрий Борисович ИП',
      room: 'п.64',
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'ZROOM000000000000000000000000325',
      street: 'Мещёрская ул',
      house: 'д.4',
      housing: null,
      plantSapId: '330I',
      postcode: '600033',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: 'Флора с.401',
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.0969246,
      longitude: 40.3489482,
      users: null,
    },
    {
      addressSapId: 800438176,
      addressName: '600024 Владимирская обл. Владимир г Василисина ул д.2 ',
      clientSapId: 200016754,
      clientName: 'Игруис ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'F515F01CF898459D932FAFE220901A9A',
      street: 'Василисина ул',
      house: 'д.2',
      housing: null,
      plantSapId: '330I',
      postcode: '600024',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.111644539339,
      longitude: 40.357390067459,
      users: null,
    },
    {
      addressSapId: 800532393,
      addressName: 'Владимирская обл. Владимир г Чайковского ул д.3 ',
      clientSapId: 200094719,
      clientName: 'Владрегионснаб ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'C39B15816143400A994BB9C7D7C813A8',
      street: 'Чайковского ул',
      house: 'д.3',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.122455050033,
      longitude: 40.361010914705,
      users: null,
    },
    {
      addressSapId: 801538828,
      addressName: 'Владимирская обл. Владимир г Ново-Ямской пер д.8 ',
      clientSapId: 200015935,
      clientName: 'ТД-опт ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: 'F2239F1A655E4E2D80192F7AC19ABAB2',
      street: 'Ново-Ямской пер',
      house: 'д.8',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Silver',
      subdistributor: null,
      latitude: 56.1223118,
      longitude: 40.3696065,
      users: null,
    },
    {
      addressSapId: 801014462,
      addressName: '600024 Владимирская обл. Владимир г Солнечная ул д.45 тел.9307477800',
      clientSapId: 200109862,
      clientName: 'Эльдорадо-Экспресс ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '4ECCA15911FB411593D1E7B3C8D698E7',
      street: 'Солнечная ул',
      house: 'д.45',
      housing: null,
      plantSapId: '330I',
      postcode: '600024',
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Iron',
      subdistributor: null,
      latitude: 56.1136201,
      longitude: 40.3535237,
      users: null,
    },
    {
      addressSapId: 801722209,
      addressName: 'Владимирская обл. Владимир г Строителей пр-кт д.21 ',
      clientSapId: 200205759,
      clientName: 'Рубин ООО',
      room: null,
      building: null,
      cityName: 'Владимир г',
      cityName1: null,
      district: null,
      guid: '37F068E5B6414533B72302C0614D5A07',
      street: 'Строителей пр-кт',
      house: 'д.21',
      housing: null,
      plantSapId: '330I',
      postcode: null,
      regionCode: '33',
      regionName: 'Владимирская обл.',
      remark: null,
      salesOrgSapId: '330E',
      segmentName: 'Gold',
      subdistributor: null,
      latitude: 56.1333205,
      longitude: 40.3603773,
      users: null,
    },
  ],
  sortByStatus: true,
  activeAddressSapId: 801898712,
};
