import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@Mesh/core/services';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if (this.authService.currentUser) {
    //     return true;
    // }
    // // no user or user is blocked
    // this.router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}});
    return true;
  }
}
