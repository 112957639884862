import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, Input } from '@angular/core';
import { Comment } from '@Mesh/core/models/comment';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
@Component({
  selector: 'app-chat-task-moderation',
  templateUrl: './chat-task-moderation.component.html',
  styleUrls: ['./chat-task-moderation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTaskModerationComponent implements OnInit {
  @Input() message: Comment;
  @Output() toggled: EventEmitter<any> = new EventEmitter<any>();
  countRepeat$: Observable<number>;
  counter: number = 0;
  tick: number = 1000;

  constructor() {}

  ngOnInit() {
    this.countRepeat$ = this.getCounter();
  }

  onToggle() {
    this.toggled.emit();
  }

  getCounter() {
    this.counter = 600;
    return timer(0, this.tick).pipe(
      take(this.counter),
      map(() => --this.counter)
    );
  }
}
