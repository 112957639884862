import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { planReducer } from '@Mesh/store/plan/plan.reducers';
import { EffectsModule } from '@ngrx/effects';
import { PlanEffects } from '@Mesh/store/plan/plan.effects';

@NgModule({
  imports: [StoreModule.forFeature('plan', planReducer), EffectsModule.forFeature([PlanEffects])],
})
export class PlanStoreModule {}
