import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderInvoceViewComponent } from './order-invoce-view.component';
import { SafePipeModule } from '@Mesh/shared/modules/safe/safe-pipe.module';

@NgModule({
  declarations: [OrderInvoceViewComponent],
  exports: [OrderInvoceViewComponent],
  entryComponents: [OrderInvoceViewComponent],
  imports: [CommonModule, SafePipeModule],
})
export class OrderInvoceViewModule {}
