import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { OrderCompletedState } from '../../../../../store/cart/cart.models';
import { Outlet } from '../../../../../core/models';

@Component({
  selector: 'app-basket-order-item',
  templateUrl: './basket-order-item.component.html',
  styleUrls: ['./basket-order-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketOrderItemComponent implements OnInit {
  @Input() outlet: Outlet;
  @Input() orderDetail: OrderCompletedState;

  constructor() {}

  ngOnInit() {}

  get hasDiscount() {
    return this.orderDetail && this.orderDetail.totalPrice !== this.orderDetail.totalDiscountPrice;
  }
}
