import { Action } from '@ngrx/store';
import { Outlet, OutletStatistic } from '@Mesh/core/models';
import { GetOutletCriteria } from '@Mesh/core/models/request/get-outlet-criteria';

export enum OutletActionsTypes {
  OutletLoadSuccess = '[Outlet ] Outlet  Success',
  OutletLoadError = '[Outlet ] Outlet  Error',

  OutletLoadPageFailure = '[Outlet] Outlet load page Failure',
  OutletLoadPageRequest = '[Outlet] Outlet load page Request',

  OutletLoadRequest = '[Outlet ] Outlet  Request',
  OutletSetActive = '[Outlet ] Outlet set active',
  OutletSetActiveSuccess = '[Outlet ] Outlet set active success',
  OutletSetStatistic = '[Outlet ] Outlet set statistic',
  OutletSetStatisticSuccess = '[Outlet ] Outlet set statistic success',
  OutletSetActiveId = '[Outlet ] Outlet set active outlet id',
  ResetOutletsValue = '[Outlet ] reset outlet value',
}

export class OutletLoadRequest implements Action {
  readonly type = OutletActionsTypes.OutletLoadRequest;
  constructor(public payload: GetOutletCriteria) {}
}

export class OutletLoadPageRequest implements Action {
  readonly type = OutletActionsTypes.OutletLoadPageRequest;

  constructor(public body: { page: number; itemsPerPage: number }) {}
}

export class OutletLoadPageFailure implements Action {
  readonly type = OutletActionsTypes.OutletLoadPageFailure;

  constructor(public error?: any) {}
}

export class OutletLoadSuccess implements Action {
  readonly type = OutletActionsTypes.OutletLoadSuccess;
  constructor(public payload: { outlet: Outlet[]; totalPage: number; totalCount: number }) {}
}

export class OutletLoadError implements Action {
  readonly type = OutletActionsTypes.OutletLoadError;
  constructor(public error: { errors: any[] | null }) {}
}

export class OutletSetActive implements Action {
  readonly type = OutletActionsTypes.OutletSetActive;
  constructor(public payload: { active: Outlet | null }) {}
}

export class OutletSetActiveId implements Action {
  readonly type = OutletActionsTypes.OutletSetActiveId;
  constructor(public payload: { id: number }) {}
}

export class OutletSetActiveSuccess implements Action {
  readonly type = OutletActionsTypes.OutletSetActiveSuccess;
}

export class OutletSetStatistic implements Action {
  readonly type = OutletActionsTypes.OutletSetStatistic;
  constructor(public payload: { statistic: OutletStatistic | null }) {}
}

export class OutletSetStatisticSuccess implements Action {
  readonly type = OutletActionsTypes.OutletSetStatisticSuccess;
}

export class ResetOutletsValue implements Action {
  readonly type = OutletActionsTypes.ResetOutletsValue;
}

export type ActionsUnion =
  | OutletLoadRequest
  | OutletLoadSuccess
  | OutletLoadError
  | OutletSetActive
  | OutletSetActiveSuccess
  | OutletSetStatistic
  | OutletSetStatisticSuccess
  | OutletSetActiveId
  | ResetOutletsValue;
