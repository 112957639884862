import { createDefaultLoadable, Loadable } from '@Mesh/utils/loadable/loadable';
import { Documents } from '@Mesh/core/models/documents';
import { POSTRequestStatus } from '@Mesh/store/user/user.state';

export interface DocumentState extends Loadable {
  data: Documents[] | [];
  totalCount: number;
  selectedId: number | null;
  errors: any[] | null;
  sentSMSStatus: POSTRequestStatus;
  sentSignedStatus: POSTRequestStatus;
  identifierId: string | null;
}

export const initialState: DocumentState = {
  ...createDefaultLoadable(),
  data: [],
  totalCount: 0,
  selectedId: null,
  errors: null,
  sentSMSStatus: POSTRequestStatus.NONE,
  sentSignedStatus: POSTRequestStatus.NONE,
  identifierId: null,
};
