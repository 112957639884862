import { PostingStatus } from '@Mesh/core/models';
import { StasticsWidgetComponent } from '@Mesh/pages/dashboard/widgets/stastics-widget/stastics-widget.component';
import { withLoadable } from '@Mesh/utils/loadable/with-loadable';
import { ActionsUnion, AddNotification, NotificationActionsTypes } from './notification.actions';
import { initialState, NotificationState } from './notification.state';

function baseNotificationReducer(state = initialState, action: ActionsUnion): NotificationState {
  switch (action.type) {
    case NotificationActionsTypes.NotificationsLoadSuccess:
      return {
        ...state,
        notifications: action.payload.notifications,
        content: action.payload.refresh
          ? action.payload.notifications.content
          : [...state.content, ...action.payload.notifications.content],
      };
    case NotificationActionsTypes.NotificationsLoadError:
      return {
        ...state,
        error: action.error,
      };
    case NotificationActionsTypes.ReadNotificationSuccess:
      return {
        ...state,
        readNotificationStatus: PostingStatus.Posted,
        content: state.content.map((i) => {
          if (i.id === action.payload.notification.id) {
            return { ...i, readAt: action.payload.notification.readAt };
          } else {
            return i;
          }
        }),
      };
    case NotificationActionsTypes.AddNotification:
      return {
        ...state,
        content: [action.payload.notification, ...state.content],
      };
    case NotificationActionsTypes.NotificationsLoadError:
      return {
        ...state,
        error: action.error,
      };
    case NotificationActionsTypes.UpdatePushTokenSuccess:
      return {
        ...state,
        pushToken: action.payload.pushToken,
      };
    default:
      return state;
  }
}

export function notificationReducer(state: NotificationState, action: ActionsUnion): NotificationState {
  return withLoadable(baseNotificationReducer, {
    loadingActionType: [
      NotificationActionsTypes.NotificationsLoadRequest,
      NotificationActionsTypes.ReadNotificationRequest,
      NotificationActionsTypes.UpdatePushTokenRequest,
    ],
    successActionType: [
      NotificationActionsTypes.NotificationsLoadSuccess,
      NotificationActionsTypes.ReadNotificationSuccess,
      NotificationActionsTypes.UpdatePushTokenSuccess,
    ],
    errorActionType: [
      NotificationActionsTypes.NotificationsLoadError,
      NotificationActionsTypes.ReadNotificationError,
      NotificationActionsTypes.UpdatePushTokenError,
    ],
  })(state, action);
}
