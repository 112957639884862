import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasketOrdersHistoryProductModalComponent } from './basket-orders-history-product-modal.component';

@NgModule({
  declarations: [BasketOrdersHistoryProductModalComponent],
  exports: [BasketOrdersHistoryProductModalComponent],
  entryComponents: [BasketOrdersHistoryProductModalComponent],
  imports: [CommonModule],
})
export class BasketOrdersHistoryProductModalModule {}
