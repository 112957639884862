import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { FIREBASE } from '@Env/environment';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MessageNotificationsComponent } from './components/message-notifications/message-notifications.component';

@NgModule({
  declarations: [NotificationsComponent, MessageNotificationsComponent],
  exports: [NotificationsComponent, MessageNotificationsComponent],
  imports: [CommonModule, AngularFireModule.initializeApp(FIREBASE), AngularFireMessagingModule, InfiniteScrollModule],
})
export class NotificationsModule {}
