import { MaterialModule } from './modules/material/material.module';
import { NgModule } from '@angular/core';

import { ExternalModule } from './external.module';
import { DirectivesModule } from './directives';
import { ComponentsModule } from '@Mesh/shared/components/components.module';
import { PipesModule } from '@Mesh/shared/pipes/pipes.module';

// TODO Импортировать все модули из Components и добавить в MODULE_COLLECTION

const MODULE_COLLECTION = [ExternalModule, DirectivesModule, ComponentsModule, PipesModule, MaterialModule];

@NgModule({
  imports: MODULE_COLLECTION,
  exports: MODULE_COLLECTION,
})
export class MainSharedModule {}
// TODO Было бы нормально назвать SharedModule, но имя занято :((
