import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { FormsModule } from '@angular/forms';
import { ChatAvailableDialogItemComponent } from './chat-available-dialog-item/chat-available-dialog-item.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ChatCommunityComponent } from './chat-community/chat-community.component';
import { ChatUserRecordComponent } from './chat-users-list/chat-user-record/chat-user-record.component';
import { ChatMainPageComponent } from './chat-main-page/chat-main-page.component';
import { ChatDialogComponent } from './chat-dialog/chat-dialog.component';
import { UserInfoModule } from '@Mesh/shared/modules/user-info/user-info.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutosizeModule } from 'ngx-autosize';
import { ChatDialogContextMenuComponent } from './chat-dialog/chat-dialog-context-menu/chat-dialog-context-menu.component';
import { ReceivedReadComponent } from './received-read/received-read.component';
import { BsDropdownModule, TooltipModule } from 'ngx-bootstrap';
import { ChatMessageComponent } from './chat-dialog/chat-message/chat-message.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ChatSearchInputComponent } from './chat-search-input/chat-search-input.component';
import { ChatHeaderComponent } from './chat-header/chat-header.component';
import { ChatUsersListComponent } from './chat-users-list/chat-users-list.component';
import { PreventParentScrollModule } from 'ngx-prevent-parent-scroll';
import { ChatDialogsListComponent } from './chat-dialogs-list/chat-dialogs-list.component';
import { ChatDialogListContextMenuComponent } from './chat-dialog-list-context-menu/chat-dialog-list-context-menu.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { LongPressDirective } from '@Mesh/shared/directives/long-press.directive';
import { ChatAudioComponent } from './chat-audio/chat-audio.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TimesModule } from '@Mesh/shared/modules/times/times.module';
import { SafePipeModule } from '@Mesh/shared/modules/safe/safe-pipe.module';
import { TokenModule } from '@Mesh/shared/modules/token/token.module';
import { MainSharedModule } from '@Mesh/shared/shared.module';
import { ChatTaskPreviewModule } from './chat-task-preview/chat-task-preview.module';
import { ChatTaskActionsModule } from './chat-dialog/chat-task-actions/chat-task-actions.module';
import { ChatMessagesPlaceholderComponent } from './chat-dialog/chat-messages-placeholder/chat-messages-placeholder.component';
import { ChatDialogTabsModule } from './chat-main-page/chat-dialog-tabs/chat-dialog-tabs.module';
import { ChatNotificationsListModule } from './chat-notifications-list/chat-notifications-list.module';
import { ProgressRecommendedOrderModule } from '../../../pages/catalog/basket/progress-recommended-order/progress-recommended-order.module';
import { ReversePipe } from './chat-dialog/reverse.pipe';

@NgModule({
  imports: [
    PreventParentScrollModule,
    NgxUiLoaderModule,
    TooltipModule.forRoot(),
    NgxDropzoneModule,
    BsDropdownModule,
    AutosizeModule,
    InfiniteScrollModule,
    UserInfoModule,
    CommonModule,
    GalleryModule,
    TimesModule,
    PerfectScrollbarModule,
    LightboxModule,
    SwiperModule,
    TimesModule,
    SafePipeModule,
    TokenModule,
    FormsModule,
    ContextMenuModule.forRoot(),
    MainSharedModule,
    ChatTaskPreviewModule,
    ChatTaskActionsModule,
    ChatDialogTabsModule,
    ChatNotificationsListModule,
    ProgressRecommendedOrderModule,
  ],
  declarations: [
    ChatDialogsListComponent,
    ChatUsersListComponent,
    ChatHeaderComponent,
    ChatSearchInputComponent,
    ChatMessageComponent,
    ReceivedReadComponent,
    ChatDialogContextMenuComponent,
    ChatMainPageComponent,
    ChatDialogComponent,
    ChatComponent,
    ChatUserRecordComponent,
    ChatAvailableDialogItemComponent,
    ChatDialogListContextMenuComponent,
    ChatCommunityComponent,
    LongPressDirective,
    ChatAudioComponent,
    ChatMessagesPlaceholderComponent,
    ReversePipe,
  ],
  exports: [ChatComponent],
})
export class ChatModule {}
