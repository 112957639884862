import { Component, ContentChildren, QueryList, AfterContentInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { delay, map } from 'rxjs/operators';
import { ChatDialogTabComponent } from './chat-dialog-tab/chat-dialog-tab.component';

@Component({
  selector: 'app-chat-dialog-tabs',
  templateUrl: './chat-dialog-tabs.component.html',
  styleUrls: ['./chat-dialog-tabs.component.scss'],
})
export class ChatDialogTabsComponent implements AfterContentInit {
  @Input() routeParam: string;
  @Output() clickButton = new EventEmitter();
  @ContentChildren(ChatDialogTabComponent) tabs: QueryList<ChatDialogTabComponent>;
  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  constructor(private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit() {
    this.route.queryParams
      .pipe(
        map((params: any) => {
          return this.tabs.find((tab) => (this.routeParam /* && params.length  */ ? tab.route === params[this.routeParam] : tab.active));
        }),
        delay(0)
      )
      .subscribe((activeTab) => {
        this.selectTab(activeTab || this.tabs.first);
        // this.changeDetectorRef.markForCheck();
      });
  }

  selectTab(selectedTab: ChatDialogTabComponent) {
    this.tabs.forEach((tab) => (tab.active = tab === selectedTab));
    this.changeTab.emit(selectedTab);
    this.changeDetectorRef.markForCheck();
  }

  onClickButton() {
    this.clickButton.emit();
  }
}
