import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressRecommendedOrderComponent } from './progress-recommended-order.component';
import { ProgressRecommendedOrderItemComponent } from './progress-recommended-order-item/progress-recommended-order-item.component';
import { ProgressBarModule } from '../../components/progress-bar/progress-bar.module';
import { SwiperModule } from 'ngx-swiper-wrapper';

@NgModule({
  declarations: [ProgressRecommendedOrderComponent, ProgressRecommendedOrderItemComponent],
  exports: [ProgressRecommendedOrderComponent, ProgressRecommendedOrderItemComponent],
  imports: [CommonModule, ProgressBarModule, SwiperModule],
})
export class ProgressRecommendedOrderModule {}
