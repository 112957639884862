import { Action } from '@ngrx/store';
import {
  CartItem,
  DeleteCartItem,
  MinimalCartRuleGroupedStatus,
  MinimalCartRules,
  MinimalCartRulesCriteria,
  OrderCompletedState,
  UpdateCartItem,
  OrderCompleted,
  CartItemDisplay,
  CartCategoryTreeCriteria,
} from '@Mesh/store/cart/cart.models';
import { Product, Stock } from '@Mesh/core/models/product';
import { CategotyTree } from '../../core/models/categories';
import { IChangedCartPosition } from '@Mesh/pages/catalog/basket/basket-interfaces';
import { OrderHistoryItem, OrderHistoryItemsCriteria } from '../order/order.model';
import { GetClientTasksCriteria } from '@Mesh/core/models/request';

export enum CartActionsTypes {
  LoadCart = '[Cart] Load cart',
  LoadCartSuccess = '[Cart] Load cart success',
  LoadCartFailure = '[Cart] Load cart failure',

  LoadCartProductsSuccess = '[Cart] Load cart products success',
  LoadCartProductsFailure = '[Cart] Load cart products failure',

  UpdateCart = '[Cart] Update cart',
  UpdateCartSuccess = '[Cart] Update cart success',
  UpdateCartFailure = '[Cart] Update cart failure',

  DeleteProduct = '[Cart] Delete product',
  DeleteProductSuccess = '[Cart] Delete product success',
  DeleteProductFailure = '[Cart] DeleteProductFailure',

  SubmitCart = '[Cart] SubmitCart',
  SubmitCartSuccess = '[Cart] SubmitCart Success',
  SubmitCartFailure = '[Cart] SubmitCart Failure',
  SetSubmittingCart = '[Cart] SubmitCart set loading',
  HandleError409 = '[Cart] HandleError409',

  DropCart = '[Cart] DropCart',
  DropCartSuccess = '[Cart] DropCartSuccess',
  DropCartFailure = '[Cart] DropCartFailure',

  AddProduct = '[Cart] Add product',
  DecrementProduct = '[Cart] Decrement product',
  ChangeAmount = '[Cart] Change amount',

  SetStock = '[Cart] Set stock',

  PageProductsLoaded = '[Cart] Page products loaded',

  Empty = '[Cart] Empty action',

  ResetCart = '[Cart] ResetCart',

  IncrementProductInCart = '[Cart] Add product from cart',
  DecrementProductInCart = '[Cart] Decrement product in cart',

  ClearOnOrderCompletedState = '[Cart] Clear on delete state',

  LoadDeliveryDate = '[Cart] Load delivery date',
  LoadDeliveryDateSuccess = '[Cart] Load delivery date success',
  LoadDeliveryDateFailure = '[Cart] Load delivery date failure',

  SetOrderSubmitState = '[Cart] Set order submit state',

  LoadMinimalCartRulesByCategory = '[Cart] Load minimal cart rules by category',
  LoadMinimalCartRulesByCategorySuccess = '[Cart] Load minimal cart rules by category success',
  LoadMinimalCartRulesByCategoryFailure = '[Cart] Load minimal cart rules by category failure',
  ResetMinimalCartRulesByCategory = '[Cart] Reset minimal cart rules by category',

  LoadMinimalCartRuleGroupedStatus = '[Cart] Load minimal cart rule grouped status',
  LoadMinimalCartRuleGroupedStatusSuccess = '[Cart] Load minimal cart rule grouped status success',
  LoadMinimalCartRuleGroupedStatusFailure = '[Cart] Load minimal cart rule grouped status failure',
  ResetMinimalCartRuleGroupedStatus = '[Cart] Reset minimal cart rule grouped status',

  UpdateRecommendedOrderInCart = '[Cart] Update recommended order cart',
  UpdateRecommendedOrderInCartSuccess = '[Cart] Update recommended order cart success',
  UpdateRecommendedOrderInCartFailure = '[Cart] Update recommended order cart failure',

  SetCurrentRecommendedOrderId = '[Cart] Set current recommended order id',
  ResetCurrentRecommendedOrderId = '[Cart] Reset Current Recommended Order ID',

  LoadRecommendedOrderInCart = '[Cart] Load recommended order cart',
  LoadRecommendedOrderInCartSuccess = '[Cart] Load recommended order cart success',
  LoadRecommendedOrderInCartFailure = '[Cart] Load recommended order cart failure',
  ResetRecommendedOrderInCart = '[Cart] Reset recommended order cart',
  LoadCartWithRecommendedSuccess = '[Cart] Load Cart with Recommended Success',

  FillUpCartsMinimalLimit = '[Cart] Fill up carts to minimal limit',
  FillUpCartsMinimalLimitSuccess = '[Cart] Fill up carts to minimal limit success',
  FillUpCartsMinimalLimitFailure = '[Cart] Fill up carts to minimal limit failure',
  ResetCartsMinimalLimit = '[Cart] Reset carts to minimal limit',

  LoadCartCategoryTree = '[Cart] Load сart сategory tree ',
  LoadCartCategoryTreeSuccess = '[Cart] Load сart сategory tree success',
  LoadCartCategoryTreeFailure = '[Cart] Load сart сategory tree failure',
  ResetCartCategoryTree = '[Cart] Reset сart сategory tree',

  FillCartsByFileProgress = '[Cart] Fill carts by fille progress',
  FillCartsByFileProgressSuccess = '[Cart] Fill carts by fille progress success',
  FillCartsByFileProgressFailure = '[Cart] Fill carts by fille progress failure',
  FillCartsByFileProgressReset = '[Cart] Fill carts by fille progress reset',

  FillCartsByFileCurrent = '[Cart] Fill carts by file current',
  FillCartsByFileCurrentSuccess = '[Cart] Fill carts by file current success',
  FillCartsByFileCurrentFailure = '[Cart] Fill carts by file current failure',

  LoadHistoryOrderItems = '[Cart] Load history order items ',
  LoadHistoryOrderItemsSuccess = '[Cart] Load history order items success',
  LoadHistoryOrderItemsFailure = '[Cart] Load history order items failure',

  ToggleProductFavorite = '[Cart] Toggle product favorite',
  ToggleProductFavoriteSuccess = '[Cart] Toggle product favorite success',
  ToggleProductFavoriteFailure = '[Cart] Toggle product favorite failure',

  ResetCartValue = '[Cart] Reset carts value',
  SetRecommendedOrderState = '[Cart] Set recommended order state',

  LoadTaskRecommendedOrder = '[Cart] Load task recommended order',
  LoadTaskRecommendedOrderSuccess = '[Cart] Load task recommended order success',
  LoadTaskRecommendedOrderFailure = '[Cart] Load task recommended order failure',
  ResetTaskRecommendedOrder = '[Cart] Reset task recommended order',

  LoadBonusRecommendedOrder = '[Cart] Load bonus recommended order',
  LoadBonusRecommendedOrderSuccess = '[Cart] Load bonus recommended order success',
  LoadBonusRecommendedOrderFailure = '[Cart] Load bonus recommended order failure',
  ResetBonusRecommendedOrder = '[Cart] Reset bonus recommended order',

  calculateRecommendedOrderCart = '[Cart] Calculate Recommended Order Cart',
  calculateRecommendedOrderCartSuccess = '[Cart] Calculate Recommended Order Cart Success',
  calculateRecommendedOrderCartFailure = '[Cart] Calculate Recommended Order Cart Failure',

  loadCartOrRecommendedOrderButton = '[Cart] Load Cart Or Recommended Order Button',
}

export class LoadCart implements Action {
  readonly type = CartActionsTypes.LoadCart;
}
export class LoadCartSuccess implements Action {
  readonly type = CartActionsTypes.LoadCartSuccess;

  constructor(public payload: CartItem[]) {}
}
export class LoadCartFailure implements Action {
  readonly type = CartActionsTypes.LoadCartFailure;
}

export class LoadCartProductsSuccess implements Action {
  readonly type = CartActionsTypes.LoadCartProductsSuccess;

  constructor(public payload: Product[]) {}
}
export class LoadCartProductsFailure implements Action {
  readonly type = CartActionsTypes.LoadCartProductsFailure;
}

export class UpdateCart implements Action {
  readonly type = CartActionsTypes.UpdateCart;

  constructor(public body: UpdateCartItem) {}
}
export class UpdateCartSuccess implements Action {
  readonly type = CartActionsTypes.UpdateCartSuccess;

  constructor(public cartItem: CartItem) {}
}
export class UpdateCartFailure implements Action {
  readonly type = CartActionsTypes.UpdateCartFailure;
}

export class DeleteProduct implements Action {
  readonly type = CartActionsTypes.DeleteProduct;

  constructor(public cartItem: DeleteCartItem) {}
}
export class DeleteProductSuccess implements Action {
  readonly type = CartActionsTypes.DeleteProductSuccess;

  constructor(public payload: { materialId: number; unit: string }) {}
}
export class DeleteProductFailure implements Action {
  readonly type = CartActionsTypes.DeleteProductFailure;
}

export class HandleError409 implements Action {
  readonly type = CartActionsTypes.HandleError409;
  constructor(public payload?: any) {}
}

export class SubmitCart implements Action {
  readonly type = CartActionsTypes.SubmitCart;
  constructor(public payload?: { recommendedOrderId?: number }) {}
}
export class SubmitCartSuccess implements Action {
  readonly type = CartActionsTypes.SubmitCartSuccess;

  constructor(public payload: { orders: OrderCompleted[]; cart: CartItemDisplay[] }) {} //public payload: { orderNumber: string }
}
export class SubmitCartFailure implements Action {
  readonly type = CartActionsTypes.SubmitCartFailure;
  constructor(public error?: any) {}
}

export class SetSubmittingCart implements Action {
  readonly type = CartActionsTypes.SetSubmittingCart;
  constructor(public isSubmitting: boolean) {}
}

export class DropCart implements Action {
  readonly type = CartActionsTypes.DropCart;
}
export class DropCartSuccess implements Action {
  readonly type = CartActionsTypes.DropCartSuccess;
}
export class DropCartFailure implements Action {
  readonly type = CartActionsTypes.DropCartFailure;
}

export class ResetCart implements Action {
  readonly type = CartActionsTypes.ResetCart;
  constructor() {}
}
export class AddProduct implements Action {
  readonly type = CartActionsTypes.AddProduct;

  constructor(public payload: { product: { id: number; materialId: number } }) {}
}
export class DecrementProduct implements Action {
  readonly type = CartActionsTypes.DecrementProduct;

  constructor(public payload: { product: { id: number; materialId: number } }) {}
}
export class ChangeAmount implements Action {
  readonly type = CartActionsTypes.ChangeAmount;

  constructor(public payload: { product: CartItemDisplay; amount: number }) {}
}

export class IncrementProductInCart implements Action {
  readonly type = CartActionsTypes.IncrementProductInCart;

  constructor(public payload: { id: number; materialId: number; unit: string; distributorName: string; type?: string }) {}
}
export class DecrementProductInCart implements Action {
  readonly type = CartActionsTypes.DecrementProductInCart;

  constructor(public payload: { id: number; materialId: number; unit: string; distributorName: string; type?: string }) {}
}

export class SetStock implements Action {
  readonly type = CartActionsTypes.SetStock;

  constructor(public payload: { id: number; stock: Stock }) {}
}

export class PageProductsLoaded implements Action {
  readonly type = CartActionsTypes.PageProductsLoaded;

  constructor(public products: Product[]) {}
}

export class ClearOnOrderCompletedState implements Action {
  readonly type = CartActionsTypes.ClearOnOrderCompletedState;
}

export class LoadDeliveryDate implements Action {
  readonly type = CartActionsTypes.LoadDeliveryDate;
}
export class LoadDeliveryDateSuccess implements Action {
  readonly type = CartActionsTypes.LoadDeliveryDateSuccess;

  constructor(public payload: { date: string }) {}
}
export class LoadDeliveryDateFailure implements Action {
  readonly type = CartActionsTypes.LoadDeliveryDateFailure;
}

export class SetOrderSubmitState implements Action {
  readonly type = CartActionsTypes.SetOrderSubmitState;

  constructor(public payload: CartItemDisplay[]) {} //OrderCompletedState
}

export class SetRecommendedOrderState implements Action {
  readonly type = CartActionsTypes.SetRecommendedOrderState;

  constructor(public payload: boolean) {}
}

export class EmptyAction implements Action {
  readonly type = CartActionsTypes.Empty;
}

export class LoadMinimalCartRulesByCategory implements Action {
  readonly type = CartActionsTypes.LoadMinimalCartRulesByCategory;

  constructor(public payload?: { materialGroupNameId: string }) {}
}
export class LoadMinimalCartRulesByCategorySuccess implements Action {
  readonly type = CartActionsTypes.LoadMinimalCartRulesByCategorySuccess;

  constructor(public payload: MinimalCartRules[]) {}
}
export class LoadMinimalCartRulesByCategoryFailure implements Action {
  readonly type = CartActionsTypes.LoadMinimalCartRulesByCategoryFailure;
}

export class ResetMinimalCartRulesByCategory implements Action {
  readonly type = CartActionsTypes.ResetMinimalCartRulesByCategory;
}

export class ResetMinimalCartRuleGroupedStatus implements Action {
  readonly type = CartActionsTypes.ResetMinimalCartRuleGroupedStatus;
}
export class LoadMinimalCartRuleGroupedStatus implements Action {
  readonly type = CartActionsTypes.LoadMinimalCartRuleGroupedStatus;
  constructor(public payload?: MinimalCartRulesCriteria) {}
}
export class LoadMinimalCartRuleGroupedStatusSuccess implements Action {
  readonly type = CartActionsTypes.LoadMinimalCartRuleGroupedStatusSuccess;

  constructor(public payload: MinimalCartRuleGroupedStatus) {}
}
export class LoadMinimalCartRuleGroupedStatusFailure implements Action {
  readonly type = CartActionsTypes.LoadMinimalCartRuleGroupedStatusFailure;
}

export class UpdateRecommendedOrderInCart implements Action {
  readonly type = CartActionsTypes.UpdateRecommendedOrderInCart;
  constructor(public payload: { recommendedOrderId: number }) {}
}
export class UpdateRecommendedOrderInCartSuccess implements Action {
  readonly type = CartActionsTypes.UpdateRecommendedOrderInCartSuccess;

  constructor(public payload: CartItem[]) {}
}
export class UpdateRecommendedOrderInCartFailure implements Action {
  readonly type = CartActionsTypes.UpdateRecommendedOrderInCartFailure;
}

export class ResetCurrentRecommendedOrderId implements Action {
  readonly type = CartActionsTypes.ResetCurrentRecommendedOrderId;
  constructor() {}
}

export class SetCurrentRecommendedOrderId implements Action {
  readonly type = CartActionsTypes.SetCurrentRecommendedOrderId;
  constructor(public payload: { recommendedOrderId: number }) {}
}
export class LoadRecommendedOrderInCart implements Action {
  readonly type = CartActionsTypes.LoadRecommendedOrderInCart;
  constructor(public payload: { recommendedOrderId: number }) {}
}
export class LoadRecommendedOrderInCartSuccess implements Action {
  readonly type = CartActionsTypes.LoadRecommendedOrderInCartSuccess;

  constructor(public payload: CartItem[]) {}
}

export class LoadCartWithRecommendedSuccess implements Action {
  readonly type = CartActionsTypes.LoadCartWithRecommendedSuccess;
  constructor(public payload: CartItem[]) {}
}
export class LoadRecommendedOrderInCartFailure implements Action {
  readonly type = CartActionsTypes.LoadRecommendedOrderInCartFailure;
}
export class ResetRecommendedOrderInCart implements Action {
  readonly type = CartActionsTypes.ResetRecommendedOrderInCart;
}

export class FillUpCartsMinimalLimit implements Action {
  readonly type = CartActionsTypes.FillUpCartsMinimalLimit;

  constructor() {}
}
export class FillUpCartsMinimalLimitSuccess implements Action {
  readonly type = CartActionsTypes.FillUpCartsMinimalLimitSuccess;

  constructor(public payload: any) {}
}
export class FillUpCartsMinimalLimitFailure implements Action {
  readonly type = CartActionsTypes.FillUpCartsMinimalLimitFailure;
}

export class LoadCartCategoryTree implements Action {
  readonly type = CartActionsTypes.LoadCartCategoryTree;

  constructor() {}
}
export class LoadCartCategoryTreeSuccess implements Action {
  readonly type = CartActionsTypes.LoadCartCategoryTreeSuccess;

  constructor(public payload: CategotyTree[]) {}
}
export class LoadCartCategoryTreeFailure implements Action {
  readonly type = CartActionsTypes.LoadCartCategoryTreeFailure;
}

export class ResetCartCategoryTree implements Action {
  readonly type = CartActionsTypes.ResetCartCategoryTree;
}

export class FillCartsByFileProgress implements Action {
  readonly type = CartActionsTypes.FillCartsByFileProgress;

  constructor(public payload: { filePath: string }) {}
}
export class FillCartsByFileProgressSuccess implements Action {
  readonly type = CartActionsTypes.FillCartsByFileProgressSuccess;

  constructor(public payload: any) {}
}
export class FillCartsByFileProgressFailure implements Action {
  readonly type = CartActionsTypes.FillCartsByFileProgressFailure;
}

export class FillCartsByFileProgressReset implements Action {
  readonly type = CartActionsTypes.FillCartsByFileProgressReset;
}

export class FillCartsByFileCurrent implements Action {
  readonly type = CartActionsTypes.FillCartsByFileCurrent;

  constructor() {}
}
export class FillCartsByFileCurrentSuccess implements Action {
  readonly type = CartActionsTypes.FillCartsByFileCurrentSuccess;

  constructor(public payload: any) {}
}
export class FillCartsByFileCurrentFailure implements Action {
  readonly type = CartActionsTypes.FillCartsByFileCurrentFailure;
}

export class LoadHistoryOrderItems implements Action {
  readonly type = CartActionsTypes.LoadHistoryOrderItems;

  constructor(public payload: Partial<OrderHistoryItemsCriteria>) {}
}
export class LoadHistoryOrderItemsSuccess implements Action {
  readonly type = CartActionsTypes.LoadHistoryOrderItemsSuccess;

  constructor(public payload: OrderHistoryItem[]) {}
}
export class LoadHistoryOrderItemsFailure implements Action {
  readonly type = CartActionsTypes.LoadHistoryOrderItemsFailure;
}
export class ToggleProductFavorite implements Action {
  readonly type = CartActionsTypes.ToggleProductFavorite;

  constructor(public payload: { materialId: number }) {}
}
export class ToggleProductFavoriteSuccess implements Action {
  readonly type = CartActionsTypes.ToggleProductFavoriteSuccess;

  constructor(public payload: any) {}
}
export class ToggleProductFavoriteFailure implements Action {
  readonly type = CartActionsTypes.ToggleProductFavoriteFailure;
}
export class ResetCartValue implements Action {
  readonly type = CartActionsTypes.ResetCartValue;
}

export class LoadTaskRecommendedOrder implements Action {
  readonly type = CartActionsTypes.LoadTaskRecommendedOrder;

  constructor(public payload: Partial<GetClientTasksCriteria>) {}
}
export class LoadTaskRecommendedOrderSuccess implements Action {
  readonly type = CartActionsTypes.LoadTaskRecommendedOrderSuccess;

  constructor(public payload: any) {}
}
export class LoadTaskRecommendedOrderFailure implements Action {
  readonly type = CartActionsTypes.LoadTaskRecommendedOrderFailure;
}

export class ResetTaskRecommendedOrder implements Action {
  readonly type = CartActionsTypes.ResetTaskRecommendedOrder;
}

export class LoadBonusRecommendedOrder implements Action {
  readonly type = CartActionsTypes.LoadBonusRecommendedOrder;

  constructor() {}
}
export class LoadBonusRecommendedOrderSuccess implements Action {
  readonly type = CartActionsTypes.LoadBonusRecommendedOrderSuccess;

  constructor(public payload: any) {}
}
export class LoadBonusRecommendedOrderFailure implements Action {
  readonly type = CartActionsTypes.LoadBonusRecommendedOrderFailure;
}

export class ResetBonusRecommendedOrder implements Action {
  readonly type = CartActionsTypes.ResetBonusRecommendedOrder;
}

export class CalculateRecommendedOrderCart implements Action {
  readonly type = CartActionsTypes.calculateRecommendedOrderCart;
  constructor(public payload: { recommendedOrderId: number }) {}
}

export class CalculateRecommendedOrderCartSuccess implements Action {
  readonly type = CartActionsTypes.calculateRecommendedOrderCartSuccess;
  constructor(public payload: { recommendedOrder: CartItem[] }) {}
}

export class CalculateRecommendedOrderCartFailure implements Action {
  readonly type = CartActionsTypes.calculateRecommendedOrderCartFailure;
  constructor(public payload: { error: any }) {}
}

export class LoadCartOrRecommendedOrderButton implements Action {
  readonly type = CartActionsTypes.loadCartOrRecommendedOrderButton;
}

export type CartActionsUnion =
  | LoadCart
  | LoadCartSuccess
  | LoadCartFailure
  | LoadCartProductsSuccess
  | HandleError409
  | LoadCartProductsFailure
  | UpdateCart
  | UpdateCartSuccess
  | UpdateCartFailure
  | DeleteProduct
  | DeleteProductSuccess
  | DeleteProductFailure
  | SubmitCart
  | SubmitCartSuccess
  | SubmitCartFailure
  | SetSubmittingCart
  | DropCart
  | DropCartSuccess
  | DropCartFailure
  | AddProduct
  | DecrementProduct
  | ChangeAmount
  | IncrementProductInCart
  | DecrementProductInCart
  | SetStock
  | PageProductsLoaded
  | EmptyAction
  | ResetCart
  | ClearOnOrderCompletedState
  | LoadDeliveryDate
  | LoadDeliveryDateSuccess
  | LoadDeliveryDateFailure
  | SetOrderSubmitState
  | SetRecommendedOrderState
  | LoadMinimalCartRulesByCategory
  | LoadMinimalCartRulesByCategorySuccess
  | LoadMinimalCartRulesByCategoryFailure
  | ResetMinimalCartRulesByCategory
  | LoadMinimalCartRuleGroupedStatus
  | LoadMinimalCartRuleGroupedStatusSuccess
  | LoadMinimalCartRuleGroupedStatusFailure
  | ResetMinimalCartRuleGroupedStatus
  | UpdateRecommendedOrderInCart
  | UpdateRecommendedOrderInCartSuccess
  | UpdateRecommendedOrderInCartFailure
  | SetCurrentRecommendedOrderId
  | ResetCurrentRecommendedOrderId
  | LoadRecommendedOrderInCart
  | LoadRecommendedOrderInCartSuccess
  | LoadCartWithRecommendedSuccess
  | LoadRecommendedOrderInCartFailure
  | ResetRecommendedOrderInCart
  | FillUpCartsMinimalLimit
  | FillUpCartsMinimalLimitSuccess
  | FillUpCartsMinimalLimitFailure
  | LoadCartCategoryTree
  | LoadCartCategoryTreeSuccess
  | LoadCartCategoryTreeFailure
  | ResetCartCategoryTree
  | FillCartsByFileProgress
  | FillCartsByFileProgressSuccess
  | FillCartsByFileProgressFailure
  | FillCartsByFileProgressReset
  | FillCartsByFileCurrent
  | FillCartsByFileCurrentSuccess
  | FillCartsByFileCurrentFailure
  | LoadHistoryOrderItems
  | LoadHistoryOrderItemsSuccess
  | LoadHistoryOrderItemsFailure
  | ToggleProductFavorite
  | ToggleProductFavoriteSuccess
  | ToggleProductFavoriteFailure
  | ResetCartValue
  | LoadTaskRecommendedOrder
  | LoadTaskRecommendedOrderSuccess
  | LoadTaskRecommendedOrderFailure
  | ResetTaskRecommendedOrder
  | LoadBonusRecommendedOrder
  | LoadBonusRecommendedOrderSuccess
  | LoadBonusRecommendedOrderFailure
  | ResetBonusRecommendedOrder
  | CalculateRecommendedOrderCart
  | CalculateRecommendedOrderCartSuccess
  | CalculateRecommendedOrderCartFailure
  | LoadCartOrRecommendedOrderButton;
