import { DOCUMENT } from '@angular/common';
import { Input, Directive, OnInit, ElementRef, Renderer2, Inject } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[iql-loading]',
})
export class LoadingDirective implements OnInit {
  // tslint:disable:no-input-rename
  @Input() text: string;
  @Input() lock = false;
  @Input('custom-class') customClass: string;
  @Input('full-screen') fullScreen = false;
  @Input('iql-loading-top') loadingTop = '50%';
  @Input('iql-loading') set showLoading(val: boolean) {
    this.visible = val;
    if (!this.cacheElement) {
      return;
    }
    this.cacheElement.innerHTML = this.makeHtml();
  }

  cacheElement: HTMLElement;
  cacheOverflow: string;
  private visible = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document // @Inject(WINDOW) private window: Window
  ) {}

  toggleLock(status: boolean = false): void {
    if (!this.lock) {
      return;
    }
    const nextValue = status ? 'hidden' : this.cacheOverflow;
    this.renderer.setStyle(this.document.body, 'overflow', nextValue);
  }

  makeHtml(): string {
    if (this.lock) {
      this.toggleLock(this.visible);
    }
    return `
      <div class="iql-loading-mask ${this.customClass} ${this.fullScreen ? ' is-fullscreen' : ''}"
        style="display: ${this.visible ? 'block' : 'none'}">
        <div class="iql-loading-spinner" style="top: ${this.loadingTop}">
          <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none"/>
          </svg>
          <p class="iql-loading-text" style="display: ${this.text ? 'block' : 'none'}">
            ${this.text}
          </p>
        </div>
      </div>
    `;
  }

  ngOnInit(): void {
    if (this.lock) {
      this.cacheOverflow = window.getComputedStyle(this.document.body).overflow;
    }

    this.cacheElement = this.renderer.createElement('div');
    this.cacheElement.innerHTML = this.makeHtml();
    const parentElement = this.fullScreen ? this.document.body : this.el.nativeElement;
    if (!this.fullScreen) {
      this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
    }
    this.renderer.appendChild(parentElement, this.cacheElement);
  }
}
