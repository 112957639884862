import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DelayedOrderUpdateNotifyComponent } from './delayed-order-update-notify.component';

@NgModule({
  declarations: [DelayedOrderUpdateNotifyComponent],
  exports: [DelayedOrderUpdateNotifyComponent],
  entryComponents: [DelayedOrderUpdateNotifyComponent],
  imports: [CommonModule],
})
export class DelayedOrderUpdateNotifyModule {}
