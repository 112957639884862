import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-orders-widget',
  templateUrl: './orders-widget.component.html',
  styleUrls: ['./orders-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersWidgetComponent implements OnInit {
  isCollapsed = window.innerWidth < 992;
  active = true;

  clickHeader($event: MouseEvent): void {
    if (this.isCollapsed) {
      this.active = !this.active;
    }
  }

  constructor() {}

  ngOnInit() {}
}
