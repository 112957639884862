export enum TableColumnType {
  text = 'text',
  textPair = 'textPair',
  multiText = 'multiText',
  checkBox = 'checkBox',
  counter = 'counter',
  planProgress = 'plan-progress',
  valueChangeable = 'value-changeable',
  textImage = 'text-image',
  textCardImage = 'text-card-image',
  textLink = 'text-link',
  arrayTextLink = 'array-text-link',
  plan = 'plan',
  planDouble = 'plan-double',
  date = 'date',
  status = 'status',
  progress = 'progress',
  targetDate = 'target-date',
  buttons = 'buttons',
  editButton = 'editButton',
  textButton = 'textButton',
  quantityButtons = 'quantityButtons',
  catalogButtons = 'catalogButtons',
  plansButtons = 'plansButtons',
  innerButtons = 'innerButtons',
  countDown = 'countDown',
  activeTasks = 'activeTasks',
  history = 'history',
  outletCount = 'outletCount',
  expand = 'expand',
  textImageToggle = 'textImageToggle',
  stock = 'stock',
  price = 'price',
  image = 'image',
  distributorName = 'distributorName',
  priceForOne = 'priceForOne',
  priceMrc = 'priceMrc',
}

export interface TableColumn {
  type: TableColumnType;
  key?: string;
  label?: string;
  value?: any;
  sortable?: boolean;
  width?: number;
  minWidth?: number | string;
  maxWidth?: number | string;
  class?: string;
  headerClass?: string;
  subName?: string;
  img?: string;
  link?: string;
  multiText?: string[];
  hide?: true;
  removeBreakpoint?: number;
}
