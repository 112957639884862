import { ActionsUnion, OutletActionsTypes } from '@Mesh/store/outlet/outlet.actions';
import { withLoadable } from '@Mesh/utils/loadable/with-loadable';
import { initialState, OutletState } from '@Mesh/store/outlet/outlet.state';
import { Outlet } from '@Mesh/core/models';

function baseOutletReducer(state = initialState, action: ActionsUnion): OutletState {
  switch (action.type) {
    case OutletActionsTypes.OutletLoadSuccess:
      return {
        ...state,
        data: action.payload.outlet,
        totalOutletsPages: action.payload.totalPage,
        totalCount: action.payload.totalCount,
      };
    case OutletActionsTypes.OutletLoadError:
      return {
        ...state,
        error: action.error,
      };
    case OutletActionsTypes.OutletSetActive:
      console.trace('active:', action.payload.active);
      return {
        ...state,
        active: action.payload.active,
      };
    case OutletActionsTypes.OutletSetActiveId:
      const active = state.data.find((outlet: Outlet) => outlet.addressSapId === action.payload.id);
      return {
        ...state,
        active,
      };
    case OutletActionsTypes.OutletSetStatistic:
      return {
        ...state,
        statistic: action.payload.statistic,
      };
    case OutletActionsTypes.ResetOutletsValue:
      return {
        ...state,
        data: initialState.data,
        active: initialState.active,
        totalOutletsPages: initialState.totalOutletsPages,
        totalCount: initialState.totalCount,
      };
    default:
      return state;
  }
}

export function outletReducer(state: OutletState, action: ActionsUnion): OutletState {
  return withLoadable(baseOutletReducer, {
    loadingActionType: [OutletActionsTypes.OutletLoadRequest, OutletActionsTypes.OutletSetStatistic],
    successActionType: [OutletActionsTypes.OutletLoadSuccess, OutletActionsTypes.OutletSetStatisticSuccess],
    errorActionType: OutletActionsTypes.OutletLoadError,
  })(state, action);
}
