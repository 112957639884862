import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { News } from '@Mesh/core/models/news';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable } from 'rxjs';
import { AsyncItem, queryState } from '@Mesh/core/models/async-item';
import { NewsLastAsyncService } from '@Mesh/core/services/async/news-last-async.service';
import { fadeIn, fadeOut } from '@Mesh/utils/animations/fade-animations';
import { Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss', './ghost/ghost-item.component.scss'],
  animations: [
    trigger('collapseState', [
      state(
        'inactive',
        style({
          opacity: '0',
          height: 0,
          display: 'none',
        })
      ),
      state(
        'active',
        style({
          opacity: '1',
          height: '*',
          display: '*',
        })
      ),
      transition('inactive => active', animate('125ms ease-in')),
      transition('active => inactive', animate('125ms ease-out')),
    ]),
    trigger('fadeOut', fadeOut()),
    trigger('fadeIn', fadeIn()),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsWidgetComponent implements OnInit {
  state = queryState;
  news$: Observable<AsyncItem<News>>;

  config: SwiperConfigInterface = {
    init: true,
    // observer: true,
    direction: 'horizontal',
    initialSlide: 0,
    slidesPerView: 'auto',
    pagination: false,
    // centeredSlides: true,
    freeMode: true,
    slidesOffsetBefore: 140,
  };

  isCollapsed = window.innerWidth < 992;
  active = true;

  clickHeader($event: MouseEvent): void {
    if (this.isCollapsed) {
      this.active = !this.active;
    }
  }

  goToUser(user) {
    return this.router.navigate([`/user/${user.login}`]);
  }

  @HostListener('window:resize', [])
  onResize() {
    this.toggleNavbar();
  }

  toggleNavbar() {
    this.isCollapsed = window.innerWidth < 992;
  }

  constructor(private newsService: NewsLastAsyncService, private router: Router) {}

  ngOnInit() {
    this.getLastNews();
  }

  getLastNews() {
    this.news$ = this.newsService.last();
  }

  navigate() {
    this.router.navigate(['/news']);
  }
}
