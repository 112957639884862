import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasketTaskLeftoverProductsComponent } from './basket-task-leftover-products.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasketTaskLeftoverProductsItemComponent } from './basket-task-leftover-products-item/basket-task-leftover-products-item.component';
import { LoadingModule } from '@Mesh/shared/directives/loading/loading.module';

@NgModule({
  declarations: [BasketTaskLeftoverProductsComponent, BasketTaskLeftoverProductsItemComponent],
  exports: [BasketTaskLeftoverProductsComponent],
  entryComponents: [BasketTaskLeftoverProductsComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LoadingModule],
})
export class BasketTaskLeftoverProductsModule {}
