import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-basket-sum-not-equal-alert',
  templateUrl: './basket-sum-not-equal-alert.component.html',
  styleUrls: ['./basket-sum-not-equal-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketSumNotEqualAlertComponent implements OnInit {
  @Output() updatedCart: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onUpdateBasket(): void {
    this.updatedCart.emit();
  }
}
