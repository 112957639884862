import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { CartItemDisplay } from '../../../../store/cart/cart.models';

@Component({
  selector: 'app-basket-item',
  templateUrl: './basket-item.component.html',
  styleUrls: ['./basket-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketItemComponent implements OnInit {
  @Input() product: CartItemDisplay;
  @Output() decrementedAmount: EventEmitter<any> = new EventEmitter();
  @Output() incrementedAmount: EventEmitter<any> = new EventEmitter();
  @Output() deleted: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  incrementAmount(product: CartItemDisplay) {
    this.incrementedAmount.emit(product);
  }

  decrementAmount(product: CartItemDisplay) {
    this.decrementedAmount.emit(product);
  }

  deleteCartItem(product: CartItemDisplay) {
    this.deleted.emit(product);
  }
}
