import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { BlankComponent, CondensedComponent } from './@pages/layouts';
import { CondensedDashboardComponent } from './pages/dashboard/condensed/dashboard.component';
import { ErrorComponent } from './pages/error/error.component';
import { UnderConstructionComponent } from '@Mesh/pages/under-construction/under-construction.component';
import { RoleGuard } from '@Mesh/core/guards/role.guard';
import { ActiveGuard } from './core/guards/active.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: 'Главная',
      pageClass: 'page-dashboard-wrapper',
    },
    pathMatch: 'full',
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        component: CondensedDashboardComponent,
      },
    ],
  },
  {
    path: 'dashboard',
    component: CondensedComponent,
    data: {
      pageClass: 'page-dashboard-wrapper',
    },
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        component: CondensedDashboardComponent,
      },
    ],
  },
  {
    path: 'catalog',
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/catalog/catalog.module#CatalogModule',
      },
    ],
  },
  {
    path: 'active',
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/active/active.module#ActiveModule',
      },
    ],
  },
  {
    path: 'profile',
    component: CondensedComponent,
    data: {
      pageClass: 'page-profile-wrapper',
    },
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/profile/profile.module#ProfileModule',
      },
    ],
  },
  {
    path: 'history',
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/history-list/history-list.module#HistoryListModule',
      },
    ],
  },
  {
    path: 'auth',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: './pages/auth/auth.module#AuthModule',
      },
    ],
  },
  {
    path: 'under_construction',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: UnderConstructionComponent,
      },
    ],
  },
  {
    path: 'error',
    component: BlankComponent,
    children: [
      {
        path: ':type',
        component: ErrorComponent,
      },
    ],
  },
  { path: '**', redirectTo: 'error/404' },
];
