import { Routes } from '@angular/router';
import { CatalogListComponent } from '@Mesh/pages/catalog/catalog-list/catalog-list.component';
import { ProductComponent } from '@Mesh/pages/catalog/product/product.component';
import { PlanListComponent } from '@Mesh/pages/catalog/plan-list/plan-list.component';

export enum CatalogPageTypes {
  Catalog,
  Plans,
}

export const CatalogRoutes: Routes = [
  {
    path: '',
    component: CatalogListComponent,
    data: {
      pageType: CatalogPageTypes.Catalog,
    },
  },
  {
    path: 'plan',
    component: PlanListComponent,
    data: {
      pageType: CatalogPageTypes.Plans,
    },
  },
  {
    path: 'product',
    component: ProductComponent,
  },
];
