import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  isDisabled: boolean;
  onTouched: any;
  onChange: any;
  value = false;
  registerOnChange(fn: any): void {
    if (fn) {
      this.onChange = fn;
    }
  }
  registerOnTouched(fn: any): void {
    if (fn) {
      this.onTouched = fn;
    }
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  writeValue(val: boolean): void {
    this.value = val;
  }
  onValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }
}
