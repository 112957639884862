import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { RecommendOrderBonus } from '../../../../../store/order/order.model';

@Component({
  selector: 'app-progress-recommended-order-item',
  templateUrl: './progress-recommended-order-item.component.html',
  styleUrls: ['./progress-recommended-order-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressRecommendedOrderItemComponent implements OnInit {
  @Input() bonusRecommendedOrders: RecommendOrderBonus;
  @Output() updatedProgress: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onUpdateProgress(event: Event) {
    event.stopPropagation();
    this.updatedProgress.emit();
  }
}
