import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Notifications, NotService } from '@Mesh/core/services/not.service';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import { AuthenticationService } from '@Mesh/core/services';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-courses-widget',
  templateUrl: './courses-widget.component.html',
  styleUrls: ['./courses-widget.component.scss'],
  animations: [
    trigger('collapseState', [
      state(
        'inactive',
        style({
          opacity: '0',
          height: 0,
          display: 'none',
        })
      ),
      state(
        'active',
        style({
          opacity: '1',
          height: '*',
          display: '*',
        })
      ),
      transition('inactive => active', animate('125ms ease-in')),
      transition('active => inactive', animate('125ms ease-out')),
    ]),
  ],
})
export class CoursesWidgetComponent implements OnInit, OnDestroy, AfterViewChecked {
  private unsubscribe$ = new Subject();
  config;
  slides;
  slides2;
  slide2;
  slide3;
  index = 0;
  @ViewChildren(SwiperDirective) swiperViewes: QueryList<SwiperDirective>;
  @ViewChild('swiper', { read: ElementRef }) swiper: ElementRef;

  count = null;

  isCollapsed = window.innerWidth < 992;
  active = true;

  clickHeader($event: MouseEvent): void {
    if (this.isCollapsed) {
      this.active = !this.active;
    }
  }

  constructor() {}

  getClasses(i) {
    let classes = 'widget-2 widget-course card no-border widget widget-loader-circle-lg no-margin full-height';
    switch (i) {
      case 0:
        classes += ' bg-primary';
        break;
      case 1:
        classes += ' bg-complete';
        break;
      case 2:
        classes += ' bg-master';
        break;
      case 3:
        classes += ' bg-success';
        break;
    }
    return classes;
  }

  ngAfterViewChecked(): void {
    const screenWidth = window.innerWidth;
    if (screenWidth < 992) {
      if (this.swiper && this.swiper.nativeElement && !this.config) {
        this.config = {
          init: true,
          observer: true,
          direction: 'horizontal',
          slidesPerView: 1,
          spaceBetween: 12,
          pagination: false,
          centeredSlides: false,
        };

        this.swiperViewes.forEach((dir) => {
          dir.init();
          dir.indexChange.subscribe((index) => {
            console.log(index);
          });
        });
      }
    }
  }

  ngOnInit() {
    const screenWidth = window.innerWidth;
    const widgets = [
      {
        id: null,
        name: 'Обучение 1',
        image_goblet: null,
        allow: false,
        image: null,
      },
      {
        id: null,
        name: 'Обучение 2',
        image_goblet: null,
        allow: false,
        image: null,
      },
      {
        id: null,
        name: 'Обучение 3',
        image_goblet: null,
        allow: false,
        image: null,
      },
      {
        id: null,
        name: 'Обучение 4',
        image_goblet: null,
        allow: false,
        image: null,
      },
    ];

    const slides = [];
    const slides2 = [];
    widgets.map((widget, index) => {
      const item = widget;
      slides.push({
        id: item.id,
        name: item.name,
        image_goblet: widget.image_goblet,
        allow: item.allow,
        image: item.image,
      });
    });
    this.slides = slides;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }
}
