import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WindowFocusService {
  _title: string;
  _secondTitle: string;

  constructor(private _service: Title) {}

  visible() {
    let eventKey;
    let stateKey;
    const keys = {
      hidden: 'visibilitychange',
      webkitHidden: 'webkitvisibilitychange',
      mozHidden: 'mozvisibilitychange',
      msHidden: 'msvisibilitychange',
    };
    for (stateKey in keys) {
      if (stateKey in document) {
        eventKey = keys[stateKey];
        break;
      }
    }
    return fromEvent(document, eventKey).pipe(
      startWith({ type: document['hidden'] ? 'blur' : 'focus' }),
      map((event: any) => {
        const v = 'visible',
          h = 'hidden',
          evtMap = {
            focus: v,
            focusin: v,
            pageshow: v,
            blur: h,
            focusout: h,
            pagehide: h,
          };

        if (event.type in evtMap) {
          return evtMap[event.type];
        } else {
          return !document['hidden'];
        }
      })
    );
  }
}
