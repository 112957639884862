import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { AppOverlayDirective } from './overlay.directive';

@NgModule({
  imports: [OverlayModule, CommonModule],
  declarations: [AppOverlayDirective],
  exports: [AppOverlayDirective],
})
export class AppOverlayModule {}
