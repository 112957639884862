import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Outlet } from '../../../../core/models';

@Component({
  selector: 'app-basket-delivery',
  templateUrl: './basket-delivery.component.html',
  styleUrls: ['./basket-delivery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketDeliveryComponent implements OnInit {
  @Input() deliveryDate: string = new Date().toDateString();
  @Input() outlet: Outlet;

  constructor() {}

  ngOnInit() {}
}
