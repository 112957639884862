import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimesPipe } from '@Mesh/shared/pipes/times.pipe';

@NgModule({
  declarations: [TimesPipe],
  exports: [TimesPipe],
  imports: [CommonModule],
})
export class TimesModule {}
