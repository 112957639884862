import { createDefaultLoadable, Loadable } from '@Mesh/utils/loadable/loadable';
import { ClientBonus } from '@Mesh/core/models/client-bonus';
import { ClientBonusPredict } from '@Mesh/core/models/client-bonus-predict';
import { Bonus } from '@Mesh/core/models/bonus';
import { OutletBonusPredict } from '@Mesh/core/models/outlet-bonus-predict';

export interface BonusState extends Loadable {
  clientBonusData: ClientBonus | null;
  clientBonusPredict: ClientBonusPredict | null;
  clientBonusPredictTotalAmount: number | null;
  clientsBonusByPeriodData: ClientBonus[] | null;

  outletBonusesData: Bonus[] | null;
  outletBonusesSum: number | null;
  outletBonusesPredictData: Bonus[] | null;
  outletBonusesPredictSum: number | null;
  outletBonusPredictData: OutletBonusPredict | null;
  outletAwaitingSum: number | null;
  errors: any[] | null;
  activeBonusId: number | null;
  activePredictBonusId: number | null;

  selectedUserBalance: number;
}

export const initialState: BonusState = {
  ...createDefaultLoadable(),
  clientBonusData: null,
  clientBonusPredict: null,
  clientsBonusByPeriodData: null,
  clientBonusPredictTotalAmount: 0,
  outletBonusesData: null,
  outletBonusesSum: null,
  outletBonusesPredictData: null,
  outletBonusesPredictSum: null,
  outletBonusPredictData: null,
  outletAwaitingSum: null,
  errors: null,
  activeBonusId: null,
  activePredictBonusId: null,
  selectedUserBalance: 0,
};
