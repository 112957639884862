import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { Comment } from '@Mesh/core/models/comment';

@Component({
  selector: 'app-chat-task-success',
  templateUrl: './chat-task-success.component.html',
  styleUrls: ['./chat-task-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTaskSuccessComponent implements OnInit {
  @Input() message: Comment;
  @Output() toggled: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  onToggle() {
    this.toggled.emit();
  }
}
