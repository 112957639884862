import { NotificationRequest, NotificationsData, Notification, NotificationTokenRequest, NotificationToken } from '@Mesh/core/models';
import { Action } from '@ngrx/store';

export enum NotificationActionsTypes {
  NotificationsLoadRequest = '[Notification] Notifications Request',
  NotificationsLoadSuccess = '[Notification] Notifications Success',
  NotificationsLoadError = '[Notification] Notifications Error',

  ReadNotificationRequest = '[Notification] Read notification Request',
  ReadNotificationSuccess = '[Notification] Read notification Success',
  ReadNotificationError = '[Notification] Read notification Error',

  AddNotification = '[Notification] Add notification',

  UpdatePushTokenRequest = '[Notification] Update push token Request',
  UpdatePushTokenSuccess = '[Notification] Update push token Success',
  UpdatePushTokenError = '[Notification] Update push token Error',
}
export class NotificationsLoadRequest implements Action {
  readonly type = NotificationActionsTypes.NotificationsLoadRequest;

  constructor(public payload: { params: NotificationRequest; refresh: boolean }) {}
}

export class NotificationsLoadSuccess implements Action {
  readonly type = NotificationActionsTypes.NotificationsLoadSuccess;

  constructor(public payload: { notifications: NotificationsData; refresh: boolean }) {}
}

export class NotificationsLoadError implements Action {
  readonly type = NotificationActionsTypes.NotificationsLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class ReadNotificationRequest implements Action {
  readonly type = NotificationActionsTypes.ReadNotificationRequest;

  constructor(public payload: { id: number }) {}
}

export class ReadNotificationSuccess implements Action {
  readonly type = NotificationActionsTypes.ReadNotificationSuccess;

  constructor(public payload: { notification: Notification }) {}
}

export class ReadNotificationError implements Action {
  readonly type = NotificationActionsTypes.ReadNotificationError;

  constructor(public error: { errors: any[] | null }) {}
}

export class AddNotification implements Action {
  readonly type = NotificationActionsTypes.AddNotification;

  constructor(public payload: { notification: Notification }) {}
}

export class UpdatePushTokenRequest implements Action {
  readonly type = NotificationActionsTypes.UpdatePushTokenRequest;

  constructor(public payload: { params: NotificationTokenRequest }) {}
}

export class UpdatePushTokenSuccess implements Action {
  readonly type = NotificationActionsTypes.UpdatePushTokenSuccess;

  constructor(public payload: { pushToken: NotificationToken }) {}
}

export class UpdatePushTokenError implements Action {
  readonly type = NotificationActionsTypes.UpdatePushTokenError;

  constructor(public error: { errors: any[] | null }) {}
}

export type ActionsUnion =
  | NotificationsLoadRequest
  | NotificationsLoadSuccess
  | NotificationsLoadError
  | ReadNotificationRequest
  | ReadNotificationSuccess
  | ReadNotificationError
  | AddNotification
  | UpdatePushTokenRequest
  | UpdatePushTokenSuccess
  | UpdatePushTokenError;
