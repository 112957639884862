import { Action } from '@ngrx/store';
import { OrderDetails, Order } from '@Mesh/core/models/order';
import { GetOrderHistoryCriteria } from '@Mesh/core/models/request/get-order-history-criteria';
import { BonusesRecommendedOrdersCriteria, RecommendOrderBonus } from './order.model';
import { Bonus } from '../../core/models/bonus';
import { DelayedOrder, DelayedOrderCriteria, DelayedOrderUpdatePayload } from '../../core/models/delayed-order';
import { Product } from '../../core/models/product';
import { IPageableList } from '../../core/models/pageable';
import { User } from '../../core/models/user';
import { CartItem } from '../cart/cart.models';

export enum OrderActionsTypes {
  OrderLoadSuccess = '[Order] Order  Success',
  OrderLoadError = '[Order] Order  Error',
  OrderLoadRequest = '[Order] Order  Request',

  OrderLoadPageFailure = '[Order] Order load page Failure',
  OrderLoadPageRequest = '[Order] Order load page Request',

  OrderSetTotalCount = '[Order] Order set total count',
  OrderSetActive = '[Order] Order set active',
  OrderSetActiveSuccess = '[Order] Order set active success',

  OrderHistoryLoadSuccess = '[Order] Order history success',
  OrderHistoryLoadError = '[Order] Order history error',
  OrderHistoryLoadRequest = '[Order] Order history request',

  OrderHistoryLoadPageFailure = '[Order] Order history load page Failure',
  OrderHistoryLoadPageRequest = '[Order] Order history load page Request',

  OrderDetailsLoadSuccess = '[Order] Order details Success',
  OrderDetailsLoadError = '[Order] Order details Error',
  OrderDetailsLoadRequest = '[Order] Order details Request',
  ResetOrdersValue = '[Order] Reset orders value',
  ResetHistoryOrdersValue = '[Order] Reset history orders value',

  OrderSetConfirmed = '[Order] Order set confirmed',
  OrderSetConfirmedSuccess = '[Order] Order set confirmed success',
  OrderSetConfirmedError = '[Order] Order set confirmed error',

  LoadBonusesRecommendedOrders = '[Cart] Load bonuses for recommended orders',
  LoadBonusesRecommendedOrdersSuccess = '[Cart] Load bonuses for recommended orders success',
  LoadBonusesRecommendedOrdersFailure = '[Cart] Load bonuses for recommended orders failure',

  LoadBonusesRecommendedOrdersByFilters = '[Cart] Load bonuses on recommended orders by filters',
  LoadBonusesRecommendedOrdersByFiltersSuccess = '[Cart] Load bonuses on recommended orders by filters success',
  LoadBonusesRecommendedOrdersByFiltersFailure = '[Cart] Load bonuses on recommended orders by filters failure',
  ResetBonusesRecommendedOrdersByFilters = '[Cart] Reset bonuses on recommended orders by filters',

  LoadBonusesRecommendedOrdersWithoutFilters = '[Order] Load bonuses on recommended orders without filters',
  LoadBonusesRecommendedOrdersWithoutFiltersSuccess = '[Order] Load bonuses on recommended orders without filters success',
  LoadBonusesRecommendedOrdersWithoutFiltersFailure = '[Order] Load bonuses on recommended orders without filters failure',

  GetDelayedOrders = '[Order] Load delayed orders',
  GetDelayedOrdersSuccess = '[Order] Load delayed orders success',
  GetDelayedOrdersError = '[Order] Load delayed orders error',

  LoadDelayedOrder = '[Order] Load delayed order by id',
  LoadDelayedOrderSuccess = '[Order] Load delayed order by id success',
  LoadDelayedOrderError = '[Order] Load delayed orders by id error',

  RemoveDelayedOrder = '[Order] Remove delayed orders',
  RemoveDelayedOrderSuccess = '[Order] Remove delayed orders success',
  RemoveDelayedOrderError = '[Order] Remove delayed orders error',

  UpdateDelayedOrder = '[Order] Update delayed orders',
  UpdateDelayedOrderSuccess = '[Order] Update delayed orders success',
  UpdateDelayedOrderError = '[Order] Update delayed orders error',
  ResetUpdateDelayedOrder = '[Order] Reset update delayed orders',

  LoadOrderProductsSuccess = '[Order] Load products for orders success',
  LoadOrderProductsError = '[Order] Load products for orders error',

  LoadOrderUsersSuccess = '[Order] Load users for orders success',
  LoadOrderUsersError = '[Order] Load users for orders error',

  RequestInvoiceDelayedOrder = '[Order] RequestInvoice delayed orders',
  RequestInvoiceDelayedOrderSuccess = '[Order] RequestInvoice delayed orders success',
  RequestInvoiceDelayedOrderError = '[Order] RequestInvoice delayed orders error',
  RequestInvoiceDelayedOrderReset = '[Order] RequestInvoice delayed orders reset',
}

export class OrderLoadRequest implements Action {
  readonly type = OrderActionsTypes.OrderLoadRequest;

  constructor(public payload: GetOrderHistoryCriteria) {}
}

export class OrderLoadPageRequest implements Action {
  readonly type = OrderActionsTypes.OrderLoadPageRequest;

  constructor(public body: { page: number; itemsPerPage: number; startDate: string; finishDate: string }) {}
}

export class OrderLoadPageFailure implements Action {
  readonly type = OrderActionsTypes.OrderLoadPageFailure;

  constructor(public error?: any) {}
}

export class OrderLoadSuccess implements Action {
  readonly type = OrderActionsTypes.OrderLoadSuccess;

  constructor(public payload: { orders: Order[]; totalPages: number; currentPage: number }) {}
}

export class OrderLoadError implements Action {
  readonly type = OrderActionsTypes.OrderLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class OrderSetTotalCount implements Action {
  readonly type = OrderActionsTypes.OrderSetTotalCount;

  constructor(public payload: { totalCount: number }) {}
}

export class OrderSetActive implements Action {
  readonly type = OrderActionsTypes.OrderSetActive;

  constructor(public payload: { docNumber: string | null }) {}
}

export class OrderSetActiveSuccess implements Action {
  readonly type = OrderActionsTypes.OrderSetActiveSuccess;
}

export class OrderDetailsLoadRequest implements Action {
  readonly type = OrderActionsTypes.OrderDetailsLoadRequest;
}

export class OrderDetailsLoadSuccess implements Action {
  readonly type = OrderActionsTypes.OrderDetailsLoadSuccess;

  constructor(public payload: { orderDetail: OrderDetails[] }) {}
}

export class OrderDetailsLoadError implements Action {
  readonly type = OrderActionsTypes.OrderDetailsLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class OrderHistoryLoadRequest implements Action {
  readonly type = OrderActionsTypes.OrderHistoryLoadRequest;

  constructor(public payload: GetOrderHistoryCriteria) {}
}

export class OrderHistoryLoadPageRequest implements Action {
  readonly type = OrderActionsTypes.OrderHistoryLoadPageRequest;

  constructor(public body: { page: number; itemsPerPage: number; startDate: string; finishDate: string }) {}
}

export class OrderHistoryLoadPageFailure implements Action {
  readonly type = OrderActionsTypes.OrderHistoryLoadPageFailure;

  constructor(public error?: any) {}
}

export class OrderHistoryLoadSuccess implements Action {
  readonly type = OrderActionsTypes.OrderHistoryLoadSuccess;

  constructor(public payload: { orders: Order[]; total: number; totalpages: number; currentPage: number }) {}
}

export class OrderHistoryLoadError implements Action {
  readonly type = OrderActionsTypes.OrderHistoryLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class ResetOrdersValue implements Action {
  readonly type = OrderActionsTypes.ResetOrdersValue;
}

export class ResetHistoryOrdersValue implements Action {
  readonly type = OrderActionsTypes.ResetHistoryOrdersValue;
}
export class OrderSetConfirmed implements Action {
  readonly type = OrderActionsTypes.OrderSetConfirmed;

  constructor(public payload: { docNumber: string }) {}
}

export class OrderSetConfirmedSuccess implements Action {
  readonly type = OrderActionsTypes.OrderSetConfirmedSuccess;
  constructor(public payload: { docNumber: string }) {}
}

export class OrderSetConfirmedError implements Action {
  readonly type = OrderActionsTypes.OrderSetConfirmedError;
  constructor(public error: { errors: any[] | null }) {}
}

export class LoadBonusesRecommendedOrders implements Action {
  readonly type = OrderActionsTypes.LoadBonusesRecommendedOrders;
  constructor(public payload?: BonusesRecommendedOrdersCriteria) {}
}
export class LoadBonusesRecommendedOrdersSuccess implements Action {
  readonly type = OrderActionsTypes.LoadBonusesRecommendedOrdersSuccess;

  constructor(public payload: RecommendOrderBonus[]) {}
}
export class LoadBonusesRecommendedOrdersFailure implements Action {
  readonly type = OrderActionsTypes.LoadBonusesRecommendedOrdersFailure;
  constructor(public error: { errors: any[] | null }) {}
}

export class LoadBonusesRecommendedOrdersByFilters implements Action {
  readonly type = OrderActionsTypes.LoadBonusesRecommendedOrdersByFilters;
  constructor(public payload?: any) {}
}
export class LoadBonusesRecommendedOrdersByFiltersSuccess implements Action {
  readonly type = OrderActionsTypes.LoadBonusesRecommendedOrdersByFiltersSuccess;

  constructor(public payload: RecommendOrderBonus[]) {}
}
export class LoadBonusesRecommendedOrdersByFiltersFailure implements Action {
  readonly type = OrderActionsTypes.LoadBonusesRecommendedOrdersByFiltersFailure;
  constructor(public error: { errors: any[] | null }) {}
}

export class ResetBonusesRecommendedOrdersByFilters implements Action {
  readonly type = OrderActionsTypes.ResetBonusesRecommendedOrdersByFilters;
}

export class LoadBonusesRecommendedOrdersWithoutFilters implements Action {
  readonly type = OrderActionsTypes.LoadBonusesRecommendedOrdersWithoutFilters;

  constructor(public payload?: BonusesRecommendedOrdersCriteria) {}
}
export class LoadBonusesRecommendedOrdersWithoutFiltersSuccess implements Action {
  readonly type = OrderActionsTypes.LoadBonusesRecommendedOrdersWithoutFiltersSuccess;

  constructor(public payload: RecommendOrderBonus[]) {}
}
export class LoadBonusesRecommendedOrdersWithoutFiltersFailure implements Action {
  readonly type = OrderActionsTypes.LoadBonusesRecommendedOrdersWithoutFiltersFailure;
}
export class GetDelayedOrders implements Action {
  readonly type = OrderActionsTypes.GetDelayedOrders;

  constructor(public payload?: DelayedOrderCriteria) {}
}

export class GetDelayedOrdersSuccess implements Action {
  readonly type = OrderActionsTypes.GetDelayedOrdersSuccess;
  constructor(public payload: IPageableList<DelayedOrder>) {}
}

export class GetDelayedOrdersError implements Action {
  readonly type = OrderActionsTypes.GetDelayedOrdersError;
  constructor(public error: { errors: any[] | null }) {}
}

export class LoadDelayedOrder implements Action {
  readonly type = OrderActionsTypes.LoadDelayedOrder;

  constructor(public payload: { delayedOrderId: number }) {}
}

export class LoadDelayedOrderSuccess implements Action {
  readonly type = OrderActionsTypes.LoadDelayedOrderSuccess;
  constructor(public payload: DelayedOrder) {}
}

export class LoadDelayedOrderError implements Action {
  readonly type = OrderActionsTypes.LoadDelayedOrderError;
  constructor(public error: { errors: any[] | null }) {}
}

export class RemoveDelayedOrder implements Action {
  readonly type = OrderActionsTypes.RemoveDelayedOrder;

  constructor(public payload: { delayedOrderId: number }) {}
}

export class RemoveDelayedOrderSuccess implements Action {
  readonly type = OrderActionsTypes.RemoveDelayedOrderSuccess;
  constructor(public payload: { delayedOrderId: number }) {}
}

export class RemoveDelayedOrderError implements Action {
  readonly type = OrderActionsTypes.RemoveDelayedOrderError;
  constructor(public error: { errors: any[] | null }) {}
}

export class LoadOrderProductsSuccess implements Action {
  readonly type = OrderActionsTypes.LoadOrderProductsSuccess;
  constructor(public payload: Product[]) {}
}

export class LoadOrderProductsError implements Action {
  readonly type = OrderActionsTypes.LoadOrderProductsError;
  constructor(public error: { errors: any[] | null }) {}
}

export class UpdateDelayedOrder implements Action {
  readonly type = OrderActionsTypes.UpdateDelayedOrder;

  constructor(public payload: DelayedOrderUpdatePayload) {}
}

export class UpdateDelayedOrderSuccess implements Action {
  readonly type = OrderActionsTypes.UpdateDelayedOrderSuccess;
  constructor(public payload: DelayedOrder) {}
}

export class UpdateDelayedOrderError implements Action {
  readonly type = OrderActionsTypes.UpdateDelayedOrderError;
  constructor(public error: any) {}
}

export class ResetUpdateDelayedOrder implements Action {
  readonly type = OrderActionsTypes.ResetUpdateDelayedOrder;

  constructor() {}
}
export class LoadOrderUsersSuccess implements Action {
  readonly type = OrderActionsTypes.LoadOrderUsersSuccess;
  constructor(public payload: User[]) {}
}

export class LoadOrderUsersError implements Action {
  readonly type = OrderActionsTypes.LoadOrderUsersError;
  constructor(public error: { errors: any[] | null }) {}
}

export class RequestInvoiceDelayedOrder implements Action {
  readonly type = OrderActionsTypes.RequestInvoiceDelayedOrder;

  constructor(public payload: any) {}
}

export class RequestInvoiceDelayedOrderSuccess implements Action {
  readonly type = OrderActionsTypes.RequestInvoiceDelayedOrderSuccess;
  constructor(public payload: any) {}
}

export class RequestInvoiceDelayedOrderError implements Action {
  readonly type = OrderActionsTypes.RequestInvoiceDelayedOrderError;
  constructor(public error: any) {}
}

export class RequestInvoiceDelayedOrderReset implements Action {
  readonly type = OrderActionsTypes.RequestInvoiceDelayedOrderReset;

  constructor() {}
}

export type ActionsUnion =
  | OrderLoadRequest
  | OrderLoadSuccess
  | OrderLoadError
  | OrderSetTotalCount
  | OrderSetActive
  | OrderSetActiveSuccess
  | OrderDetailsLoadRequest
  | OrderDetailsLoadSuccess
  | OrderDetailsLoadError
  | OrderHistoryLoadRequest
  | OrderHistoryLoadSuccess
  | OrderHistoryLoadError
  | ResetOrdersValue
  | ResetHistoryOrdersValue
  | OrderHistoryLoadPageRequest
  | OrderHistoryLoadPageFailure
  | OrderSetConfirmed
  | OrderSetConfirmedSuccess
  | OrderSetConfirmedError
  | LoadBonusesRecommendedOrders
  | LoadBonusesRecommendedOrdersSuccess
  | LoadBonusesRecommendedOrdersFailure
  | LoadBonusesRecommendedOrdersByFilters
  | LoadBonusesRecommendedOrdersByFiltersSuccess
  | LoadBonusesRecommendedOrdersByFiltersFailure
  | ResetBonusesRecommendedOrdersByFilters
  | LoadBonusesRecommendedOrdersWithoutFilters
  | LoadBonusesRecommendedOrdersWithoutFiltersSuccess
  | LoadBonusesRecommendedOrdersWithoutFiltersFailure
  | GetDelayedOrders
  | GetDelayedOrdersSuccess
  | GetDelayedOrdersError
  | LoadDelayedOrder
  | LoadDelayedOrderSuccess
  | LoadDelayedOrderError
  | RemoveDelayedOrder
  | RemoveDelayedOrderSuccess
  | RemoveDelayedOrderError
  | LoadOrderProductsSuccess
  | LoadOrderProductsError
  | UpdateDelayedOrder
  | UpdateDelayedOrderSuccess
  | UpdateDelayedOrderError
  | ResetUpdateDelayedOrder
  | LoadOrderUsersSuccess
  | LoadOrderUsersError
  | RequestInvoiceDelayedOrder
  | RequestInvoiceDelayedOrderSuccess
  | RequestInvoiceDelayedOrderError
  | RequestInvoiceDelayedOrderReset;
