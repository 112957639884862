import { Action } from '@ngrx/store';
import { Plan } from '@Mesh/core/models/plan';
import { IPlanGroup } from '@Mesh/core/services/plan.service';

export enum PlanActionsTypes {
  PlanLoadSuccess = '[Plan] Plan Success',
  PlanSetActive = '[Plan] Plan set active',
  PlanLoadError = '[Plan] Plan  Error',
  PlanLoadRequest = '[Plan] Plan Request',
}

export class PlanLoadRequest implements Action {
  readonly type = PlanActionsTypes.PlanLoadRequest;
}

export class PlanLoadSuccess implements Action {
  readonly type = PlanActionsTypes.PlanLoadSuccess;
  constructor(public payload: { plan: IPlanGroup[] }) {}
}

export class PlanSetActive implements Action {
  readonly type = PlanActionsTypes.PlanSetActive;
  constructor(public payload: { active: Plan }) {}
}

export class PlanLoadError implements Action {
  readonly type = PlanActionsTypes.PlanLoadError;
  constructor(public error: { errors: any[] | null }) {}
}

export type PlanActionsUnion = PlanLoadRequest | PlanLoadSuccess | PlanSetActive | PlanLoadError;
