import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SharedModule } from '@Mesh/@pages/components/shared.module';
import { AddressDropdownComponent } from './address-dropdown/address-dropdown.component';
import { NgModule } from '@angular/core';
import { BreadcrumbComponent } from '@Mesh/shared/components/breadcrumb/breadcrumb.component';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from '@Mesh/shared/components/checkbox/checkbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IframeDocumentComponent } from '@Mesh/shared/components/iframe-document/iframe-document.component';
import { QuickviewBasketComponent } from './quickview-basket/quickview-basket.component';
import { BasketModule } from '../../pages/catalog/basket/basket.module';
import { ProgressBarModule } from './progress-bar/progress-bar.module';

const MODULE_COLLECTION = [
  BreadcrumbComponent,
  CheckboxComponent,
  AddressDropdownComponent,
  IframeDocumentComponent,
  QuickviewBasketComponent,
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NgxUiLoaderModule, ProgressBarModule, FormsModule, SharedModule, BasketModule],
  declarations: MODULE_COLLECTION,
  exports: MODULE_COLLECTION,
})
export class ComponentsModule {}
