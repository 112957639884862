import { LoginActionsTypes, LoginActionsUnion } from '@Mesh/store/login/login.actions';
import { withLoadable } from '@Mesh/utils/loadable/with-loadable';
import { initialState, LoginState } from '@Mesh/store/login/login.state';
import { POSTRequestStatus } from '@Mesh/store/user/user.state';

function baseLoginReducer(state = initialState, action: LoginActionsUnion): LoginState {
  switch (action.type) {
    case LoginActionsTypes.LoginSuccess:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      };
    case LoginActionsTypes.AuthCancel:
      return initialState;
    case LoginActionsTypes.LoginError:
      return {
        ...state,
        error: action.error,
      };
    case LoginActionsTypes.GetLegalEntitiesResponse:
      return {
        ...state,
        entities: action.payload,
      };
    case LoginActionsTypes.CheckExistPhoneNumberSuccess:
      return {
        ...state,
        canBeAuthorized: action.payload.canBeAuthorized,
      };
    case LoginActionsTypes.CheckExistPhoneNumberError:
      return {
        ...state,
        error: action.error,
      };
    case LoginActionsTypes.RegisterPhoneNumberSuccess:
      return {
        ...state,
        identifier: action.payload.identifier,
        documents: action.payload.documents || [],
        shouldCreatePassword: action.payload.shouldCreatePassword,
      };
    case LoginActionsTypes.RegisterPhoneNumberError:
      return {
        ...state,
        error: action.error,
      };
    case LoginActionsTypes.ConfirmRegisterSuccess:
      return {
        ...state,
        name: action.payload.name,
        inn: action.payload.inn,
        identifier: action.payload.identifier,
      };
    case LoginActionsTypes.ConfirmRegisterError:
      return {
        ...state,
        error: action.error,
      };
    case LoginActionsTypes.AcceptCodeSuccess:
      return {
        ...state,
        acceptCodeSuccess: true,
      };
    case LoginActionsTypes.AcceptCodeError:
      return {
        ...state,
        acceptCodeSuccess: false,
        error: action.error,
      };
    case LoginActionsTypes.SignupSuccess:
      return {
        ...state,
        token: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        user: action.payload.user,
      };
    case LoginActionsTypes.SignupError:
      return {
        ...state,
        error: action.error,
      };
    case LoginActionsTypes.ApplicationSuccess:
      return {
        ...state,
        applicationStatus: action.payload.applicationStatus,
      };
    case LoginActionsTypes.ApplicationError:
      return {
        ...state,
        applicationStatus: false,
        error: action.error,
      };

    case LoginActionsTypes.RequestUpdatePassword:
      return {
        ...state,
        updatePasswordVerifyStatus: POSTRequestStatus.SENT,
      };
    case LoginActionsTypes.RequestUpdatePasswordSuccess:
      return {
        ...state,
        error: null,
        updatePasswordIdentifier: action.payload.identifier,
        updatePasswordVerifyStatus: POSTRequestStatus.SUCCESS,
      };
    case LoginActionsTypes.RequestUpdatePasswordFailure:
      return {
        ...state,
        error: action.payload.error,
      };
    case LoginActionsTypes.VerifyUpdatePassword:
      return {
        ...state,
        updatePasswordSmsVerifyStatus: POSTRequestStatus.SENT,
      };
    case LoginActionsTypes.VerifyUpdatePasswordSuccess:
      return {
        ...state,
        error: null,
        updatePasswordSmsVerifyStatus: POSTRequestStatus.SUCCESS,
      };
    case LoginActionsTypes.VerifyUpdatePasswordFailure:
      return {
        ...state,
        error: action.payload.error,
        updatePasswordSmsVerifyStatus: POSTRequestStatus.FAILURE,
      };
    case LoginActionsTypes.SetNewPasswordRequestSuccess:
      return {
        ...state,
        token: action.payload.accessToken,
        passwordSetSuccess: true,
      };
    case LoginActionsTypes.SetNewPasswordRequestFailure:
      return {
        ...state,
        error: action.payload.error,
      };

    case LoginActionsTypes.RequestUserUpdatePassword:
      return {
        ...state,
        updatePasswordVerifyStatus: POSTRequestStatus.SENT,
      };
    case LoginActionsTypes.RequestUserUpdatePasswordSuccess:
      return {
        ...state,
        newUserPassword: action.payload.password,
        updatePasswordIdentifier: action.payload.identifier,
        updatePasswordVerifyStatus: POSTRequestStatus.SUCCESS,
      };
    case LoginActionsTypes.RequestUserUpdatePasswordFailure:
      return {
        ...state,
        error: action.payload.error,
      };
    case LoginActionsTypes.VerifyUpdatePassword:
      return {
        ...state,
        updatePasswordSmsVerifyStatus: POSTRequestStatus.SENT,
      };
    case LoginActionsTypes.UpdateLoginUserAvatar:
      return {
        ...state,
        // @ts-ignore
        user: { ...state.user, avatar: action.payload.avatar },
      };
    case LoginActionsTypes.GetUserSuccess:
      return {
        ...state,
        // @ts-ignore
        user: { ...action.payload.user },
      };
    case LoginActionsTypes.SetClientSuccess:
      return {
        ...state,
        isAuthenticated: !!action.payload.user && !!action.payload.token,
        user: action.payload.user,
        token: action.payload.token,
      };
    case LoginActionsTypes.SendSmsCodeParticipantSuccess:
      return {
        ...state,
        error: null,
        identifierParticipant: action.payload.identifier ? action.payload.identifier : null,
      };
    case LoginActionsTypes.CreateParticipantSuccess:
      return {
        ...state,
        error: null,
        applicationStatus: true,
      };
    case LoginActionsTypes.SendSmsCodeParticipantFailure:
      return {
        ...state,
        error: action.payload.error,
        applicationStatus: false,
      };
    case LoginActionsTypes.CreateParticipantFailure:
      return {
        ...state,
        error: action.payload.error,
        applicationStatus: false,
      };
    case LoginActionsTypes.ResetIdentifierParticipant:
      return {
        ...state,
        identifierParticipant: null,
      };
    case LoginActionsTypes.RefreshToken:
      return {
        ...state,
        error: null,
        token: null,
      };
    case LoginActionsTypes.RefreshTokenSuccess:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        error: null,
      };
    case LoginActionsTypes.RefreshTokenFailure:
      return {
        ...state,
        error: action.payload.error,
      };
    case LoginActionsTypes.Logout:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        refreshToken: null,
        user: null,
      };
    default:
      return state;
  }
}

export function loginReducer(state: LoginState, action: LoginActionsUnion): LoginState {
  return withLoadable(baseLoginReducer, {
    loadingActionType: [
      LoginActionsTypes.Login,
      LoginActionsTypes.CheckExistPhoneNumber,
      LoginActionsTypes.RegisterPhoneNumber,
      LoginActionsTypes.AcceptCode,
      LoginActionsTypes.Signup,
      LoginActionsTypes.ApplicationRequest,
      LoginActionsTypes.RequestUpdatePassword,
      LoginActionsTypes.VerifyUpdatePassword,
      LoginActionsTypes.SetNewPasswordRequest,
      LoginActionsTypes.SendSmsCodeParticipant,
      LoginActionsTypes.CreateParticipant,
      LoginActionsTypes.RefreshToken,
    ],
    successActionType: [
      LoginActionsTypes.LoginSuccess,
      LoginActionsTypes.CheckExistPhoneNumberSuccess,
      LoginActionsTypes.RegisterPhoneNumberSuccess,
      LoginActionsTypes.AcceptCodeSuccess,
      LoginActionsTypes.SignupSuccess,
      LoginActionsTypes.ApplicationSuccess,
      LoginActionsTypes.RequestUpdatePasswordSuccess,
      LoginActionsTypes.VerifyUpdatePasswordSuccess,
      LoginActionsTypes.SetNewPasswordRequestSuccess,
      LoginActionsTypes.SetClientSuccess,
      LoginActionsTypes.SendSmsCodeParticipantSuccess,
      LoginActionsTypes.CreateParticipantSuccess,
      LoginActionsTypes.RefreshTokenSuccess,
    ],
    errorActionType: [
      LoginActionsTypes.LoginError,
      LoginActionsTypes.CheckExistPhoneNumberError,
      LoginActionsTypes.RegisterPhoneNumberError,
      LoginActionsTypes.AcceptCodeError,
      LoginActionsTypes.SignupError,
      LoginActionsTypes.ApplicationError,
      LoginActionsTypes.RequestUpdatePasswordFailure,
      LoginActionsTypes.VerifyUpdatePasswordFailure,
      LoginActionsTypes.SetNewPasswordRequestFailure,
      LoginActionsTypes.SendSmsCodeParticipantFailure,
      LoginActionsTypes.CreateParticipantFailure,
      LoginActionsTypes.RefreshTokenFailure,
    ],
  })(state, action);
}
