import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { bonusReducer } from '@Mesh/store/bonus/bonus.reducers';
import { BonusEffects } from '@Mesh/store/bonus/bonus.effects';

@NgModule({
  imports: [StoreModule.forFeature('bonus', bonusReducer), EffectsModule.forFeature([BonusEffects])],
})
export class BonusStoreModule {}
