import { AuthenticationService } from '@Mesh/core/services/authentication.service';
import { Store, select } from '@ngrx/store';
import { ReadNotificationRequest } from '@Mesh/store/notification/notification.actions';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Notification } from '@Mesh/core/models/notification';
import { filter } from 'rxjs/operators';
import { WebsocketService } from '@Mesh/core/services/websocket.service';
import { Sounds, SoundService } from '@Mesh/core/services/chat/sound.service';
import { AppState } from '@Mesh/store/app.state';
import { notificationSelectors } from '@Mesh/store/notification/notification.selectors';

@Component({
  selector: 'iql-message-notifications',
  templateUrl: './message-notifications.component.html',
  styleUrls: ['./message-notifications.component.scss'],
})
export class MessageNotificationsComponent implements OnInit {
  popups: any[] = [];
  notifications: any[] = [];
  allNotifications: any[];
  notificationToOpen;
  notifications$ = this.store.pipe(select(notificationSelectors.getNotificationsContent));

  constructor(
    private service: WebsocketService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private authService: AuthenticationService,
    private soundService: SoundService
  ) {}

  ngOnInit() {
    this.notifications$.subscribe((notifications) => {
      if (notifications) {
        this.notifications = notifications
          .filter((notification) => {
            const popup = this.popups.find((popup) => popup.identifier.indexOf('id=' + notification.id) !== -1);
            if (popup && !popup.sound && notification.payload && notification.payload.eventType === 'message') {
              popup.sound = true;
              this.soundService.play(Sounds.NOTIFICATION);
            }
            return !!popup;
          })
          .map((notification) => {
            return {
              ...notification,
            } as Notification;
          });
        this.cdr.detectChanges();
      }
    });

    this.service.messagesSubject$.pipe(filter(({ entityType }) => entityType === 'NOTIFICATION')).subscribe((event) => {
      this.popups.unshift({
        ...event,
        sound: false,
      });
    });
  }

  // loadNotification() {
  //   let params: NotificationRequest = {
  //     userId: this.authService.currentUser.id,
  //     size: 10,
  //     page: 0,
  //     sort: 'id',
  //     direction: 'desc',
  //   };
  //   return this.store.dispatch(new NotificationsLoadRequest({ params }));
  // }

  removePopup(event: Event, index: number, notification: any) {
    if (event) {
      event.stopPropagation();
    }
    this.notifications = this.notifications.filter((item) => item !== notification);
    this.popups = [];
  }

  openNotification(event: Event, index: number, notification: any) {
    this.removePopup(null, index, notification);
    this.store.dispatch(new ReadNotificationRequest({ id: notification.id }));
    // this.store.dispatch(goToNotification({ notification }));
  }
}
