import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { orderReducer } from '@Mesh/store/order/order.reducers';
import { OrderEffects } from '@Mesh/store/order/order.effects';

@NgModule({
  imports: [StoreModule.forFeature('order', orderReducer), EffectsModule.forFeature([OrderEffects])],
})
export class OrderStoreModule {}
