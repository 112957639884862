import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { loginReducer } from '@Mesh/store/login/login.reducers';
import { LoginEffects } from '@Mesh/store/login/login.effects';
import { StoreModule } from '@ngrx/store';
import { USER_CONFIG_TOKEN, USER_LOCAL_STORAGE_KEY, USER_STORAGE_KEYS } from '@Mesh/store/login/user.token';
import { LocalStorageService } from '@Mesh/core/services/local-storage';
import { getUserConfig } from '@Mesh/store/user/user-store.module';

@NgModule({
  imports: [StoreModule.forFeature('login', loginReducer, USER_CONFIG_TOKEN), EffectsModule.forFeature([LoginEffects])],
  providers: [
    { provide: USER_STORAGE_KEYS, useValue: ['user'] },
    { provide: USER_LOCAL_STORAGE_KEY, useValue: 'user__storage' },
    {
      provide: USER_CONFIG_TOKEN,
      deps: [USER_STORAGE_KEYS, USER_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getUserConfig,
    },
  ],
})
export class LoginStoreModule {}
