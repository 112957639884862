import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Product } from '../../../../../../core/models/product';

@Component({
  selector: 'app-chat-task-leftover-products',
  templateUrl: './chat-task-leftover-products.component.html',
  styleUrls: ['./chat-task-leftover-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTaskLeftoverProductsComponent implements OnInit, OnChanges {
  @Input() products: Product[] = [];

  @Output() submited: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  message: string = 'Пожалуйста, укажите остатки по следующим позициям товаров';
  error: boolean;
  get controls() {
    return this.products || [];
  }
  get changes() {
    return this.form.valueChanges;
  }
  get valid() {
    return this.form.valid;
  }
  get value() {
    return this.form.value;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.createGroup();
  }

  ngOnChanges() {
    if (this.form) {
      const controls = Object.keys(this.form.controls);
      const configControls = (this.controls || []).map((item) => String(item.materialId));

      controls.filter((control) => !configControls.includes(control)).forEach((control) => this.form.removeControl(control));

      configControls
        .filter((control) => !controls.includes(control))
        .forEach((name) => {
          const config = this.products.find((control) => String(control.materialId) === name);
          this.form.addControl(name, this.createControl(0));
        });
    }
  }

  createGroup() {
    const group = this.fb.group({});
    this.controls.forEach((control) => group.addControl(String(control.materialId), this.createControl(0)));
    return group;
  }

  createControl(value: number) {
    return this.fb.control(0, [Validators.required, Validators.min(0)]);
  }

  onSubmit() {
    // if (this.valid) {
    this.submited.emit(this.form.getRawValue());
    // } else {
    //   this.message = 'Вы не указали остатки по следующим позициям Нажмите подтвердить, если всё верно';
    //   this.error = true;
    // }
  }

  onConfirm() {
    this.submited.emit(this.form.getRawValue());
  }
}
