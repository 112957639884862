import {
  HistoryTaskLoadPageRequestFailure,
  LoadTaskById,
  LoadTaskByIdFailure,
  LoadTaskByIdSuccess,
  ModerationTaskLoadPageRequest,
  ModerationTaskLoadPageRequestFailure,
  ResetHistoryTaskValue,
  ResetModerationTaskValue,
  ResetTaskValue,
  TaskProgressLoad,
  TaskProgressLoadError,
  TaskProgressLoadSuccess,
} from './task.actions';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { TaskService } from '@Mesh/core/services';
import { Observable, of } from 'rxjs';
import {
  CompletedTaskLoadError,
  CompletedTaskLoadRequest,
  CompletedTaskLoadSuccess,
  HistoryTaskLoadError,
  HistoryTaskLoadPageRequest,
  HistoryTaskLoadRequest,
  HistoryTaskLoadSuccess,
  ModerationTaskLoadRequest,
  ModerationTaskLoadSuccess,
  TaskActionsTypes,
  TaskLoadError,
  TaskLoadPageRequest,
  TaskLoadRequest,
  TaskLoadSuccess,
  TaskSetTotalCount,
} from '@Mesh/store/task/task.actions';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { IPageableList } from '@Mesh/core/models/pageable';
import { Task } from '@Mesh/core/models/task';
import { GetClientTasksCriteria } from '@Mesh/core/models/request';
import { taskSelectors } from '@Mesh/store/task/task.selectors';
import { OutletActionsTypes, OutletSetActive, OutletSetActiveId } from '../outlet/outlet.actions';
import { getGetStartDateAndFinishDate } from '@Mesh/core/services/util';

@Injectable()
export class TaskEffects {
  constructor(private actions$: Actions, private store$: Store<AppState>, private taskService: TaskService) {}

  @Effect()
  load: Observable<Action> = this.actions$.pipe(
    ofType<TaskLoadRequest>(TaskActionsTypes.TaskLoadRequest),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    switchMap(([param, activeOutlet]) =>
      this.taskService
        .getClientTasks({
          ...param.payload,
          addressSapId: activeOutlet?.addressSapId,
        })
        .pipe(
          /*            mergeMap((tasks) => {
                console.log('tasks#:', tasks);

                const tasksIds = tasks.content.map(task => task.id);

                const tasksProgress$ = this.dataService.getAllTasksProgressByOutlet(activeOutlet, tasksIds);

                return forkJoin([of(tasks), tasksProgress$]).pipe(
                    map(([taskList, progress]) => {
                        return {...taskList, content: this.mergeTaskProgress(taskList.content, progress)};
                    })
                );

            }),*/
          mergeMap((res) => {
            return [
              new TaskLoadSuccess({ tasks: res.content, totalPages: res.totalPages }),
              new TaskSetTotalCount({ totalCount: res.totalElements, onModeration: false }),
            ];
          }),
          catchError((error) => of(new TaskLoadError(error)))
        )
    )
  );

  @Effect()
  loadPage: Observable<Action> = this.actions$.pipe(
    ofType<TaskLoadPageRequest>(TaskActionsTypes.TaskLoadPageRequest),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    withLatestFrom(this.store$.select(taskSelectors.getActiveTasksTasks)),
    switchMap(([[param, activeOutlet], tasks]) => {
      const criteria: Partial<GetClientTasksCriteria> = {
        pageNumber: param.body.page,
        addressSapId: activeOutlet?.addressSapId,
        pageSize: param.body.itemsPerPage,
        clientSapId: activeOutlet?.clientSapId,
        completed: false,
        taskStatuses: ['NEW'],
        onlyActual: true,
        notOverdue: true,
      };
      return this.taskService.getClientTasks(criteria).pipe(
        mergeMap((data) => {
          const newTasksArr = [...tasks, ...data.content] as Task[];
          return [
            new TaskLoadSuccess({ tasks: newTasksArr, totalPages: data.totalPages }),
            new TaskSetTotalCount({ totalCount: data.totalElements, onModeration: false }),
          ];
        }),
        catchError((error) => {
          return of(new HistoryTaskLoadError(error));
        })
      );
    })
  );

  @Effect()
  loadModeration: Observable<Action> = this.actions$.pipe(
    ofType<ModerationTaskLoadRequest>(TaskActionsTypes.ModerationTaskLoadRequest),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    switchMap(([param, activeOutlet]) => {
      return this.taskService
        .getClientTasks({
          ...param.payload,
          addressSapId: activeOutlet?.addressSapId,
          onModeration: true,
        })
        .pipe(
          mergeMap((res) => [
            new ModerationTaskLoadSuccess({ tasks: res.content, totalPage: res.totalPages }),
            new TaskSetTotalCount({ totalCount: res.totalElements, onModeration: true }),
          ]),
          catchError((error) => of(new HistoryTaskLoadError(error)))
        );
    })
  );

  @Effect()
  loadModerationPage: Observable<Action> = this.actions$.pipe(
    ofType<ModerationTaskLoadPageRequest>(TaskActionsTypes.ModerationTaskLoadPageRequest),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    withLatestFrom(this.store$.select(taskSelectors.getModerationTasks)),
    switchMap(([[param, activeOutlet], tasks]) => {
      const criteria: Partial<GetClientTasksCriteria> = {
        pageNumber: param.body.page,
        addressSapId: activeOutlet?.addressSapId,
        pageSize: param.body.itemsPerPage,
        clientSapId: activeOutlet?.clientSapId,
        completed: false,
        onModeration: true,
        onlyActual: true,
        notOverdue: true,
      };
      return this.taskService.getClientTasks(criteria).pipe(
        mergeMap((res) => {
          const newModerationTasksArr = [...tasks, ...res.content] as Task[];
          return [
            new ModerationTaskLoadSuccess({ tasks: newModerationTasksArr, totalPage: res.totalPages }),
            new TaskSetTotalCount({ totalCount: res.totalElements, onModeration: true }),
          ];
        }),
        catchError((error) => of(new ModerationTaskLoadPageRequestFailure(error)))
      );
    })
  );

  @Effect()
  loadCompleted: Observable<Action> = this.actions$.pipe(
    ofType<ModerationTaskLoadRequest>(TaskActionsTypes.CompletedTaskLoadRequest),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    switchMap(([param, activeOutlet]) => {
      return this.taskService
        .getClientTasks({
          ...param.payload,
          addressSapId: activeOutlet?.addressSapId,
          completed: true,
        })
        .pipe(
          mergeMap((res) => [new CompletedTaskLoadSuccess({ totalCount: res.totalElements })]),
          catchError((error) => of(new CompletedTaskLoadError(error)))
        );
    })
  );

  @Effect()
  loadHistory: Observable<Action> = this.actions$.pipe(
    ofType<HistoryTaskLoadRequest>(TaskActionsTypes.HistoryTaskLoadRequest),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    switchMap(([param, activeOutlet]) =>
      this.taskService
        .getClientTasks({
          ...param.payload,
          addressSapId: activeOutlet?.addressSapId,
        })
        .pipe(
          /*            mergeMap((tasks) => {
                console.log('tasks#:', tasks);

                const tasksIds = tasks.content.map(task => task.id);

                const tasksProgress$ = this.dataService.getAllTasksProgressByOutlet(activeOutlet, tasksIds);

                return forkJoin([of(tasks), tasksProgress$]).pipe(
                    map(([taskList, progress]) => {
                        return {...taskList, content: this.mergeTaskProgress(taskList.content, progress)};
                    })
                );

            }),*/
          map(
            (payload: IPageableList<Task>) =>
              new HistoryTaskLoadSuccess({
                tasks: payload.content,
                tasksCount: payload.totalElements,
                totalPages: payload.totalPages,
                currentPage: 0,
              })
          ),
          catchError((error) => of(new HistoryTaskLoadError(error)))
        )
    )
  );

  @Effect()
  loadHistoryPage: Observable<Action> = this.actions$.pipe(
    ofType<HistoryTaskLoadPageRequest>(TaskActionsTypes.HistoryTaskLoadPageRequest),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    withLatestFrom(this.store$.select(taskSelectors.getHistoryTasks)),
    switchMap(([[param, activeOutlet], historyTasks]) => {
      const criteria: Partial<GetClientTasksCriteria> = {
        pageNumber: param.body.page,
        addressSapId: activeOutlet?.addressSapId,
        pageSize: param.body.itemsPerPage,
        clientSapId: activeOutlet?.clientSapId,
      };
      return this.taskService.getClientTasks(criteria).pipe(
        map((payload: IPageableList<Task>) => {
          const newHistoryTaskArr = [...historyTasks, ...payload.content] as Task[];
          return new HistoryTaskLoadSuccess({
            tasks: newHistoryTaskArr,
            tasksCount: payload.totalElements,
            totalPages: payload.totalPages,
            currentPage: param.body.page,
          });
        }),
        catchError((error) => of(new HistoryTaskLoadPageRequestFailure(error)))
      );
    })
  );

  @Effect()
  changeOutlet: Observable<Action> = this.actions$.pipe(
    ofType<OutletSetActive | OutletSetActiveId>(OutletActionsTypes.OutletSetActive, OutletActionsTypes.OutletSetActiveId),
    map(() => {
      return new ResetHistoryTaskValue();
    })
  );

  @Effect()
  loadHistoryTaksOnReset: Observable<Action> = this.actions$.pipe(
    ofType<ResetHistoryTaskValue>(TaskActionsTypes.ResetHistoryTaskValue),
    map(() => {
      return new HistoryTaskLoadPageRequest({ page: 0, itemsPerPage: 10 });
    })
  );

  @Effect()
  taskChangeOutlet: Observable<Action> = this.actions$.pipe(
    ofType<OutletSetActive | OutletSetActiveId>(OutletActionsTypes.OutletSetActive, OutletActionsTypes.OutletSetActiveId),
    map(() => {
      return new ResetTaskValue();
    })
  );

  @Effect()
  loadTaksOnReset: Observable<Action> = this.actions$.pipe(
    ofType<ResetTaskValue>(TaskActionsTypes.ResetTaskValue),
    map(() => {
      return new TaskLoadPageRequest({ page: 0, itemsPerPage: 10, onlyActual: true, notOverdue: true });
    })
  );

  @Effect()
  moderationTaskChangeOutlet: Observable<Action> = this.actions$.pipe(
    ofType<OutletSetActive | OutletSetActiveId>(OutletActionsTypes.OutletSetActive, OutletActionsTypes.OutletSetActiveId),
    map(() => {
      return new ResetModerationTaskValue();
    })
  );

  @Effect()
  loadModerationTaksOnReset: Observable<Action> = this.actions$.pipe(
    ofType<ResetModerationTaskValue>(TaskActionsTypes.ResetModerationTaskValue),
    map(() => {
      return new ModerationTaskLoadRequest({ pageNumber: 0, pageSize: 9999, completed: false });
    })
  );

  @Effect()
  loadTaskProgress: Observable<Action> = this.actions$.pipe(
    ofType<TaskProgressLoad>(TaskActionsTypes.TaskProgressLoad),
    map((action) => action.payload),
    mergeMap((payload) =>
      this.taskService.geProgressTask(payload).pipe(
        map((data) => new TaskProgressLoadSuccess(data)),
        catchError((error) => of(new TaskProgressLoadError(error)))
      )
    )
  );

  @Effect()
  loadTaskById$ = this.actions$.pipe(
    ofType<LoadTaskById>(TaskActionsTypes.LoadTaskById),
    switchMap((action) => {
      return this.taskService.getTaskById(action.payload.id).pipe(
        map((res) => new LoadTaskByIdSuccess(res)),
        catchError(() => of(new LoadTaskByIdFailure()))
      );
    })
  );
}
