import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DelayOrderRequestInvoiceComponent } from './delay-order-request-invoice.component';

@NgModule({
  declarations: [DelayOrderRequestInvoiceComponent],
  exports: [DelayOrderRequestInvoiceComponent],
  entryComponents: [DelayOrderRequestInvoiceComponent],
  imports: [CommonModule],
})
export class DelayOrderRequestInvoiceModule {}
