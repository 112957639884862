import { Directive, ElementRef, HostListener, Input, Renderer2, ViewChild } from '@angular/core';

@Directive({
  selector: '[appButton]',
})
export class ButtonDirective {
  @Input('subscribeDocumentBtn') subBtn: HTMLButtonElement;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseover') onMouseEnter() {
    this.btnWidth('105px', '440px', true);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.btnWidth('40px', '520px', false);
  }

  private btnWidth(w: string, subBtnW: string, elementText) {
    this.el.nativeElement.style.width = w;
    this.subBtn.style.width = subBtnW;
    if (elementText) {
      this.el.nativeElement.innerHTML = 'Скачать';
      this.el.nativeElement.style.backgroundPosition = '9px';
      this.el.nativeElement.style.paddingLeft = '20px';
      this.el.nativeElement.style.color = '#FFFFFF';
    } else {
      this.el.nativeElement.innerHTML = '';
    }
  }
}
