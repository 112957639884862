import { Component, OnInit, Input, EventEmitter, Output, Renderer2 } from '@angular/core';

interface IAccordionItem {
  icon: string;
  name: string;
  items: Notification[];
}

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @Input() accordionItems: IAccordionItem[];
  @Output() markAsRead = new EventEmitter<string>();

  // sentBy, user info
  testAvatar = 'https://logodix.com/logo/576948.png';
  testFullname = 'Анна Чура';
  testPosition = 'Супервайзер';

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    console.log(this.accordionItems);
  }
  getFullUsername(user) {
    const fullname = user.surname ? user.name + user.surname : user.name;
    return fullname;
  }
  read(id) {
    this.markAsRead.emit(id);
  }
  toggleContent(accItem: any) {
    const hasClass = accItem.classList.contains('active');
    const accContent = accItem.querySelector('.accordion-content');
    // toggle 'active' class
    if (hasClass) {
      this.renderer.removeClass(accItem, 'active');
    } else {
      this.renderer.addClass(accItem, 'active');
    }
    // animated appearance
    if (accContent.style.maxHeight) {
      accContent.style.maxHeight = null;
    } else {
      accContent.style.maxHeight = accContent.scrollHeight + 'px';
    }
  }
}
