import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { RecommendOrderBonus } from '../../../../store/order/order.model';

@Component({
  selector: 'app-auto-order-info',
  templateUrl: './auto-order-info.component.html',
  styleUrls: ['./auto-order-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoOrderInfoComponent implements OnInit {
  @Input() bonusRecommendedOrders: RecommendOrderBonus;

  constructor() {}

  ngOnInit() {}
}
