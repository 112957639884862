import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { MinimalCartRules } from '../../../../../store/cart/cart.models';

@Component({
  selector: 'app-delayed-order-update-notify',
  templateUrl: './delayed-order-update-notify.component.html',
  styleUrls: ['./delayed-order-update-notify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelayedOrderUpdateNotifyComponent implements OnInit {
  @Input() minimalCartRuleCart: MinimalCartRules[];
  @Output() closed: EventEmitter<boolean> = new EventEmitter();
  categoriesName: string[] = [];

  constructor() {}

  ngOnInit() {
    this.minimalCartRuleCart.forEach((item) => {
      const categories = Object.values(item.categoryNames)[0];
      this.categoriesName = [...this.categoriesName, categories];
    });
  }

  get categories() {
    return this.categoriesName.length === 0 ? '' : '"' + this.categoriesName.join('", "') + '"';
  }

  onAddedProduct() {
    this.closed.emit();
  }
}
