import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Outlet } from '@Mesh/core/models';
import { Product } from '@Mesh/core/models/product';
import { LeftoverProduct, UpdateLeftoverPayload } from '@Mesh/core/models/update-leftover-products';
import { DataService } from '@Mesh/core/services';
import { atLeastOneGreaterThanZeroValidator } from './validators-form';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-basket-task-leftover-products',
  templateUrl: './basket-task-leftover-products.component.html',
  styleUrls: ['./basket-task-leftover-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketTaskLeftoverProductsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() outlet: Outlet;
  @Input() products: Product[] = [];
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() updatedCart: EventEmitter<any> = new EventEmitter();

  form: FormGroup;
  message: string = 'Пожалуйста, укажите остатки по следующим позициям товаров';
  error: boolean;
  successLeftover: boolean = false;
  loading: boolean = false;

  protected unsubscribe$ = new Subject();

  get controls() {
    return this.products || [];
  }
  get changes() {
    return this.form.valueChanges;
  }
  get valid() {
    return this.form.valid;
  }
  get value() {
    return this.form.value;
  }

  constructor(private fb: FormBuilder, private dataService: DataService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.form = this.createGroup();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(): void {
    if (this.form) {
      const controls = Object.keys(this.form.controls);
      const configControls = (this.controls || []).map((item) => String(item.materialId));

      controls.filter((control) => !configControls.includes(control)).forEach((control) => this.form.removeControl(control));

      configControls
        .filter((control) => !controls.includes(control))
        .forEach((name) => {
          const config = this.products.find((control) => String(control.materialId) === name);
          this.form.addControl(name, this.createControl(0));
        });
    }
  }

  closeModal(): void {
    this.closed.emit();
  }

  createGroup(): FormGroup {
    const group = this.fb.group({}, { validators: atLeastOneGreaterThanZeroValidator() });
    this.controls.forEach((control) => group.addControl(String(control.materialId), this.createControl(0)));
    return group;
  }

  createControl(value: number): FormControl {
    return this.fb.control(0, [Validators.min(0)]);
  }

  onSubmit(): void {
    this.loading = true;
    const payload = this.form.getRawValue();
    this.setLeftoverProducts(payload);
  }

  setLeftoverProducts(data: { [x: string]: number }): void {
    const payload: UpdateLeftoverPayload = {
      addressSapId: this.outlet.addressSapId,
      items: Object.keys(data)
        .map((key) => ({ materialId: key, quantity: data[key] } as unknown as LeftoverProduct))
        .filter((data) => data.quantity > 0),
    };
    this.dataService
      .setLeftoverProducts(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.loading = false;
        this.successLeftover = true;
        this.updatedCart.emit();
        this.cdr.markForCheck();
      });
  }
}
