import { Action } from '@ngrx/store';
import { Task, TaskProgress } from '@Mesh/core/models/task';
import { GetClientTasksCriteria } from '@Mesh/core/models/request/get-client-tasks-criteria';

export enum TaskActionsTypes {
  TaskLoadSuccess = '[Task] Task Success',
  TaskSetTotalCount = '[Task] Task set total count',
  TaskLoadError = '[Task] Task Error',
  TaskLoadRequest = '[Task] Task Request',

  TaskLoadPageRequest = '[Task] Task load page request',
  TaskLoadPageRequestFailure = '[Task] Task load page request failure',

  ModerationTaskLoadPageRequest = '[Task] Load moderation task page request',
  ModerationTaskLoadPageRequestFailure = '[Task] Load moderation task page request failure',

  HistoryTaskLoadRequest = '[Task] History task Request',
  HistoryTaskLoadSuccess = '[Task] History task Success',
  HistoryTaskLoadError = '[Task] History task Error',

  HistoryTaskLoadPageRequest = '[Task] History task page request',
  HistoryTaskLoadPageFailure = '[Task] History task page request failure',

  ModerationTaskLoadRequest = '[Task] Load moderation task request',
  ModerationTaskLoadError = '[Task] Load moderation task error',
  ModerationTaskLoadSuccess = '[Task] Load moderation task success',

  CompletedTaskLoadRequest = '[Task] Load completed task request',
  CompletedTaskLoadError = '[Task] Load completed task error',
  CompletedTaskLoadSuccess = '[Task] Load completed task success',
  ResetHistoryTaskValue = '[Task] Reset history task value',
  ResetTaskValue = '[Task] Reset task value',
  ResetModerationTaskValue = '[Task] Reset moderation task value',

  TaskProgressLoad = '[Task] Task progress Load',
  TaskProgressLoadSuccess = '[Task] Task progress Success',
  TaskProgressLoadError = '[Task] Task progress Error',

  LoadTaskById = '[Task] Task load by id ',
  LoadTaskByIdSuccess = '[Task] Task load by id  success',
  LoadTaskByIdFailure = '[Task] Task load by id  failure',
}

export class TaskLoadPageRequest implements Action {
  readonly type = TaskActionsTypes.TaskLoadPageRequest;

  constructor(public body: { page: number; itemsPerPage: number; onlyActual: true; notOverdue: true }) {}
}

export class TaskLoadPageRequestFailure implements Action {
  readonly type = TaskActionsTypes.TaskLoadPageRequestFailure;

  constructor(public error?: any) {}
}

export class ModerationTaskLoadPageRequest implements Action {
  readonly type = TaskActionsTypes.ModerationTaskLoadPageRequest;

  constructor(public body: { page: number; itemsPerPage: number; completed: boolean; onlyActual: boolean; notOverdue: boolean }) {}
}

export class ModerationTaskLoadPageRequestFailure implements Action {
  readonly type = TaskActionsTypes.ModerationTaskLoadPageRequestFailure;

  constructor(public error?: any) {}
}

export class TaskLoadRequest implements Action {
  readonly type = TaskActionsTypes.TaskLoadRequest;

  constructor(public payload: GetClientTasksCriteria) {}
}

export class TaskLoadSuccess implements Action {
  readonly type = TaskActionsTypes.TaskLoadSuccess;

  constructor(public payload: { tasks: Task[]; totalPages: number }) {}
}

export class TaskSetTotalCount implements Action {
  readonly type = TaskActionsTypes.TaskSetTotalCount;

  constructor(public payload: { totalCount: number; onModeration: boolean }) {}
}

export class TaskLoadError implements Action {
  readonly type = TaskActionsTypes.TaskLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class HistoryTaskLoadRequest implements Action {
  readonly type = TaskActionsTypes.HistoryTaskLoadRequest;

  constructor(public payload: GetClientTasksCriteria) {}
}

export class HistoryTaskLoadPageRequest implements Action {
  readonly type = TaskActionsTypes.HistoryTaskLoadPageRequest;

  constructor(public body: { page: number; itemsPerPage: number }) {}
}

export class HistoryTaskLoadPageRequestFailure implements Action {
  readonly type = TaskActionsTypes.HistoryTaskLoadPageFailure;

  constructor(public error?: any) {}
}

export class HistoryTaskLoadSuccess implements Action {
  readonly type = TaskActionsTypes.HistoryTaskLoadSuccess;

  constructor(public payload: { tasks: Task[]; tasksCount: number; totalPages: number; currentPage: number }) {}
}

export class HistoryTaskLoadError implements Action {
  readonly type = TaskActionsTypes.HistoryTaskLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class ModerationTaskLoadRequest implements Action {
  readonly type = TaskActionsTypes.ModerationTaskLoadRequest;

  constructor(public payload: GetClientTasksCriteria) {}
}

export class ModerationTaskLoadError implements Action {
  readonly type = TaskActionsTypes.ModerationTaskLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class ModerationTaskLoadSuccess implements Action {
  readonly type = TaskActionsTypes.ModerationTaskLoadSuccess;

  constructor(public payload: { tasks: Task[]; totalPage: number }) {}
}

export class CompletedTaskLoadRequest implements Action {
  readonly type = TaskActionsTypes.CompletedTaskLoadRequest;

  constructor(public payload: GetClientTasksCriteria) {}
}

export class CompletedTaskLoadError implements Action {
  readonly type = TaskActionsTypes.CompletedTaskLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class CompletedTaskLoadSuccess implements Action {
  readonly type = TaskActionsTypes.CompletedTaskLoadSuccess;

  constructor(public payload: { totalCount: number }) {}
}
export class ResetHistoryTaskValue implements Action {
  readonly type = TaskActionsTypes.ResetHistoryTaskValue;
}

export class ResetTaskValue implements Action {
  readonly type = TaskActionsTypes.ResetTaskValue;
}

export class ResetModerationTaskValue implements Action {
  readonly type = TaskActionsTypes.ResetModerationTaskValue;
}

export class TaskProgressLoad implements Action {
  readonly type = TaskActionsTypes.TaskProgressLoad;

  constructor(public payload: { taskOutletClientId: number }) {}
}

export class TaskProgressLoadSuccess implements Action {
  readonly type = TaskActionsTypes.TaskProgressLoadSuccess;

  constructor(public payload: TaskProgress) {}
}

export class TaskProgressLoadError implements Action {
  readonly type = TaskActionsTypes.TaskProgressLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class LoadTaskById implements Action {
  readonly type = TaskActionsTypes.LoadTaskById;

  constructor(public payload: { id: number }) {}
}
export class LoadTaskByIdSuccess implements Action {
  readonly type = TaskActionsTypes.LoadTaskByIdSuccess;

  constructor(public payload: any) {}
}
export class LoadTaskByIdFailure implements Action {
  readonly type = TaskActionsTypes.LoadTaskByIdFailure;
}

export type TaskActionsUnion =
  | TaskLoadRequest
  | TaskLoadSuccess
  | TaskSetTotalCount
  | TaskLoadError
  | HistoryTaskLoadRequest
  | HistoryTaskLoadSuccess
  | HistoryTaskLoadError
  | ModerationTaskLoadRequest
  | ModerationTaskLoadError
  | ModerationTaskLoadSuccess
  | CompletedTaskLoadRequest
  | CompletedTaskLoadSuccess
  | CompletedTaskLoadError
  | TaskLoadPageRequest
  | TaskLoadPageRequestFailure
  | ResetHistoryTaskValue
  | ResetTaskValue
  | ResetModerationTaskValue
  | TaskProgressLoad
  | TaskProgressLoadSuccess
  | TaskProgressLoadError
  | LoadTaskById
  | LoadTaskByIdSuccess
  | LoadTaskByIdFailure;
