import { Component } from '@angular/core';

@Component({
  selector: 'app-chat-dialog-list-context-menu',
  templateUrl: './chat-dialog-list-context-menu.component.html',
  styleUrls: ['./chat-dialog-list-context-menu.component.scss'],
})
export class ChatDialogListContextMenuComponent {
  // this module does not have animations, set lazy false
  lazy = true;

  constructor() {}
}
