import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Outlet } from '../../../../core/models';
import { OrderCompletedState, OrderCompletedStatusType } from '../../../../store/cart/cart.models';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { RequestInvoiceDelayedOrder } from '../../../../store/order/order.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-basket-success-screen',
  templateUrl: './basket-success-screen.component.html',
  styleUrls: ['./basket-success-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketSuccessScreenComponent implements OnInit, OnChanges {
  @Input() outlet: Outlet;
  @Input() orderDetails: OrderCompletedState[];
  @Output() closeCart = new EventEmitter();
  orderCompletedStatusType = OrderCompletedStatusType;
  showSuccesInvoce: boolean = false;
  indexActiveOrder: number = 0;

  constructor(private store: Store<AppState>, private cdr: ChangeDetectorRef, private router: Router) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.orderDetails) {
      this.indexActiveOrder = 0;
    }
  }

  get currentOrder(): OrderCompletedState {
    return this.orderDetails && this.orderDetails.length && this.orderDetails[this.indexActiveOrder];
  }

  next(): void {
    this.indexActiveOrder++;
    this.cdr.detectChanges();
  }

  onClose(): void {
    this.closeCart.emit();
  }

  onRequestInvoice(): void {
    const delayedOrderId = this.currentOrder.delayedOrderId;
    if (delayedOrderId) {
      this.store.dispatch(new RequestInvoiceDelayedOrder({ delayedOrderId, shouldRequestInvoice: true }));
    }
    this.showSuccesInvoce = true;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.closeCart.emit();
      this.router.navigate(['/catalog']);
    }, 2200);
  }
}
