import { RecommendOrderBonus } from '@Mesh/store/order/order.model';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-recommend-order-card-progress',
  templateUrl: './recommend-order-card-progress.component.html',
  styleUrls: ['./recommend-order-card-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendOrderCardProgressComponent implements OnInit {
  @Input() bonusRecommendedOrders: RecommendOrderBonus;
  constructor() {}

  ngOnInit() {}
}
