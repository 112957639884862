import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TitleService } from '../title/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@Mesh/core/services';
import { User } from '../../models/user';
import { Subscription } from 'rxjs';
import { Breadcrumb, BreadcrumbService } from 'angular-crumbs';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { loginSelectors } from '@Mesh/store/login/login.selectors';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['../../../@pages/layouts/condensed/condensed.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutComponent implements OnInit, OnDestroy {
  title;
  secondTitle;
  activedRoute: string;
  public user: User;
  public subscription: Subscription = new Subscription();

  constructor(
    private _router: Router,
    public authService: AuthenticationService,
    public titleService: TitleService,
    public breadcrumbService: BreadcrumbService,
    public cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private store: Store<AppState>
  ) {}

  get coverImage() {
    switch (this.pageType) {
      case 'tasks':
        return 'assets/img/megapolis/jumbotron/tasks.jpg';
      case 'learn':
        return 'assets/img/megapolis/jumbotron/profile.jpg';
      case 'shop':
        return 'assets/img/megapolis/jumbotron/awards.jpg';
      case 'rating':
        return 'assets/img/megapolis/jumbotron/rating.jpg';
      case 'profile':
        return 'assets/img/megapolis/jumbotron/profile.jpg';
      default:
        return 'assets/img/megapolis/jumbotron/profile.jpg';
    }
  }

  get pageType() {
    return this.activedRoute;
  }

  get isTasks() {
    return this.activedRoute === 'tasks';
  }

  get isLearn() {
    return this.activedRoute === 'learn';
  }

  get isAwards() {
    return this.activedRoute === 'shop';
  }

  get isRating() {
    return this.activedRoute === 'rating';
  }

  get isProfile() {
    return this.activedRoute === 'profile';
  }

  get isProduct() {
    return this.activedRoute === 'product';
  }

  get isNews() {
    return this.activedRoute === 'news';
  }
  ngOnInit() {
    this.subscription.add(this.store.select(loginSelectors.selectUser).subscribe((user) => (this.user = user)));

    this.subscription.add(
      this.route.url.subscribe(() => {
        switch (true) {
          case this._router.url.indexOf('shop/product') !== -1:
            this.activedRoute = 'product';
            break;
          case this._router.url.indexOf('tasks') !== -1:
            this.activedRoute = 'tasks';
            break;
          case this._router.url.indexOf('learn') !== -1:
            this.activedRoute = 'learn';
            break;
          case this._router.url.indexOf('shop') !== -1:
            this.activedRoute = 'shop';
            break;
          case this._router.url.indexOf('rating') !== -1:
            this.activedRoute = 'rating';
            break;
          case this._router.url.indexOf('profile') !== -1:
            this.activedRoute = 'profile';
            break;
          case this._router.url.indexOf('news') !== -1:
            this.activedRoute = 'news';
            break;

          default:
            this.activedRoute = '';
        }
        this.cdr.detectChanges();
      })
    );

    this.subscription.add(
      this.titleService.title$.subscribe((title) => {
        this.title = title;

        this.cdr.detectChanges();
      })
    );

    this.subscription.add(
      this.titleService.secondTitle$.subscribe((secondTitle) => {
        this.secondTitle = secondTitle;

        this.cdr.detectChanges();
      })
    );

    this.subscription.add(
      this.breadcrumbService.breadcrumbChanged.subscribe((tt) => {
        this.cdr.detectChanges();
      })
    );
  }

  isLastCrumb(breadcrumb: Breadcrumb, breadcrumbs: Breadcrumb[]): boolean {
    return breadcrumbs.indexOf(breadcrumb) === breadcrumbs.length - 1;
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
