import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { IMAGE_CLOUD_URL } from '@Env/environment';
import { Order } from '@Mesh/core/models/order';

@Component({
  selector: 'app-order-invoce-view',
  templateUrl: './order-invoce-view.component.html',
  styleUrls: ['./order-invoce-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderInvoceViewComponent implements OnInit {
  @Input() order: Order;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  IMAGE_CLOUD_URL = IMAGE_CLOUD_URL;

  constructor(private http: HttpClient) {}

  ngOnInit() {}

  closeModal() {
    this.closed.emit();
  }

  onLoadIframe(iframe: HTMLIFrameElement) {
    console.log('Message send!');
  }

  get invoiceSrc() {
    return this.order && this.order.invoicePdfLinks.length && IMAGE_CLOUD_URL + this.order.invoicePdfLinks[0];
  }

  downloadInvoice() {
    const url = this.invoiceSrc;
    const orderNumber = this.order?.orderNumber || 'unknown';

    if (url) {
      this.http.get(url, { responseType: 'blob' }).subscribe(
        (blob) => {
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = `invoice_${orderNumber}.pdf`;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(objectUrl);
          document.body.removeChild(a);
        },
        (error) => {
          console.error('Error downloading the file:', error);
        }
      );
    } else {
      console.error('Invoice URL is not available');
    }
  }
}
