import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-basket-empty-cart',
  templateUrl: './basket-empty-cart.component.html',
  styleUrls: ['./basket-empty-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketEmptyCartComponent implements OnInit {
  @Output() closed: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClosed() {
    this.closed.emit();
  }
}
