import { NgModule } from '@angular/core';
import { META_REDUCERS, MetaReducer, StoreModule } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { OutletStoreModule } from '@Mesh/store/outlet';
import { TaskStoreModule } from '@Mesh/store/task';
import { UserStoreModule } from '@Mesh/store/user';
import { LoginStoreModule } from '@Mesh/store/login';
import { APP_LOCAL_STORAGE_KEY, APP_STORAGE_KEYS } from '@Mesh/store/app.tokens';
import { LocalStorageService } from '@Mesh/core/services/local-storage';
import { storageMetaReducer } from '@Mesh/store/storage-metareducer';
import { PlanStoreModule } from '@Mesh/store/plan';
import { debug } from '@Mesh/store/debug.reducer';
import { OrderStoreModule } from '@Mesh/store/order';
import { DocumentStoreModule } from '@Mesh/store/document';
import { BonusStoreModule } from '@Mesh/store/bonus';
import { FinanceStoreModule } from '@Mesh/store/finance/finance-store.module';
import { NotificationStoreModule } from '@Mesh/store/notification/notification-store.module';
import { CartStoreModule } from '@Mesh/store/cart/card-store.module';
import { OrderCartStoreModule } from './order-cart/order-cart-store.module';

export function getMetaReducers(saveKeys: string[], localStorageKey: string, storageService: LocalStorageService): MetaReducer<any>[] {
  return [storageMetaReducer(saveKeys, localStorageKey, storageService), debug];
}

const APP_STORAGE_KEYS_VALUE = ['login.isAuthenticated', 'login.token', 'login.user', 'login.refreshToken'];
const APP_LOCAL_STORAGE_KEY_VALUE = 'app__storage';
@NgModule({
  imports: [
    LoginStoreModule,
    UserStoreModule,
    OutletStoreModule,
    DocumentStoreModule,
    BonusStoreModule,
    OrderStoreModule,
    TaskStoreModule,
    PlanStoreModule,
    FinanceStoreModule,
    NotificationStoreModule,
    CartStoreModule,
    OrderCartStoreModule,
    StoreModule.forRoot({ router: routerReducer }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([]),
  ],
  providers: [
    { provide: APP_STORAGE_KEYS, useValue: APP_STORAGE_KEYS_VALUE },
    { provide: APP_LOCAL_STORAGE_KEY, useValue: APP_LOCAL_STORAGE_KEY_VALUE },
    {
      provide: META_REDUCERS,
      deps: [APP_STORAGE_KEYS, APP_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getMetaReducers,
    },
  ],
  declarations: [],
})
export class AppStoreModule {}
