import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { MinimalCartRuleGroupedStatus } from '../../../../store/cart/cart.models';

@Component({
  selector: 'app-basket-header',
  templateUrl: './basket-header.component.html',
  styleUrls: ['./basket-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketHeaderComponent implements OnInit {
  @Input() totalOrderPrice: number;
  @Input() totalAmountOfOrder: number;
  @Input() minimalCartRulesGroupStatus: MinimalCartRuleGroupedStatus;

  constructor() {}

  ngOnInit() {}
}
