import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { CLOUD_UPLOAD_DOC_URL, IMAGE_CLOUD_URL } from '../../../../../environments/environment';
import { UploadChangeParam } from '../../../../@pages/components/upload/interface';

@Component({
  selector: 'app-basket-fill-by-file-button',
  templateUrl: './basket-fill-by-file-button.component.html',
  styleUrls: ['./basket-fill-by-file-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketFillByFileButtonComponent implements OnInit {
  @Output() uploaded = new EventEmitter<UploadChangeParam>();
  fileTemplateUrl = IMAGE_CLOUD_URL + '/20230522/4779d3bf-70f3-4fc5-a704-f7bc0c7e3708.xlsx';
  CLOUD_UPLOAD_DOC_URL = CLOUD_UPLOAD_DOC_URL;

  constructor() {}

  ngOnInit() {}

  uploadFile(event: UploadChangeParam): void {
    this.uploaded.emit(event);
  }
}
