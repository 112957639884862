export interface IPageableList<T> {
  content: T[];
  pageable: IPageable;
  totalPages: number;
  last: boolean;
  totalElements: number;
  number: number;
  sort: any[];
  size: number;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface IPageable {
  sort: any[];
  offset: number;
  pageNumber: number;
  pageSize: number;
}

export class PageableList<T> implements IPageableList<T> {
  content: T[] = [];
  empty: boolean = true;
  first: boolean = true;
  last: boolean = false;
  number: number = 0;
  numberOfElements: number = 0;
  pageable: IPageable = null;
  size: number = 0;
  sort: any[] = null;
  totalElements: number = 0;
  totalPages: number = 0;

  public static empty(): PageableList<null> {
    return new PageableList<null>();
  }
}
