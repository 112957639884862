import { createDefaultLoadable, Loadable } from '@Mesh/utils/loadable/loadable';
import { NotificationToken, NotificationsData, PostingStatus, Notification } from '@Mesh/core/models';

export interface NotificationState extends Loadable {
  pushToken: NotificationToken;
  notifications: NotificationsData;
  content: Notification[];
  readNotificationStatus: PostingStatus;
}

export const initialState: NotificationState = {
  ...createDefaultLoadable(),
  pushToken: null,
  notifications: null,
  content: [],
  readNotificationStatus: PostingStatus.Initial,
};
