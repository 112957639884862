import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  Inject,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IMAGES_URL } from '../../../../../environments/environment';
import { Product, Stock } from '../../../../core/models/product';
import { CartProductsFacade } from '../../services/cart-products.facade';
import { CART_PRODUCT_SERVICE } from '../../services/cart-products.token';
import { CatalogUnitModalComponent } from '../product-table/catalog-modal/catalog-unit-modal/catalog-unit-modal.component';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent implements OnInit, OnDestroy {
  @Input() product: Product;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  readonly IMAGES_URL = IMAGES_URL;
  stock$: Observable<Stock>;
  stock: Stock;
  countProduct = new FormControl(0, [Validators.required, Validators.min(1)]);
  quantity$: Observable<number>;
  totalAmount: number = 0;

  private unsubscribe$ = new Subject();
  bsModalRef: BsModalRef;
  basketCount: number;

  constructor(
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef,
    @Inject(CART_PRODUCT_SERVICE) private cartProductsFacade: CartProductsFacade
  ) {}

  ngOnInit(): void {
    // console.log(this.product);
    this.stock$ = this.cartProductsFacade.getStock(this.product);
    this.stock$.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.stock = value;
      this.setTotalAmount();
      this.cdr.markForCheck();
    });
    this.quantity$ = this.cartProductsFacade.getQuantity(this.product);
    this.quantity$.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      // this.countProduct.setValue(value, { emitEvent: false });
      this.basketCount = value;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeModal(): void {
    this.closed.emit();
  }

  increment(): void {
    const count = this.countProduct.value;
    this.countProduct.setValue(count + 1);
    this.setTotalAmount();
  }

  decrement(): void {
    const count = this.countProduct.value;
    this.countProduct.setValue(count - 1);
    this.setTotalAmount();
  }

  get hasStockDiscount() {
    return this.stock && this.stock.ndsPrice !== this.stock.discountNdsPrice;
  }

  setTotalAmount(): void {
    this.totalAmount = this.countProduct.value * (this.hasStockDiscount ? this.stock.discountNdsPrice : this.stock.ndsPrice);
  }

  onChooseStock(): void {
    this.bsModalRef = this.modalService.show(
      CatalogUnitModalComponent,
      Object.assign({}, { class: 'gray modal-dialog-centered modal-notify basket-error' })
    );
    this.bsModalRef.content.selectCurrentProduct(this.product);
    this.bsModalRef.content.setCurrentStock(this.stock);
    this.bsModalRef.content.onClose.subscribe((stock) => {
      if (stock) {
        this.cartProductsFacade.setStock(this.product.id, stock);
      }
      this.bsModalRef.hide();
    });
  }

  get possibleIncrement() {
    return this.stock?.quantityMaterial > this.countProduct.value;
  }

  get possibleDecrement() {
    return this.countProduct.value > 0;
  }

  onAddToBasket(): void {
    this.cartProductsFacade.changeAmount({ product: this.product, amount: this.countProduct.value + this.basketCount });
    this.closed.emit();
  }
}
