import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Notification } from '../../../../../core/models';

@Component({
  selector: 'app-chat-notifications-list-item',
  templateUrl: './chat-notifications-list-item.component.html',
  styleUrls: ['./chat-notifications-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatNotificationsListItemComponent implements OnInit {
  @Input() notification: Notification;

  constructor() {}

  ngOnInit() {}
}
