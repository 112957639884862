import { createSelector } from '@ngrx/store';
import { selectFinanceState } from '@Mesh/store/app.state';

export const financeSelectors = {
  getBalance: createSelector(selectFinanceState, (state) => state.balance),
  getPayHistory: createSelector(selectFinanceState, (state) => state.payHistory),
  getTotalPages: createSelector(selectFinanceState, (state) => state.totalPages),
  getFinanceSum: createSelector(selectFinanceState, (state) => state.financeSum),
  getFinanceFullData: createSelector(selectFinanceState, (state) => state.financeFullData),
  getUserFinanceInformation: createSelector(selectFinanceState, (state) => state.userFinanceInformation),
  getBalanceTree: createSelector(selectFinanceState, (state) => state.balanceTree),
};
