import { FinanceCardData, FinanceFullData, PaymentSchedule, UserFinanceInformation } from './finance.models';
import {
  LoadBalanceTree,
  LoadBalanceTreeError,
  LoadBalanceTreeSuccess,
  LoadFinanceFullData,
  LoadFinanceFullDataSuccess,
  LoadPayHistoryPage,
  LoadPayHistoryPageFailure,
  LoadUserFinanceInformation,
  LoadUserFinanceInformationSuccess,
  LoadUserFinanceSum,
  LoadUserFinanceSumError,
  LoadUserFinanceSumSuccess,
} from './finance.actions';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { FinanceActionsTypes, LoadPayHistory, LoadPayHistoryError, LoadPayHistorySuccess } from '@Mesh/store/finance/finance.actions';
import { loginSelectors } from '@Mesh/store/login/login.selectors';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { FinanceService } from '@Mesh/core/services/finance.service';
import { of, combineLatest } from 'rxjs';
import _ from 'lodash';

@Injectable()
export class FinanceEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private financeService: FinanceService) {}

  @Effect()
  loadPayHistory = this.actions$.pipe(
    ofType<LoadPayHistory>(FinanceActionsTypes.LoadPayHistory),
    withLatestFrom(this.store.select(loginSelectors.selectUser)),
    filter(([, user]) => !!user && !!user.client),
    switchMap(([action, user]) => {
      return this.financeService.getPayHistory({ ...action.criteria, clientSapId: user.client.clientSapId }).pipe(
        map((data) => new LoadPayHistorySuccess(data)),
        catchError(() => of(new LoadPayHistoryError()))
      );
    })
  );

  @Effect()
  loadPayHistoryPage = this.actions$.pipe(
    ofType<LoadPayHistoryPage>(FinanceActionsTypes.LoadPayHistoryPage),
    withLatestFrom(this.store.select(loginSelectors.selectUser)),
    filter(([, user]) => !!user && !!user.client),
    switchMap(([action, user]) => {
      const criteria = {
        sort: 'buDate',
        size: action.body.itemsPerPage,
        page: action.body.page,
        direction: 'desc',
        startDate: action.body.startDate,
        endDate: action.body.finishDate,
      };
      return this.financeService.getPayHistory({ ...criteria, clientSapId: user.client.clientSapId }).pipe(
        map((data) => {
          return new LoadPayHistorySuccess(data);
        }),
        catchError(() => of(new LoadPayHistoryPageFailure()))
      );
    })
  );

  @Effect()
  loadFinanceUserSum = this.actions$.pipe(
    ofType<LoadUserFinanceSum>(FinanceActionsTypes.LoadUserFinanceSum),
    switchMap((action) => {
      return this.financeService.getBalanceByDistributor({ clientSapId: [action.payload.clientSapId] }).pipe(
        map((data) => new LoadUserFinanceSumSuccess(data[0])),
        catchError((error) => of(new LoadUserFinanceSumError(error)))
      );
    })
  );

  @Effect()
  loadBalanceTree = this.actions$.pipe(
    ofType<LoadBalanceTree>(FinanceActionsTypes.LoadBalanceTree),
    switchMap((action) => {
      return this.financeService.getBalanceTree({ clientSapId: [action.criteria.clientSapId] }).pipe(
        map((data) => new LoadBalanceTreeSuccess(data[0].childs)),
        catchError((error) => of(new LoadBalanceTreeError(error)))
      );
    })
  );

  @Effect()
  LoadFinanceSchedule = this.actions$.pipe(
    ofType<LoadFinanceFullData>(FinanceActionsTypes.LoadFinanceFullData),
    withLatestFrom(this.store.select(loginSelectors.selectUser)),
    filter(([, user]) => !!user && !!user.client),
    switchMap(([action, user]) => {
      const criteria = {
        sort: '',
        size: action.body.itemsPerPage,
        page: action.body.page,
        direction: '',
        startDate: action.body.startDate,
        endDate: action.body.finishDate,
      };
      return combineLatest([
        this.financeService.getFinanceSchedule({ ...criteria, clientSapId: user.client.clientSapId }),
        this.financeService.getBalance({ clientSapId: user.client.clientSapId }),
        this.financeService.getFinanceScheduleNearest({ clientSapId: user.client.clientSapId }),
      ]).pipe(
        map(([financeSchedule, balance, nearest]) => {
          let payScheduleFilter;
          const _financeBalance = balance.map((b) => {
            const nearestFind = nearest.find((n) => n.kkber === b.kkber && (n.sumFp ?? 0) > 0);
            payScheduleFilter = financeSchedule.content.filter((ps) => ps.kkber === b.kkber);
            return { ...b, nearest: nearestFind, paySchedule: payScheduleFilter };
          });

          let financeBalance: FinanceCardData;
          let paymentSchedule: PaymentSchedule[] = [];

          const providerList = _.chain(_financeBalance)
            .groupBy('buText')
            .map((value, key: string) => {
              let providerCardData: FinanceCardData;
              let nearestPayment: number;
              let nearestPayDate: string;
              const _n = value
                .filter((o) => !!o.nearest)
                .map((o) => {
                  nearestPayment = o.nearest.sumFp;
                  nearestPayDate = o.nearest.payDate;
                  return o.nearest;
                });
              value.forEach((el) => {
                return (providerCardData = {
                  id: el.id,
                  title: key,
                  delayed: el.delayed,
                  nearestPayment: nearestPayment,
                  nearestPayDate: nearestPayDate,
                  limit: el.balance,
                  debLimit: el.debLimit,
                  status: 1,
                });
              });
              return { provider: key, data: providerCardData, children: [], show: null };
            })
            .value();

          const financeDpsList = _.chain(_financeBalance)
            .groupBy('externalContractNumber')
            .map((value, key: string) => {
              let financeDpsCardData: FinanceCardData;
              let nearestPayment: number;
              let nearestPayDate: string;
              const _n = value
                .filter((o) => !!o.nearest)
                .map((o) => {
                  nearestPayment = o.nearest.sumFp;
                  nearestPayDate = o.nearest.payDate;
                  return o.nearest;
                });
              value.forEach((el) => {
                return (financeDpsCardData = {
                  id: el.id,
                  title: key,
                  delayed: el.delayed,
                  nearestPayment: nearestPayment,
                  nearestPayDate: nearestPayDate,
                  limit: el.balance,
                  debLimit: el.debLimit,
                  status: 2,
                });
              });
              return { provider: 'ДП-' + key, data: financeDpsCardData, children: [], show: null };
            })
            .value();

          const financeUipList = _.chain(_financeBalance)
            .groupBy('kkber')
            .map((value, key: string) => {
              let financeUipCardData: FinanceCardData;
              let nearestPayment: number;
              let nearestPayDate: string;
              const _n = value
                .filter((o) => !!o.nearest)
                .map((o) => {
                  nearestPayment = o.nearest.sumFp;
                  nearestPayDate = o.nearest.payDate;
                  return o.nearest;
                });
              paymentSchedule = payScheduleFilter.map((item) => {
                return {
                  paymentDate: item.payDate,
                  paymentSum: item.sumFp,
                };
              });

              value.forEach((el) => {
                financeBalance = {
                  id: el.id,
                  balance: el.balance,
                  buDate: el.buDate,
                  debLimit: el.debLimit,
                  debts: el.debts,
                  plan: el.plan,
                  debtswa: el.debtswa,
                  delayed: el.delayed,
                };

                return (financeUipCardData = {
                  id: el.id,
                  title: key,
                  delayed: el.delayed,
                  nearestPayment: nearestPayment,
                  nearestPayDate: nearestPayDate,
                  limit: el.balance,
                  debLimit: el.debLimit,
                  status: 3,
                });
              });
              return { provider: 'УИП-' + key, data: financeUipCardData, children: [], show: null };
            })
            .value();
          financeUipList.map(
            (el) =>
              (el.children = [
                {
                  provider: 'Баланс',
                  template: 'balancePayScheduleTemplate',
                  children: [{ template: 'detailsBalanceTemplate', data: financeBalance, children: [], show: null }],
                },
                {
                  provider: 'График платежей',
                  template: 'balancePayScheduleTemplate',
                  children: [{ template: 'detailsPayScheduleTemplate', data: paymentSchedule, children: [], show: null }],
                },
              ])
          );
          financeDpsList.map((el) => (el.children = financeUipList));
          providerList.map((el) => (el.children = financeDpsList));
          const finalData: FinanceFullData[] = providerList;
          return new LoadFinanceFullDataSuccess(finalData);
        })
      );
    })
  );

  @Effect()
  loadUserFinanceInformation = this.actions$.pipe(
    ofType<LoadUserFinanceInformation>(FinanceActionsTypes.LoadUserFinanceInformation),
    withLatestFrom(this.store.select(loginSelectors.selectUser)),
    filter(([, user]) => !!user && !!user.client),
    switchMap(([action, user]) => {
      return combineLatest([
        this.financeService.getBalance({ clientSapId: user.client.clientSapId }),
        this.financeService.getFinanceScheduleNearest({ clientSapId: user.client.clientSapId }),
      ]).pipe(
        map(([balance, nearest]) => {
          const _financeInfo = balance.map((b) => {
            const nearestFind = nearest.find((n) => n.kkber === b.kkber);
            return { ...b, nearest: nearestFind };
          });

          let userFinanceInformation: UserFinanceInformation;

          _financeInfo.forEach((item) => {
            return (userFinanceInformation = {
              buText: item.buText,
              payDate: item.nearest && item.nearest.payDate,
              sumFp: item.nearest && item.nearest.sumFp,
            });
          });
          return new LoadUserFinanceInformationSuccess(userFinanceInformation);
        })
      );
    })
  );
}
