import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-busket-order-order-invoce',
  templateUrl: './busket-order-order-invoce.component.html',
  styleUrls: ['./busket-order-order-invoce.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusketOrderOrderInvoceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
