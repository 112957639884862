import { HostListener, Injectable } from '@angular/core';
import { CatalogHelper } from '@Mesh/shared/helpers/catalog.helper';

@Injectable({ providedIn: 'root' })
export class ResizeHelper {
  webMode = true;
  smallWebMode = false;
  tabletMode = false;
  mobileMode = false;

  constructor(private catalogHelper: CatalogHelper) {}

  recalcSize() {
    if (window.innerWidth < 600) {
      this.mobileMode = true;
      this.tabletMode = false;
      this.webMode = false;
      this.smallWebMode = false;
      this.catalogHelper.recalcTableSize();
    } else if (window.innerWidth > 600 && window.innerWidth < 1400) {
      this.tabletMode = true;
      this.mobileMode = false;
      this.webMode = false;
      this.smallWebMode = false;
      // this.catalogHelper.recalcTableSize();
    } else if (window.innerWidth < 1921) {
      this.smallWebMode = true;
      this.tabletMode = false;
      this.mobileMode = false;
      this.webMode = false;
      this.catalogHelper.recalcTableSize();
    } else {
      this.webMode = true;
      this.tabletMode = false;
      this.mobileMode = false;
      this.smallWebMode = false;
    }
  }
}
