import { GetFinanceSum } from './../../../core/models/request/get-finance-sum';
import { UsersLoadPageRequest } from '@Mesh/store/user/user.actions';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { filter, map, switchMap, takeUntil, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { AuthenticationService } from '@Mesh/core/services/authentication.service';
import { User } from '@Mesh/core/models/user';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TitleService } from '@Mesh/core/modules/title/title.service';
import { ModalManageService } from '@Mesh/core/modules/modal-manage/modal-manage.service';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { loginSelectors } from '@Mesh/store/login/login.selectors';
import { OutletLoadPageRequest, OutletSetActiveId, ResetOutletsValue } from '@Mesh/store/outlet/outlet.actions';
import { getGetStartDateAndFinishDate } from '@Mesh/core/services/util';
import {
  GetDelayedOrders,
  OrderHistoryLoadPageRequest,
  OrderHistoryLoadRequest,
  OrderLoadPageRequest,
  OrderLoadRequest,
  OrderSetActive,
  OrderSetConfirmed,
  RemoveDelayedOrder,
  RequestInvoiceDelayedOrder,
} from '@Mesh/store/order/order.actions';
import {
  CompletedTaskLoadRequest,
  HistoryTaskLoadPageRequest,
  HistoryTaskLoadRequest,
  ModerationTaskLoadPageRequest,
  ModerationTaskLoadRequest,
  TaskLoadPageRequest,
  TaskLoadRequest,
  TaskProgressLoad,
} from '@Mesh/store/task/task.actions';
import { OutletState } from '@Mesh/store/outlet/outlet.state';
import {
  companyMock,
  historyMock,
  ordersMock,
  organizationMock,
  targetsMock,
  tasksMock,
  tradePointsMock,
} from '@Mesh/core/consts/dashboard-mock';
import { Order } from '@Mesh/core/models/order';
import { Outlet } from '@Mesh/core/models';
import { getOrdersWithoutDeliveredTrue, orderSelectors } from '@Mesh/store/order/order.selectors';
import { taskSelectors } from '@Mesh/store/task/task.selectors';
import { documentSelectors } from '@Mesh/store/document/document.selectors';
import { DocumentLoadRequest } from '@Mesh/store/document/document.actions';
import { bonusSelectors } from '@Mesh/store/bonus';
import { financeSelectors } from '@Mesh/store/finance/finance.selectors';
import { userSelectors } from '@Mesh/store/user/user.selectors';
import { Bonus } from '@Mesh/core/models/bonus';
import { Plan } from '@Mesh/core/models/plan';
import { DocChange } from '@Mesh/pages/profile/components/event-types';
import { scrollToTopHelper } from '@Mesh/shared/helpers/scrollToTop.helper';
import { saveAs } from 'file-saver';
import { Documents, documentsTypes } from '@Mesh/core/models/documents';
import { HttpClient } from '@angular/common/http';
import { LoadPayHistory, LoadPayHistoryPage, LoadUserFinanceInformation, LoadUserFinanceSum } from '@Mesh/store/finance/finance.actions';
import { IMAGE_CLOUD_URL } from '@Env/environment';
import { BonusService } from '../../../core/services/bonus.service';
import { GetClientHistoryCriteria } from '../../../core/models/request/get-client-bonus-history';
import { ClientHistoryBonus } from '../../../core/models/client-bonus-history';
import { GetUserHistoryCriteria } from '../../../core/models/request/get-user-bonus-history';
import { UserHistoryBonus } from '../../../core/models/user-bonus-history';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserBonusModalComponent } from '../components/user-bonus-modal/user-bonus-modal.component';
import { SelectedUserBonusBalanceLoad } from '../../../store/bonus/bonus.actions';
import { Task } from '../../../core/models/task';
import { DelayedOrder } from '../../../core/models/delayed-order';
import { DelayedOrderEditComponent } from '../../active/order/delayed-order-edit/delayed-order-edit.component';
import { DelayedOrderHistoryComponent } from '../../active/order/delayed-order-history/delayed-order-history.component';
import { DelayedOrderNotifyDropComponent } from '../../active/order/delayed-order-notify-drop/delayed-order-notify-drop.component';
import { DelayOrderRequestInvoiceComponent } from '../../active/order/delay-order-request-invoice/delay-order-request-invoice.component';
import { OrderInvoceViewComponent } from '@Mesh/pages/active/order/order-invoce-view/order-invoce-view.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss', '../../../../../node_modules/@iqlru/iqretail-components/assets/common.scss'],

  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CondensedDashboardComponent implements OnInit, OnDestroy, AfterViewChecked {
  user: User;
  config;
  index = 0;
  user$ = this.store.select(loginSelectors.selectUser);
  outlets$: Observable<OutletState>;
  totalOutletPages$ = this.store.select(outletSelectors.getTotalPages);
  activeOutletId$: Observable<number> = this.store.select(outletSelectors.getActiveId);
  activeOutlet$: Observable<Outlet> = this.store.select(outletSelectors.getActive);
  activeTasks$ = this.store.select(taskSelectors.getActiveTasksTasks);
  totalActiveTasks$ = this.store.select(taskSelectors.getActiveTotalCount);
  totalTasksPages$ = this.store.select(taskSelectors.getTotalPages);
  orders$: Observable<Order[]> = this.store.select(getOrdersWithoutDeliveredTrue);
  totalActiveOrdersPages$ = this.store.select(orderSelectors.getOrderTotalPages);
  details$ = this.store.select(orderSelectors.getDetailedOrder);
  historyOrders$ = this.store.select(orderSelectors.getHistoryOrders);
  totalHistoryOrders$ = this.store.select(orderSelectors.getHistoryTotalCount);
  historyTasks$ = this.store.select(taskSelectors.getHistoryTasks);
  moderationTasks$ = this.store.select(taskSelectors.getModerationTasks);
  totalModerationTasks$ = this.store.select(taskSelectors.getModerationTotalCount);
  totalCompletedTasks$ = this.store.select(taskSelectors.getCompletedTotalCount);
  totalDocs$ = this.store.select(documentSelectors.getDocumentTotalCount);
  documents$ = this.store.select(documentSelectors.getDocuments);
  plans$ = new BehaviorSubject<Partial<Plan>[]>([]);
  bonuses$ = this.store.select(bonusSelectors.getOutletBonuses);
  completedPlan$ = this.store.select(bonusSelectors.getComletedOutletBonuses);
  outletBonusesSum$ = this.store.select(bonusSelectors.getOutletBonusesSum);
  outletAwaitingSum$ = this.store.select(bonusSelectors.getAwaitingSum);
  clientBonusData$ = this.store.select(bonusSelectors.getClientBonus);
  clientBonusPredictTotalAmount$ = this.store.select(bonusSelectors.getClientBonusPredictTotalAmount);
  predictBonuses$ = this.store.select(bonusSelectors.getPredictionData);
  selectedOrder$ = this.store.select(orderSelectors.getSelectedId);
  balance$ = this.store.select(financeSelectors.getBalance);
  payHistory$ = this.store.select(financeSelectors.getPayHistory);
  users$ = this.store.select(userSelectors.users);
  usersTotalPages$ = this.store.select(userSelectors.usersTotalPages);
  usersTotalCount$ = this.store.select(userSelectors.totalCount);
  getHistoryOrdersCurrentPage$ = this.store.select(orderSelectors.getHistoryOrdersCurrentPage);
  getHistoryTasksCurrentPage$ = this.store.select(taskSelectors.historyTasksCurrentPage);
  getActiveOrdersCurrentPage$ = this.store.select(orderSelectors.getActiveOrdersCurrentPage);
  getUsersCurrentPage$ = this.store.select(userSelectors.usersCurrentPage);
  loading$ = this.store.select(orderSelectors.selectLoading);
  outletsTotalCount$ = this.store.select(outletSelectors.getTotalCount);
  activeOrdersTotalCount$ = this.store.select(orderSelectors.getOrderTotalCount);
  userFinanceSum$ = this.store.select(financeSelectors.getFinanceSum);
  identifier$: Observable<number>;
  userFinanceInfo$ = this.store.select(financeSelectors.getUserFinanceInformation);
  delayedOrders$ = this.store.select(orderSelectors.getDelayedOrders);
  ordersProducts$ = this.store.select(orderSelectors.getOrdersProducts);
  getOrdersUsers$ = this.store.select(orderSelectors.getOrdersUsers);
  orgStaff;
  view: string;
  readonly avatarUrl$ = this.user$.pipe(
    map((user) => {
      return IMAGE_CLOUD_URL + user.avatar;
    })
  );

  readonly openView$ = new BehaviorSubject<
    'addEmployee' | 'sms-verification' | 'sms-verified' | 'employee-deleted-verified' | 'employee-delete' | 'update-pass' | 'none'
  >('none');
  readonly userDialogsData$ = new BehaviorSubject<{ show: boolean; selectedUserId: number }>(undefined);
  readonly deleteUserData$ = new BehaviorSubject<number>(undefined);

  companyMock = companyMock;
  ordersMock = ordersMock;
  tasksMock = tasksMock;
  tradePointsMock = tradePointsMock;
  targetsMock = targetsMock;
  historyMock = historyMock;
  organizationMock = organizationMock;

  selectedDocId: number;
  readonly selectedDoc$ = new BehaviorSubject<Documents>(undefined);
  readonly iframeSrc$ = new BehaviorSubject<string>(undefined);
  readonly subscribeButtonToggle = new BehaviorSubject(false);
  readonly subscribeDocumentToggle = new BehaviorSubject(false);
  readonly lastAction$ = new BehaviorSubject<Action>(undefined);

  documents: Documents[] = [];

  private unsubscribe$ = new Subject();
  clientBonusesHistory: ClientHistoryBonus[] = [];
  userBonusesHistory: UserHistoryBonus[] = [];
  loadingBonusHistory: boolean;
  pageToLoadNextBonuses: number = 1;
  pageToLoadNextBonusesClient: number = 1;
  loadingBonusHistoryClient: boolean;
  bsModalRef: any;
  IMAGE_CLOUD_URL = IMAGE_CLOUD_URL;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: TitleService,
    private modalManageService: ModalManageService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
    private http: HttpClient,
    private bonusService: BonusService,
    private modalService: BsModalService
  ) {
    this.user$ = this.store.pipe(select(loginSelectors.selectUser));
    this.outlets$ = this.store.pipe(select(outletSelectors.selectOutlet));
    this.identifier$ = this.store.pipe(select(loginSelectors.selectPhoneIdentifier));
  }

  ngOnInit() {
    this.store
      .pipe(
        select(loginSelectors.selectUser),
        filter((user) => !!user),
        switchMap((user) => {
          // load outlets
          this.store.dispatch(new ResetOutletsValue());
          this.store.dispatch(new OutletLoadPageRequest({ page: 0, itemsPerPage: 10 }));
          this.store.dispatch(new GetDelayedOrders({ page: 0, size: 20 }));
          const financeSum: GetFinanceSum = {
            clientSapId: user.client.clientSapId,
          };
          this.store.dispatch(new LoadUserFinanceSum(financeSum));
          return this.store.select(outletSelectors.getActive).pipe(
            filter((outlet) => !!outlet),
            map((outlet) => {
              // tslint:disable-next-line:prefer-const
              let [startDate, finishDate] = getGetStartDateAndFinishDate();
              // startDate = '2021-01-01'; // need to remove hard-code '2021-01-01'

              this.store.dispatch(
                new DocumentLoadRequest({
                  page: 0,
                  sort: '',
                  size: 9999,
                  startDate: '',
                  endDate: '',
                  clientSapId: user.client.clientSapId,
                  documentType: documentsTypes,
                  direction: '',
                })
              );

              this.store.dispatch(new LoadPayHistoryPage({ page: 0, itemsPerPage: 10, startDate: startDate, finishDate: finishDate }));

              this.store.dispatch(new LoadUserFinanceInformation());

              return outlet;
            })
          );
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.identifier$.subscribe((identifier) => {
      if (identifier) {
        this.view = 'modal';
      }
    });
    this.orgStaff = 0;
    this.store
      .select(loginSelectors.selectUser)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((user) => !!user)
      )
      .subscribe((user) => {
        this.user = user;
        this.cdr.detectChanges();
        this.loadBonusHistory();
        this.loadBonusHistoryClient();
      });

    this.store
      .select(documentSelectors.getDocuments)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((docs) => {
        this.documents = docs;
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.title();
      });

    this.title();

    this.bonuses$
      .pipe(
        filter((bonuses) => !!bonuses),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((bonuses) => {
        this.setPlans(bonuses);
      });

    this.openView$.subscribe((v) => {
      if (v === 'none') {
        document.body.classList.remove('disable-scroll');
      } else {
        document.body.classList.add('disable-scroll');
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }

  ngAfterViewChecked(): void {}

  selectOrgContent(id: number) {
    this.orgStaff = id;
  }

  title() {
    this.titleService.setTitle('Главная');
  }

  viewChange(view: string) {
    this.view = view;
  }

  getOrderDetails(docNumber: string) {
    this.store.dispatch(new OrderSetActive({ docNumber }));
  }

  changeActive(outletId: number) {
    this.store.dispatch(new OutletSetActiveId({ id: outletId }));
  }

  loadTradePointsPages(page: number) {
    this.store.dispatch(new OutletLoadPageRequest({ page: page, itemsPerPage: 10 }));
  }

  loadAllTasks() {
    this.store.dispatch(new TaskLoadRequest({ pageNumber: 0, pageSize: 9999, completed: false, onlyActual: true, notOverdue: true }));
  }

  loadTasksPages(page: number) {
    this.store.dispatch(new TaskLoadPageRequest({ page, itemsPerPage: 10, onlyActual: true, notOverdue: true }));
  }

  loadAllModerationTasks() {
    this.store.dispatch(
      new ModerationTaskLoadRequest({ pageNumber: 0, pageSize: 9999, completed: false, onlyActual: true, notOverdue: true })
    );
  }

  loadModerationTasksPages(page: number) {
    this.store.dispatch(
      new ModerationTaskLoadPageRequest({ page: page, itemsPerPage: 10, completed: false, onlyActual: true, notOverdue: true })
    );
  }

  loadAllActiveOrders() {
    const [startDate, finishDate] = getGetStartDateAndFinishDate();

    this.store.dispatch(
      new OrderLoadRequest({
        pageNumber: 0,
        sort: [],
        pageSize: 9999,
        startDate,
        finishDate,
        status: [0, 1],
      })
    );
  }

  loadActiveOrdersPages(page) {
    const [startDate, finishDate] = getGetStartDateAndFinishDate();
    this.store.dispatch(new OrderLoadPageRequest({ page: page, itemsPerPage: 10, startDate: startDate, finishDate: finishDate }));
  }

  loadAllOrdersHistory() {
    const [startDate, finishDate] = getGetStartDateAndFinishDate();

    this.store.dispatch(
      new OrderHistoryLoadRequest({
        pageNumber: 0,
        sort: [],
        pageSize: 9999,
        startDate,
        finishDate,
        status: [0, 1, 2, 3],
      })
    );
  }

  loadOrdersHistoryPages(page: number) {
    const [startDate, finishDate] = getGetStartDateAndFinishDate();
    this.store.dispatch(new GetDelayedOrders({ page: page, size: 20 }));
    this.store.dispatch(new OrderHistoryLoadPageRequest({ page: page, itemsPerPage: 10, startDate: startDate, finishDate: finishDate }));
  }

  loadAllTasksHistory() {
    combineLatest([this.user$, this.activeOutlet$])
      .pipe(take(1))
      .subscribe(([user, outlet]) => {
        this.store.dispatch(
          new HistoryTaskLoadRequest({
            pageNumber: 0,
            pageSize: 9999,
            clientSapId: user.client.clientSapId,
            addressSapId: outlet.addressSapId,
            completed: true,
          })
        );
      });
  }

  loadHistoryTasksPages(page: number) {
    this.store.dispatch(new HistoryTaskLoadPageRequest({ page: page, itemsPerPage: 10 }));
  }

  loadAllPayHistory() {
    const [startDate, finishDate] = getGetStartDateAndFinishDate();

    this.store.dispatch(new LoadPayHistory({ sort: '', size: 9999, page: 0, direction: '', startDate, endDate: finishDate }));
  }

  loadPayHistoryPages(itemsPerPage) {
    const [startDate, finishDate] = getGetStartDateAndFinishDate();
    this.store.dispatch(new LoadPayHistoryPage({ page: 0, itemsPerPage: itemsPerPage + 5, startDate: startDate, finishDate: finishDate }));
  }

  loadUsersPages(page: number) {
    this.store.dispatch(new UsersLoadPageRequest({ page: page, itemsPerPage: 10 }));
  }

  setPlans(bonuses: Bonus[]) {
    const plans: Partial<Plan>[] = [];
    bonuses.forEach((bonus) => {
      plans.push({
        plan_id: bonus.planId,
        planName: bonus.planName,
        category: bonus.categoryName,
        path: bonus.path,
        parentPath: bonus.parentPath,
      });
    });
    this.plans$.next(plans);
  }

  onUserChange() {}

  onUserDelete() {}

  onUserPermissionsChange() {}

  openDialog(
    onViewType:
      | 'addEmployee'
      | 'sms-verification'
      | 'sms-verified'
      | 'employee-deleted-verified'
      | 'employee-delete'
      | 'update-pass'
      | 'none'
  ) {
    this.openView$.next(onViewType);
  }

  onShowAddEmployee(show: boolean, selectedUserId = -1): void {
    this.userDialogsData$.next({ show, selectedUserId });
  }

  editUser(userId: number) {
    this.onShowAddEmployee(true, userId);
  }

  removeUser(userId: number) {
    this.deleteUserData$.next(userId);
    this.openDialog('employee-delete');
  }

  downloadDocument(docId: number): void {
    this.onDocChange({ type: 'downloadDoc', data: docId });
  }

  signDocument(docId: number): void {
    this.onDocChange({ type: 'signDoc', data: docId });
  }

  subscribeDocument(flag, subscribeButton?): void {
    this.subscribeButtonToggle.next(!!subscribeButton);
    if (flag) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
      this.selectedDoc$.next(null);
    }
    this.subscribeDocumentToggle.next(!this.subscribeDocumentToggle.value);
  }

  onDownloadClick(): void {
    const doc = this.selectedDoc$?.value;
    if (doc.htmlUrl) {
      this.downloadFile(doc.htmlUrl)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data) => saveAs(data, doc.filename.replace(/\s+/gm, '_') + '.html'));
    }
  }

  onShowDoc(flag: boolean, id?: number): void {
    if (!flag) {
      document.body.classList.remove('disable-scroll');
      this.subscribeDocumentToggle.next(false);
    } else {
      if (!this.selectedDoc$.value || this.selectedDoc$.value?.id !== id) {
        this.selectedDoc$.next(this.documents.find((d) => d.id === id));
        this.downloadFile(this.selectedDoc$.value?.htmlUrl)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((blob) => {
            this.iframeSrc$.next(URL.createObjectURL(blob));
            this.subscribeButtonToggle.next(!this.selectedDoc$.value?.isSigned && this.selectedDoc$.value?.shouldSign);
            document.body.classList.add('disable-scroll');
            this.subscribeDocumentToggle.next(true);
            this.cdr.detectChanges();
          });
      }
    }
  }

  onDocChange(evt: DocChange): void {
    switch (evt.type) {
      case 'signDoc':
      case 'downloadDoc':
        this.onShowDoc(true, evt.data);
        break;
      default:
        console.log('fake call with: ', evt);
    }
  }

  private downloadFile(filePath: string): Observable<any> {
    return this.http.get(filePath, { responseType: 'text' }).pipe(map((data) => new Blob([data], { type: 'text/html' })));
  }

  setLastAction(action: Action) {
    this.lastAction$.next(action);
  }

  navigateToPlan(planId: number) {
    const queryParams: { [key: string]: string } = {
      planId: planId.toString(),
    };
    this.router.navigate(['catalog', 'plan'], { queryParams });
  }

  navToHistoryOrdersDetails({ data }: { data: string }) {
    this.router.navigate([`/history/orders`], { state: { data } });
  }

  navigateToHistory() {
    this.router.navigate(['history', 'orders']);
  }

  navToActiveOrders() {
    this.router.navigate(['active', 'order']);
  }

  navToActiveOrdersDetails({ data }: { data: string }) {
    this.router.navigate([`/active/order`], { state: { data } });
  }

  navigateToCatalog() {
    this.router.navigate(['catalog']);
  }

  navToTaskDetails(task: Task) {
    this.store.dispatch(new TaskProgressLoad({ taskOutletClientId: task.id }));
    const queryParams = {
      action: 'chat',
      action_type: 'task-comments',
      action_id: task.id,
      task_id: task.taskId,
      address_sap_id: task.addressSapId,
      client_sap_id: task.clientSapId,
      sent_by: null,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  navToAllTasks() {
    this.router.navigate(['active/task']);
  }

  navigateToProfile() {
    this.router.navigate(['/profile']);
  }

  loadBonusHistoryClient(page?: number) {
    if (this.loadingBonusHistoryClient) {
      return;
    }

    this.loadingBonusHistory = true;
    let params: GetClientHistoryCriteria = {
      clientSapId: this.user.client.clientSapId,
      sort: 'id',
      direction: 'DESC',
    };
    if (this.pageToLoadNextBonusesClient > 1) {
      params = { ...params, page: this.pageToLoadNextBonusesClient };
    }
    this.bonusService
      .getClientHistoryBonus(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.clientBonusesHistory = [...this.clientBonusesHistory, ...data.content];
        this.loadingBonusHistoryClient = false;
        this.pageToLoadNextBonusesClient++;
      });
  }

  loadBonusHistory(page?: number) {
    if (this.loadingBonusHistory) {
      return;
    }

    this.loadingBonusHistory = true;
    let params: GetUserHistoryCriteria = {
      userIds: [this.user.id],
      sort: 'id',
      direction: 'DESC',
    };
    if (this.pageToLoadNextBonuses > 1) {
      params = { ...params, page: this.pageToLoadNextBonuses };
    }
    this.bonusService
      .getUserHistoryBonus(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.userBonusesHistory = [...this.userBonusesHistory, ...data.content];
        this.loadingBonusHistory = false;
        this.pageToLoadNextBonuses++;
      });
  }

  onShowUserHistoryPoint(user: User) {
    if (this.bsModalRef) {
      this.bsModalRef.content.closed.unsubscribe();
    }
    this.store.dispatch(new SelectedUserBonusBalanceLoad({ userId: user.id }));
    this.bsModalRef = this.modalService.show(UserBonusModalComponent, {
      initialState: { user },
      class: 'modal-dialog-centered user-bonus-modal',
    });
    this.bsModalRef.content.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.bsModalRef.hide();
    });
  }

  onConfirmedDeliveryOrder(docNumber: string) {
    this.store.dispatch(new OrderSetConfirmed({ docNumber }));
  }

  onEditedDelayedOrder(delayedOrder: DelayedOrder): void {
    this.router.navigate(['/active/delayed-order-edit'], {
      queryParams: { addressSapId: delayedOrder.addressSapId, delayedOrderId: delayedOrder.id },
      queryParamsHandling: 'merge',
    });
  }

  onShowHistoryDelayedOrder(delayedOrder: DelayedOrder): void {
    this.bsModalRef = this.modalService.show(DelayedOrderHistoryComponent, {
      class: 'modal-dialog-right',
      initialState: { orderHistories: delayedOrder.history },
    });
    this.bsModalRef.content.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.onClosedModal();
    });
  }

  onDropedDelayedOrder(delayedOrderId: number) {
    this.bsModalRef = this.modalService.show(DelayedOrderNotifyDropComponent, {
      class: 'modal-dialog-centered modal-notify',
    });
    this.bsModalRef.content.canceled.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.onClosedModal();
    });
    this.bsModalRef.content.droped.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.store.dispatch(new RemoveDelayedOrder({ delayedOrderId }));
      this.onClosedModal();
    });
  }

  onRequestInvoiceDelayedOrder(delayedOrderId: number) {
    this.store.dispatch(new RequestInvoiceDelayedOrder({ delayedOrderId, shouldRequestInvoice: true }));
    this.bsModalRef = this.modalService.show(DelayOrderRequestInvoiceComponent, {
      class: 'modal-dialog-centered modal-notify',
    });
    setTimeout(() => {
      this.bsModalRef.hide();
    }, 2000);
  }

  onClosedModal() {
    this.bsModalRef?.hide();
  }

  onShowInvoceOrder(order: Order): void {
    this.bsModalRef = this.modalService.show(OrderInvoceViewComponent, {
      class: 'modal-dialog-right modal-dialog-sm',
      initialState: { order },
    });
    this.bsModalRef.content.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.onClosedModal();
    });
  }
}
