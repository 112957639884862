import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { userReducer } from '@Mesh/store/user/user.reducers';
import { UserEffects } from '@Mesh/store/user/user.effects';
import { StoreModule } from '@ngrx/store';
import { LocalStorageService } from '@Mesh/core/services/local-storage';
import { storageMetaReducer } from '@Mesh/store/storage-metareducer';

export function getUserConfig(saveKeys: string[], localStorageKey: string, storageService: LocalStorageService) {
  return { metaReducers: [storageMetaReducer(saveKeys, localStorageKey, storageService)] };
}

@NgModule({
  imports: [StoreModule.forFeature('user', userReducer), EffectsModule.forFeature([UserEffects])] /*,
  providers: [
    {provide: USER_STORAGE_KEYS, useValue: ['user']},
    {provide: USER_LOCAL_STORAGE_KEY, useValue: 'user__storage'},
    {
      provide: USER_CONFIG_TOKEN,
      deps: [USER_STORAGE_KEYS, USER_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getUserConfig
    }
  ]*/,
})
export class UserStoreModule {}
