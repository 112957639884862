import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-recommended-orders-info',
  templateUrl: './recommended-orders-info.component.html',
  styleUrls: ['./recommended-orders-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendedOrdersInfoComponent implements OnInit {
  @Input() totalAmount: number;
  @Input() numberOfOrder: number;
  @Input() showClosed: boolean;
  @Input() changesRecommendedCart: boolean = false;

  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() updated: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClosed() {
    this.closed.emit();
  }

  onUpdateRecommendOrder() {
    this.updated.emit();
  }
}
