import { withLoadable } from '@Mesh/utils/loadable/with-loadable';
import { ActionsUnion, DocumentActionsTypes } from '@Mesh/store/document/document.actions';
import { DocumentState, initialState } from '@Mesh/store/document/document.state';
import { POSTRequestStatus } from '@Mesh/store/user/user.state';

function baseDocumentReducer(state = initialState, action: ActionsUnion): DocumentState {
  switch (action.type) {
    case DocumentActionsTypes.DocumentLoadSuccess:
      return {
        ...state,
        data: action.payload.data,
      };
    case DocumentActionsTypes.DocumentLoadError:
      return {
        ...state,
        error: action.error,
      };
    case DocumentActionsTypes.DocumentSetTotalCount:
      return {
        ...state,
        totalCount: action.payload.totalCount,
      };
    case DocumentActionsTypes.DocumentSetActive:
      return {
        ...state,
        selectedId: action.payload.id,
      };
    case DocumentActionsTypes.DocumentSignRequest:
      return {
        ...state,
        sentSMSStatus: POSTRequestStatus.SENT,
      };
    case DocumentActionsTypes.DocumentSignSuccess:
      return {
        ...state,
        sentSMSStatus: POSTRequestStatus.SUCCESS,
        identifierId: action.payload.identifierId,
      };
    case DocumentActionsTypes.DocumentSignError:
      return {
        ...state,
        sentSMSStatus: POSTRequestStatus.FAILURE,
      };
    case DocumentActionsTypes.DocumentSignedRequest:
      return {
        ...state,
        sentSignedStatus: POSTRequestStatus.SENT,
      };
    case DocumentActionsTypes.DocumentSignedSuccess:
      return {
        ...state,
        sentSignedStatus: POSTRequestStatus.SUCCESS,
      };
    case DocumentActionsTypes.DocumentSignedError:
      return {
        ...state,
        sentSignedStatus: POSTRequestStatus.FAILURE,
      };
    default:
      return state;
  }
}

export function documentReducer(state: DocumentState, action: ActionsUnion): DocumentState {
  return withLoadable(baseDocumentReducer, {
    loadingActionType: [DocumentActionsTypes.DocumentLoadRequest],
    successActionType: [DocumentActionsTypes.DocumentLoadSuccess],
    errorActionType: [DocumentActionsTypes.DocumentLoadError],
  })(state, action);
}
