import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, page: 'order' | 'task'): string {
    if (!value) {
      return '';
    }
    if (page === 'order') {
      return this.formatOrderDate(value);
    } else if (page === 'task') {
      return this.formatTaskDate(value);
    }
    return value;
  }

  private formatOrderDate(sDate: string): string {
    const date = new Date(sDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (
      (day < 10 ? '0' + day : day) +
      '.' +
      (month < 10 ? '0' + month : month) +
      '.' +
      year +
      ' - ' +
      date.getHours() +
      ':' +
      date.getMinutes()
    );
  }

  private formatTaskDate(sDate: string): string {
    const date = new Date(sDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + year;
  }
}
