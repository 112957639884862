import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cartFeatureKey, CartState } from '@Mesh/store/cart/cart.reducers';
import { CartItem, CartItemDisplay, CartItemType } from '@Mesh/store/cart/cart.models';
import { IMAGES_URL } from '@Env/environment';
import { RequestStatus } from '@Mesh/shared/enums/request-status.enum';

const selectCartState = createFeatureSelector<CartState>(cartFeatureKey);

export const selectCart = createSelector(selectCartState, (state) => state.cart);

export const numberOfCartItems = createSelector(selectCart, (items) => items.length);

export const getCartAmount = createSelector(selectCart, (items) => items.reduce((prev, curr) => prev + (curr?.quantity || 0), 0));

export const selectTotalCartPrice = createSelector(selectCart, (items) => {
  return items.reduce((prev, curr) => prev + (curr.price || 0), 0);
});

const selectCartProducts = createSelector(selectCartState, (state) => state.cartProducts);

export const selectMinimalCartRules = createSelector(selectCartState, (state) => state.minimalCartRules);

export const selectMinimalCartRulesGroupStatus = createSelector(selectCartState, (state) => state.minimalCartRulesGroupStatus);

export const selectCategoryCart = createSelector(selectCartState, (state) => state.categoryCart);

const selectCartProductById = (productId: number) => {
  return createSelector(selectCartProducts, (products) => {
    return products.find((el) => el.id === productId);
  });
};

export const selectCartItemByMaterialIdAndUnit = (materialId: number, unit: string) => {
  return createSelector(selectCart, (items) => {
    return items.find((el) => el.materialId === materialId && el.unit === unit);
  });
};

export const selectCartItemByMaterialIdAndUnitAndDistributorName = (materialId: number, unit: string, distributorName: string) => {
  return createSelector(selectCart, (items) => {
    return items.find((el) => el.materialId === materialId && el.unit === unit && el.distributorName === distributorName);
  });
};
export const selectOrdersHistoryMap = createSelector(selectCartState, (state) => state.ordersHistoryMap);

export const selectProductsForCartDisplay = createSelector(
  selectCart,
  selectCartProducts,
  selectOrdersHistoryMap,
  (cartItems, cartProducts, ordersHistoryMap) => {
    return cartItems.map((item: CartItem): CartItemDisplay => {
      const result: Partial<CartItemDisplay> = {
        materialId: item.materialId,
        unit: item.unit,
        mrc: item.mrc,
        ndsPrice: item.ndsPrice,
        price: item.price,
        discountNdsPrice: item.discountNdsPrice,
        discountPrice: item.discountPrice,
        totalNdsPriceTag: item.quantity * item.ndsPrice,
        totalDiscountNdsPriceTag: item.quantity * item.discountNdsPrice,
        quantity: item.quantity,
        hasDiscount: item.ndsPrice !== item.discountNdsPrice,
        salesOrgSapId: item.salesOrgSapId,
        distributorCode: item.distributorCode,
        distributorName: item.distributorName,
        deliveryDate: item.deliveryDate,
        type: item.type,
        hierarchy: item.hierarchy,
        ordersHistory: ordersHistoryMap[item.materialId],
      };
      const product = cartProducts.find((el) => el.materialId === item.materialId);
      if (product) {
        const stock = product.stock.find((el) => el.unit === item.unit);
        result.name = product.materialName;
        result.baseQuantUnit = stock?.baseQuantUnit;
        result.baseUnit = stock?.baseUnit;
        result.baseNdsPrice = stock?.baseNdsPrice;
        result.orderAmount = item.quantity;
        result.image = IMAGES_URL + product.url;
        result.id = product.id;
        result.quantityMaterial = stock.quantityMaterial;
        result.isFavorite = product.isFavorite;
        result.salePlans = product.salePlans;
        result.planIds = product.planIds;
      }
      return result as CartItemDisplay;
    });
  }
);

export const totalAmountOfOrder = createSelector(selectProductsForCartDisplay, (items) => {
  return items.reduce((prev, curr) => prev + curr.orderAmount, 0);
});

export const selectTotalPriceOfOrder = createSelector(selectProductsForCartDisplay, (items) => {
  return items.reduce((rev, curr) => rev + curr.totalNdsPriceTag, 0);
});

export const selectTotalDiscountPriceOfOrder = createSelector(selectProductsForCartDisplay, (items) => {
  return items.reduce((rev, curr) => rev + curr.totalDiscountNdsPriceTag, 0);
});

export const selectStockMap = createSelector(selectCartState, (state) => state.cartStockMap);

export const selectSelectedStockByProductId = (productId: number) => {
  return createSelector(selectStockMap, (stockMap) => {
    return stockMap[productId];
  });
};

export const selectStockByProductIdUnit = (productId: number, unit: string) => {
  return createSelector(selectCartProductById(productId), (product) => {
    return product && product.stock.find((stock) => stock.unit === unit);
  });
};

export const selectStockByProductIdUnitDistributor = (productId: number, unit: string, distributorName: string) => {
  return createSelector(selectCartProductById(productId), (product) => {
    if (!product) {
      return null;
    }

    for (const group of product.groupedStock) {
      const foundStock = group.stock.find((stock) => stock.unit === unit && stock.distributorName === distributorName);
      if (foundStock) {
        return foundStock;
      }
    }

    return null;
  });
};

export const selectCartItemByProductIdInRows = (productId: number) => {
  return createSelector(selectStockMap, selectCart, (stockMap, items) => {
    const stock = stockMap[productId];
    return items.find(
      (el) =>
        el?.materialId === stock?.materialId &&
        el?.unit === stock?.unit &&
        el?.distributorCode === stock?.distributorCode &&
        el?.distributorName === stock?.distributorName &&
        el?.salesOrgSapId === stock?.salesOrgSapId &&
        el?.ndsPrice === stock?.ndsPrice
    );
    // return items.find((el) => el.materialId === stock.materialId);
  });
};

export const selectCartItemQuantityByProductIdInRows = (productId: number) => {
  return createSelector(selectCartItemByProductIdInRows(productId), (cartItem) => {
    return cartItem?.quantity || 0;
  });
};

export const possibleToDecrement = (productId: number) => {
  return createSelector(selectCartItemQuantityByProductIdInRows(productId), (quantity) => {
    return quantity > 0;
  });
};

export const productIsFavorite = (productId: number) => {
  return createSelector(selectCartProductById(productId), (cartItem) => {
    return cartItem.isFavorite;
  });
};

export const possibleToIncrement = (productId: number) => {
  return createSelector(
    selectSelectedStockByProductId(productId),
    selectCartItemQuantityByProductIdInRows(productId),
    (stock, quantity) => {
      return stock?.quantityMaterial > quantity;
    }
  );
};

export const selectMaxCountSelectedStock = (productId: number) => {
  return createSelector(selectSelectedStockByProductId(productId), (stock) => {
    return stock?.quantityMaterial;
  });
};

export const selectOrderCompletedState = createSelector(selectCartState, (state) => {
  return state.orderCompletedState;
});

export const selectCartChangedData = createSelector(selectCartState, (state) => {
  return state.cartChangedData;
});

export const showOrderDetails = createSelector(selectCartState, (state) => {
  return state.submitCartStatus === RequestStatus.SUCCESS;
});

export const groupByMaterialGroupNameId = createSelector(selectCartProducts, (products) => {
  return (products || []).reduce(
    (entryMap, product) => entryMap.set(product.materialGroupNameId, [...(entryMap.get(product.materialGroupNameId) || []), product]),
    new Map()
  );
});

export const selectRecommendedCart = createSelector(selectCartState, (state) => state.recommendedCart);
export const selectDefaultCart = createSelector(selectCartState, (state) => state.defaultCart);
export const totalRecommendedCartAmount = createSelector(selectRecommendedCart, (items) => {
  return items.reduce((prev, curr) => prev + curr.ndsPrice * curr.quantity, 0);
});

export const numberOfRecommendedCart = createSelector(selectRecommendedCart, (items) => items.length);
export const numberOfOtherCart = createSelector(
  selectCart,
  (items) => items.filter((item) => item.type.indexOf(CartItemType.NORMAL) !== -1).length
);
export const numberOfRecommendCart = createSelector(
  selectCart,
  (items) => items.filter((item) => item.type.indexOf(CartItemType.RECOMMENDED) !== -1).length
);
export const nextDeliveryDate = createSelector(selectCartState, (state) => state.nextDeliveryDate);
export const changesRecommendedCart = createSelector(selectCartState, (state) => state.changesRecommendedCart);
export const selectSubmitCartError = createSelector(selectCartState, (state) => state.submitCartError);
export const selectFillCartByFileProgress = createSelector(selectCartState, (state) => state.fillByFileProgress);
export const selectCurrentRecommendedOrderId = createSelector(selectCartState, (state) => state.currentRecommendedOrderId);
export const selectCurrentRecommendedOrderActive = createSelector(selectCartState, (state) => Boolean(state.currentRecommendedOrderId));
export const selectTaskRecommendedOrder = createSelector(selectCartState, (state) => state.taskRecommendedOrder);
export const selectShowRecommendedOrderButton = createSelector(selectCartState, (state: CartState) => state.showRecommendedOrderButton);
export const selectIsSubmittingCart = createSelector(selectCartState, (state) => state.isSubmittingCart);
