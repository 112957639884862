import { NgModule } from '@angular/core';

import { AppOverlayModule } from './overlay';

const MODULE_COLLECTION = [AppOverlayModule];

@NgModule({
  imports: MODULE_COLLECTION,
  exports: [MODULE_COLLECTION],
  declarations: [],
})
export class DirectivesModule {}
