import { createSelector } from '@ngrx/store';
import { selectDocumentState } from '@Mesh/store/app.state';
import { DocumentState } from '@Mesh/store/document/document.state';
import { API_USER_URL } from '@Env/environment';
import { Documents } from '@Mesh/core/models/documents';

const baseDocUrl = `${API_USER_URL}/document/{docID}/html`;

export const documentSelectors = {
  getDocuments: createSelector(selectDocumentState, (state: DocumentState) => {
    console.log('DOCUMENTS::::', state.data);
    return state.data.length
      ? (state.data as Documents[]).map((el) => ({ ...el, htmlUrl: baseDocUrl.replace('{docID}', '' + el.id) }))
      : [];
  }),
  getDocumentTotalCount: createSelector(selectDocumentState, (state: DocumentState) => state.totalCount),
  getError: createSelector(selectDocumentState, (state: DocumentState) => state.error),
  getSelectedId: createSelector(selectDocumentState, (state: DocumentState) => state.selectedId),
  getSentSMSStatus: createSelector(selectDocumentState, (state: DocumentState) => state.sentSMSStatus),
  getSentSignedStatus: createSelector(selectDocumentState, (state: DocumentState) => state.sentSignedStatus),
  getSelectedDocument: createSelector(selectDocumentState, (state: DocumentState) => state.data?.find((d) => d['id'] === state.selectedId)),
  getIdentifierId: createSelector(selectDocumentState, (state) => state.identifierId),
};
