import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { OutletState } from './../../../store/outlet/outlet.state';
import { Observable } from 'rxjs/Observable';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { AppState } from '@Mesh/store/app.state';
import { select, Store } from '@ngrx/store';
import { Outlet } from '@Mesh/core/models';
import { OutletSetActive } from '@Mesh/store/outlet/outlet.actions';

@Component({
  selector: 'app-address-dropdown',
  templateUrl: './address-dropdown.component.html',
  styleUrls: ['./address-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressDropdownComponent implements OnInit {
  @Input() show: boolean;
  outlets$: Observable<OutletState>;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.outlets$ = this.store.pipe(select(outletSelectors.selectOutlet));
  }

  getAddress(outlet: Outlet) {
    return [outlet.street, outlet.house, outlet.housing, outlet.room].filter((s) => !!s).join(' ');
  }

  onChangeOutlet($event: Outlet): void {
    this.onClose.emit(false);
    this.store.dispatch(new OutletSetActive({ active: $event }));
  }
}
