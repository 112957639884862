import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { OrderHistoryItem } from '../../../../store/order/order.model';

@Component({
  selector: 'app-basket-orders-history-product-modal',
  templateUrl: './basket-orders-history-product-modal.component.html',
  styleUrls: ['./basket-orders-history-product-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketOrdersHistoryProductModalComponent implements OnInit {
  @Input() orderHistoryList: OrderHistoryItem[];
  @Output() closed: EventEmitter<any> = new EventEmitter();
  showMore = false;
  sliceSize = 3;
  unitMap = {
    PAK: 'ПАЧ',
    ST: 'ШТ',
  };
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  onClosed(): void {
    this.closed.emit();
  }

  onClick(): void {
    this.sliceSize = this.orderHistoryList.length;
    if (this.showMore) {
      this.onClosed();
      return;
    }
    this.showMore = !this.showMore;
    this.cdr.markForCheck();
  }
}
