import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { User } from '@Mesh/core/models/user';
import * as moment from 'moment';
import { pgSelectComponent } from '@Mesh/@pages/components/select/select.component';
import { StatisticsPoints } from '@Mesh/core/models/statistics-points';
import { KeyValue } from '@angular/common';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { fromEvent, Subject } from 'rxjs';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fadeIn, fadeOut } from '@Mesh/utils/animations/fade-animations';
import { takeUntil } from 'rxjs/operators';
import { DataService } from '@Mesh/core/services';

declare let d3: any;
declare var pg: any;
moment.locale('ru');
const ru = d3.locale({
  decimal: ',',
  thousands: '\xa0',
  grouping: [3],
  currency: ['', ' руб.'],
  dateTime: '%A, %e %B %Y г. %X',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
  shortDays: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
  shortMonths: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
});
const SEND_FORMAT = 'YYYY-MM-DD';

class Group {
  name: string;
  value: moment.unitOfTime.Base | moment.unitOfTime._quarter;
  group: string;
  unitName: string;
  axisLabel: string;
  constructor(
    name: string,
    value: moment.unitOfTime.Base | moment.unitOfTime._quarter,
    group: string,
    unitName: string,
    axisLabel: string
  ) {
    this.name = name;
    this.value = value;
    this.group = group;
    this.unitName = unitName;
    this.axisLabel = axisLabel;
  }
}

class Unit {
  name: string;
  start: string;
  end: string;
  constructor(name: string, start: string, end: string) {
    this.name = name;
    this.start = start;
    this.end = end;
  }
}
@Component({
  selector: 'task-statistics-widget',
  templateUrl: './task-statistics-widget.component.html',
  styleUrls: ['./task-statistics-widget.component.scss', './ghost/ghost-item.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('collapseState', [
      state(
        'inactive',
        style({
          opacity: '0',
          height: 0,
        })
      ),
      state(
        'active',
        style({
          opacity: '1',
          height: '*',
        })
      ),
      transition('inactive => active', animate('125ms ease-in')),
      transition('active => inactive', animate('125ms ease-out')),
    ]),
    trigger('fadeOut', fadeOut()),
    trigger('fadeIn', fadeIn()),
  ],
})
export class TaskStatisticsWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;
  @ViewChild('unitSelect') unitSelect: pgSelectComponent;
  private unsubscribe$ = new Subject();
  _user: User;
  statistics: StatisticsPoints;
  spinner = SPINNER;
  activeUnit = 0;
  nvd3BarData = [];
  nvd3BarOptions;
  history = {};
  start: moment.Moment;
  end: moment.Moment;
  date: number;
  historyPage: number;
  enough = false;
  scrollWindow = window.innerWidth < 992;
  scrollDistance = window.innerWidth < 992 ? 1 : 2;
  isCollapsed = window.innerWidth < 992;
  _active = true;
  _activeHistory = false;
  lastActivity: string;
  lastOperation: number;
  isLoaded = false;
  windowWidth = window.innerWidth;
  configPs = {};
  nvd3LineOptions;
  nvd3LineData;
  COLOR_SUCCESS = pg.getColor('success');
  COLOR_DANGER = pg.getColor('danger');
  COLOR_PRIMARY = pg.getColor('primary');
  COLOR_COMPLETE = pg.getColor('complete');
  public config: SwiperConfigInterface = {
    init: true,
    observer: true,
    direction: 'vertical',
    slidesPerView: 'auto',
    freeMode: true,
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    mousewheel: true,
    navigation: false,
    pagination: false,
  };
  groups = [
    new Group('Месяц', 'month', 'day', 'month', 'Дни'),
    new Group('Квартал', 'quarter', 'week', 'quarter', 'Месяцы'),
    new Group('Год', 'year', 'month', 'year', 'Месяцы'),
    new Group('Все', 'month', 'day', 'all', 'Дни'),
  ];
  xTickValues = [];
  yDomainMax = 100;
  yPointsMax = 0;
  activeGroup = this.groups[3];
  units = this.initUnits();
  gradientGraphColors = {
    cash: '#7575CA',
    reputation: '#FCC02A',
    skill: '#F16393',
    kpi: '#58C7DA',
  };
  ghostHistories = new Array(6).fill(null);

  @Input()
  set user(value: User) {
    const id = this.user?.id;
    this._user = value;

    if (this.user && this.user.id !== id) {
      this.isLoaded = false;
      this.init();
    }
  }

  get user() {
    return this._user;
  }

  set Active(value: boolean) {
    this._active = value;
  }

  get Active(): boolean {
    return this._active;
  }

  toggleNavbar() {
    this.isCollapsed = window.innerWidth < 992;
  }

  clickHeader($event: MouseEvent): void {
    if (this.isCollapsed) {
      this.Active = !this.Active;
    }
  }

  clickHistory($event: MouseEvent): void {
    if (this.isCollapsed) {
      this._activeHistory = !this._activeHistory;
    }
  }

  getStart(): moment.Moment {
    return this.start.clone();
  }

  getEnd(): moment.Moment {
    return this.end.clone();
  }

  initUnits() {
    const units = {
      month: [],
      quarter: [],
      year: [],
      all: [],
    };

    const now = moment();
    const start = moment().year(2019).startOf('year');
    const startMonth = start.clone();
    const endMonth = now.clone();

    while (endMonth.isAfter(startMonth) || endMonth.isSame(startMonth)) {
      units.month.push([endMonth.format('MMMM YYYY'), endMonth.startOf('month').unix()]);
      endMonth.subtract(1, 'month');
    }

    const startQuarter = start.clone();
    const endQuarter = now.clone();

    while (endQuarter.isAfter(startQuarter) || endQuarter.isSame(startQuarter)) {
      units.quarter.push(['Квартал ' + endQuarter.format('Q YYYY'), endQuarter.startOf('quarter').unix()]);
      endQuarter.subtract(1, 'quarter');
    }

    const startYear = start;
    const endYear = now.clone();

    while (endYear.isAfter(startYear) || endYear.isSame(startYear)) {
      units.year.push([endYear.year().toString(), endYear.startOf('year').unix()]);
      endYear.subtract(1, 'year');
    }

    return units;
  }

  constructor(private cdr: ChangeDetectorRef, private ngxService: NgxUiLoaderService, private dataService: DataService) {}

  ngAfterViewInit(): void {
    if (this.unitSelect) {
      this.unitSelect.Value = this.activeUnit.toString();
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    }
  }

  ngOnInit(): void {
    fromEvent(window, 'resize').debounceTime(100).subscribe(this.onResize.bind(this));

    this.updateChart();

    this.dataService.getChartSampleData().subscribe((d: any) => {
      setTimeout(() => {
        this.nvd3LineData = d.nvd3.line;
        this.nvd3LineOptions = {
          chart: {
            type: 'lineChart',
            color: [this.COLOR_SUCCESS, this.COLOR_DANGER, this.COLOR_PRIMARY, this.COLOR_COMPLETE],
            x: function (d) {
              return d[0];
            },
            y: function (d) {
              return d[1];
            },
            // duration: 500,
            clipEdge: true,
            useInteractiveGuideline: true,
            margin: {
              left: 30,
              bottom: 35,
            },
            showLegend: false,
            xAxis: {
              tickFormat: (d) => {
                return d3.time.format('%a')(new Date(d));
              },
            },
            yAxis: {
              tickFormat: (d) => {
                return Math.round(d);
              },
            },
          },
        };
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
      }, 1000);
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
    this.unsubscribe$.next(true);
  }

  onResize(e) {
    if (this.windowWidth !== window.innerWidth) {
      this.windowWidth = window.innerWidth;
      this.toggleNavbar();
      this.updateChart();
    }
  }

  changeFilter(filter: Group) {
    if (filter !== this.activeGroup) {
      this.activeGroup = filter;
      this.reloadStatistics();
    }
  }

  changeUnit() {
    this.date = this.activeUnit;
    this.reloadStatistics();
  }

  init() {
    this.date = this.getLastStatisticsDate();
    this.reloadStatistics();
  }

  reloadStatistics() {
    this.updateUnit();
    this.updateRange();
    this.reloadHistory();
    this.getStatistics();
  }

  updateGroupAll() {
    const startPoint = this.statistics.points[1] ? this.statistics.points[1].timestamp : 0;
    const startKpi = this.statistics.kpi[1] ? this.statistics.kpi[1].timestamp : 0;

    const start = !startPoint || !startKpi ? Math.max(startPoint, startKpi) : Math.min(startPoint, startKpi);
    const end = Math.max(this.statistics.points.slice(-1)[0].timestamp, this.statistics.kpi.slice(-1)[0].timestamp);

    this.start = moment.unix(start);
    this.end = moment.unix(end);

    if (this.end.diff(this.start, 'days') < 32) {
      this.activeGroup.value = 'month';
      this.activeGroup.group = 'day';
      this.activeGroup.axisLabel = 'Дни';
    } else {
      this.activeGroup.value = 'year';
      this.activeGroup.group = 'month';
      this.activeGroup.axisLabel = 'Месяцы';
    }
  }

  historyCount() {
    return Object.keys(this.history).length;
  }

  loadMore() {
    if (!this.enough && (!this.isCollapsed || (this._activeHistory && this.isCollapsed))) {
      this.historyPage++;
      this.getHistory();
    }
  }

  getLastStatisticsDate(): number {
    return moment().startOf(this.activeGroup.value).unix();
  }

  updateUnit() {
    this.activeUnit = moment.unix(this.date).startOf(this.activeGroup.value).unix();

    if (this.unitSelect) {
      this.unitSelect.Value = this.activeUnit.toString();
    }
  }

  updateRange() {
    if (this.activeGroup.unitName === 'all') {
      this.start = null;
      this.end = null;
    } else {
      this.start = moment.unix(this.activeUnit).startOf(this.activeGroup.value);
      this.end = moment.unix(this.activeUnit).endOf(this.activeGroup.value);
    }
  }

  getStatistics() {
    this.startLoader();
    const start = this.start ? this.start.format(SEND_FORMAT) : null;
    const end = this.end ? this.end.format(SEND_FORMAT) : null;
    const group = this.activeGroup ? this.activeGroup.group : 'day';
  }

  startLoader() {
    if (this.isLoaded) {
      this.ngxService.startLoader('task-statistics-widget-graph-loader');
    }
  }

  stopLoader() {
    if (this.isLoaded) {
      this.ngxService.stopLoader('task-statistics-widget-graph-loader');
    }
  }

  reloadHistory() {
    this.history = {};
    this.historyPage = 1;
    this.enough = false;
    this.getHistory();
  }

  getHistory() {
    const start = this.start ? this.start.format(SEND_FORMAT) : null;
    const end = this.end ? this.end.format(SEND_FORMAT) : null;
  }

  updateDataChart() {
    const cash = [],
      skill = [],
      reputation = [],
      kpi = [];
    this.nvd3BarData = [];

    if (
      this.statistics.points[0].user_points === 0 &&
      this.statistics.points.length < 2 &&
      this.statistics.kpi[0].kpi === 0 &&
      this.statistics.kpi.length < 2
    ) {
      return;
    }

    const start = this.activeGroup.value === 'month' ? this.getStart().subtract(1, 'day') : this.getStart().subtract(15, 'day');

    const startUnix = start.unix();
    const endUnix = this.getEnd().unix();
    this.xTickValues = this.getXaxisValues();

    this.statistics.points.forEach((userPoint, key) => {
      const date = userPoint.timestamp !== 0 ? userPoint.timestamp : startUnix;
      cash.push([date, userPoint.user_points]);
      skill.push([date, userPoint.score]);
      reputation.push([date, userPoint.rating]);
    });

    const lastPoints = this.statistics.points.slice(-1)[0];
    cash.push([endUnix, lastPoints.user_points]);
    skill.push([endUnix, lastPoints.score]);
    reputation.push([endUnix, lastPoints.rating]);

    const yPoints = Math.max.apply(
      Math,
      this.statistics.points.map(function (point) {
        return Math.max(point.user_points, point.score, point.rating);
      })
    );

    this.yPointsMax = yPoints ? yPoints : 100;

    const getKpi = (value: number) => {
      return (value * 100 * this.yPointsMax) / 100;
    };

    const yKpiMax = Math.max.apply(
      Math,
      this.statistics.kpi.map(function (kpi) {
        return getKpi(kpi.kpi);
      })
    );

    this.statistics.kpi.forEach((item, key) => {
      const date = item.timestamp !== 0 ? item.timestamp : startUnix;
      kpi.push([date, getKpi(item.kpi)]);
    });

    const lastKpi = this.statistics.kpi.slice(-1)[0];
    kpi.push([endUnix, getKpi(lastKpi.kpi)]);

    this.yDomainMax = Math.max(this.yPointsMax, yKpiMax);

    this.nvd3BarData.push({
      key: 'Cash',
      values: cash,
      color: '#7575CA',
      area: true,
    });

    this.nvd3BarData.push({
      key: 'Reputation',
      values: reputation,
      color: '#FCC02A',
      area: true,
    });

    this.nvd3BarData.push({
      key: 'Skilllevel',
      values: skill,
      color: '#F16393',
      area: true,
    });

    this.nvd3BarData.push({
      key: 'KPI',
      values: kpi,
      color: '#58C7DA',
      area: true,
    });
  }

  updateChart() {
    const unit = this.activeGroup.value;
    const windowWidth = window.innerWidth;
    const left = windowWidth > 991 ? 45 : 30;
    const right = windowWidth > 991 ? 20 : 0;

    this.nvd3BarOptions = {
      chart: {
        type: 'lineChart',
        x: function (d) {
          return d[0];
        },
        y: function (d) {
          return d[1];
        },
        // width: '100%',
        // height: 285,
        stacked: false,
        showControls: false,
        clipEdge: false,
        legendPosition: 'bottom',
        legend: {
          rightAlign: false,
        },
        xAxis: {
          // axisLabel: this.activeGroup.axisLabel,
          showMaxMin: false,
          tickValues: this.xTickValues,
          tickPadding: 8,
          rotateLabels: 0,
          tickFormat: (d) => {
            let format = '';

            if (this.activeGroup.unitName === 'all') {
              if (this.activeGroup.group === 'day') {
                format = '%d-%m-%y';
              } else {
                format = '%m-%y';
              }
            } else {
              format = unit === 'month' ? '%d' : '%b';
            }

            return ru.timeFormat(format)(new Date(d * 1000));
          },
        },
        yAxis: {
          showMaxMin: false,
          ticks: 5,
          tickFormat: function (d) {
            return d3.format('.0f')(d);
          },
        },
        yDomain: [0, this.yDomainMax],
        groupSpacing: 0.1,
        margin: {
          top: 20,
          right: right,
          bottom: 0,
          left: left,
        },
        duration: 500,
        tooltip: {
          contentGenerator: (d) => {
            const series = d.series[0];
            if (series.value === null) {
              return;
            }
            const value = series.key !== 'KPI' ? series.value : Math.round((series.value / this.yPointsMax) * 100) / 100;
            const rows =
              '<tr>' +
              '<td class="legend-color-guide"><div style="background-color:' +
              series.color +
              ';"></div></td>' +
              '<td class="key"><strong>' +
              series.key +
              ': </strong>' +
              value +
              '</td>' +
              '</tr>';

            return '<table>' + '<tbody>' + rows + '</tbody>' + '</table>';
          },
        },
        callback: (chart) => {
          const svg = d3.select('.task-statistics-widget-block svg');

          Object.entries(this.gradientGraphColors).forEach(([key, value]) => {
            let linearGrad = svg.append('linearGradient');
            linearGrad.attr('id', 'grad-statistics-' + key);
            linearGrad.attr('x1', '0%');
            linearGrad.attr('x2', '100%');
            linearGrad.attr('y1', '0%');
            linearGrad.attr('y2', '100%');
            linearGrad.html(
              '<stop stop-color="' +
                this.gradientGraphColors[key] +
                '" stop-opacity="0.2"/>' +
                '<stop offset="1" stop-color="' +
                this.gradientGraphColors[key] +
                '" stop-opacity="0.04"/>'
            );
          });

          const width = chart.legend.width();
          const legend = svg.select('.nv-legendWrap')[0][0];
          const legendWidth = legend.getBoundingClientRect().width;
          const diff = width - legendWidth;
          const marginLeft = (diff > 0 ? diff : 0) / 2;
          chart.legend.margin({ top: 5, left: marginLeft });
          legend.classList.add('active');
          const circles = svg.select('.nv-legend').selectAll('.nv-legend-symbol');

          circles[0].map((circle) => {
            d3.select(circle).attr('r', 3);
          });

          // const axis = svg.select('.nv-x .nv-wrap');
          // axis.append('text')
          //   .attr('class', 'nv-axislabel')
          //   .attr('x', -20)
          //   .attr('y', 15)
          //   .attr('text-anchor', 'middle')
          //   .text(this.activeGroup.axisLabel);

          if (this.activeGroup.unitName === 'all') {
            chart.xAxis.rotateLabels(-45);
          }

          if (this.scrollWindow && this.activeGroup.unitName === 'year') {
            chart.xAxis.rotateLabels(-45);
          }

          setTimeout(() => {
            const groups = svg.select('.nv-groups');
            const groupsRect = groups[0][0].getBoundingClientRect();

            groups
              .append('text')
              .attr('class', 'nv-axislabel')
              .attr('x', groupsRect.width - this.activeGroup.axisLabel.length * 11)
              .attr('y', groupsRect.height - 10)
              .attr('text-anchor', 'right')
              .text(this.activeGroup.axisLabel);
          }, 800);

          chart.update();
        },
      },
    };

    this.isLoaded = true;
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  returnZero() {
    return 0;
  }

  getXaxisValues() {
    const values = [];
    const type = this.activeGroup.value;
    const start = this.getStart();
    const end = this.getEnd();

    if (type === 'month') {
      while (end.isAfter(start)) {
        values.push(start.unix());
        start.add(3, 'days');
      }
    } else {
      while (end.isAfter(start)) {
        values.push(start.unix());
        start.add(1, 'months');
      }
    }

    return values;
  }
}
