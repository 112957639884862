import { createDefaultLoadable, Loadable } from '@Mesh/utils/loadable/loadable';
import { IPlanGroup } from '@Mesh/core/services/plan.service';
import { Plan } from '@Mesh/core/models/plan';

export interface PlanState extends Loadable {
  data: IPlanGroup[];
  active: Plan;
  errors: any[] | null;
}

export const initialState: PlanState = {
  ...createDefaultLoadable(),
  data: [],
  active: null,
  errors: null,
};
