import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { User } from '@Mesh/core/models/user';

@Component({
  selector: 'app-chat-user-record',
  templateUrl: './chat-user-record.component.html',
  styleUrls: ['./chat-user-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatUserRecordComponent implements OnInit {
  @Input('user') user: User;
  @Input('first_text') first_text: string;
  @Input('second_text') second_text: string;

  constructor() {}

  ngOnInit() {}
}
