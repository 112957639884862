import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { IMAGES_URL } from '../../../../../../environments/environment';
import { Product, SalePlans } from '../../../../../core/models/product';
import { CatalogHelper } from '../../../../helpers/catalog.helper';

@Component({
  selector: 'app-chat-task-product-item',
  templateUrl: './chat-task-product-item.component.html',
  styleUrls: ['./chat-task-product-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ChatTaskProductItemComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ChatTaskProductItemComponent,
    },
  ],
})
export class ChatTaskProductItemComponent implements ControlValueAccessor, Validator {
  @Input() product: Product;
  @Input() count: number = 1;
  readonly IMAGES_URL = IMAGES_URL;
  quantity = 0;
  @Input() min = 0;
  @Input() max: number;
  constructor(private catalogHelper: CatalogHelper) {}

  onChange = (quantity) => {};

  onTouched = () => {};

  touched = false;

  @Input() disabled = false;

  onAdd() {
    this.markAsTouched();
    if (!this.disabled) {
      this.quantity += this.count;
      this.onChange(this.quantity);
    }
  }

  onRemove() {
    this.markAsTouched();
    if (!this.disabled) {
      this.quantity -= this.count;
      this.onChange(this.quantity);
    }
  }

  setQuantity() {
    this.onChange(this.quantity);
  }

  writeValue(quantity: number) {
    this.quantity = quantity;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const quantity = control.value;
    if (quantity <= 0) {
      return {
        mustBePositive: {
          quantity,
        },
      };
    }
  }

  getShortPlanName(salePlan: SalePlans) {
    return this.catalogHelper.getShortPlanName(salePlan);
  }

  filteredPlans(salePlans: SalePlans[]) {
    return this.catalogHelper.filteredPlans(salePlans);
  }

  getLabelBg(planId: number) {
    return this.catalogHelper.getLabelBg(planId);
  }
}
