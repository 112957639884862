import { Action } from '@ngrx/store';
import { DelayedOrderCart } from '../../core/models/delayed-order';
import { Product, Stock } from '../../core/models/product';

export enum OrderCartActionsTypes {
  LoadCart = '[OrderCart] Load cart',
  LoadCartSuccess = '[OrderCart] Load cart success',
  LoadCartFailure = '[OrderCart] Load cart failure',

  LoadCartProductsSuccess = '[OrderCart] Load cart products success',

  UpdateCart = '[OrderCart] Update cart',
  UpdateCartSuccess = '[OrderCart] Update cart success',
  UpdateCartFailure = '[OrderCart] Update cart failure',

  DeleteProduct = '[OrderCart] Delete product',
  DeleteProductSuccess = '[OrderCart] Delete product success',
  AddProduct = '[OrderCart] Add product',
  DecrementProduct = '[OrderCart] Decrement product',
  ChangeAmount = '[OrderCart] Change amount',

  SetStock = '[OrderCart] Set stock',

  PageProductsLoaded = '[OrderCart] Page products loaded',

  Empty = '[OrderCart] Empty action',

  ResetCart = '[OrderCart] ResetCart',

  IncrementProductInCart = '[OrderCart] Add product from cart',
  DecrementProductInCart = '[OrderCart] Decrement product in cart',
}

export class LoadCart implements Action {
  readonly type = OrderCartActionsTypes.LoadCart;
}
export class LoadCartSuccess implements Action {
  readonly type = OrderCartActionsTypes.LoadCartSuccess;

  constructor(public payload: DelayedOrderCart[]) {}
}
export class LoadCartFailure implements Action {
  readonly type = OrderCartActionsTypes.LoadCartFailure;
}

export class LoadCartProductsSuccess implements Action {
  readonly type = OrderCartActionsTypes.LoadCartProductsSuccess;

  constructor(public payload: Product[]) {}
}

export class UpdateCart implements Action {
  readonly type = OrderCartActionsTypes.UpdateCart;

  constructor(public body: Partial<DelayedOrderCart>) {}
}
export class UpdateCartSuccess implements Action {
  readonly type = OrderCartActionsTypes.UpdateCartSuccess;

  constructor(public cartItem: DelayedOrderCart) {}
}
export class UpdateCartFailure implements Action {
  readonly type = OrderCartActionsTypes.UpdateCartFailure;
}

export class DeleteProduct implements Action {
  readonly type = OrderCartActionsTypes.DeleteProduct;

  constructor(public cartItem: any) {}
}

export class DeleteProductSuccess implements Action {
  readonly type = OrderCartActionsTypes.DeleteProductSuccess;

  constructor(public payload: { materialId: number; unit: string }) {}
}

export class ResetCart implements Action {
  readonly type = OrderCartActionsTypes.ResetCart;
  constructor() {}
}
export class AddProduct implements Action {
  readonly type = OrderCartActionsTypes.AddProduct;

  constructor(public payload: { product: { id: number; materialId: number } }) {}
}
export class DecrementProduct implements Action {
  readonly type = OrderCartActionsTypes.DecrementProduct;

  constructor(public payload: { product: { id: number; materialId: number } }) {}
}
export class ChangeAmount implements Action {
  readonly type = OrderCartActionsTypes.ChangeAmount;

  constructor(public payload: { product: { id: number; materialId: number }; amount: number }) {}
}

export class IncrementProductInCart implements Action {
  readonly type = OrderCartActionsTypes.IncrementProductInCart;

  constructor(public payload: { id: number; materialId: number; unit: string; distributorName: string }) {}
}
export class DecrementProductInCart implements Action {
  readonly type = OrderCartActionsTypes.DecrementProductInCart;

  constructor(public payload: { id: number; materialId: number; unit: string; distributorName: string }) {}
}

export class SetStock implements Action {
  readonly type = OrderCartActionsTypes.SetStock;

  constructor(public payload: { id: number; stock: Stock }) {}
}

export class PageProductsLoaded implements Action {
  readonly type = OrderCartActionsTypes.PageProductsLoaded;

  constructor(public products: Product[]) {}
}

export class EmptyAction implements Action {
  readonly type = OrderCartActionsTypes.Empty;
}

export type OrderCartActionsUnion =
  | LoadCartSuccess
  | LoadCartProductsSuccess
  | UpdateCartSuccess
  | DeleteProduct
  | DeleteProductSuccess
  | AddProduct
  | DecrementProduct
  | ChangeAmount
  | IncrementProductInCart
  | DecrementProductInCart
  | SetStock
  | PageProductsLoaded
  | EmptyAction
  | ResetCart;
