import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { News } from '@Mesh/core/models/news';

@Component({
  selector: 'app-news-social',
  templateUrl: './news-social.component.html',
  styleUrls: ['./news-social.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state(
        'true',
        style({
          height: '500px',
          // "height": 'auto',
          'padding-top': '32px',
          'padding-bottom': '20px',
        })
      ),
      state('false', style({ height: '0px' })),
      transition('false <=> true', animate('0.3s')),
    ]),
  ],
})
export class NewsSocialComponent implements OnInit {
  _news: any;
  watched: boolean = false;
  _action: boolean = true;
  @Output() likeEvent: EventEmitter<any> = new EventEmitter();
  @Output() commentClickedEvent: EventEmitter<any> = new EventEmitter();
  @Output() stateChange = new EventEmitter<{ state: 'startOpen' | 'startClose' | 'endOpen' | 'endClose' }>();
  catchEvent({ phaseName, toState, fromState }) {
    if (phaseName === 'start') {
      this.stateChange.emit({ state: toState ? 'startOpen' : 'startClose' });
    } else if (phaseName === 'done') {
      this.stateChange.emit({ state: toState ? 'endOpen' : 'endClose' });
    }
  }

  @Input()
  set action(value: boolean) {
    this._action = value;
  }

  get action(): boolean {
    return this._action;
  }

  @Input()
  set news(value: any) {
    this._news = value;
    this.cdr.detectChanges();
  }

  get news(): any {
    return this._news;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  like() {}

  commentClicked() {}
}
