import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { financeFeatureKey, financeReducer } from '@Mesh/store/finance/finance.reducer';
import { EffectsModule } from '@ngrx/effects';
import { FinanceEffects } from '@Mesh/store/finance/finance.effects';

@NgModule({
  imports: [StoreModule.forFeature(financeFeatureKey, financeReducer), EffectsModule.forFeature([FinanceEffects])],
})
export class FinanceStoreModule {}
