import { DelayedOrderCart } from '../../core/models/delayed-order';
import { Product, Stock } from '../../core/models/product';
import { OrderCartActionsTypes, OrderCartActionsUnion } from './order-cart.actions';

export const cartFeatureKey = 'order-cart';

export interface OrderCartState {
  cart: DelayedOrderCart[];
  cartProducts: Product[];
  cartStockMap: Record<number, Stock>; // product id, Stock
}

const initialState: OrderCartState = {
  cart: [],
  cartProducts: [],
  cartStockMap: {},
};

export function cartReducer(state = initialState, action: OrderCartActionsUnion): OrderCartState {
  switch (action.type) {
    case OrderCartActionsTypes.LoadCartSuccess:
      return {
        ...state,
        cart: [...action.payload],
      };
    case OrderCartActionsTypes.LoadCartProductsSuccess:
      return {
        ...state,
        cartProducts: action.payload,
      };
    case OrderCartActionsTypes.UpdateCartSuccess:
      const tempUpdateCart = [...state.cart];
      const updatedItemIndex = tempUpdateCart.findIndex(
        (el) =>
          el.materialId === action.cartItem.materialId &&
          el.unit === action.cartItem.unit &&
          el.distributorCode === action.cartItem.distributorCode
        // &&
        // el.distributorName === action.cartItem.distributorName &&
        // el.salesOrgSapId === action.cartItem.salesOrgSapId &&
        // el.ndsPrice === action.cartItem.ndsPrice
      );
      if (updatedItemIndex === -1) {
        tempUpdateCart.push({ ...(action.cartItem as DelayedOrderCart) });
      } else {
        if (action.cartItem.quantity) {
          tempUpdateCart[updatedItemIndex] = { ...(action.cartItem as DelayedOrderCart) };
        } else {
          tempUpdateCart.splice(updatedItemIndex, 1);
        }
      }

      console.log(tempUpdateCart);
      return {
        ...state,
        cart: [...tempUpdateCart],
      };

    case OrderCartActionsTypes.DeleteProductSuccess:
      const tempRemoveCart = [...state.cart];
      const indexToRemove = tempRemoveCart.findIndex(
        (el) => el.materialId === action.payload.materialId && el.unit === action.payload.unit
      );
      tempRemoveCart.splice(indexToRemove, 1);
      return {
        ...state,
        cart: [...tempRemoveCart],
      };

    case OrderCartActionsTypes.SetStock:
      const obj = { ...state.cartStockMap };
      obj[action.payload.id] = action.payload.stock;
      return {
        ...state,
        cartStockMap: { ...obj },
      };

    case OrderCartActionsTypes.PageProductsLoaded:
      const stockObj: Record<number, Stock> = {};
      action.products
        .filter((el) => !!el)
        .forEach((product) => {
          if (!stockObj[product.id]) {
            stockObj[product.id] = product.stock.sort((a, b) =>
              a.baseQuantUnit !== b.baseQuantUnit ? (a.baseQuantUnit < b.baseQuantUnit ? -1 : 1) : 0
            )[0];
          }
        });
      return {
        ...state,
        cartStockMap: stockObj,
        cartProducts: action.products,
      };

    case OrderCartActionsTypes.ResetCart:
      return {
        ...state,
        cart: [],
      };

    default:
      return state;
  }
}
