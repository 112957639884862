import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NotificationsData, Notification, NotificationRequest } from '../../../../core/models';
import { AppState } from '../../../../store/app.state';
import { loginSelectors } from '../../../../store/login/login.selectors';
import { NotificationsLoadRequest } from '../../../../store/notification/notification.actions';
import { notificationSelectors } from '../../../../store/notification/notification.selectors';

@Component({
  selector: 'app-chat-notifications-list',
  templateUrl: './chat-notifications-list.component.html',
  styleUrls: ['./chat-notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatNotificationsListComponent implements OnInit {
  notificationsData$: Observable<NotificationsData>;
  notifications$: Observable<Notification[]>;
  page: number = 1;
  user: any;
  user$: Observable<any>;

  constructor(readonly store: Store<AppState>) {}

  ngOnInit() {
    this.notificationsData$ = this.store.pipe(select(notificationSelectors.getNotificationsData));
    this.notifications$ = this.store.pipe(select(notificationSelectors.getNotificationsContent));
    this.user$ = this.store.pipe(select(loginSelectors.selectUser));
    this.user$.subscribe((user) => {
      this.user = user;
      this.loadNotification();
    });
  }

  loadNotification() {
    const params: NotificationRequest = {
      userId: this.user.id,
      size: 10,
      page: this.page,
      sort: 'id',
      direction: 'desc',
    };
    return this.store.dispatch(new NotificationsLoadRequest({ params, refresh: false }));
  }

  onScroll() {
    this.page = this.page + 1;
    this.loadNotification();
  }
}
