import * as moment from 'moment';
import { Component, OnInit, ChangeDetectionStrategy, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { loginSelectors } from '@Mesh/store/login/login.selectors';
import { notificationSelectors } from '@Mesh/store/notification/notification.selectors';
import { NotificationRequest, NotificationTokenRequest } from '@Mesh/core/models';
import { AuthenticationService } from '@Mesh/core/services';
import { NotificationsLoadRequest, ReadNotificationRequest, UpdatePushTokenRequest } from '@Mesh/store/notification/notification.actions';
import { WebsocketService } from '@Mesh/core/services/websocket.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
@Component({
  selector: 'iql-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit, OnDestroy {
  // notifications$ = of(data);
  user$ = this.store.pipe(select(loginSelectors.selectUser));
  notifications$ = this.store.pipe(select(notificationSelectors.getNotificationsData));
  content$ = this.store.pipe(select(notificationSelectors.getNotificationsContent));
  page = 0;
  size: 10;
  opened: boolean = false;
  destroy$ = new Subject();
  user;

  constructor(
    private eref: ElementRef,
    readonly store: Store<AppState>,
    private angularFireMessaging: AngularFireMessaging,
    private authService: AuthenticationService,
    private websocketService: WebsocketService
  ) {}

  openNotification(notification: Notification) {}

  read(id: number) {
    return this.store.dispatch(new ReadNotificationRequest({ id }));
  }

  dateAtFromNow(date) {
    if (date) {
      return moment.utc(date).from(moment.now());
    }
  }

  trunc(str: string, length: number) {
    return str.substr(0, length - 1) + (str.length > length ? '...' : '');
  }

  getNotificationIcon(entityType) {
    return {
      report: '/assets/img/notifications/document.png',
      support: '/assets/img/notifications/chat.png',
      'users-chat': '/assets/img/notifications/chat.png',
      user: '/assets/img/notifications/user.png',
      'task-comments': '/assets/img/notifications/user.png',
      bonus: '/assets/img/notifications/bonus.png',
    }[entityType];
  }

  @HostListener('document:click', ['$event'])
  documentClick(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.opened = false;
    }
  }

  toggle() {
    this.opened = !this.opened;
  }

  loadNotification() {
    const params: NotificationRequest = {
      userId: this.user.id,
      size: 10,
      page: this.page,
      sort: 'id',
      direction: 'desc',
    };
    return this.store.dispatch(new NotificationsLoadRequest({ params, refresh: false }));
  }

  onScroll() {
    this.page = this.page + 1;
    this.loadNotification();
  }

  ngOnInit() {
    this.user$.subscribe((user) => {
      this.user = user;
      this.loadNotification();

      this.angularFireMessaging.messages.subscribe((payload) => {
        console.error('new message received. ', payload);
      });
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          const params = {
            userId: user.id,
            deviceId: 'web',
            token,
          } as NotificationTokenRequest;
          this.store.dispatch(new UpdatePushTokenRequest({ params }));
        },
        (err) => {
          console.error('Unable to get permission to notify.', err);
        }
      );
      this.angularFireMessaging.messaging.subscribe((_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      });
      this.websocketService.connect();
      this.websocketService.messagesSubject$
        .pipe(
          filter(({ entityType }) => entityType === 'NOTIFICATION'),
          takeUntil(this.destroy$)
        )
        .subscribe((notification) => {
          console.log(notification);
          setTimeout(() => {
            this.page = 0;
            const params: NotificationRequest = {
              userId: user.id,
              size: 10,
              page: 0,
              sort: 'id',
              direction: 'desc',
            };
            return this.store.dispatch(new NotificationsLoadRequest({ params, refresh: true }));
          }, 500);
        });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
