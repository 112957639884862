import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'task-item-empty',
  templateUrl: './task-item-empty.component.html',
  styleUrls: ['./task-item-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskItemEmptyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
