import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { taskReducer } from '@Mesh/store/task/task.reducers';
import { TaskEffects } from '@Mesh/store/task/task.effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [StoreModule.forFeature('task', taskReducer), EffectsModule.forFeature([TaskEffects])],
})
export class TaskStoreModule {}
