import { NgModule, ModuleWithProviders } from '@angular/core';
import { WindowFocusService } from './window-focus.service';

@NgModule({})
export class WindowFocusModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: WindowFocusModule,
      providers: [WindowFocusService],
    };
  }
}
