import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  isDevMode,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { pagesToggleService } from '../../services/toggler.service';
import { QuickviewService } from './quickview.service';
import { Notifications, NotService } from '@Mesh/core/services';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

declare var pg: any;

@Component({
  selector: 'pg-quickview',
  templateUrl: './quickview.component.html',
  styleUrls: ['./quickview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickviewComponent2 implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  subscriptions: Array<Subscription> = [];
  private _isOpen: boolean = false;

  get isOpen(): boolean {
    return this._isOpen;
  }

  ngAfterViewChecked(): void {
    // console.log('parent - 0');
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  set isOpen(value: boolean) {
    this._isOpen = value;
    this.toggler.setQuickviewBodyLayoutClass(value);
    console.log('why?');
    this.cdr.detectChanges();
    if (!value) {
      const queryParams = {
        action: null,
        action_type: null,
        action_id: null,
        sent_by: null,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      });
    }
  }

  constructor(
    private _service: QuickviewService,
    private toggler: pagesToggleService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotService
  ) {}

  get isDevMode() {
    return isDevMode();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.toggler.quickViewToggle.subscribe((message) => {
        console.log('toggle:', message);
        this.toggle();
      })
    );
    this.subscriptions.push(
      this.notificationService.subscribe(Notifications.MenuOpened, {
        next: (val) => {
          if (val && window.innerWidth <= 600 && this.isOpen) {
            this.isOpen = false;
          }
        },
      })
    );

    this.route.queryParams
      .filter((params) => params.action)
      .subscribe((params) => {
        if (params.action === 'chat') {
          if (!this.isOpen) {
            this.toggler.toggleQuickView();
          }
        }
      });
  }

  closeQuickview() {
    if (this.isOpen) {
      this.toggler.toggleQuickView();
    }
  }

  toggle() {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
    this.cdr.detectChanges();
    // console.log(this.isOpen);
  }
}
