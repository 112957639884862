import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { MinimalCartRuleGroupedStatus } from '../../../../store/cart/cart.models';

@Component({
  selector: 'app-minimal-card-rules',
  templateUrl: './minimal-card-rules.component.html',
  styleUrls: ['./minimal-card-rules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MinimalCardRulesComponent {
  @Input() minimalCartRulesGroupStatus: MinimalCartRuleGroupedStatus;
}
