import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatTaskActionsComponent } from './chat-task-actions.component';
import { ChatTaskProductModule } from '../../chat-task-product/chat-task-product.module';
import { ChatTaskModerationComponent } from './chat-task-moderation/chat-task-moderation.component';
import { ChatTaskLeftoverProductsComponent } from './chat-task-leftover-products/chat-task-leftover-products.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ChatTaskSuccessComponent } from './chat-task-success/chat-task-success.component';
import { MinuteSecondsPipe } from './chat-task-moderation/minute-seconds.pipe';

@NgModule({
  declarations: [
    ChatTaskActionsComponent,
    ChatTaskModerationComponent,
    ChatTaskLeftoverProductsComponent,
    ChatTaskSuccessComponent,
    MinuteSecondsPipe,
  ],
  exports: [ChatTaskActionsComponent],
  imports: [CommonModule, ChatTaskProductModule, ReactiveFormsModule],
})
export class ChatTaskActionsModule {}
