import { Outlet, OutletStatistic } from '@Mesh/core/models';
import { createDefaultLoadable, Loadable } from '@Mesh/utils/loadable/loadable';

export interface OutletState extends Loadable {
  data: Outlet[] | [];
  active: Outlet | null;
  errors: any[] | null;
  statistic: OutletStatistic | null;
  totalOutletsPages: number;
  totalCount: number;
}

export const initialState: OutletState = {
  ...createDefaultLoadable(),
  data: [],
  active: null,
  errors: null,
  statistic: null,
  totalOutletsPages: 0,
  totalCount: 0,
};
