import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessScreenComponent } from './success-screen.component';
import { SuccsesOrderInvoceComponent } from './succses-order-invoce/succses-order-invoce.component';

@NgModule({
  declarations: [SuccessScreenComponent, SuccsesOrderInvoceComponent],
  exports: [SuccessScreenComponent],
  imports: [CommonModule],
})
export class SuccessScreenModule {}
