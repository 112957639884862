import { withLoadable } from '@Mesh/utils/loadable/with-loadable';
import { ActionsUnion, BonusActionsTypes } from '@Mesh/store/bonus/bonus.actions';
import { BonusState, initialState } from '@Mesh/store/bonus/bonus.state';

function baseBonusReducer(state = initialState, action: ActionsUnion): BonusState {
  switch (action.type) {
    case BonusActionsTypes.ClientBonusLoadSuccess:
      return {
        ...state,
        clientBonusData: action.payload.data,
      };
    case BonusActionsTypes.ClientBonusLoadError:
      return {
        ...state,
        error: action.error,
      };
    case BonusActionsTypes.ClientBonusPredictLoadSuccess:
      return {
        ...state,
        clientBonusPredict: action.payload.data,
      };
    case BonusActionsTypes.ClientBonusPredictTotalAmountLoadSuccess:
      return {
        ...state,
        clientBonusPredictTotalAmount: action.payload.total,
      };
    case BonusActionsTypes.ClientBonusPredictLoadError:
      return {
        ...state,
        error: action.error,
      };
    case BonusActionsTypes.ClientsBonusByPeriodLoadSuccess:
      return {
        ...state,
        clientsBonusByPeriodData: action.payload.data,
      };
    case BonusActionsTypes.ClientsBonusByPeriodLoadError:
      return {
        ...state,
        error: action.error,
      };
    case BonusActionsTypes.OutletBonusesLoadSuccess:
      return {
        ...state,
        outletBonusesData: action.payload.data.filter((bonus) => !!bonus),
        outletBonusesSum: action.payload.bonusSum,
      };
    case BonusActionsTypes.OutletBonusesLoadError:
      return {
        ...state,
        error: action.error,
      };
    case BonusActionsTypes.OutletBonusesPredictLoadSuccess:
      return {
        ...state,
        outletBonusesPredictData: action.payload.data.filter((bonus) => !!bonus),
        outletBonusesPredictSum: action.payload.bonusPredictSum,
      };
    case BonusActionsTypes.OutletBonusesPredictLoadError:
      return {
        ...state,
        error: action.error,
      };
    case BonusActionsTypes.OutletBonusPredictLoadSuccess:
      return {
        ...state,
        outletBonusPredictData: action.payload.data,
      };
    case BonusActionsTypes.OutletBonusPredictLoadError:
      return {
        ...state,
        error: action.error,
      };
    case BonusActionsTypes.OutletAwaitingSumLoadRequestSuccess:
      return {
        ...state,
        outletAwaitingSum: action.payload.data,
      };
    case BonusActionsTypes.OutletAwaitingSumLoadRequestError:
      return {
        ...state,
        error: action.error,
      };
    case BonusActionsTypes.SetActiveBonus:
      return {
        ...state,
        activeBonusId: action?.payload?.bonus?.planId || null,
      };
    case BonusActionsTypes.SetActiveBonusAndPredictBonusByPlanId:
      return {
        ...state,
        activeBonusId: action.payload.planId,
        activePredictBonusId: action.payload.planId,
      };

    case BonusActionsTypes.SelectedUserBonusBalanceLoadSuccess:
      return {
        ...state,
        selectedUserBalance: action.payload.bonus,
      };
    case BonusActionsTypes.SelectedUserBonusBalanceLoadError:
      return {
        ...state,
        error: action.error,
      };
    case BonusActionsTypes.SelectedUserBonusBalanceReset:
      return {
        ...state,
        selectedUserBalance: 0,
        error: null,
      };
    default:
      return state;
  }
}

export function bonusReducer(state: BonusState, action: ActionsUnion): BonusState {
  return withLoadable(baseBonusReducer, {
    loadingActionType: [
      BonusActionsTypes.ClientBonusLoadRequest,
      BonusActionsTypes.ClientBonusPredictLoadRequest,
      BonusActionsTypes.ClientsBonusByPeriodLoadRequest,
      BonusActionsTypes.OutletBonusPredictLoadRequest,
      BonusActionsTypes.OutletBonusesLoadRequest,
      BonusActionsTypes.OutletBonusesPredictLoadRequest,
    ],
    successActionType: [
      BonusActionsTypes.ClientBonusLoadSuccess,
      BonusActionsTypes.ClientBonusPredictLoadSuccess,
      BonusActionsTypes.ClientsBonusByPeriodLoadSuccess,
      BonusActionsTypes.OutletBonusPredictLoadSuccess,
      BonusActionsTypes.OutletBonusesLoadSuccess,
      BonusActionsTypes.OutletBonusesPredictLoadSuccess,
    ],
    errorActionType: [
      BonusActionsTypes.ClientBonusLoadError,
      BonusActionsTypes.ClientBonusPredictLoadError,
      BonusActionsTypes.ClientsBonusByPeriodLoadError,
      BonusActionsTypes.OutletBonusPredictLoadError,
      BonusActionsTypes.OutletBonusesLoadError,
      BonusActionsTypes.OutletBonusesPredictLoadError,
    ],
  })(state, action);
}
