export interface NotificationTokenRequest {
  userId: number;
  deviceId: string;
  token: string;
}

export interface NotificationRequest {
  userId: number;
  size: number;
  page: number;
  sort?: string;
  direction?: string;
}

export interface WebsocketEvent {
  id: number;
  title: string;
  clickAction: string;
  image: string;
  body: string;
  entityId: string;
  entityType: string;
  payload: any;
  createdAt: string;
  updatedAt: string;
  readAt: string;
  opened?: boolean;
}

export interface Notification {
  id: number;
  title: string;
  clickAction: string;
  image: string;
  body: string;
  entityId: string;
  entityType: string;
  payload: any;
  createdAt: string;
  updatedAt: string;
  readAt: string;
  opened?: boolean;
  timeout?: boolean;
  popId?: number;
}

export interface NotificationsData {
  content: Notification[];
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  number: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  size: number;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface NotificationToken {
  id: number;
  userId: number;
  deviceId: string;
  token: string;
}

export enum PostingStatus {
  Initial = 'Initial',
  Posting = 'Posting',
  Posted = 'Posted',
  Error = 'Error',
}
