import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { RecommendOrderBonus } from '../../../../store/order/order.model';

@Component({
  selector: 'app-basket-recommend-order',
  templateUrl: './basket-recommend-order.component.html',
  styleUrls: ['./basket-recommend-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketRecommendOrderComponent implements OnInit {
  @Input() recommendedOrder: RecommendOrderBonus;
  @Input() active: boolean;
  @Output() activeChanges = new EventEmitter<{ active: boolean; recommendedOrderId: number }>();
  @Output() updatedBasketToOrder = new EventEmitter<any>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  onToggle(): void {
    this.active = !this.active;
    this.activeChanges.emit({ active: this.active, recommendedOrderId: this.recommendedOrder.id });
    this.cdr.markForCheck();
  }

  onUpdateBasketToOrder(): void {
    this.updatedBasketToOrder.emit(this.recommendedOrder.id);
  }
}
