import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material';
import { TreeWrapperComponent } from './wrappers/tree-wrapper';
import { MatTreeModule } from '@angular/material/tree';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@Mesh/@pages/components/shared.module';

const WRAPPERS = [TreeWrapperComponent];

@NgModule({
  imports: [MatTreeModule, MatIconModule, CommonModule, SharedModule],
  exports: [MatTreeModule, MatIconModule, ...WRAPPERS, SharedModule],
  declarations: [...WRAPPERS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MaterialModule {}
