import { NgModule, ModuleWithProviders } from '@angular/core';
import { TitleService } from './title.service';

@NgModule({})
export class TitleModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: TitleModule,
      providers: [TitleService],
    };
  }
}
