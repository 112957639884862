import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ResizeHelper } from '@Mesh/shared/helpers/resize.helper';

@Injectable()
export class ScrollService {
  private readonly mobileHeaderExpandedSubject$ = new BehaviorSubject(true);
  readonly mobileHeaderExpanded$ = this.mobileHeaderExpandedSubject$.asObservable().pipe(
    distinctUntilChanged((prev, curr) => prev === curr),
    debounceTime(50)
  );

  private readonly desktopHeaderExpandedSubject$ = new BehaviorSubject(true);
  readonly desktopHeaderExpanded$ = this.desktopHeaderExpandedSubject$.asObservable().pipe(
    distinctUntilChanged((prev, curr) => prev === curr),
    debounceTime(300)
  );

  private desktopBreakpoint = 10;
  private mobileBreakpoint = 100;

  constructor(private resize: ResizeHelper) {}

  onScroll() {
    if (this.resize.mobileMode) {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      // const breakpoint = this.filtersElement?.nativeElement?.clientHeight || this.filtersElement?.nativeElement?.offsetHeight;
      if (scrollTop >= this.mobileBreakpoint && this.mobileHeaderExpandedSubject$.value) {
        this.mobileHeaderExpandedSubject$.next(false);
      } else if (scrollTop < this.mobileBreakpoint && !this.mobileHeaderExpandedSubject$.value) {
        this.mobileHeaderExpandedSubject$.next(true);
      }
    } else {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      // const breakpoint = this.filtersElement?.nativeElement?.clientHeight || this.filtersElement?.nativeElement?.offsetHeight;
      if (scrollTop >= this.desktopBreakpoint && this.desktopHeaderExpandedSubject$.value) {
        this.desktopHeaderExpandedSubject$.next(false);
      } else if (scrollTop < this.desktopBreakpoint && !this.desktopHeaderExpandedSubject$.value) {
        this.desktopHeaderExpandedSubject$.next(true);
      }
    }
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
