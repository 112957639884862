import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasketHeaderComponent } from './basket-header.component';
import { MinimalCardRulesModule } from '../../components/minimal-card-rules/minimal-card-rules.module';

@NgModule({
  declarations: [BasketHeaderComponent],
  exports: [BasketHeaderComponent],
  imports: [CommonModule, MinimalCardRulesModule],
})
export class BasketHeaderModule {}
