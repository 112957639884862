import { Injectable, isDevMode } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { LocalStorageService } from '@Mesh/core/services/local-storage';

import { AppState } from '@Mesh/store/app.state';
import { loginSelectors } from '@Mesh/store/login/login.selectors';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private store: Store<AppState>, private localStorage: LocalStorageService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(select(loginSelectors.selectIsAuthenticated)).map((value) => {
      if (!value) {
        console.log('redirect');
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
      }
      return value;
    });
  }
}
