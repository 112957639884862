import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ChatService } from '@Mesh/shared/modules/chat/chat.service';
import { ChatDialogService } from '@Mesh/shared/modules/chat/chat-dialog/chat-dialog.service';
import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { Outlet } from '@Mesh/core/models/outlet';
import { ChatDialog } from '../models/chat-dialog';

@Component({
  selector: 'app-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatHeaderComponent implements OnInit {
  @Input() name: string;
  @Input() icon: string;
  @Input() status: string;
  @Input() dialog: ChatDialog;
  @Input() nameClickable: boolean;
  private _sticky: boolean;
  @Output() onBack = new EventEmitter();
  @Output() onNameClick = new EventEmitter();
  outlet: Outlet;

  get sticky(): boolean {
    return this._sticky;
  }

  @Input()
  set sticky(value: boolean) {
    this._sticky = value;
    this.cdr.detectChanges();
  }

  nameClick() {
    this.onNameClick.emit();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private chatService: ChatService,
    private feathersService: FeathersService,
    private chatDialogService: ChatDialogService
  ) {}

  ngOnInit() {
    console.log('dialog:', this.dialog);
  }
}
