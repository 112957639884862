import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { User } from '@Mesh/core/models/user';
import { trigger } from '@angular/animations';
import { fadeIn, fadeOut } from '@Mesh/utils/animations/fade-animations';
import { IMAGES_URL } from '@Env/environment';
import { select, Store } from '@ngrx/store';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { Observable } from 'rxjs';
import { Outlet, OutletStatistic } from '@Mesh/core/models';
import { AppState } from '@Mesh/store/app.state';
import { OutletState } from '@Mesh/store/outlet/outlet.state';
import { OutletSetActive } from '@Mesh/store/outlet/outlet.actions';
@Component({
  selector: 'app-emodji',
  templateUrl: './emodji.component.html',
  styleUrls: ['./emodji.component.scss', './ghost/ghost-item.component.scss'],
  animations: [trigger('fadeOut', fadeOut()), trigger('fadeIn', fadeIn())],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EmodjiComponent implements OnInit {
  @ViewChild('userStatus') userStatus: ElementRef;
  _user: User;
  activeOutlet$: Observable<Outlet>;
  outlets$: Observable<OutletState>;
  stats$: Observable<OutletStatistic>;
  selectedOption;

  readonly IMAGES_URL = IMAGES_URL;

  constructor(private store: Store<AppState>) {}

  @Input()
  set user(value: User) {
    this._user = value;
  }

  get user() {
    return this._user;
  }

  ngOnInit() {
    this.outlets$ = this.store.pipe(select(outletSelectors.selectOutlet));
    this.stats$ = this.store.pipe(select(outletSelectors.selectOutletStatus));
    console.log('USER', this._user);
  }

  onChangeOutlet($event: Outlet): void {
    this.store.dispatch(new OutletSetActive({ active: $event }));
  }
}
