import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delayed-order-notify-drop',
  templateUrl: './delayed-order-notify-drop.component.html',
  styleUrls: ['./delayed-order-notify-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelayedOrderNotifyDropComponent implements OnInit {
  @Output() canceled: EventEmitter<boolean> = new EventEmitter();
  @Output() droped: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onDrop() {
    this.droped.emit();
  }

  onCancel() {
    this.canceled.emit();
  }
}
