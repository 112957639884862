import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import {
  AddProduct,
  CartActionsTypes,
  ChangeAmount,
  DecrementProduct,
  DecrementProductInCart,
  DeleteProduct,
  DeleteProductFailure,
  DeleteProductSuccess,
  EmptyAction,
  FillCartsByFileCurrent,
  FillCartsByFileCurrentFailure,
  FillCartsByFileCurrentSuccess,
  FillCartsByFileProgress,
  FillCartsByFileProgressFailure,
  FillCartsByFileProgressReset,
  FillCartsByFileProgressSuccess,
  FillUpCartsMinimalLimit,
  FillUpCartsMinimalLimitFailure,
  FillUpCartsMinimalLimitSuccess,
  HandleError409,
  IncrementProductInCart,
  LoadCart,
  LoadCartCategoryTree,
  LoadCartCategoryTreeFailure,
  LoadCartCategoryTreeSuccess,
  LoadCartFailure,
  LoadCartProductsFailure,
  LoadCartProductsSuccess,
  LoadCartSuccess,
  LoadCartWithRecommendedSuccess,
  LoadDeliveryDate,
  LoadDeliveryDateFailure,
  LoadDeliveryDateSuccess,
  LoadHistoryOrderItemsFailure,
  LoadHistoryOrderItemsSuccess,
  LoadMinimalCartRuleGroupedStatus,
  LoadMinimalCartRuleGroupedStatusFailure,
  LoadMinimalCartRuleGroupedStatusSuccess,
  LoadMinimalCartRulesByCategory,
  LoadMinimalCartRulesByCategoryFailure,
  LoadMinimalCartRulesByCategorySuccess,
  LoadRecommendedOrderInCart,
  LoadRecommendedOrderInCartFailure,
  LoadRecommendedOrderInCartSuccess,
  LoadTaskRecommendedOrder,
  LoadTaskRecommendedOrderFailure,
  LoadTaskRecommendedOrderSuccess,
  ResetCartValue,
  SetOrderSubmitState,
  SubmitCart,
  SubmitCartFailure,
  SubmitCartSuccess,
  ToggleProductFavorite,
  ToggleProductFavoriteFailure,
  ToggleProductFavoriteSuccess,
  UpdateCart,
  UpdateCartFailure,
  UpdateCartSuccess,
  UpdateRecommendedOrderInCart,
  UpdateRecommendedOrderInCartFailure,
  UpdateRecommendedOrderInCartSuccess,
} from '@Mesh/store/cart/cart.actions';
import { catchError, concatMap, defaultIfEmpty, filter, first, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { CartService } from '@Mesh/pages/catalog/services/cart.service';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { combineLatest, Observable, of } from 'rxjs';
import { DataService, TaskService } from '@Mesh/core/services';
import { ProductCriteria } from '@Mesh/core/models/request/product-criteria';
import {
  CartItemType,
  CartRuleGroupedStatus,
  DeleteCartItemCriteria,
  MinimalCartRulesCriteria,
  UpdateCartItem,
} from '@Mesh/store/cart/cart.models';
import { CartActions, CartSelectors } from '@Mesh/store/cart/index';
import {
  selectCartItemByMaterialIdAndUnit,
  selectCartItemByMaterialIdAndUnitAndDistributorName,
  selectCurrentRecommendedOrderId,
  selectSelectedStockByProductId,
  selectStockByProductIdUnit,
  selectStockByProductIdUnitDistributor,
} from '@Mesh/store/cart/cart.selectors';
import { loginSelectors } from '@Mesh/store/login/login.selectors';
import { bonusSelectors } from '../bonus';
import { OutletSetActive, OutletSetActiveId, OutletActionsTypes } from '../outlet/outlet.actions';
import { ResetHistoryTaskValue } from '../task/task.actions';
import { OrderHistoryItemsCriteria } from '../order/order.model';
import { OrderService } from '../../core/services/order.service';
import { OrderStatus } from '../../core/models';
import moment from 'moment';
import { getGetStartDateAndFinishDate } from '../../core/services/util';
import { LoadBonusesRecommendedOrders } from '../order/order.actions';

@Injectable()
export class CartEffects {
  @Effect()
  loadCart$ = this.actions$.pipe(
    ofType<LoadCart>(CartActionsTypes.LoadCart),
    withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(loginSelectors.selectUserId)),
    switchMap(([, outlet, userId]) => {
      return this.cartService.loadCart(outlet.addressSapId, { userId }).pipe(
        map((data) => {
          return new LoadCartSuccess(data);
        }),
        catchError(() => {
          return of(new LoadCartFailure());
        })
      );
    })
  );

  @Effect()
  loadCartProducts$ = this.actions$.pipe(
    ofType<LoadCartSuccess | UpdateCartSuccess | LoadRecommendedOrderInCartSuccess | LoadCartWithRecommendedSuccess>(
      CartActionsTypes.LoadCartSuccess,
      CartActionsTypes.UpdateCartSuccess,
      CartActionsTypes.LoadRecommendedOrderInCartSuccess,
      CartActionsTypes.UpdateRecommendedOrderInCartSuccess,
      CartActionsTypes.LoadCartWithRecommendedSuccess
    ),
    withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(CartSelectors.selectCart)),
    switchMap(([, outlet, cart]) => {
      const materialIds = cart.map((el) => el.materialId);

      if (!materialIds || materialIds.length === 0) {
        return of();
      }

      const params: ProductCriteria = {
        pageNumber: 0,
        pageSize: 9999,
        sort: ['rank|ASC'],
        addressSapId: outlet.addressSapId,
        clientSapId: outlet.clientSapId,
        materialIds,
      };
      return this.dataService.getProducts(params).pipe(
        map((data) => {
          return new LoadCartProductsSuccess(data.content);
        }),
        catchError(() => {
          return of(new LoadCartProductsFailure());
        })
      );
    })
  );

  @Effect()
  loadOrderItems$ = this.actions$.pipe(
    ofType<LoadCartSuccess | UpdateCartSuccess | LoadRecommendedOrderInCartSuccess>(
      CartActionsTypes.LoadCartSuccess,
      CartActionsTypes.UpdateCartSuccess,
      CartActionsTypes.LoadRecommendedOrderInCartSuccess,
      CartActionsTypes.UpdateRecommendedOrderInCartSuccess,
      CartActionsTypes.LoadCartWithRecommendedSuccess
    ),
    withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(CartSelectors.selectCart)),
    switchMap(([, outlet, cart]) => {
      const materialIds = cart.map((el) => el.materialId);

      if (!materialIds || materialIds.length === 0) {
        return of();
      }

      const now = new Date();
      const mount = new Date();
      mount.setMonth(now.getMonth() - 1);

      const params: Partial<OrderHistoryItemsCriteria> = {
        pageNumber: 0,
        pageSize: 9999,
        addressSapId: outlet.addressSapId,
        materialIds,
        status: [OrderStatus.inBasket, OrderStatus.accepted, OrderStatus.finished],
        startDate: moment(mount).format('YYYY-MM-DD'),
        finishDate: moment(now).format('YYYY-MM-DD'),
      };

      return this.orderService.getOrdersByItem(params).pipe(
        map((data) => new LoadHistoryOrderItemsSuccess(data.content)),
        catchError(() => of(new LoadHistoryOrderItemsFailure()))
      );
    })
  );

  @Effect()
  submitCart$ = this.actions$.pipe(
    ofType<SubmitCart>(CartActionsTypes.SubmitCart),
    withLatestFrom(
      this.store.select(outletSelectors.getActive),
      this.store.select(loginSelectors.selectUserId),
      this.store.select(CartSelectors.selectTotalPriceOfOrder),
      this.store.select(CartSelectors.selectCurrentRecommendedOrderId)
    ),
    switchMap(([action, outlet, userId, cartSum, recommendedOrderId]) => {
      const recommendedWithOthers = !!recommendedOrderId;
      return this.cartService.submitCart(outlet.addressSapId, '', userId, cartSum, false, recommendedOrderId, recommendedWithOthers).pipe(
        withLatestFrom(this.store.select(CartSelectors.selectProductsForCartDisplay)),
        map(([response, items]) => {
          return new SubmitCartSuccess({ orders: response, cart: items });
        }),
        catchError((err) => {
          if (err.status === 409) {
            return of(new HandleError409(err));
          }
          return of(new SubmitCartFailure(err));
        })
      );
    })
  );

  @Effect()
  updateCart$ = this.actions$.pipe(
    ofType<UpdateCart>(CartActionsTypes.UpdateCart),
    withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(loginSelectors.selectUserId)),
    switchMap(([action, outlet, userId]) => {
      return this.cartService.updateCart(outlet.addressSapId, { ...action.body, userId: userId }).pipe(
        map((data) => {
          return new UpdateCartSuccess(data);
        }),
        catchError((err) => {
          if (err.status === 409) {
            return of(new HandleError409(err));
          } else {
            return of(new UpdateCartFailure());
          }
        })
      );
    })
  );

  @Effect()
  deleteProduct$ = this.actions$.pipe(
    ofType<DeleteProduct>(CartActionsTypes.DeleteProduct),
    withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(loginSelectors.selectUserId)),
    switchMap(([action, outlet, userId]) => {
      const body: DeleteCartItemCriteria = {
        addressSapId: outlet.addressSapId,
        userId: userId,
        materialId: action.cartItem.materialId,
        unit: action.cartItem.unit,
        mrc: action.cartItem.mrc,
        salesOrgSapId: action.cartItem.salesOrgSapId,
        distributorCode: action.cartItem.distributorCode,
      };
      return this.cartService.deleteProduct(outlet.addressSapId, body).pipe(
        map(() => {
          return new DeleteProductSuccess({ materialId: action.cartItem.materialId, unit: action.cartItem.unit });
        }),
        catchError((err) => {
          if (err.status === 409) {
            return of(new HandleError409(err));
          } else {
            return of(new DeleteProductFailure());
          }
        })
      );
    })
  );

  @Effect()
  changeProductAmount$ = this.actions$.pipe(
    ofType<AddProduct | DecrementProduct>(CartActionsTypes.AddProduct, CartActionsTypes.DecrementProduct),
    switchMap((action) => {
      return of(action).pipe(
        withLatestFrom(this.store.select(selectSelectedStockByProductId(action.payload.product.id))),
        switchMap(([, stock]) => {
          return of(stock).pipe(
            withLatestFrom(
              this.store.select(
                selectCartItemByMaterialIdAndUnitAndDistributorName(action.payload.product.materialId, stock.unit, stock.distributorName)
              ),
              this.store.select(loginSelectors.selectUserId)
            ),
            map(([, product, userId]) => {
              const quantity =
                action.type === CartActionsTypes.AddProduct ? (product ? product.quantity + 1 : 1) : product ? product.quantity - 1 : 0;
              const body: UpdateCartItem = {
                quantity,
                materialId: action.payload.product.materialId,
                userId: userId,
                mrc: stock?.mrc,
                unit: stock?.unit,
                salesOrgSapId: stock?.salesOrgSapId,
                distributorCode: stock?.distributorCode,
              };
              return new UpdateCart(body);
            })
          );
        })
      );
    })
  );

  @Effect()
  changeProductAmountInCart$ = this.actions$.pipe(
    ofType<IncrementProductInCart | DecrementProductInCart>(
      CartActionsTypes.IncrementProductInCart,
      CartActionsTypes.DecrementProductInCart
    ),
    switchMap((action) => {
      return of(action).pipe(
        withLatestFrom(
          this.store.select(selectStockByProductIdUnitDistributor(action.payload.id, action.payload.unit, action.payload.distributorName))
        ),
        switchMap(([, stock]) => {
          return of(stock).pipe(
            withLatestFrom(
              this.store.select(
                selectCartItemByMaterialIdAndUnitAndDistributorName(action.payload.materialId, stock.unit, action.payload.distributorName)
              ),
              this.store.select(loginSelectors.selectUserId),
              this.store.select(CartSelectors.selectCurrentRecommendedOrderId)
            ),
            map(([, product, userId, recommendedOrderId]) => {
              const quantity =
                action.type === CartActionsTypes.IncrementProductInCart
                  ? product
                    ? product.quantity + 1
                    : 1
                  : product
                  ? product.quantity - 1
                  : 0;

              const body: UpdateCartItem = {
                quantity,
                userId: userId,
                materialId: action.payload.materialId,
                mrc: stock?.mrc,
                unit: stock?.unit,
                salesOrgSapId: stock?.salesOrgSapId,
                distributorCode: stock?.distributorCode,
              };
              // Если тип CartItem равен RECOMMENDED, добавляем recommendedOrderId в тело запроса
              if (product?.type === CartItemType.RECOMMENDED) {
                body.recommendedOrderId = recommendedOrderId;
              }

              return new UpdateCart(body);
            })
          );
        })
      );
    })
  );

  @Effect()
  changeAmount$ = this.actions$.pipe(
    ofType<ChangeAmount>(CartActionsTypes.ChangeAmount),
    switchMap((action) => {
      return of(action).pipe(
        withLatestFrom(this.store.select(selectSelectedStockByProductId(action.payload.product.id))),
        switchMap(([, stock]) => {
          return of(stock).pipe(
            withLatestFrom(
              this.store.select(
                selectCartItemByMaterialIdAndUnitAndDistributorName(action.payload.product.materialId, stock?.unit, stock?.distributorName)
              ),
              this.store.select(loginSelectors.selectUserId)
            ),
            map(([, product, userId]) => {
              const quantity = action.payload.amount;
              if (quantity === product?.quantity) {
                return new EmptyAction();
              }
              const body: UpdateCartItem = {
                quantity,
                userId: userId,
                materialId: action.payload.product.materialId,
                mrc: stock?.mrc,
                unit: stock?.unit,
                salesOrgSapId: stock?.salesOrgSapId,
                distributorCode: stock?.distributorCode,
              };
              return new UpdateCart(body);
            })
          );
        })
      );
    })
  );

  @Effect()
  loadDeliveryDateDispatch$ = this.actions$.pipe(
    ofType<SubmitCartSuccess>(CartActionsTypes.SubmitCartSuccess),
    switchMap(() => {
      return [new LoadDeliveryDate(), new LoadBonusesRecommendedOrders({ executed: false, predict: true })];
    })
  );

  @Effect()
  loadClosestDeliveryDate$ = this.actions$.pipe(
    ofType<LoadDeliveryDate>(CartActionsTypes.LoadDeliveryDate),
    switchMap(() =>
      this.store.select(outletSelectors.getActive).pipe(
        filter((outlet) => !!outlet),
        first(),
        switchMap((outlet) =>
          this.cartService.getClosestDeliveryDate(outlet.addressSapId).pipe(
            map((data) => new LoadDeliveryDateSuccess({ date: data.deliveryDate })),
            catchError(() => of(new LoadDeliveryDateFailure()))
          )
        )
      )
    )
  );

  @Effect()
  loadMinimalCartRulesByCategory$ = this.actions$.pipe(
    ofType<LoadMinimalCartRulesByCategory>(CartActionsTypes.LoadMinimalCartRulesByCategory),
    withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(loginSelectors.selectUserId)),
    switchMap(([action, outlet, userId]) => {
      const params: MinimalCartRulesCriteria = {
        addressSapId: outlet.addressSapId,
        userId,
        ...action.payload,
      };
      return this.cartService.getMinimalCartRuleGroupedStatus(outlet.addressSapId, params).pipe(
        map((response) => new LoadMinimalCartRulesByCategorySuccess(response.minimalCartRuleCartResponses)),
        catchError(() => of(new LoadMinimalCartRulesByCategoryFailure()))
      );
    })
  );

  @Effect()
  loadMinimalCartRuleGroupedStatus$ = this.actions$.pipe(
    ofType<LoadMinimalCartRuleGroupedStatus>(CartActionsTypes.LoadMinimalCartRuleGroupedStatus),
    withLatestFrom(
      this.store.select(outletSelectors.getActive),
      this.store.select(loginSelectors.selectUserId),
      this.store.select(CartSelectors.selectCurrentRecommendedOrderId)
    ),
    switchMap(([action, { addressSapId }, userId, recommendedOrderId]) => {
      const params: MinimalCartRulesCriteria = {
        addressSapId,
        userId,
        recommendedOrderId,
        isRecommendedWithOthers: !!recommendedOrderId,
      };
      return this.cartService.getMinimalCartRuleGroupedStatus(addressSapId, params).pipe(
        map((response) => new LoadMinimalCartRuleGroupedStatusSuccess(response)),
        catchError(() => of(new LoadMinimalCartRuleGroupedStatusFailure()))
      );
    })
  );

  @Effect()
  updateRecommendedOrder$ = this.actions$.pipe(
    ofType<UpdateRecommendedOrderInCart>(CartActionsTypes.UpdateRecommendedOrderInCart),
    withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(loginSelectors.selectUserId)),
    switchMap(([action, outlet, userId]) => {
      const payload = {
        userId: userId,
      };
      return this.cartService.updateRecommendedOrderCart(outlet.addressSapId, action.payload.recommendedOrderId, payload).pipe(
        map((res) => new UpdateRecommendedOrderInCartSuccess(res)),
        catchError((err) => {
          if (err.status === 409) {
            return of(new HandleError409(err));
          } else {
            return of(new UpdateRecommendedOrderInCartFailure());
          }
        })
      );
    })
  );

  // @Effect()
  // loadRecommendedOrder$ = this.actions$.pipe(
  //   ofType<LoadRecommendedOrderInCart>(CartActionsTypes.LoadRecommendedOrderInCart),
  //   withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(loginSelectors.selectUserId)),
  //   switchMap(([action, outlet, userId]) => {
  //     const payload = {
  //       userId: userId,
  //     };
  //     return this.cartService.updateRecommendedOrderCart(outlet.addressSapId, action.payload.recommendedOrderId, payload).pipe(
  //       map((res) => new LoadRecommendedOrderInCartSuccess(res)),
  //       catchError(() => of(new LoadRecommendedOrderInCartFailure()))
  //     );
  //   })
  // );

  @Effect()
  loadRecommendedOrderAndCart$ = this.actions$.pipe(
    ofType<LoadRecommendedOrderInCart>(CartActionsTypes.LoadRecommendedOrderInCart),
    withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(loginSelectors.selectUserId)),
    mergeMap(([action, outlet, userId]) => {
      const payload = { userId };

      return this.cartService.updateRecommendedOrderCart(outlet.addressSapId, action.payload.recommendedOrderId, payload).pipe(
        map((res) => new LoadRecommendedOrderInCartSuccess(res)),
        catchError(() => of(new LoadRecommendedOrderInCartFailure())),
        concatMap((recommendedOrderResult) => {
          return this.cartService.loadCart(outlet.addressSapId, { userId }).pipe(
            map((data) => [recommendedOrderResult, new LoadCartWithRecommendedSuccess(data)]),
            catchError(() => of([recommendedOrderResult, new LoadCartFailure()]))
          );
        })
      );
    }),
    mergeMap((actions) => actions)
  );

  @Effect()
  fillUpCartsMinimalLimit$ = this.actions$.pipe(
    ofType<FillUpCartsMinimalLimit>(CartActionsTypes.FillUpCartsMinimalLimit),
    withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(loginSelectors.selectUserId)),
    switchMap(([_, outlet, userId]) => {
      const payload = {
        userId,
      };
      return this.cartService.fillUpCartsMinimalLimit(outlet.addressSapId, payload).pipe(
        switchMap(() => {
          return this.cartService.loadCart(outlet.addressSapId, { userId }).pipe(map((data) => new FillUpCartsMinimalLimitSuccess(data)));
        }),
        catchError(() => of(new FillUpCartsMinimalLimitFailure()))
      );
    })
  );

  @Effect()
  LoadCartCategoryTree$ = this.actions$.pipe(
    ofType<LoadCartCategoryTree>(CartActionsTypes.LoadCartCategoryTree),
    withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(loginSelectors.selectUserId)),
    switchMap(([action, outlet, userId]) => {
      const criteria = {
        addressSapId: outlet.addressSapId,
        userId: userId,
      };
      return this.cartService.getCartCategoryTree(criteria).pipe(
        map((res) => new LoadCartCategoryTreeSuccess(res)),
        catchError(() => of(new LoadCartCategoryTreeFailure()))
      );
    })
  );

  @Effect()
  FillCartsByFileProgress$ = this.actions$.pipe(
    ofType<FillCartsByFileProgress>(CartActionsTypes.FillCartsByFileProgress),
    // withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(loginSelectors.selectUserId)),
    switchMap((action) => {
      return this.cartService.getProgressFillCartByFile(action.payload.filePath).pipe(
        map((res) => new FillCartsByFileProgressSuccess(res)),
        catchError(() => of(new FillCartsByFileProgressFailure()))
      );
    })
  );

  @Effect()
  FillCartsByFileCurrent$ = this.actions$.pipe(
    ofType<FillCartsByFileCurrent>(CartActionsTypes.FillCartsByFileCurrent),
    withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(loginSelectors.selectUserId)),
    switchMap(([action, outlet, userId]) => {
      const payload = {
        userId: userId,
      };
      return this.cartService.getFillCartByFileCurrentLoaded({ addressSapId: outlet.addressSapId }).pipe(
        map((res) => new FillCartsByFileCurrentSuccess(res)),
        catchError(() => of(new FillCartsByFileCurrentFailure()))
      );
    })
  );

  @Effect()
  ToggleProductFavorite$ = this.actions$.pipe(
    ofType<ToggleProductFavorite>(CartActionsTypes.ToggleProductFavorite),
    map((action) => action.payload),
    switchMap(({ materialId }) => {
      return this.dataService.toggleProductFavorite(materialId).pipe(
        map(() => new ToggleProductFavoriteSuccess({ materialId })),
        catchError(() => of(new ToggleProductFavoriteFailure()))
      );
    })
  );

  @Effect()
  changeOutlet: Observable<Action> = this.actions$.pipe(
    ofType<OutletSetActive | OutletSetActiveId>(OutletActionsTypes.OutletSetActive, OutletActionsTypes.OutletSetActiveId),
    map(() => {
      return new ResetCartValue();
    })
  );

  @Effect()
  loadActiveOnReset: Observable<Action> = this.actions$.pipe(
    ofType<ResetCartValue>(CartActionsTypes.ResetCartValue),
    switchMap(() => {
      return [
        new LoadDeliveryDate(),
        new FillCartsByFileProgressReset(),
        new LoadBonusesRecommendedOrders({ executed: false, predict: true }),
      ];
    })
  );

  @Effect()
  loadTaskRecommendedOrder$ = this.actions$.pipe(
    ofType<LoadTaskRecommendedOrder>(CartActionsTypes.LoadTaskRecommendedOrder),
    withLatestFrom(this.store.select(outletSelectors.getActive)),
    switchMap(([{ payload }, { addressSapId, clientSapId }]) => {
      const params = {
        addressSapId,
        clientSapId,
        recommendedOrderId: payload.recommendedOrderId,
      };
      return this.taskService.getClientTasks(params).pipe(
        map((res) => new LoadTaskRecommendedOrderSuccess(res.content)),
        catchError(() => of(new LoadTaskRecommendedOrderFailure()))
      );
    })
  );

  @Effect()
  calculateRecommendedOrderCart$: Observable<Action> = this.actions$.pipe(
    ofType<CartActions.CalculateRecommendedOrderCart>(CartActionsTypes.calculateRecommendedOrderCart),
    withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(CartSelectors.selectCurrentRecommendedOrderId)),
    switchMap(([{ payload }, { addressSapId }, recommendedOrderId]) =>
      this.cartService.calculateRecommendedOrderCart(addressSapId, payload.recommendedOrderId).pipe(
        map((recommendedOrder) => new CartActions.CalculateRecommendedOrderCartSuccess({ recommendedOrder })),
        catchError((error) => of(new CartActions.CalculateRecommendedOrderCartFailure({ error })))
      )
    )
  );

  @Effect()
  loadCartOrRecommendedOrderButton$ = this.actions$.pipe(
    ofType<CartActions.LoadCartOrRecommendedOrderButton>(CartActionsTypes.loadCartOrRecommendedOrderButton),
    withLatestFrom(this.store.select(CartSelectors.selectCurrentRecommendedOrderId)),
    switchMap(([action, recommendedOrderId]) => {
      if (recommendedOrderId) {
        return of(new LoadRecommendedOrderInCart({ recommendedOrderId }));
      } else {
        return of(new LoadCart());
      }
    })
  );

  // @Effect()
  // loadBonusRecommendedOrder$ = this.actions$.pipe(
  //   ofType<LoadBonusRecommendedOrder>(CartActionsTypes.LoadBonusRecommendedOrder),
  //   withLatestFrom(this.store.select(outletSelectors.getActive), this.store.select(loginSelectors.selectUserId)),
  //   switchMap(([action, outlet, userId]) => {
  //     const payload = {
  //       userId: userId,
  //     };
  //     return this.cartService.LoadBonusRecommendedOrderCart(outlet.addressSapId, payload).pipe(
  //       map((res) => new LoadBonusRecommendedOrderSuccess(res)),
  //       catchError(() => of(new LoadBonusRecommendedOrderFailure()))
  //     );
  //   })
  // );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private cartService: CartService,
    private dataService: DataService,
    private orderService: OrderService,
    private taskService: TaskService
  ) {}
}
