import { Component, OnInit, ChangeDetectionStrategy, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { RecommendOrderBonus } from '../../../../store/order/order.model';

@Component({
  selector: 'app-progress-recommended-order',
  templateUrl: './progress-recommended-order.component.html',
  styleUrls: ['./progress-recommended-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressRecommendedOrderComponent implements OnInit {
  defaultConfig: SwiperConfigInterface = {
    normalizeSlideIndex: true,
    init: true,
    observer: true,
    direction: 'horizontal',
    navigation: true,
    spaceBetween: 20,
    slidesPerView: 5,
    freeMode: false,
    watchOverflow: true,
    breakpoints: {
      768: {
        slidesPerView: 1,
        freeMode: false,
      },
      1300: {
        slidesPerView: 1,
        freeMode: false,
      },
      1662: {
        slidesPerView: 1,
        freeMode: false,
      },
      2020: {
        slidesPerView: 2,
        freeMode: false,
      },
      2560: {
        slidesPerView: 3,
        freeMode: false,
      },
    },
  };
  @ViewChild(SwiperDirective) swiper: SwiperDirective;
  @Input() bonusesByOrders: RecommendOrderBonus[];
  @Output() changedOrder: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  changeSlide(index: number) {
    this.changedOrder.emit(this.bonusesByOrders[index].id);
  }
}
