import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Outlet } from '@Mesh/core/models';
import { select, Store } from '@ngrx/store';
import { OutletSetActive } from '@Mesh/store/outlet/outlet.actions';
import { AppState } from '@Mesh/store/app.state';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { OutletState } from '@Mesh/store/outlet/outlet.state';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';

@Component({
  selector: 'app-shops-widget',
  templateUrl: './shops-widget.component.html',
  styleUrls: ['./shops-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopsWidgetComponent implements OnInit {
  isCollapsed = window.innerWidth < 992;
  active = true;
  config = {};
  filter: FormControl;

  outlets$: Observable<OutletState>;
  filter$: Observable<string>;
  filteredOutlets$: Observable<Outlet[]>;

  clickHeader($event: MouseEvent): void {
    if (this.isCollapsed) {
      this.active = !this.active;
    }
  }

  constructor(private store: Store<AppState>, private cd: ChangeDetectorRef) {
    this.filter = new FormControl('');
    this.outlets$ = this.store.pipe(select(outletSelectors.selectOutlet));
    this.filter$ = this.filter.valueChanges.pipe(startWith(''));

    this.filteredOutlets$ = this.filterOutlets();
  }

  ngOnInit() {}

  setActiveOutlet(outlet: Outlet): void {
    this.store.dispatch(new OutletSetActive({ active: outlet }));
  }

  private filterOutlets(): Observable<Outlet[]> {
    return combineLatest([this.outlets$, this.filter$]).pipe(
      map(([outlets, filter]: any[]) => {
        return outlets.data.filter((outlet: any) => outlet.addressName.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
      })
    );
  }
}
