import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-stastics-widget',
  templateUrl: './stastics-widget.component.html',
  styleUrls: ['./stastics-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StasticsWidgetComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
