import { FinanceState, initialState } from '@Mesh/store/finance/finance.state';
import { FinanceActionsTypes, FinanceActionsUnion } from '@Mesh/store/finance/finance.actions';

export const financeFeatureKey = 'finance';

export function financeReducer(state = initialState, action: FinanceActionsUnion): FinanceState {
  switch (action.type) {
    case FinanceActionsTypes.LoadBalanceSuccess:
      return {
        ...state,
        // balance: action.data
      };
    case FinanceActionsTypes.LoadPayHistorySuccess:
      return {
        ...state,
        payHistory: action.data,
      };

    case FinanceActionsTypes.LoadUserFinanceSumSuccess:
      return {
        ...state,
        financeSum: action.data,
      };

    case FinanceActionsTypes.LoadUserFinanceSumError:
      return {
        ...state,
        errors: action.error.errors,
      };

    case FinanceActionsTypes.LoadFinanceFullDataSuccess:
      return {
        ...state,
        financeFullData: action.data,
      };

    case FinanceActionsTypes.LoadUserFinanceInformationSuccess:
      return {
        ...state,
        userFinanceInformation: action.data,
      };

    case FinanceActionsTypes.LoadBalanceTreeSuccess:
      return {
        ...state,
        balanceTree: action.data,
      };
    default:
      return state;
  }
}
