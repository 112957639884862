import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasketDeliveryComponent } from './basket-delivery.component';
import { pgUtilModule } from '../../../../shared/components/util/pg-util.module';

@NgModule({
  declarations: [BasketDeliveryComponent],
  exports: [BasketDeliveryComponent],
  imports: [CommonModule, pgUtilModule],
})
export class BasketDeliveryModule {}
