import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DocumentEffects } from '@Mesh/store/document/document.effects';
import { documentReducer } from '@Mesh/store/document/document.reducers';

@NgModule({
  imports: [StoreModule.forFeature('document', documentReducer), EffectsModule.forFeature([DocumentEffects])],
})
export class DocumentStoreModule {}
