import { loginSelectors } from '@Mesh/store/login/login.selectors';
import { AppState } from '@Mesh/store/app.state';
import { PipeTransform, Pipe } from '@angular/core';
import { AuthenticationService } from '@Mesh/core/services/authentication.service';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Pipe({ name: 'token' })
export class TokenPipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  transform(url) {
    return this.store.select(loginSelectors.selectToken).pipe(
      map((token) => {
        return (url += (url.split('?')[1] ? '&' : '?') + 'token=' + token);
      })
    );
  }
}
