import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ChatTaskProductItemComponent } from './chat-task-product-item/chat-task-product-item.component';
import { ChatTaskProductComponent } from './chat-task-product.component';

@NgModule({
  declarations: [ChatTaskProductComponent, ChatTaskProductItemComponent],
  exports: [ChatTaskProductComponent, ChatTaskProductItemComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
})
export class ChatTaskProductModule {}
