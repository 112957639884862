// TODO Этот модуль можно использовать во всех shared модулях из папки Components
// MainSharedModule не использовать в папках Shared && Core
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// TODO Импортировать встроенные модули Angular, например, Forms и добавить в MODULE_COLLECTION

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ResponsiveModule } from 'ngx-responsive';
// TODO Импортировать все модули третьтх производителей и добавить в MODULE_COLLECTION
// Было бы удобно для Material и Bootstrap завести отдельные собирающие модули, и сюда импортировать одной строкой

// TODO Плохой план. Если папка @pages используется как образец, то надо брать оттуда компонетты, перемещать в shared и использовать
// Ссылаться на папку @pages  нельзя категорически, к концу работы ее надо удалить из приложения.
// Увы, это рекомендация https://docs.pages.revox.io/v/angular/
// TODO - Упорядочить @Mesh/@pages/components/select/shared.module, а этот блок вынести в отдельный файл
import { SharedModule } from '@Mesh/@pages/components/shared.module';
import { pgSelectModule } from '@Mesh/@pages/components/select/select.module';
import { pgTabsModule } from '@Mesh/shared/components/tabs/tabs.module';

// TODO Вот это Грабли !!!! Не буду пока импортировать, от этого модуля просто необходимо избавиться.
// import { CourseModuleModule } from '@Mesh/core/modules/course-module/course-module.module';

const MODULE_COLLECTION = [
  CommonModule,
  FormsModule,
  NgxDatatableModule,
  ModalModule,
  SwiperModule,
  SharedModule,
  pgTabsModule,
  pgSelectModule,
];

@NgModule({
  imports: [...MODULE_COLLECTION, ResponsiveModule.forRoot()],
  exports: MODULE_COLLECTION,
})
export class ExternalModule {}
