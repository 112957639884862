import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasketComponent } from './basket.component';
import { MinimalCardRulesModule } from '../components/minimal-card-rules/minimal-card-rules.module';
import { BasketItemComponent } from './basket-item/basket-item.component';
import { ProgressRecommendedOrderModule } from './progress-recommended-order/progress-recommended-order.module';
import { RecommendedOrdersInfoComponent } from './recommended-orders-info/recommended-orders-info.component';
import { SuccessScreenModule } from './success-screen/success-screen.module';
import { RecommendedOrdersComponent } from './recommended-orders/recommended-orders.component';
import { AutoOrderInfoModule } from '../components/auto-order-info/auto-order-info.module';
import { BasketFillMinimalAlertComponent } from './basket-fill-minimal-alert/basket-fill-minimal-alert.component';
import { BasketHeaderModule } from './basket-header/basket-header.module';
import { BasketDeliveryModule } from './basket-delivery/basket-delivery.module';
import { RecommendedCartListComponent } from './recommended-cart-list/recommended-cart-list.component';
import { FilterListPipe } from './recommended-cart-list/filter-list.pipe';
import { CartGroupDatePipe } from './cart-group-date.pipe';
import { GroupByModule } from '../../../shared/modules/group-by/group-by.module';
import { pgUtilModule } from '../../../@pages/components/util/pg-util.module';
import { BasketHierarchyFilterComponent } from './basket-hierarchy-filter/basket-hierarchy-filter.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { BasketSumNotEqualAlertComponent } from './basket-sum-not-equal-alert/basket-sum-not-equal-alert.component';
import { CartChangedModalComponent } from './cart-changed-modal/cart-changed-modal.component';
import { BasketFillByFileComponent } from './basket-fill-by-file/basket-fill-by-file.component';
import { pgUploadModule } from '../../../@pages/components/upload/upload.module';
import { BasketFillByFileAlertComponent } from './basket-fill-by-file/basket-fill-by-file-alert/basket-fill-by-file-alert.component';
import { BasketNewComponent } from './basket-new/basket-new.component';
import { BasketEmptyCartComponent } from './basket-empty-cart/basket-empty-cart.component';
import { BasketFillByFileButtonComponent } from './basket-fill-by-file-button/basket-fill-by-file-button.component';
import { BasketProductItemComponent } from './basket-product-item/basket-product-item.component';
import { BasketRecommendOrderComponent } from './basket-recommend-order/basket-recommend-order.component';
import { BasketMinimalCardRulesComponent } from './basket-minimal-card-rules/basket-minimal-card-rules.component';
import { BasketTotalInfoComponent } from './basket-total-info/basket-total-info.component';
import { BasketSuccessScreenComponent } from './basket-success-screen/basket-success-screen.component';
import { BasketOrderProductItemComponent } from './basket-success-screen/basket-order-product-item/basket-order-product-item.component';
import { BasketOrderItemComponent } from './basket-success-screen/basket-order-item/basket-order-item.component';
import { BusketOrderOrderInvoceComponent } from './basket-success-screen/busket-order-order-invoce/busket-order-order-invoce.component';
import { SplitPipe } from './basket-minimal-card-rules/split.pipe';
import { BasketProductItemSmComponent } from './basket-product-item-sm/basket-product-item-sm.component';
import { ResponsiveModule } from 'ngx-responsive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasketOrderProductSmItemComponent } from './basket-success-screen/basket-order-product-sm-item/basket-order-product-sm-item.component';
import { SwitchCheckedModule } from '../../../shared/components/switch/switch-checked.module';
import { DefaultCartListComponent } from './default-cart-list/default-cart-list.component';
import { BasketOrdersHistoryProductModalModule } from './basket-orders-history-product-modal/basket-orders-history-product-modal.module';
import { BasketRecommendOrderButtonComponent } from './basket-recommend-order-button/basket-recommend-order-button.component';
import { RecommendOrderCardComponent } from './recommend-order-card/recommend-order-card.component';
import { RecommendOrderCardProgressComponent } from './recommend-order-card/recommend-order-card-progress/recommend-order-card-progress.component';
import { BasketTaskLeftoverProductsModule } from '@Mesh/pages/catalog/basket/basket-task-leftover-products/basket-task-leftover-products.module';

@NgModule({
  declarations: [
    BasketComponent,
    BasketItemComponent,
    RecommendedOrdersInfoComponent,
    RecommendedOrdersComponent,
    BasketFillMinimalAlertComponent,
    RecommendedCartListComponent,
    FilterListPipe,
    CartGroupDatePipe,
    BasketHierarchyFilterComponent,
    BasketSumNotEqualAlertComponent,
    CartChangedModalComponent,
    BasketFillByFileComponent,
    BasketFillByFileAlertComponent,
    BasketNewComponent,
    BasketEmptyCartComponent,
    BasketFillByFileButtonComponent,
    BasketProductItemComponent,
    BasketRecommendOrderComponent,
    BasketMinimalCardRulesComponent,
    BasketTotalInfoComponent,
    BasketSuccessScreenComponent,
    BasketOrderProductItemComponent,
    BasketOrderItemComponent,
    BusketOrderOrderInvoceComponent,
    SplitPipe,
    BasketProductItemSmComponent,
    BasketOrderProductSmItemComponent,
    DefaultCartListComponent,
    BasketRecommendOrderButtonComponent,
    RecommendOrderCardComponent,
    RecommendOrderCardProgressComponent,
  ],
  exports: [BasketComponent, BasketNewComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BasketHeaderModule,
    BasketDeliveryModule,
    MinimalCardRulesModule,
    ProgressRecommendedOrderModule,
    SuccessScreenModule,
    AutoOrderInfoModule,
    GroupByModule,
    pgUtilModule,
    DragScrollModule,
    SwiperModule,
    pgUploadModule,
    ResponsiveModule.forRoot(),
    SwitchCheckedModule,
    BasketOrdersHistoryProductModalModule,
    BasketTaskLeftoverProductsModule,
  ],
  entryComponents: [
    BasketFillMinimalAlertComponent,
    BasketSumNotEqualAlertComponent,
    CartChangedModalComponent,
    BasketFillByFileAlertComponent,
  ],
})
export class BasketModule {}
