import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatDialogTabComponent } from './chat-dialog-tab/chat-dialog-tab.component';
import { ChatDialogTabsComponent } from './chat-dialog-tabs.component';

@NgModule({
  declarations: [ChatDialogTabComponent, ChatDialogTabsComponent],
  exports: [ChatDialogTabComponent, ChatDialogTabsComponent],
  imports: [CommonModule],
})
export class ChatDialogTabsModule {}
