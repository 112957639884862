import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IMAGE_CLOUD_URL } from '../../../../../../environments/environment';
import { ChatTaskStepTypes } from '../../../../../core/models/chat-task-step-types';
import { TaskStep } from '../../../../../core/models/comment';
import { Product } from '../../../../../core/models/product';
import { AppState } from '../../../../../store/app.state';
import { LoadBonusesRecommendedOrders } from '../../../../../store/order/order.actions';
import { orderSelectors } from '../../../../../store/order/order.selectors';
import { ChatService } from '../../chat.service';

@Component({
  selector: 'app-chat-task-preview-step',
  templateUrl: './chat-task-preview-step.component.html',
  styleUrls: ['./chat-task-preview-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTaskPreviewStepComponent implements OnInit {
  @Input() step: TaskStep;
  @Input() time: string;
  @Input() clientSapId: number;
  @Input() addressSapId: number;
  imagesUrl = IMAGE_CLOUD_URL;
  stepTypes = ChatTaskStepTypes;
  productsStock$: Observable<any>;
  bonusRecommendedOrders$ = this.store.select(orderSelectors.getBonusRecommendedOrders);

  constructor(private chatService: ChatService, private store: Store<AppState>) {}

  ngOnInit() {
    this.getStockProducts();
    this.getRecommendedOrders();
  }

  getStockProducts() {
    const findStep = this.step && this.step.type.name === ChatTaskStepTypes.CALCULATE_REST_OF_PRODUCT;
    if (findStep) {
      let params = {
        clientSapId: this.clientSapId,
        pageSize: 10,
        addressSapId: this.addressSapId,
        ignoreStock: true,
      };
      if (this.step.recommendedOrderConfigId) {
        params = { ...params, ...{ recommendedOrderConfigId: this.step.recommendedOrderConfigId } };
      }
      this.productsStock$ = this.chatService.getStockProducts(params);
    }
  }

  getRecommendedOrders() {
    if (this.step.type.name === ChatTaskStepTypes.AUTO_RECOMMENDED_ORDER && this.step?.stepSuccess?.recommendedOrderId != null) {
      this.store.dispatch(
        new LoadBonusesRecommendedOrders({ recommendedOrderId: this.step.stepSuccess.recommendedOrderId, executed: false, predict: true })
      );
    }
  }
}
