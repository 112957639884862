import { Component, OnInit, ChangeDetectionStrategy, Input, NgZone, AfterViewInit } from '@angular/core';
import { Gallery, ImageItem } from '@ngx-gallery/core';
import { IMAGE_CLOUD_URL } from '../../../../../../environments/environment';
import { TaskStep } from '../../../../../core/models';

@Component({
  selector: 'app-chat-task-images',
  templateUrl: './chat-task-images.component.html',
  styleUrls: ['./chat-task-images.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTaskImagesComponent implements AfterViewInit {
  @Input()
  public set step(value: TaskStep) {
    this._step = value;
  }
  public get step(): TaskStep {
    return this._step;
  }
  private _step: TaskStep;
  imagesUrl = IMAGE_CLOUD_URL;
  @Input() mine: boolean;

  constructor(public gallery: Gallery, private zone: NgZone) {}

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      if (this.step.imageUrls) {
        const lightboxGalleryRef = this.gallery.ref('lightbox' + this.step.id);

        lightboxGalleryRef.setConfig({
          thumb: false,
        });

        lightboxGalleryRef.setConfig({
          thumb: false,
        });

        const items = this.step.imageUrls.map(
          (item) => new ImageItem({ src: this.imagesUrl + item.url, thumb: this.imagesUrl + item.url })
        );
        lightboxGalleryRef.load(items);
      }
    });
  }
}
