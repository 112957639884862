import { IMAGE_CLOUD_URL } from '@Env/environment';
import { RecommendOrderBonus } from '@Mesh/store/order/order.model';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-recommend-order-card',
  templateUrl: './recommend-order-card.component.html',
  styleUrls: ['./recommend-order-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendOrderCardComponent implements OnInit {
  @Input() recommendedOrder: RecommendOrderBonus;
  imageUrl = IMAGE_CLOUD_URL;

  constructor() {}

  ngOnInit() {}

  get imageOrDefault() {
    return this.recommendedOrder?.config?.image ? this.imageUrl + '/' + this.recommendedOrder?.config?.image : null;
  }
}
