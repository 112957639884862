import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { pgCardModule } from '../../@pages/components/card/card.module';
import { pgSwitchModule } from '../../@pages/components/switch/switch.module';
import { NvD3Module } from 'ngx-nvd3';
import { NgxEchartsModule } from 'ngx-echarts';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { SharedModule } from '../../@pages/components/shared.module';
import { pgCollapseModule } from '@Mesh/@pages/components/collapse';
import { ResponsiveModule } from 'ngx-responsive';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { EmodjiComponent } from '@Mesh/pages/dashboard/widgets/emodji/emodji.component';
import { TasksWidgetComponent } from '@Mesh/pages/dashboard/widgets/tasks-widget/tasks-widget.component';
import { CoursesWidgetComponent } from '@Mesh/pages/dashboard/widgets/courses-widget/courses-widget.component';
import { OrdersWidgetComponent } from '@Mesh/pages/dashboard/widgets/orders-widget/orders-widget.component';
import { ShopsWidgetComponent } from '@Mesh/pages/dashboard/widgets/shops-widget/shops-widget.component';
import { StasticsWidgetComponent } from '@Mesh/pages/dashboard/widgets/stastics-widget/stastics-widget.component';
import { TargetsWidgetComponent } from '@Mesh/pages/dashboard/widgets/targets-widget/targets-widget.component';
import { NewsWidgetComponent } from '@Mesh/pages/dashboard/widgets/news-widget/news-widget.component';
import { UserAvatarComponent } from '@Mesh/pages/dashboard/widgets/user-avatar/user-avatar.component';
import { NewsSocialComponent } from '@Mesh/pages/dashboard/widgets/news-social/news-social.component';
import { TaskStatisticsWidgetComponent } from '@Mesh/pages/dashboard/widgets/task-statistics-widget/task-statistics-widget.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { pgSelectModule } from '@Mesh/@pages/components/select/select.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TimesModule } from '@Mesh/shared/modules/times/times.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { TruncatePipe } from '@Mesh/shared/pipes/truncate.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TaskItemComponent } from '@Mesh/pages/dashboard/widgets/tasks-widget/task-item/task-item.component';
import { TaskItemGhostComponent } from '@Mesh/pages/dashboard/widgets/tasks-widget/task-item-ghost/task-item-ghost.component';
import { OrderItemComponent } from '@Mesh/pages/dashboard/widgets/orders-widget/order-item/order-item.component';
import { TaskItemEmptyComponent } from '@Mesh/pages/dashboard/widgets/tasks-widget/task-item-empty/task-item-empty.component';
import { MainSharedModule } from '@Mesh/shared';
import { NgxMaskModule } from 'ngx-mask';
import { DateFormatPipe } from '@Mesh/core/pipe/date-format.pipe';
import { UserBonusModalModule } from './components/user-bonus-modal/user-bonus-modal.module';
import { DelayedOrderEditModule } from '../active/order/delayed-order-edit/delayed-order-edit.module';
import { DelayedOrderHistoryModule } from '../active/order/delayed-order-history/delayed-order-history.module';
import { DelayedOrderNotifyDropModule } from '../active/order/delayed-order-notify-drop/delayed-order-notify-drop.module';
import { ProgressBarModule } from '../../shared/components/progress-bar/progress-bar.module';
import { DelayOrderRequestInvoiceModule } from '../active/order/delay-order-request-invoice/delay-order-request-invoice.module';
import { OrderInvoceViewModule } from '@Mesh/pages/active/order/order-invoce-view/order-invoce-view.module';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

const components = [
  EmodjiComponent,
  TasksWidgetComponent,
  CoursesWidgetComponent,
  OrdersWidgetComponent,
  ShopsWidgetComponent,
  StasticsWidgetComponent,
  TargetsWidgetComponent,
  NewsWidgetComponent,
  UserAvatarComponent,
  NewsSocialComponent,
  TaskStatisticsWidgetComponent,
  TaskItemComponent,
  TaskItemEmptyComponent,
  OrderItemComponent,
  TaskItemGhostComponent,
  TruncatePipe,
  DateFormatPipe,
];

@NgModule({
  imports: [
    ModalModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    pgCardModule,
    TimesModule,
    pgCollapseModule,
    NvD3Module,
    NgxEchartsModule,
    SwiperModule,
    SharedModule,
    MainSharedModule,
    pgSelectModule,
    PerfectScrollbarModule,
    pgSwitchModule,
    ResponsiveModule.forRoot(),
    ModalModule,
    InfiniteScrollModule,
    NgxUiLoaderModule,
    TabsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    UserBonusModalModule,
    DelayedOrderHistoryModule,
    DelayedOrderNotifyDropModule,
    DelayedOrderEditModule,
    DelayOrderRequestInvoiceModule,
    ProgressBarModule,
    OrderInvoceViewModule,
  ],
  declarations: components,
  exports: components,
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
})
export class DashboardModule {}
