import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TitleService {
  title$: BehaviorSubject<string>;
  secondTitle$: BehaviorSubject<string>;
  _title: string;
  _secondTitle: string;

  constructor(private _service: Title) {
    this.title$ = new BehaviorSubject<string>('');
    this.secondTitle$ = new BehaviorSubject<string>('');
  }

  setTitle(title: string) {
    this._title = title;
    this._service.setTitle(title);
    this.title$.next(title);
  }

  setSecondTitle(secondTitle: string) {
    this._secondTitle = secondTitle;
    this.secondTitle$.next(secondTitle);
  }
}
