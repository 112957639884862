import { Pipe, PipeTransform } from '@angular/core';
import { CartItemDisplay } from '../../../../store/cart/cart.models';

@Pipe({
  name: 'filterList',
})
export class FilterListPipe implements PipeTransform {
  transform(items: CartItemDisplay[], term: string): any {
    return (items || []).filter((item) => item.type.indexOf(term) !== -1);
  }
}
