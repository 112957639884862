import { Component, OnInit, ChangeDetectionStrategy, Input, ElementRef, ViewChild, HostListener } from '@angular/core';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-iframe-document',
  templateUrl: './iframe-document.component.html',
  styleUrls: ['./iframe-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframeDocumentComponent implements OnInit {
  @ViewChild('iframe') iframe: ElementRef;

  _src: string;

  @Input()
  width: string;

  @Input()
  height: string;

  doc;

  spinner = SPINNER;

  @Input()
  set src(value) {
    this.ngxService.startLoader('document-loader');
    this._src = value;
  }

  get src() {
    return this._src;
  }

  constructor(private el: ElementRef, private ngxService: NgxUiLoaderService) {}

  onLoad(iframe) {
    setTimeout(() => {
      this.calculateSize();
    }, 400);
  }

  @HostListener('window:resize', ['$event'])
  calculateSize() {
    this.doc = this.iframe.nativeElement.contentDocument;
    if (this.doc.body.getElementsByTagName('main')[0]) {
      var scale = this.doc.body.offsetWidth / 1200;
      this.doc.body.style.height = (this.doc.body.offsetHeight * scale).toFixed(2) + 'px';
      this.doc.body.style.transform = 'scale(' + scale.toFixed(2) + ')';
      this.doc.body.style.transformOrigin = 'left top';
    }
    this.ngxService.stopLoader('document-loader');
  }

  ngOnInit() {}
}
