import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Task } from '@Mesh/core/models/task';
import { TaskService } from '@Mesh/core/services';
import { trigger } from '@angular/animations';
import { fadeIn } from '@Mesh/utils/animations/fade-animations';
import { IMAGES_URL } from '@Env/environment';

@Component({
  selector: 'app-task',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('fadeIn', fadeIn(':self'))],
})
export class TaskItemComponent implements OnInit, OnDestroy {
  _task: Task;
  _progress: any; // TaskProgress;
  readonly IMAGES_URL = IMAGES_URL;

  constructor(private taskService: TaskService) {}

  @Input()
  set task(value: Task) {
    this._task = value;
  }

  get task() {
    return this._task;
  }

  set progress(value) {
    this._progress = value;
  }

  get progress() {
    return this._progress;
  }

  ngOnInit(): void {
    // this.progress = this.taskService.getTaskProgressOld(this.task);
  }

  ngOnDestroy(): void {}
}
