import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';
import { HttpClientModule } from '@angular/common/http';

import { SecondarySidebarComponent } from './secondary-sidebar/secondary-sidebar.component';

import { QuickviewService } from './quickview/quickview.service';
import { ModalModule, PaginationModule, ProgressbarModule, TypeaheadModule } from 'ngx-bootstrap';

import { ParallaxDirective } from './parallax/parallax.directive';
import { FormGroupDefaultDirective } from './forms/form-group-default.directive';
import { ViewDirective } from './view/view.directive';

import { pgCollapseModule } from './collapse/collapse.module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ContainerComponent } from './container/container.component';
import { pageContainer } from './container/page-container.component';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { MenuAltComponent } from './menu/menu-alt.component';
import { MenuIconComponent } from './menu/menu-icon.component';

import { ListItemComponent } from './list-view/list-item/list-item.component';
import { ListViewContainerComponent } from './list-view/list-view-container/list-view-container.component';
import { pgRetinaDirective } from './retina/retina.directive';
import { NvD3Module } from 'ngx-nvd3';
import { NgxEchartsModule } from 'ngx-echarts';
import { pgCardModule } from './card/card.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ResponsiveModule } from 'ngx-responsive';
import { NgxMaskModule } from 'ngx-mask';
import { pgDatePickerModule } from '@Mesh/@pages/components/datepicker/datepicker.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { pgSelectModule } from '@Mesh/@pages/components/select/select.module';
import { pgPaginationModule } from '@Mesh/@pages/components/pagination/pagination.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SvgIconComponent } from '@Mesh/utils/svg-icon/svg-icon.component';
import { SearchOverlayComponent } from '@Mesh/@pages/components/search-overlay/search-overlay.component';
import { AccordionComponent } from '@Mesh/@pages/components/accordion/accordion.component';
import { QuickviewComponent2 } from './quickview/quickview.component';
import { BreadcrumbComponent } from '@Mesh/@pages/components/breadcrumb/breadcrumb.component';
import { ButtonDirective } from '@Mesh/shared/directives/button.directive';
import { SafePipe } from '@Mesh/shared/pipes/safe.pipe';
import { NumberFormatPipe } from '@Mesh/shared/pipes/number-format.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ObserversModule,
    TypeaheadModule.forRoot(),
    pgCollapseModule.forRoot(),
    PerfectScrollbarModule,
    RouterModule,
    pgCardModule,
    NvD3Module,
    NgxEchartsModule,
    FullCalendarModule,
    SwiperModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxUiLoaderModule,
    ResponsiveModule.forRoot(),
    NgxMaskModule.forRoot(),
    pgDatePickerModule,
    NgxDaterangepickerMd.forRoot(),
    FlexLayoutModule,
    pgSelectModule,
    FormsModule,
    PaginationModule,
    pgPaginationModule,
    ProgressbarModule,
    InfiniteScrollModule,
  ],
  declarations: [
    SecondarySidebarComponent,
    SearchOverlayComponent,
    ParallaxDirective,
    FormGroupDefaultDirective,
    ViewDirective,
    BreadcrumbComponent,
    ContainerComponent,
    pageContainer,
    MenuComponent,
    MenuAltComponent,
    AccordionComponent,
    MenuIconComponent,
    ListItemComponent,
    ListViewContainerComponent,
    pgRetinaDirective,
    SvgIconComponent,
    QuickviewComponent2,
    ButtonDirective,
    SafePipe,
    NumberFormatPipe,
  ],
  exports: [
    SecondarySidebarComponent,
    SearchOverlayComponent,
    ParallaxDirective,
    FormGroupDefaultDirective,
    ViewDirective,
    ContainerComponent,
    AccordionComponent,
    pageContainer,
    MenuComponent,
    MenuAltComponent,
    MenuIconComponent,
    ListItemComponent,
    ListViewContainerComponent,
    pgRetinaDirective,
    SvgIconComponent,
    ButtonDirective,
    SafePipe,
    NumberFormatPipe,
  ],
  providers: [
    QuickviewService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
})
export class SharedModule {}
