import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  AddUserError,
  AddUserRequest,
  AddUserSuccess,
  CheckExistenceUserError,
  CheckExistenceUserRequest,
  CheckExistenceUserSuccess,
  ClientIdsForActiveUsersError,
  ClientIdsForActiveUsersRequest,
  ClientIdsForActiveUsersSuccess,
  InvitationAddNewUserError,
  InvitationAddNewUserRequest,
  InvitationAddNewUserSuccess,
  ResetUsersValue,
  SendSmsRemoveUserError,
  SendSmsRemoveUserRequest,
  SendSmsRemoveUserSuccess,
  SetUsersTotalCount,
  UpdateAvatar,
  UpdateAvatarError,
  UpdateAvatarSuccess,
  UpdatePermissionsError,
  UpdatePermissionsRequest,
  UpdatePermissionsSuccess,
  UpdateUserError,
  UpdateUserRequest,
  UpdateUserSuccess,
  UserActionsTypes,
  UsersLoadError,
  UsersLoadPageRequest,
  UsersLoadPageRequestFailure,
  UsersLoadRequest,
  UsersLoadSuccess,
  VerifyAddNewUserError,
  VerifyAddNewUserRequest,
  VerifyAddNewUserSuccess,
  VerifyRemoveUserError,
  VerifyRemoveUserRequest,
  VerifyRemoveUserSuccess,
} from './user.actions';
import { UserService } from '@Mesh/core/services';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { userSelectors } from './user.selectors';
import { GetUserCriteria, PostUpdateUser } from '@Mesh/core/models/request';
import { outletSelectors } from '../outlet/outlet.selectors';
import { loginSelectors } from '../login/login.selectors';
import { User } from '@Mesh/core/models/user';
import { OutletActionsTypes, OutletSetActive, OutletSetActiveId } from '../outlet/outlet.actions';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private userService: UserService, private store$: Store<AppState>) {}

  @Effect()
  load: Observable<Action> = this.actions$.pipe(
    ofType<UsersLoadRequest>(UserActionsTypes.UsersLoadRequest),
    switchMap(({ payload }) => {
      return this.userService.getUsers(payload).pipe(
        mergeMap((response) => [
          new SetUsersTotalCount({ totalCount: response.totalElements }),
          new UsersLoadSuccess({ users: response.content, totalPages: response.totalPages, currentPage: 0 }),
        ]),
        catchError((error) => of(new UsersLoadError(error)))
      );
    })
  );

  @Effect()
  loadPage: Observable<Action> = this.actions$.pipe(
    ofType<UsersLoadPageRequest>(UserActionsTypes.UsersLoadPageRequest),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    withLatestFrom(this.store$.select(userSelectors.users)),
    switchMap(([[param, activeOutlet], users]) => {
      const criterUsersCriteriaia: Partial<GetUserCriteria> = {
        page: param.body.page,
        addressSapId: [activeOutlet?.addressSapId],
        size: param.body.itemsPerPage,
        clientSapId: activeOutlet?.clientSapId,
        extendedFormat: true,
      };
      return this.userService.getUsers(criterUsersCriteriaia).pipe(
        mergeMap((response) => {
          const newUsersArr = [...users, ...response.content] as User[];
          return [
            new SetUsersTotalCount({ totalCount: response.totalElements }),
            new UsersLoadSuccess({ users: newUsersArr, totalPages: response.totalPages, currentPage: param.body.page }),
          ];
        }),
        catchError((error) => of(new UsersLoadPageRequestFailure(error)))
      );
    })
  );

  @Effect()
  addUser: Observable<Action> = this.actions$.pipe(
    ofType<AddUserRequest>(UserActionsTypes.AddUserRequest),
    switchMap((action) => {
      return this.userService.addUser(action.payload).pipe(
        map((user) => new AddUserSuccess({ user })),
        catchError((error) => of(new AddUserError(error)))
      );
    })
  );

  @Effect()
  sendSmsRemoveUser: Observable<Action> = this.actions$.pipe(
    ofType<SendSmsRemoveUserRequest>(UserActionsTypes.SendSmsRemoveUserRequest),
    switchMap((action) => {
      return this.userService.sendSmsRemoveUser(action.payload).pipe(
        map((res) => new SendSmsRemoveUserSuccess()),
        catchError((error) => of(new SendSmsRemoveUserError(error)))
      );
    })
  );

  @Effect()
  checkExistenceUser: Observable<Action> = this.actions$.pipe(
    ofType<CheckExistenceUserRequest>(UserActionsTypes.CheckExistenceUserRequest),
    switchMap((action) => {
      return this.userService.checkExistenceUser(action.payload.phoneNumber).pipe(
        map((res) => new CheckExistenceUserSuccess({ canBeAuthorized: res.canBeAuthorized })),
        catchError((error) => of(new CheckExistenceUserError(error)))
      );
    })
  );

  @Effect()
  verifyAddUser: Observable<Action> = this.actions$.pipe(
    ofType<VerifyAddNewUserRequest>(UserActionsTypes.VerifyAddNewUserRequest),
    switchMap((action) => {
      return this.userService.verifyAddUser(action.payload).pipe(
        map((user) => new VerifyAddNewUserSuccess()),
        catchError((error) => of(new VerifyAddNewUserError(error)))
      );
    })
  );

  @Effect()
  verifyRemoveUser: Observable<Action> = this.actions$.pipe(
    ofType<VerifyRemoveUserRequest>(UserActionsTypes.VerifyRemoveUserRequest),
    switchMap((action) => {
      return this.userService.verifyRemoveUser(action.payload).pipe(
        map((user) => new VerifyRemoveUserSuccess()),
        catchError((error) => of(new VerifyRemoveUserError(error)))
      );
    })
  );

  @Effect()
  sendRequestAddNewUser: Observable<Action> = this.actions$.pipe(
    ofType<InvitationAddNewUserRequest>(UserActionsTypes.InvitationAddNewUserRequest),
    switchMap((action) => {
      return this.userService.sendSmsUser(action.payload).pipe(
        map((user) => new InvitationAddNewUserSuccess()),
        catchError((error) => of(new InvitationAddNewUserError(error)))
      );
    })
  );

  @Effect()
  updateUser: Observable<Action> = this.actions$.pipe(
    ofType<UpdateUserRequest>(UserActionsTypes.UpdateUserRequest),
    switchMap((action) => {
      return this.userService.updateUser(action.payload.userId, action.payload.data).pipe(
        map((user) => new UpdateUserSuccess({ user })),
        catchError((error) => of(new UpdateUserError(error)))
      );
    })
  );

  @Effect()
  updatePermissions: Observable<Action> = this.actions$.pipe(
    ofType<UpdatePermissionsRequest>(UserActionsTypes.UpdatePermissionsRequest),
    switchMap((action) => {
      return this.userService.upsertUserPermissions(action.payload.userId, action.payload.data).pipe(
        map((user) => new UpdatePermissionsSuccess({ user })),
        catchError((error) => of(new UpdatePermissionsError(error)))
      );
    })
  );

  @Effect()
  getClientIdsForActiveUsers: Observable<Action> = this.actions$.pipe(
    ofType<ClientIdsForActiveUsersRequest>(UserActionsTypes.ClientIdsForActiveUsersRequest),
    switchMap((action) => {
      return this.userService.getClientIdsForActiveUsers().pipe(
        map((res) => new ClientIdsForActiveUsersSuccess({ clientSapIds: res.clientSapIds })),
        catchError((error) => of(new ClientIdsForActiveUsersError(error)))
      );
    })
  );

  @Effect()
  changeOutlet: Observable<Action> = this.actions$.pipe(
    ofType<OutletSetActive | OutletSetActiveId>(OutletActionsTypes.OutletSetActive, OutletActionsTypes.OutletSetActiveId),
    map(() => {
      return new ResetUsersValue();
    })
  );

  @Effect()
  loadActiveOrdersOnReset: Observable<Action> = this.actions$.pipe(
    ofType<ResetUsersValue>(UserActionsTypes.ResetUsersValue),
    map(() => {
      return new UsersLoadPageRequest({ page: 0, itemsPerPage: 10 });
    })
  );

  @Effect()
  changeUserAvatar: Observable<Action> = this.actions$.pipe(
    ofType<UpdateAvatar>(UserActionsTypes.UpdateAvatar),
    withLatestFrom(this.store$.select(loginSelectors.selectUser)),
    switchMap(([action, user]) => {
      return this.userService.changeAvatar(action.payload.file).pipe(
        switchMap((res) => {
          const body = { avatar: res.relativeUrl } as PostUpdateUser;
          return this.userService.updateUser(user.id, body).pipe(
            map(() => new UpdateAvatarSuccess({ content: res })),
            catchError((error) => of(new UpdateAvatarError(error)))
          );
        })
      );
    })
  );
}
