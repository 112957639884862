import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DelayedOrderNotifyDropComponent } from './delayed-order-notify-drop.component';

@NgModule({
  declarations: [DelayedOrderNotifyDropComponent],
  exports: [DelayedOrderNotifyDropComponent],
  entryComponents: [DelayedOrderNotifyDropComponent],
  imports: [CommonModule],
})
export class DelayedOrderNotifyDropModule {}
