import { IMAGES_URL } from '@Env/environment';
import { Product } from '@Mesh/core/models/product';
import { CatalogHelper } from '@Mesh/shared/helpers/catalog.helper';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-basket-task-leftover-products-item',
  templateUrl: './basket-task-leftover-products-item.component.html',
  styleUrls: ['./basket-task-leftover-products-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BasketTaskLeftoverProductsItemComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: BasketTaskLeftoverProductsItemComponent,
    },
  ],
})
export class BasketTaskLeftoverProductsItemComponent implements ControlValueAccessor, Validator {
  @Input() product: Product;
  @Input() count: number = 1;
  quantity = 0;
  @Input() min = 0;
  @Input() max: number;
  readonly IMAGES_URL = IMAGES_URL;

  constructor(private catalogHelper: CatalogHelper) {}

  onChange = (quantity) => {};

  onTouched = () => {};

  touched = false;

  @Input() disabled = false;

  onAdd() {
    this.markAsTouched();
    if (!this.disabled) {
      this.quantity += this.count;
      this.onChange(this.quantity);
    }
  }

  onRemove() {
    this.markAsTouched();
    if (!this.disabled) {
      this.quantity -= this.count;
      this.onChange(this.quantity);
    }
  }

  setQuantity() {
    this.onChange(this.quantity);
  }

  writeValue(quantity: number) {
    this.quantity = quantity;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const quantity = control.value;
    if (quantity < 0) {
      return {
        mustBePositive: {
          quantity,
        },
      };
    }
    return null;
  }
}
