import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'cartGroupDate',
})
export class CartGroupDatePipe implements PipeTransform {
  transform(value: any, groupByKey: string) {
    const groupList: any[] = [];
    const groupedElements: any = {};

    (value || []).forEach((obj: any) => {
      const c = obj[groupByKey] !== null ? moment(obj[groupByKey]).startOf('day').format() : 'notDate';

      if (!(c in groupedElements)) {
        groupedElements[c] = [];
      }
      groupedElements[c].push(obj);
    });

    for (const prop in groupedElements) {
      if (groupedElements.hasOwnProperty(prop)) {
        groupList.push({
          key: prop,
          list: groupedElements[prop],
        });
      }
    }
    return groupList;
  }
}
