import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { outletReducer } from '@Mesh/store/outlet/outlet.reducers';
import { OutletEffects } from '@Mesh/store/outlet/outlet.effects';
import { OUTLET_CONFIG_TOKEN, OUTLET_LOCAL_STORAGE_KEY, OUTLET_STORAGE_KEYS } from '@Mesh/store/outlet/outlet.token';
import { LocalStorageService } from '@Mesh/core/services/local-storage';
import { storageMetaReducer } from '@Mesh/store/storage-metareducer';
import { StoreModule } from '@ngrx/store';

export function getOutletConfig(saveKeys: string[], localStorageKey: string, storageService: LocalStorageService) {
  return { metaReducers: [storageMetaReducer(saveKeys, localStorageKey, storageService)] };
}

@NgModule({
  imports: [StoreModule.forFeature('outlet', outletReducer, OUTLET_CONFIG_TOKEN), EffectsModule.forFeature([OutletEffects])],
  providers: [
    { provide: OUTLET_STORAGE_KEYS, useValue: ['active'] },
    { provide: OUTLET_LOCAL_STORAGE_KEY, useValue: 'outlet__storage' },
    {
      provide: OUTLET_CONFIG_TOKEN,
      deps: [OUTLET_STORAGE_KEYS, OUTLET_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getOutletConfig,
    },
  ],
})
export class OutletStoreModule {}
