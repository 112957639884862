import { withLoadable } from '@Mesh/utils/loadable/with-loadable';
import { ActionsUnion, OrderActionsTypes } from '@Mesh/store/order/order.actions';
import { initialState, OrderState } from '@Mesh/store/order/order.state';

function baseOrderReducer(state = initialState, action: ActionsUnion): OrderState {
  switch (action.type) {
    case OrderActionsTypes.OrderLoadSuccess:
      return {
        ...state,
        orders: action.payload.orders,
        totalPages: action.payload.totalPages,
      };
    case OrderActionsTypes.OrderLoadError:
      return {
        ...state,
        error: action.error,
      };
    case OrderActionsTypes.OrderSetTotalCount:
      return {
        ...state,
        totalCount: action.payload.totalCount,
      };
    case OrderActionsTypes.OrderSetActive:
      return {
        ...state,
        selectedDocNumber: action.payload.docNumber,
      };
    case OrderActionsTypes.OrderDetailsLoadRequest:
      return {
        ...state,
        orderDetails: [],
      };
    case OrderActionsTypes.OrderDetailsLoadSuccess:
      return {
        ...state,
        orderDetails: action.payload.orderDetail,
      };
    case OrderActionsTypes.OrderDetailsLoadError:
      return {
        ...state,
        error: action.error,
      };
    case OrderActionsTypes.OrderHistoryLoadPageRequest:
      return {
        ...state,
        ordersHistoryLoading: true,
      };
    case OrderActionsTypes.OrderHistoryLoadSuccess:
      return {
        ...state,
        historyOrders: action.payload.orders,
        totalHistoryCount: action.payload.total,
        totalPages: action.payload.totalpages,
        hisrotyOrdersCurrentPage: action.payload.currentPage,
        ordersHistoryLoading: false,
      };
    case OrderActionsTypes.OrderHistoryLoadPageFailure:
      return {
        ...state,
        ordersHistoryLoading: false,
      };
    case OrderActionsTypes.OrderHistoryLoadError:
      return {
        ...state,
        error: action.error,
      };

    case OrderActionsTypes.ResetOrdersValue:
      return {
        ...state,
        orders: [],
        historyOrders: [],
        totalCount: 0,
        orderDetails: [],
        selectedDocNumber: null,
        errors: null,
        totalHistoryCount: 0,
        totalPages: 0,
        activeOrdersCurrentPage: 0,
        hisrotyOrdersCurrentPage: 0,
        ordersHistoryLoading: false,
        delayedOrders: [],
        totalCountDelayOrder: 0,
        editedDelayedOrder: null,
        ordersProducts: {},
        ordersUsers: {},
        errorsDelayedOrder: null,
        completedUpdateDelayedOrder: false,
        completedRequestInvoiceDelayedOrder: false,
      };

    case OrderActionsTypes.OrderSetConfirmedSuccess:
      const findOrder = (state.orders || []).find((order) => order.docNumber === action.payload.docNumber);
      return {
        ...state,
        historyOrders: [findOrder, ...state.historyOrders],
        orders: (state.orders || []).filter((order) => order.docNumber !== action.payload.docNumber),
        totalCount: state.totalCount - 1,
      };

    case OrderActionsTypes.LoadBonusesRecommendedOrdersSuccess:
      return {
        ...state,
        bonusRecommendedOrders: action.payload,
      };

    case OrderActionsTypes.LoadBonusesRecommendedOrdersByFiltersSuccess:
      return {
        ...state,
        bonusRecommendedOrdersByCatalog: action.payload,
        bonusRecommendedOrders: action.payload,
      };

    case OrderActionsTypes.LoadBonusesRecommendedOrdersWithoutFiltersSuccess:
      return {
        ...state,
        bonusRecommendedOrdersByCatalog: action.payload,
        bonusRecommendedOrders: action.payload,
      };

    case OrderActionsTypes.LoadBonusesRecommendedOrdersByFiltersFailure:
      return {
        ...state,
        bonusRecommendedOrdersByCatalog: null,
      };

    case OrderActionsTypes.LoadBonusesRecommendedOrdersWithoutFiltersFailure:
      return {
        ...state,
        bonusRecommendedOrdersByCatalog: null,
      };

    case OrderActionsTypes.GetDelayedOrdersSuccess:
      return {
        ...state,
        delayedOrders: action.payload.content,
        totalCountDelayOrder: action.payload.totalElements,
      };

    case OrderActionsTypes.LoadDelayedOrderSuccess:
      return {
        ...state,
        editedDelayedOrder: action.payload,
      };

    case OrderActionsTypes.RemoveDelayedOrderSuccess:
      return {
        ...state,
        completedUpdateDelayedOrder: true,
        delayedOrders: (state.delayedOrders || []).filter((order) => order.id !== action.payload.delayedOrderId),
        totalCountDelayOrder: state.totalCountDelayOrder - 1,
      };

    case OrderActionsTypes.UpdateDelayedOrderSuccess:
      return {
        ...state,
        completedUpdateDelayedOrder: true,
        delayedOrders: state.delayedOrders.map((item) => (item.id === action.payload.id ? { ...item, ...action.payload } : item)),
      };

    case OrderActionsTypes.UpdateDelayedOrderError:
      return {
        ...state,
        completedUpdateDelayedOrder: false,
        errorsDelayedOrder: action.error,
      };

    case OrderActionsTypes.ResetUpdateDelayedOrder:
      return {
        ...state,
        completedUpdateDelayedOrder: false,
        errorsDelayedOrder: null,
      };

    case OrderActionsTypes.RequestInvoiceDelayedOrderSuccess:
      return {
        ...state,
        completedRequestInvoiceDelayedOrder: true,
        delayedOrders: state.delayedOrders.map((item) =>
          item.id === action.payload.delayedOrderId ? { ...item, shouldRequestInvoice: true } : item
        ),
      };

    case OrderActionsTypes.RequestInvoiceDelayedOrderError:
      return {
        ...state,
        completedRequestInvoiceDelayedOrder: false,
      };

    case OrderActionsTypes.RequestInvoiceDelayedOrderReset:
      return {
        ...state,
        completedRequestInvoiceDelayedOrder: false,
      };

    case OrderActionsTypes.LoadOrderProductsSuccess:
      return {
        ...state,
        ordersProducts: (action.payload || []).reduce((r, e) => {
          r[e.materialId] = e;
          return r;
        }, {}),
      };

    case OrderActionsTypes.LoadOrderUsersSuccess:
      return {
        ...state,
        ordersUsers: (action.payload || []).reduce((r, e) => {
          r[e.id] = e;
          return r;
        }, {}),
      };
    default:
      return state;
  }
}

export function orderReducer(state: OrderState, action: ActionsUnion): OrderState {
  return withLoadable(baseOrderReducer, {
    loadingActionType: [OrderActionsTypes.OrderLoadRequest, OrderActionsTypes.OrderDetailsLoadRequest],
    successActionType: [OrderActionsTypes.OrderLoadSuccess, OrderActionsTypes.OrderDetailsLoadSuccess],
    errorActionType: [OrderActionsTypes.OrderLoadError, OrderActionsTypes.OrderDetailsLoadError],
  })(state, action);
}
