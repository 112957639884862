import { Injectable } from '@angular/core';
import { Howl } from 'howler';

export const Sounds = {
  CHAT_MESSAGE_INCOMING: 'CHAT_MESSAGE_INCOMING',
  CHAT_MESSAGE_OUTCOMING: 'CHAT_MESSAGE_OUTCOMING',
  CHAT_MESSAGE_LIKED: 'CHAT_MESSAGE_LIKED',
  CHAT_MESSAGE_DELETE: 'CHAT_MESSAGE_DELETE',
  CHAT_TEXT_TYPING: 'CHAT_TEXT_TYPING',
  NOTIFICATION: 'NOTIFICATION',
};
const soundsAssets = {
  CHAT_MESSAGE_INCOMING: '/assets/sound/chat_message_incoming',
  CHAT_MESSAGE_OUTCOMING: '/assets/sound/chat_message_outcoming',
  CHAT_MESSAGE_LIKED: '/assets/sound/chat_message_like',
  CHAT_MESSAGE_DELETE: '/assets/sound/chat_message_delete',
  CHAT_TEXT_TYPING: '/assets/sound/chat_text_typing',
  NOTIFICATION: '/assets/sound/chat_message_incoming',
};

@Injectable({ providedIn: 'root' })
export class SoundService {
  sounds = {};

  constructor() {
    Object.keys(Sounds).forEach((key) => {
      this.sounds[Sounds[key]] = new Howl({
        src: [soundsAssets[key] + '.mp3', soundsAssets[key] + '.aac', soundsAssets[key] + '.ogg'],
      });
    });
  }

  play(id) {
    if (!this.sounds[id].playing()) {
      this.sounds[id].play();
    }
  }
}
