import { Deserializable } from './deserializable';
import 'moment-duration-format';
import { AsyncItem } from '@Mesh/core/models/async-item';
import moment from 'moment';
import { User } from '@Mesh/core/models/user';

export class News implements Deserializable {
  id: number;
  image?: string;
  image_text?: string;
  name?: string;
  author: User;
  preview_text?: string;
  content?: JSON;
  tags?: any[];
  published_at?: string;
  likes_count: number;
  num_users: number;
  liked: boolean;
  num_comments: number;
  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  get publishedAtDate() {
    return moment(this.published_at, 'DD.MM.YYYY hh:mm:ss');
  }

  get publishedFromNow() {
    return moment(this.publishedAtDate).fromNow();
  }
}

export type AsyncNews = AsyncItem<News>;
