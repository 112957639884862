import { Injectable } from '@angular/core';
import { Plan } from '@Mesh/core/models/plan';
import { id } from '@swimlane/ngx-datatable';

export interface IPlanGroup {
  name: string;
  sapId: string;
  dictionary: string[];
  plans: Plan[];
}

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  _planGroups: IPlanGroup[] = [
    {
      name: 'Табак',
      sapId: 'TAB_ASS',
      dictionary: ['топ', 'табак', 'drive', 'itg', 'jti', 'pmi', 'зажигалки'],
      plans: [],
    },
    {
      name: 'РРП',
      sapId: 'RRP',
      dictionary: ['iqos'],
      plans: [],
    },
    {
      name: 'Напитки',
      sapId: 'DRINK',
      dictionary: ['bull'],
      plans: [],
    },
    {
      name: 'Другое',
      sapId: 'NOT_PROD',
      dictionary: [],
      plans: [],
    },
  ];

  get planGroups() {
    return this._planGroups;
  }

  salePlansMapping(plans: Plan[]): IPlanGroup[] {
    let planGroup = [];
    planGroup = JSON.parse(JSON.stringify(this._planGroups));

    plans.forEach((plan) => {
      const { isMoney, quantRequire, delivQount, delivAmount } = plan as any;
      let total, fact, need, unit, progress;

      if (isMoney === true) {
        need = quantRequire - delivAmount < 0 ? 0 : quantRequire - delivAmount;
        fact = delivAmount;
        total = quantRequire;
      } else {
        need = quantRequire * 20 - delivQount;
        fact = delivQount;
        total = quantRequire * 20;
      }
      progress = (fact * 100) / total;
      unit = isMoney ? 'руб.' : 'шт.';

      const copyPlan = { ...plan, need, fact, total, unit, progress };

      let idx = planGroup.length - 1;
      planGroup.forEach((cat, index) => {
        const include = cat.dictionary.some((el) => plan.planName.toLowerCase().includes(el));
        if (include) {
          idx = index;
          return;
        }
      });
      planGroup[idx].plans.push(copyPlan);
    });
    return planGroup;
  }
}
