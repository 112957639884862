import * as moment from 'moment';

export function stringifyObject(obj: unknown): { [key: string]: string } {
  if (!obj) {
    return null;
  }
  const stringData = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== undefined) {
      stringData[key] = obj[key].toString();
    }
  });
  return stringData;
}

export function getGetStartDateAndFinishDate(): string[] {
  const endDate = moment();
  const startDate = moment().subtract(1, 'year');
  return [startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]];
}

export function getGetStartDateAndNextMonthEndDate(): string[] {
  const endDate = moment().add(1, 'months');
  const startDate = moment().subtract(1, 'year');
  return [startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]];
}
