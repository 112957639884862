import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { AuthenticationService } from '@Mesh/core/services';
import { NotificationService } from '@Mesh/core/services/notification.service';
import {
  NotificationActionsTypes,
  NotificationsLoadError,
  NotificationsLoadRequest,
  NotificationsLoadSuccess,
  ReadNotificationError,
  ReadNotificationRequest,
  ReadNotificationSuccess,
  UpdatePushTokenError,
  UpdatePushTokenRequest,
  UpdatePushTokenSuccess,
} from './notification.actions';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private notificationService: NotificationService,
    private authService: AuthenticationService
  ) {}

  @Effect()
  load: Observable<Action> = this.actions$.pipe(
    ofType<NotificationsLoadRequest>(NotificationActionsTypes.NotificationsLoadRequest),
    switchMap((data) =>
      this.notificationService.getNotifications(data.payload.params).pipe(map((res) => ({ api: res, refresh: data.payload.refresh })))
    ),
    map((res) => new NotificationsLoadSuccess({ notifications: res.api, refresh: res.refresh })),
    catchError((error) => of(new NotificationsLoadError(error)))
  );

  @Effect()
  readNotification: Observable<Action> = this.actions$.pipe(
    ofType<ReadNotificationRequest>(NotificationActionsTypes.ReadNotificationRequest),
    mergeMap((data) => this.notificationService.readNotification(data.payload.id)),
    map((res) => new ReadNotificationSuccess({ notification: res })),
    catchError((error) => of(new ReadNotificationError(error)))
  );

  @Effect()
  updateNotificationToken: Observable<Action> = this.actions$.pipe(
    ofType<UpdatePushTokenRequest>(NotificationActionsTypes.UpdatePushTokenRequest),
    switchMap((data) => this.notificationService.updateToken(data.payload.params)),
    map((res) => new UpdatePushTokenSuccess({ pushToken: res })),
    catchError((error) => of(new UpdatePushTokenError(error)))
  );
}
